import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../../../contexts/UserContext";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const NavWrapper = styled.div`
  text-align: right;
  margin-bottom: 3rem;

  & > .mobile-navigation {
    display: none;
  }

  @media only screen and (max-width: 430px) {
    & > .mobile-navigation {
      display: block;

      & img {
        max-width: 125px;
        margin-top: 25px;
      }
    }

    & > .desktop-navigation {
      display: none;
    }
  }
`;

const Main = ({ showHeaderLogo = true }) => {
  const { user, signOut } = useUser();
  const navigate = useNavigate();

  const navLinks = {
    links: [
      {
        label: "About Us",
        url: "https://www.mananahelp.com/about-us",
        className: "btn btn-link",
      },
      {
        label: "Become A Helper",
        url: "/become-a-helper",
        onClick: () => {
          /* window._gtag("event", "conversion", {
            send_to: "AW-11127239856/NsVNCLXXlpUYELDp8Lkp",
          }); */
          navigate("/become-a-helper");
        },
        className: "btn btn-outline-primary ps-4 pe-4",
      },
      {
        label: "Find Help",
        url: "/find-help",
        className: "btn btn-primary ps-4 pe-4",
      },
    ],
    dropdown: [
      {
        label: "Sign In",
        url: "/sign-in",
      },
      {
        divider: true,
        mobile: false,
      },
      {
        label: "Become A Helper",
        url: "/become-a-helper",
        onClick: () => {
          /* window._gtag("event", "conversion", {
            send_to: "AW-11127239856/NsVNCLXXlpUYELDp8Lkp",
          }); */
          navigate("/become-a-helper");
        },
        mobile: false,
      },
      {
        label: "Find Help",
        url: "/find-help",
        mobile: false,
      },
    ],
  };

  if (user) {
    navLinks.dropdown = [
      {
        label: user.userType === "customer" ? "Bookings" : "Dashboard",
        url: user.userType === "customer" ? "/bookings" : "/dashboard",
      },
      {
        label: "Profile",
        url: "/profile",
      },
      {
        divider: true,
      },
      {
        label: "Sign Out",
        url: "/sign-out",
        onClick: () => signOut(),
      },
    ];

    // remove the last two nav links
    navLinks.links.pop();
    navLinks.links.pop();

    // overwrite the dropdown
    if (user.userType === "admin") {
      navLinks.dropdown = [
        {
          label: "Admin Dashboard",
          url: "/admin",
        },
        {
          label: "Sign Out",
          url: "/sign-out",
          onClick: () => signOut(),
        },
      ];
    }
  }

  return (
    <NavWrapper>
      <Mobile
        user={user}
        signOut={signOut}
        links={navLinks}
        showLogo={showHeaderLogo}
      />
      <Desktop
        user={user}
        signOut={signOut}
        links={navLinks}
        showLogo={showHeaderLogo}
      />
    </NavWrapper>
  );
};

export default Main;
