import React from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Prompt from "../../common/Prompt";

const AboutMe = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    about: get(user, "helper.profile.description") || "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  return (
    <SubInternalLayout title={`About me`}>
      <Helmet>
        <title>About Me | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <p className="text-muted body1">
        Tell us a little bit about yourself. Why do you want to be a Manana
        helper? When you aren't busy working, how do you like to spend your
        time?
      </p>

      <FormGroup>
        <FormControl
          as="textarea"
          name="about"
          className="form-control"
          placeholder="Tell us a bit about you."
          onChange={onChange}
          value={formData.about}
          style={{ minHeight: 120 }}
          maxLength={500}
        />
      </FormGroup>
      <p className="text-end m-0 p-0">
        <strong>{formData.about.length}/500 CHAR MAX</strong>
      </p>

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "helper.profile.description":
                formData.about.length === 0 ? null : formData.about, // convert back to null on empty
            })
          }
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default AboutMe;
