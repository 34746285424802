import React from "react";
import { Form, Button } from "react-bootstrap";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useFieldToggle } from "../../../hooks/useFieldToggle";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import { TextLabel } from "../../common/labels";

const PersonalInfo = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange } = useFormData({
    name: get(user, "name") || "",
    email: get(user, "email") || "",
    phone: get(user, "settings.mobile") || "",
  });
  const { isToggled, toggleBtn } = useFieldToggle();

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your chnages have been successfully saved.");
    updateUser(res);
  });

  return (
    <SubInternalLayout title={`Personal Info`}>
      <Helmet>
        <title>Personal Information | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("name")}
          <Form.Label>Name</Form.Label>
          {!isToggled("name") ? (
            <TextLabel val={formData.name} />
          ) : (
            <Form.Control
              name="name"
              placeholder="name"
              onChange={onChange}
              value={formData.name}
              required
            />
          )}
        </Form.Group>
      </div>
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("email")}
          <Form.Label>Email</Form.Label>
          {!isToggled("email") ? (
            <TextLabel val={formData.email} />
          ) : (
            <Form.Control
              type="email"
              name="email"
              placeholder="email address"
              onChange={onChange}
              value={formData.email}
              required
            />
          )}
        </Form.Group>
      </div>
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("phone")}
          <Form.Label>Phone number</Form.Label>
          {!isToggled("phone") ? (
            <TextLabel val={formData.phone} />
          ) : (
            <Form.Control
              as={InputMask}
              mask="999-999-9999"
              type="text"
              name="phone"
              placeholder="123-123-1234"
              onChange={onChange}
              value={formData.phone}
            />
          )}
        </Form.Group>
      </div>

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              name: formData.name,
              email: formData.email,
              "settings.mobile": formData.phone,
            })
          }
          disabled={
            !hasChanged ||
            save.isLoading ||
            formData.name.length < 3 ||
            (formData.phone && !/\d{3}-\d{3}-\d{4}/.test(formData.phone))
          }
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default PersonalInfo;
