import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";

import PublicLayout from "../layout/PublicLayout";
import { removeCookie } from "../../lib/cookies";

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    if (error) {
      alert("Error triggered");
    }
  }, [error]);

  return (
    <PublicLayout header="Oh no!">
      <Container>
        <p>
          It looks like we encountered an error. Please use the button below to
          reset your experience and try again.
        </p>
        <p>
          <Button
            variant="danger"
            onClick={() => {
              window.localStorage.clear();
              window.sessionStorage.clear();
              removeCookie("token");
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        </p>
      </Container>
    </PublicLayout>
  );
};

export default ErrorFallback;
