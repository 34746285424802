import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import get from "lodash.get";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { Helmet } from "react-helmet-async";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useFieldToggle } from "../../../hooks/useFieldToggle";
import { useApiPut, useApiGet, useApiDelete } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import { TextLabel } from "../../common/labels";
import { ucwords } from "../../../lib/stringHelpers";

import AddBankAccountModal from "./AddBankAccountModal";

const PaymentSettings = () => {
  const { user, updateUser } = useUser();
  const [showBankAccountModal, setShowBankAccountModal] = useState(false);
  const { formData, hasChanged, onChange } = useFormData({
    standard: get(user, "helper.hourlyRates.standard") || "",
    specialized: get(user, "helper.hourlyRates.specialized") || "",
    overnight: get(user, "helper.hourlyRates.overnight") || "",
  });
  const queryClient = useQueryClient();

  const { isLoading, data: bankAccounts } = useApiGet(
    "bankAccounts",
    "/payments/connect-bank-accounts",
    undefined,
    {
      onError: (err) => toast.error(err.message),
      staleTime: 1000,
    }
  );

  const { isToggled, toggleBtn } = useFieldToggle({
    standard: formData.standard === "",
    specialized: formData.specialized === "",
    overnight: formData.overnight === "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
  });

  const deleteBankAccount = useApiDelete((res) => {
    toast.success("The selected bank account was successfully deleted.");
    queryClient.invalidateQueries("bankAccounts");
  });

  const renderBankAccounts = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }

    return bankAccounts.map((acct, i) => {
      return (
        <div key={i} className="field-list-item">
          <div className="float-end">
            <Button
              type="button"
              variant="link"
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this account?"
                  )
                ) {
                  deleteBankAccount.mutate(
                    `/payments/connect-bank-accounts/${acct.id}`
                  );
                }
              }}
              disabled={acct.default_for_currency}
            >
              <Delete />
            </Button>
          </div>
          <p className="body2">
            {acct.bank_name}{" "}
            {acct.default_for_currency ? (
              <label
                className="ms-3 label"
                style={{
                  background: "#ffc8b4",
                  borderRadius: "4px",
                  padding: "4px 8px",
                }}
              >
                DEFAULT
              </label>
            ) : null}
          </p>
          <p className="body1 text-muted">
            {ucwords(acct.account_holder_type)}...{acct.last4}
          </p>
        </div>
      );
    });
  };

  return (
    <SubInternalLayout title={`Payment Settings`}>
      <Helmet>
        <title>Payment Settings | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <p className="subtitle-2">DIRECT DEPOSIT ACCOUNTS</p>
      {renderBankAccounts()}
      <div className="mb-5">
        <Button
          variant="outline-primary"
          onClick={() => setShowBankAccountModal(true)}
          className="btn-outline-primary d-block w-100"
          size="sm"
        >
          <Add /> ADD A DIRECT DEPOSIT ACCOUNT
        </Button>
      </div>
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("standard")}
          <Form.Label>Rate for everyday tasks</Form.Label>
          {!isToggled("standard") ? (
            <TextLabel val={`$${formData.standard}`} />
          ) : (
            <Form.Control
              type="number"
              name="standard"
              placeholder="0.00"
              onChange={onChange}
              value={formData.standard}
            />
          )}
        </Form.Group>
      </div>
      {/*
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("specialized")}
          <Form.Label>Rate for specialized tasks</Form.Label>
          {!isToggled("specialized") ? (
            <TextLabel val={`$${formData.specialized}`} />
          ) : (
            <Form.Control
              type="number"
              name="specialized"
              placeholder="0.00"
              onChange={onChange}
              value={formData.specialized}
            />
          )}
        </Form.Group>
      </div> */}
      <div className="field-list-item">
        <Form.Group>
          {toggleBtn("overnight")}
          <Form.Label>Rate for overnight tasks</Form.Label>
          {!isToggled("overnight") ? (
            <TextLabel val={`$${formData.overnight || "-"}`} />
          ) : (
            <Form.Control
              type="number"
              name="overnight"
              placeholder="0.00"
              onChange={onChange}
              value={formData.overnight}
            />
          )}
        </Form.Group>
      </div>

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "helper.hourlyRates.standard": formData.standard,
              "helper.hourlyRates.specialized": formData.standard, // these should match since we're removing/deprecating specialized rates
              "helper.hourlyRates.overnight": formData.overnight,
            })
          }
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
      <AddBankAccountModal
        show={showBankAccountModal}
        onToggle={() => setShowBankAccountModal(false)}
        onSuccess={() => {
          toast.success("Your bank account has been successfully saved.");
          queryClient.invalidateQueries("bankAccounts");
        }}
      />
    </SubInternalLayout>
  );
};

export default PaymentSettings;
