import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { RRule } from "rrule";
import moment from "moment-timezone";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import ContentBlock from "../common/ContentBlock";
import { useFormData } from "../../hooks/useFormData";
import { useApiGet, useApiPost } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";

import HelperAvailabilityForm from "../customer/components/HelperAvailabilityForm";
import HelperFullProfile from "../helper/HelperFullProfile";

/*
  STEP 3.5
*/
const JobRequestHelperProfile = () => {
  const { job, saveJob } = useJob();
  const { title, setTitle } = usePage();
  const { helperId } = useParams();
  const navigate = useNavigate();
  const { formData, onChange } = useFormData({
    helpers: [],
    availabilityFilters: job.availability || null,
  });
  const [showAvail, setShowAvail] = useState(false);
  const [showIsAvailable, setShowIsAvailable] = useState(false);
  const [showNotAvailable, setShowNotAvailable] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  const { isLoading, data: user } = useApiGet(
    `helper-${helperId}`,
    `/helpers/${helperId}`,
    null,
    {
      onError: (err) => {
        toast.error(err.message);
        navigate(-1);
      },
    }
  );

  const checkAvailability = useApiPost(
    `/helpers/${helperId}/check-availability`,
    (resp) => {
      setIsAvailable(resp.length > 0);
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle("Who would you like to send a help request to?");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAvailable === false) {
      setShowNotAvailable(true);
    } else if (isAvailable === true) {
      setShowIsAvailable(true);
    }
  }, [isAvailable]);

  const selectHelper = (selected) => {
    let updates = {
      requestedHelpers: [...job.requestedHelpers, helperId],
      availability: {
        ...formData.availabilityFilters,
        startDate: selected.day,
        startTime: moment(selected.timeslots[0].start).format("HH:mm"),
        endDate: null,
        endTime: moment(selected.timeslots[0].end).format("HH:mm"),
        jobType: "One-Time",
        rrule: "",
        frequency: "weekly",
        weekdays: [],
      },
      _helpers: [...job._helpers, user],
    };

    // if the date changed, we need to remove all of the
    // helpers except this one and update the date on the job
    if (
      formData.availabilityFilters &&
      formData.availabilityFilters.startDate &&
      formData.availabilityFilters.startDate !== selected.day
    ) {
      // overwrite the existing selections
      updates.requestedHelpers = [helperId];
      updates._helpers = [user];
    }

    saveJob(updates);
    navigate("/find-help/helpers");
  };

  const getDateFromAvailability = () => {
    if (!job.availability || !job.availability.startDate) {
      return null;
    }

    if (job.availability.jobType === "One-Time") {
      return job.availability.startDate;
    }

    const ruleConfig = {
      freq: RRule.WEEKLY,
      interval: 1,
      dtstart: moment
        .utc(
          `${job.availability.startDate} ${job.availability.startTime}`,
          "YYYY-MM-DD HH:mm"
        )
        .toDate(),
      tzid: moment.tz.guess(),
    };

    const rule = new RRule(ruleConfig);
    const next = rule.after(moment.utc(ruleConfig.dtstart).toDate(), true);

    return moment(next).format("YYYY-MM-DD");
  };

  const renderProfile = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <div style={{ position: "relative" }}>
        <HelperAvailabilityForm
          show={showAvail}
          toggle={() => setShowAvail(!showAvail)}
          selections={formData.availabilityFilters}
          onApply={(newAvailFilters) => {
            onChange({
              target: {
                name: "availabilityFilters",
                value: newAvailFilters,
              },
            });
            checkAvailability.mutate(newAvailFilters);
          }}
          style={{ margin: "1rem -3rem" }}
        />
        <Button
          type="button"
          variant="link"
          onClick={() => navigate("/find-help/helpers")}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
        >
          <ArrowBack />
        </Button>

        <HelperFullProfile
          user={user}
          userSelDate={getDateFromAvailability()}
          onSelectHelper={(sel) => selectHelper(sel)}
        />
      </div>
    );
  };

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Select Help"}
      onStepBack={() => navigate("/find-help/services")}
      wrapInContentBlock={false}
    >
      <Helmet>
        <title>Helper Profile | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <ContentBlock>{renderProfile()}</ContentBlock>

      <Modal show={showIsAvailable} onHide={() => setShowIsAvailable(false)}>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-end">
            <Close onClick={() => setShowIsAvailable(false)} />
          </div>
          <p className="subtitle1 text-center bold mb-4">
            {user?.name} is available!
          </p>
          <p className="subtitle2">
            Keep in mind, you can send help requests to more than one helper.
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="primary"
              style={{ minWidth: 150 }}
              onClick={() => {
                setShowIsAvailable(false);
                selectHelper();
              }}
            >
              OKAY
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showNotAvailable} onHide={() => setShowNotAvailable(false)}>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-end">
            <Close onClick={() => setShowNotAvailable(false)} />
          </div>
          <p className="subtitle1 text-center bold mb-4">
            {user?.name} is not available
          </p>
          <p className="subtitle2">
            Check their availability tab to see when they may be available.
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="primary"
              style={{ minWidth: 150 }}
              onClick={() => {
                setShowNotAvailable(false);
              }}
            >
              OKAY
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </RegisterLayout>
  );
};

export default JobRequestHelperProfile;
