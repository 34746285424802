import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Badge, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";

import ProfilePicture from "../common/ProfilePicture";
import StarRating from "../common/StarRating";
import Tabs from "../common/Tabs";
import Calendar from "../common/Calendar";
import ReviewsList from "../common/ReviewsList";
import {
  ProfileHeader,
  ProfilePhoto,
  ProfileTitle,
  ProfileAvailability,
} from "./components/Helper.elements";
import { ucwords, nl2br } from "../../lib/stringHelpers";

const noop = () => {};

const HelperFullProfile = ({
  user,
  size = "sm",
  userSelDate = null,
  useTimeslotCheckboxes = false,
  btnLabel = "SELECT HELPER",
  selectable = true,
  onSelectHelper = noop,
  onCancel = noop,
}) => {
  const helper = user.helper;
  const profile = helper.profile;
  const rating = user._ratings || { score: 0, count: 0 };
  const [calDateSel, setCalDateSel] = useState({
    day: null,
    timeslots: [],
    selected: null,
  });

  const selectDate = (day) => {
    const timeslots = [];

    user._cache.forEach((cache) => {
      const dt = moment(cache.start).format("YYYY-MM-DD");
      if (dt === day) {
        timeslots.push(cache);
      }
    });

    setCalDateSel({ day, timeslots, selected: null });
  };

  useEffect(() => {
    if (!userSelDate) {
      return;
    }

    selectDate(userSelDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelDate]);

  const displayUserName = (user) => {
    if (user.userType === "system") {
      return "Manana";
    }

    return ucwords(user.name);
  };

  const renderAvailability = () => {
    const curDateVal = moment().valueOf();
    const disableBeforeDt = moment().add(24, "hours").startOf("day");
    const dates = [];

    user._cache.forEach((cache) => {
      const dt = moment(cache.start);
      const dtFormatted = dt.format("YYYY-MM-DD");
      if (
        !dates.includes(dtFormatted) &&
        dt.valueOf() >= disableBeforeDt.valueOf()
      ) {
        dates.push(dtFormatted);
      }
    });

    return (
      <div>
        <Calendar
          date={moment().toDate()}
          selectedDates={calDateSel.day ? [calDateSel.day] : []}
          isLoading={false}
          highlightDates={dates}
          onDateSelect={(day) => selectDate(day)}
          disableBeforeDate={disableBeforeDt.format("YYYY-MM-DD")}
        />

        {!calDateSel.day ? null : (
          <ProfileAvailability>
            {calDateSel.timeslots.length === 0 ? (
              <p className="text-center">
                No timeslots are available for this day.
              </p>
            ) : (
              <>
                {calDateSel.timeslots.map((slot, slotIdx) => {
                  const start = moment(slot.start);
                  const end = moment(slot.end);
                  const label = (
                    <>
                      <strong className="d-block">
                        {start.format("ddd, MMM Do")}
                      </strong>
                      <p className="text-muted">
                        Between {start.format("h:mm A")} and{" "}
                        {end.format("h:mm A")}
                      </p>
                    </>
                  );

                  if (!useTimeslotCheckboxes) {
                    return label;
                  }

                  return (
                    <Form.Group key={slotIdx}>
                      <Form.Check
                        type="checkbox"
                        id={slotIdx}
                        name={`slot-${slotIdx}`}
                        disabled={start.valueOf() < curDateVal}
                        label={label}
                        onChange={() => {
                          if (
                            calDateSel.selected &&
                            calDateSel.selected._id === slot._id
                          ) {
                            setCalDateSel({
                              ...calDateSel,
                              selected: null,
                            });
                          } else {
                            setCalDateSel({
                              ...calDateSel,
                              selected: slot,
                            });
                          }
                        }}
                        checked={
                          calDateSel.selected &&
                          calDateSel.selected._id === slot._id
                        }
                      />
                    </Form.Group>
                  );
                })}
                {selectable ? (
                  <Button
                    variant="primary"
                    size="lg"
                    className="w-100 mt-3 mb-3"
                    onClick={() => onSelectHelper(calDateSel)}
                    disabled={
                      !calDateSel.timeslots.length === 0 ||
                      (useTimeslotCheckboxes && !calDateSel.selected)
                    }
                  >
                    {btnLabel}
                  </Button>
                ) : null}
                {useTimeslotCheckboxes ? (
                  <Button
                    variant="link"
                    size="lg"
                    className="w-100 mb-3"
                    onClick={() => onCancel()}
                  >
                    CANCEL
                  </Button>
                ) : null}
              </>
            )}
          </ProfileAvailability>
        )}
      </div>
    );
  };

  return (
    <div
      className="helper-profile mb-4"
      style={{
        borderBottom: "1px solid var(--border-color)",
        paddingBottom: "1rem",
      }}
    >
      {size === "lg" ? (
        <Row>
          <Col xs={6} sm={4} md={3}>
            <ProfilePicture user={user} size="lg" />
          </Col>
          <Col xs={6} sm={8} md={9}>
            <h2 className="mt-4">{displayUserName(user)}</h2>
            {user.userType !== "system" ? (
              <StarRating
                rating={rating.score || 0}
                totalRatings={rating.count || 0}
              />
            ) : null}
            {profile && profile.licenses && profile.licenses.length > 0
              ? profile.licenses.map((l, lIndex) => {
                  let matches = /\s\((.*)\)$/.exec(l.title);
                  const abbr = matches ? matches[1] : l.title;
                  return (
                    <Badge
                      key={lIndex}
                      bg="secondary"
                      className="mr-1"
                      style={{ marginRight: "5px" }}
                    >
                      {abbr}
                    </Badge>
                  );
                })
              : null}
          </Col>
        </Row>
      ) : (
        <ProfileHeader>
          <ProfilePhoto xs={3}>
            <ProfilePicture user={user} size="md" />
          </ProfilePhoto>
          <ProfileTitle>
            <h3 className="mb-1">{user.name}</h3>
            <div className="mb-2">
              <StarRating
                rating={rating.score || 0}
                totalRatings={rating.count || 0}
              />
            </div>
            {profile && profile.licenses && profile.licenses.length > 0
              ? profile.licenses.map((l, lIndex) => {
                  let matches = /\s\((.*)\)$/.exec(l.title);
                  const abbr = matches ? matches[1] : l.title;
                  return (
                    <Badge
                      key={lIndex}
                      bg="secondary"
                      className="mr-1"
                      style={{ marginRight: "5px" }}
                    >
                      {abbr}
                    </Badge>
                  );
                })
              : null}
          </ProfileTitle>
        </ProfileHeader>
      )}

      <br />

      <Tabs
        tabs={[
          {
            label: "PROFILE",
            content: (
              <div>
                <h3 className="mb-3">Profile</h3>
                <h5>About me</h5>
                <div className="text-muted">
                  {nl2br(profile.description) ||
                    "Helper has not added any about me information."}
                </div>
                <div className="divider" />
                <h5>Languages</h5>
                <p className="text-muted">
                  {profile.languages
                    ? profile.languages.join(", ")
                    : "Not Available"}
                </p>
                <div className="divider" />
                <h5>Age</h5>
                <p className="text-muted">
                  {profile.dateOfBirth
                    ? moment
                        .utc()
                        .diff(moment.utc(profile.dateOfBirth), "years")
                    : "Not Available"}
                </p>
                <div className="divider" />
                <h5>Gender</h5>
                <p className="text-muted">
                  {profile.gender || "Not Available"}
                </p>
              </div>
            ),
          },
          {
            label: "EXPERIENCE",
            content: (
              <div>
                <h3 className="mb-3">Experience</h3>
                {profile.workExperience && profile.workExperience.length > 0 ? (
                  <div>
                    {profile.workExperience.map((work, i) => {
                      return (
                        <div key={i} className="mt-3">
                          <h5 style={{ color: "#000", fontSize: "1.15rem" }}>
                            {work.employer}, {work.title}
                          </h5>
                          <p>
                            {moment.utc(work.startDate).format("MM/DD/YYYY")} -{" "}
                            {work.currentJob
                              ? "present"
                              : moment.utc(work.endDate).format("MM/DD/YYYY")}
                          </p>
                          <div>{nl2br(work.description)}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <p>The helper has not entered any work experience.</p>
                )}
              </div>
            ),
          },
          {
            label: "REVIEWS",
            content: (
              <div>
                <h3 className="mb-3">Reviews</h3>
                <ReviewsList
                  userId={user._id}
                  publicRequest={true}
                  emptyMsg={<p>{user.name} does not have any reviews yet.</p>}
                />
              </div>
            ),
          },
          {
            label: "AVAILABILITY",
            content: (
              <div>
                <h3 className="mb-3">Select an available day</h3>
                <p className="text-muted">
                  This helper is available on the highlighted dates. Select a
                  date to view their available hours.
                </p>
                {renderAvailability()}
              </div>
            ),
          },
        ]}
        justifyLinks={true}
        enableLocation={true}
      />
    </div>
  );
};

HelperFullProfile.propTypes = {
  user: PropTypes.object.isRequired,
};

export default HelperFullProfile;
