import React from "react";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import ReviewsList from "../../common/ReviewsList";

const Reviews = () => {
  const { user } = useUser();

  return (
    <SubInternalLayout title={`Reviews`}>
      <Helmet>
        <title>Reviews | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <ReviewsList userId={user._id} />
    </SubInternalLayout>
  );
};

export default Reviews;
