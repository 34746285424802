import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Button, FormGroup, FormControl, Alert } from "react-bootstrap";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import { useFormData } from "../../hooks/useFormData";
import useHelper from "../../hooks/useHelper";
import { useApiPost } from "../../hooks/useApi";
import { SubmitButton, HideOnMobile } from "../common/Registration.elements";
import { ResponsiveBtnGroup, ResendBtn } from "../common/Responsive.elements";
import { SetupTitle } from "../common/Title.elements";
import RegisterLayout from "../layout/RegisterLayout";
import { PaddedForm } from "../common/PaddedForm";

const HelperRegisterVerification = () => {
  const codeRef = useRef();
  const { formData, onChange } = useFormData({
    code: "",
  });
  const { user, verificationToken, verified } = useUser();
  const { helper } = useHelper();
  const [resendBtnDisabled, setResendBtnDisabled] = useState(true);
  const [showResendAlert, setShowResendAlert] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    codeRef.current.focus();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (user) {
      navigate("/welcome");
    }
    disableResendBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const callApi = useApiPost(`/register/verify`, (res) => {
    verified(res);
  });

  const resendAutorizationCode = useApiPost(`/register/resend-verification`);

  const onSubmit = (e) => {
    e.preventDefault();
    callApi.mutate({ token: verificationToken, code: formData.code });
  };

  const disableResendBtn = () => {
    setResendBtnDisabled(true);
    setTimeout(() => {
      setResendBtnDisabled(false);
    }, 1000 * 10); // 10 seconds;
  };

  const onResendCode = (e) => {
    setShowResendAlert(true);
    disableResendBtn();
    resendAutorizationCode({ email: user.email });
  };

  return (
    <RegisterLayout
      title="We've sent you a verification link to the email address provided."
      steps={["Location", "Services", "Business", "Account"]}
      activeStep={3}
      onStepBack={() => navigate("/become-a-helper/account")}
    >
      <Helmet>
        <title>Verify | Become A Helper | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <PaddedForm onSubmit={onSubmit}>
        <HideOnMobile>
          <Button
            type="button"
            variant="link"
            onClick={() => navigate("/become-a-helper/account")}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
            disabled={true}
          >
            <ArrowBack />
          </Button>
        </HideOnMobile>

        {showResendAlert && (
          <Alert variant="success" className="header-error">
            A new verification code has been sent to your email.
          </Alert>
        )}

        <SetupTitle>Enter your verification code to continue.</SetupTitle>
        <p className="text-muted mb-4">The code was sent to {helper.email}.</p>
        {callApi.isError ? (
          <Alert variant="danger">{callApi.error.message}</Alert>
        ) : null}
        <FormGroup>
          <FormControl
            type="text"
            maxLength={12}
            name="code"
            value={formData.code}
            onChange={onChange}
            required={true}
            ref={codeRef}
          />
        </FormGroup>

        <ResponsiveBtnGroup>
          <ResendBtn
            onClick={() => onResendCode()}
            disabled={resendBtnDisabled}
          >
            RESEND CODE
          </ResendBtn>
          <SubmitButton
            type="submit"
            disabled={formData.code.length < 6 || callApi.isLoading}
          >
            NEXT
          </SubmitButton>
        </ResponsiveBtnGroup>
      </PaddedForm>
    </RegisterLayout>
  );
};

export default HelperRegisterVerification;
