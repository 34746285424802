import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";
import { DashboardModal, useUppy } from "@uppy/react";

import { useUser } from "../../contexts/UserContext";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/image-editor/dist/style.css";

const RawUploadButton = ({
  btnLabel = "Add Image",
  className,
  endpoint,
  endpointHeaders = {},
  onUpload,
  cropperOpts = null,
  imgQuality = 0.8,
}) => {
  const { token } = useUser();
  const [open, setOpen] = useState(false);
  const [resp, setResp] = useState(null);
  const uppy = useUppy(() => {
    const opts = cropperOpts || {
      viewMode: 1,
      background: false,
      autoCropArea: 1,
      responsive: true,
      aspectRatio: 1,
    };

    return new Uppy({
      id: "uppy",
      restrictions: {
        maxFileSize: 10000000, //10MB
        allowedFileTypes: ["image/*"],
        maxNumberOfFiles: 1,
      },
      autoProceed: false,
      debug: true,
    })
      .use(XHRUpload, {
        endpoint,
        headers: {
          authorization: `Bearer ${token}`,
          ...endpointHeaders,
        },
      })
      .use(ImageEditor, {
        id: "ImageEditor",
        quality: imgQuality,
        cropperOptions: opts,
      })
      .on("upload-success", (file, response) => {
        setResp({ file, response });
        // uppy.reset();
        setOpen(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      try {
        uppy.close({ reason: "unmount" });
      } catch (err) {
        // console.log("UNMOUNT ERROR: ", err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resp && typeof onUpload === "function") {
      onUpload(resp);
    }
  }, [resp, onUpload]);

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        className={className || ""}
        style={{ cursor: "pointer" }}
      >
        {btnLabel}
      </div>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside={true}
        autoOpenFileEditor={true}
        open={open}
        onRequestClose={() => setOpen(false)}
        plugins={["Instagram", "ImageEditor"]}
        metaFields={[{ id: "name", name: "Name", placeholder: "File Name" }]}
      />
    </>
  );
};

RawUploadButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  endpointHeaders: PropTypes.object,
  onUpload: PropTypes.func.isRequired,
  cropperOpts: PropTypes.object,
  imgQuality: PropTypes.number,
};

export default RawUploadButton;
