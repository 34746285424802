import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { useApiGet } from "../../../hooks/useApi";
import Loading from "../../common/Loading";
import JobListItem from "./JobListItem";

const JobList = ({
  jobStatus,
  startDate = undefined,
  userId = undefined,
  sortDir = undefined,
  onDataLoad = () => {},
}) => {
  const [filters, setFilters] = useState({
    page: 1,
    jobStatus,
    startDate,
    userId,
    sortDir,
  });
  const [fullData, setFullData] = useState([]);

  const { isLoading, data } = useApiGet("bookings", "/bookings", filters, {
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    setFilters({ ...filters, userId, sortDir });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortDir, userId]);

  useEffect(() => {
    if (data) {
      let updates = [];

      if (filters.page === 1) {
        updates = [...data];
      } else {
        updates = [...fullData, ...data];
      }

      setFullData(updates);
      onDataLoad(updates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const renderFooter = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (fullData.length === filters.page * 25) {
      return (
        <Button
          variant="link"
          onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
          className={data.length > 0 ? "mt-3 mb-3" : "d-none"}
          size="sm"
        >
          LOAD MORE
        </Button>
      );
    }

    return null;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (fullData.length === 0) {
    return <p className="text-center">No bookings were found.</p>;
  }

  return (
    <>
      {fullData.map((row, rowIdx) => {
        return <JobListItem key={rowIdx} jobRequest={row} />;
      })}
      <div className="text-center">{renderFooter()}</div>
    </>
  );
};

export default JobList;
