import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useFormData } from "../../hooks/useFormData";
import { useApiPost } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";
import { HideOnMobile } from "../helper/components/NewHelper.elements";
import { PaddedForm } from "../common/PaddedForm";

/*
  STEP 4
*/

const JobRequestRegister = () => {
  const { job } = useJob();
  const { signIn, verified } = useUser();
  const { title, setTitle } = usePage();
  const { formData, onChange } = useFormData({
    name: "",
    email: "",
    mobile: "",
    acceptTerms: false,
    marketingTerms: true,
  });
  const navigate = useNavigate();
  const [formError, setFormError] = useState(null);
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  const registerUser = useApiPost(
    "/register/customer",
    (results) => {
      signIn(results.token);
      navigate("/find-help/verify");
    },
    {
      onError: (err) =>
        console.log("onError: ", err, err.data, err.response.data),
    }
  );

  const registerUserWithGoogle = useApiPost(
    "/register/customer-with-google",
    (res) => {
      verified(res);
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle("Create a Manana account to complete this booking.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayload = () => {
    return {
      email: formData.email.trim(),
      name: formData.name.trim(),
      customer: {
        serviceArea: job.serviceArea._id,
      },
      address: {
        postal: job.zip,
      },
      settings: {
        timezone: moment.tz.guess(),
        mobile: formData.mobile || null,
        marketingTerms: {
          sms: formData.marketingTerms,
          email: formData.marketingTerms,
        },
      },
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!/^[\w]+\s+[\w]+/gi.test(formData.name)) {
      setFormError(
        "Please enter your full name - at least two words required."
      );
      return;
    }

    if (formData.mobile && !/\d{3}-\d{3}-\d{4}/.test(formData.mobile)) {
      toast.error(
        "Invalid phone number format. Please check your mobile number and try again."
      );
      return;
    }

    registerUser.mutate(getPayload());
  };

  const isDisabled = () => {
    if (
      !formData.acceptTerms ||
      formData.name.length < 3 ||
      formData.email.length < 3
    ) {
      return true;
    }

    if (!/^.+@.+\..+$/.test(formData.email)) {
      return true;
    }

    return false;
  };

  const content = (
    <PaddedForm onSubmit={onSubmit}>
      <HideOnMobile>
        <Button
          type="button"
          variant="link"
          onClick={() => navigate(-1)}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
        >
          <ArrowBack />
        </Button>
      </HideOnMobile>

      <h2 className="mb-4">Create account</h2>

      {formError ? <Alert variant="danger">{formError}</Alert> : null}
      {registerUser.isError ? (
        <Alert variant="danger">{registerUser.error.response.data.msg}</Alert>
      ) : null}
      {registerUserWithGoogle.isError ? (
        <Alert variant="danger">{registerUserWithGoogle.error.message}</Alert>
      ) : null}

      <Form.Group className="mb-4">
        <Form.Control
          type="text"
          name="name"
          placeholder="Full name"
          value={formData.name}
          required={true}
          onChange={onChange}
          style={{ display: "block" }}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Control
          type="email"
          name="email"
          placeholder="your@email.com"
          value={formData.email}
          required={true}
          onChange={onChange}
          style={{ display: "block" }}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <InputMask
          type="tel"
          name="mobile"
          mask="999-999-9999"
          className="form-control"
          placeholder="123-123-1234 (optional)"
          onChange={onChange}
          value={formData.mobile}
        />
      </Form.Group>

      <hr />

      <Form.Group className="mb-3" controlId="cb-terms">
        <Form.Check
          type="checkbox"
          id="cb-agree-terms"
          label={
            <>
              I agree to all{" "}
              <Link
                to="https://www.mananahelp.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms &amp; Conditions
              </Link>{" "}
              and acknowledge the{" "}
              <Link
                to="https://www.mananahelp.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
              .
            </>
          }
          value={true}
          checked={formData.acceptTerms}
          size="lg"
          onChange={(e) =>
            onChange({
              target: { name: "acceptTerms", value: e.target.checked },
            })
          }
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="cb-background">
        <Form.Check
          type="checkbox"
          label="I agree to receive marketing communications from Manana."
          value={true}
          checked={formData.marketingTerms}
          size="lg"
          onChange={(e) =>
            onChange({
              target: { name: "marketingTerms", value: e.target.checked },
            })
          }
        />
      </Form.Group>
      <div className="text-end">
        <Button
          type="submit"
          variant="primary"
          style={{ width: "100%", fontFamily: "Noto Sans" }}
          disabled={isDisabled()}
        >
          {registerUser.isLoading ? "..." : "NEXT"}
        </Button>
      </div>
    </PaddedForm>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Create Account"}
      onStepBack={() => navigate("/find-help/helpers")}
    >
      <Helmet>
        <title>Register | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestRegister;
