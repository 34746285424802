import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Card,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import AttachMoney from "@mui/icons-material/AttachMoney";
import Alarm from "@mui/icons-material/Alarm";
import BarChart from "@mui/icons-material/BarChart";
import Block from "@mui/icons-material/Block";
import DataUsage from "@mui/icons-material/DataUsage";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import CalendarToday from "@mui/icons-material/CalendarToday";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useQueryClient } from "react-query";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";
import numeral from "numeral";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import { useApiGet } from "../../hooks/useApi";
import InternalLayout from "../layout/InternalLayout";
import { MobileLoadingMessage } from "./Dashboard.elements";
import useOnboard from "../../hooks/useOnboard";
import StripeHeaderNotice from "../common/StripeHeaderNotice";

import "./dashboard.css";

const Dashboard = () => {
  const { user } = useUser();
  const { setTitle } = usePage();
  const [error] = useState(null);
  const navigate = useNavigate();
  const { showOnBoarding } = useOnboard();
  const [dateRange, setDateRange] = useState({
    startDate: moment.utc().startOf("week").toDate(),
    endDate: moment.utc().endOf("week").toDate(),
    key: "selection",
  });
  const queryClient = useQueryClient();
  const [dateInterval, setDateInterval] = useState("week");

  const { isLoading, data } = useApiGet(
    "dashboard",
    "/dashboard",
    {
      startDate: moment.utc(dateRange.startDate).format("YYYY-MM-DD"),
      endDate: moment.utc(dateRange.endDate).format("YYYY-MM-DD"),
    },
    { staleTime: 1000 }
  );

  useEffect(() => {
    if (!user.helper) {
      navigate("/calendar");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDateRangeBack = () => {
    setDateRange({
      startDate: moment.utc(dateRange.startDate).subtract(1, dateInterval),
      endDate: moment.utc(dateRange.endDate).subtract(1, dateInterval),
      key: "selection",
    });
  };

  const onDateRangeForward = () => {
    setDateRange({
      startDate: moment.utc(dateRange.startDate).add(1, dateInterval),
      endDate: moment.utc(dateRange.endDate).add(1, dateInterval),
      key: "selection",
    });
  };

  const DateRangePopover = (
    <Popover
      id="popover-datefilter"
      style={{ width: "auto", maxWidth: "none" }}
    >
      <Popover.Header>Select date</Popover.Header>
      <Popover.Body>
        <div className="d-flex justify-content-center mt-4">
          <DateRangePicker
            onChange={(value) => setDateRange(value.selection)}
            ranges={[dateRange]}
            direction="vertical"
            scroll={{ enabled: true }}
            moveRangeOnFirstSelection={false}
            months={1}
          />
        </div>
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (dateRange.startTime && dateRange.endTime) {
      queryClient.invalidateQueries("dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    setTitle("");

    if (showOnBoarding) {
      navigate("/welcome");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InternalLayout>
      <Helmet>
        <title>Dashboard | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {error ? (
        <Alert variant="danger" className="header-error">
          {error.message}
        </Alert>
      ) : null}
      {isLoading ? (
        <MobileLoadingMessage>
          <p>Loading...</p>
        </MobileLoadingMessage>
      ) : (
        <Container className="dashboard">
          <h1 className="mb-5">Dashboard</h1>

          <StripeHeaderNotice />

          <Card className="p-3 mb-5">
            <Card.Body className="d-flex align-items-center">
              <div className="card-icon">
                <AttachMoney />
              </div>
              <div
                className="px-4"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/payments#paid")}
              >
                <Card.Title>Total amount earned</Card.Title>
                <Card.Text>
                  {numeral(data.totalAmountEarned).format("$0,0.00")}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>

          <Card className="p-3 mb-5">
            <Card.Body className="d-flex align-items-center">
              <div className="card-icon">
                <Alarm />
              </div>
              <div className="px-4 border-right">
                <Card.Title>Your Avg. Response Time</Card.Title>
                <Card.Text>{data.avgResponseTime.helperAvgTime}</Card.Text>
              </div>
              <div className="px-4">
                <Card.Title>Avg. of Other Helpers</Card.Title>
                <Card.Text>{data.avgResponseTime.othersAvgTime}</Card.Text>
              </div>
            </Card.Body>
          </Card>

          <div className="mb-5">
            <Row>
              <Col xs={12} sm={6} md={4} className="mb-3">
                <span className="body1">
                  {moment
                    .utc(dateRange.startDate)
                    .format(
                      moment
                        .utc(dateRange.startDate)
                        .isSame(dateRange.endDate, "year")
                        ? "MMM DD"
                        : "MMM DD YYYY"
                    )}
                  -{moment.utc(dateRange.endDate).format("MMM DD YYYY")}
                </span>
              </Col>
              <Col xs={12} sm={6} md={4} className="mb-3 text-end">
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={DateRangePopover}
                  rootClose
                >
                  <CalendarToday className="mx-3 cursor-pointer" />
                </OverlayTrigger>
                <ArrowBackIos
                  className="mx-3 cursor-pointer"
                  onClick={onDateRangeBack}
                />
                <ArrowForwardIos
                  className="mx-3 cursor-pointer"
                  onClick={onDateRangeForward}
                />
              </Col>
              <Col xs={12} sm={12} md={4} className="mb-3">
                <Form.Select
                  value={dateInterval}
                  onChange={(evt) => setDateInterval(evt.target.value)}
                >
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="Year">Year</option>
                </Form.Select>
              </Col>
            </Row>

            <Card className="p-3">
              <Card.Body className="d-flex align-items-center">
                <div className="card-icon">
                  <BarChart />
                </div>
                <div className="px-4">
                  <Card.Title>Amount earned</Card.Title>
                  <Card.Text>
                    {numeral(
                      data.transactionInfo.reduce(
                        (sum, transaction) => sum + transaction.uv,
                        0
                      )
                    ).format("$0,0.00")}
                  </Card.Text>
                </div>
              </Card.Body>
              <Card.Body>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={data.transactionInfo}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                  >
                    <Line
                      type="linear"
                      dataKey="uv"
                      stroke="#356f7c"
                      dot={false}
                    />
                    <XAxis dataKey="name" />
                    <YAxis
                      tickFormatter={(value) =>
                        numeral(value).format("$0,0.00")
                      }
                    />

                    <ReferenceLine
                      x={
                        data.transactionInfo[data.transactionInfo.length - 1]
                          ?.date
                      }
                      stroke="#f3ebea"
                    />
                    {/* <ReferenceLine y="160" stroke="#f3ebea"/> */}
                  </LineChart>
                </ResponsiveContainer>
              </Card.Body>
            </Card>
          </div>

          <Row>
            <Col xs={12} sm={12} md={6}>
              <Card className="p-3 mb-5">
                <Card.Body className="d-flex align-items-center">
                  <div className="card-icon">
                    <DataUsage />
                  </div>
                  <div className="px-4">
                    <Card.Title>Capacity booked</Card.Title>
                    <Card.Text className="mb-0">
                      {data.jobInfo.availability}%
                    </Card.Text>
                    {/* <div className="d-flex align-items-center">
                      <div className="triangle-up success"></div>
                      <div className="mx-3">25%</div>
                    </div> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6}>
              <Card className="p-3 mb-5">
                <Card.Body className="d-flex align-items-center">
                  <div className="card-icon">
                    <Block />
                  </div>
                  <div className="px-4">
                    <Card.Title>Cancellations</Card.Title>
                    <Card.Text className="mb-0">
                      {data.jobInfo.canceled}
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </InternalLayout>
  );
};

export default Dashboard;
