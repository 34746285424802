import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormCheck,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import styled from "styled-components";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { SubmitButton, HideOnMobile } from "../common/Registration.elements";
import { SetupTitle } from "../common/Title.elements";
import useHelper from "../../hooks/useHelper";
import RegisterLayout from "../layout/RegisterLayout";
import { PaddedForm } from "../common/PaddedForm";

const StyledCheck = styled(FormGroup)`
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 1rem;
`;

const HelperRegisterRates = () => {
  const { helper, saveHelper } = useHelper();
  const [formData, setFormData] = useState({
    standard: helper.standard || 15,
    specialized: helper.specialized || 15,
    overnight: helper.overnight || 100,
    jobTypes: helper.jobTypes || ["One-Time"],
  });
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    saveHelper({
      ...formData,
      specialized: formData.standard,
      availableOvernight: formData.jobTypes.includes("Overnight"),
    });
    navigate("/become-a-helper/account");
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <RegisterLayout
      title="Let's set up your business."
      steps={["Location", "Services", "Business", "Account"]}
      activeStep={2}
      onStepBack={() => navigate("/become-a-helper/services")}
    >
      <Helmet>
        <title>Rates | Become A Helper | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <PaddedForm onSubmit={onSubmit}>
        <HideOnMobile>
          <Button
            type="button"
            variant="link"
            onClick={() => navigate("/become-a-helper/services")}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          >
            <ArrowBack />
          </Button>
        </HideOnMobile>

        <SetupTitle>What's your hourly rate?</SetupTitle>

        <FormGroup>
          <p className="subtitle2">EVERYDAY TASKS</p>
          <p className="text-muted body1">
            The average rate for help providers in your area for everyday tasks
            is $15.
          </p>
          <Row>
            <Col xs={6} sm={4}>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <FormControl
                  type="number"
                  name="standard"
                  placeholder="$15"
                  value={formData.standard}
                  onChange={onChange}
                  required={true}
                  min={10}
                />
              </InputGroup>
            </Col>
            <Col>
              <span style={{ fontSize: "1.5rem" }}>per hour</span>
            </Col>
          </Row>
        </FormGroup>

        {/* helper.dementiaCare ? (
          <>
            <hr />

            <FormGroup>
              <p className="subtitle2">SPECIALIZED SERVICES</p>
              <p className="text-muted body1">
                The average rate for help providers in your area for specialized
                services is $25.
              </p>
              <Row>
                <Col xs={6} sm={4}>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <FormControl
                      type="number"
                      name="specialized"
                      placeholder="$25"
                      value={formData.specialized}
                      onChange={onChange}
                      required={true}
                      min={10}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <span style={{ fontSize: "1.5rem" }}>per hour</span>
                </Col>
              </Row>
            </FormGroup>
          </>
        ) : null */}

        <hr />

        <SetupTitle>What type of jobs are you looking for?</SetupTitle>
        <StyledCheck className="mb-3 mt-3">
          <FormCheck
            type="checkbox"
            label="One-time jobs"
            id="jobTypesOneTime"
            value="One-Time"
            checked={formData.jobTypes.includes("One-Time")}
            size="lg"
            onChange={(e) => {
              let types = formData.jobTypes;

              if (e.target.checked) {
                types.push("One-Time");
              } else {
                types = types.filter((v) => v !== "One-Time");
              }
              onChange({
                target: { name: "jobTypes", value: types },
              });
            }}
          />
        </StyledCheck>
        <StyledCheck className="mb-3">
          <FormCheck
            type="checkbox"
            label="Recurring jobs"
            id="jobTypesRecurring"
            value="Recurring"
            checked={formData.jobTypes.includes("Recurring")}
            size="lg"
            onChange={(e) => {
              let types = formData.jobTypes;

              if (e.target.checked) {
                types.push("Recurring");
              } else {
                types = types.filter((v) => v !== "Recurring");
              }
              onChange({
                target: { name: "jobTypes", value: types },
              });
            }}
          />
        </StyledCheck>
        <StyledCheck className="mb-3">
          <FormCheck
            type="checkbox"
            label="Overnight jobs"
            id="jobTypesOvernight"
            value="Overnight"
            checked={formData.jobTypes.includes("Overnight")}
            size="lg"
            onChange={(e) => {
              let types = formData.jobTypes;

              if (e.target.checked) {
                types.push("Overnight");
              } else {
                types = types.filter((v) => v !== "Overnight");
              }
              onChange({
                target: { name: "jobTypes", value: types },
              });
            }}
          />

          {formData.jobTypes.includes("Overnight") ? (
            <div style={{ marginLeft: "3rem" }}>
              <p className="text-muted body1 mb-3 mt-3">
                The average overnight rate for help providers in your area is
                $100.
              </p>
              <Row>
                <Col xs={6} sm={4}>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <FormControl
                      type="number"
                      name="overnight"
                      value={
                        !formData.jobTypes.includes("Overnight")
                          ? ""
                          : formData.overnight
                      }
                      required={formData.jobTypes.includes("Overnight")}
                      onChange={onChange}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <span style={{ fontSize: "1.5rem" }}>a night</span>
                </Col>
              </Row>
            </div>
          ) : null}
        </StyledCheck>

        <div className="text-end">
          <SubmitButton type="submit" disabled={formData.services === null}>
            NEXT
          </SubmitButton>
        </div>
      </PaddedForm>
    </RegisterLayout>
  );
};

export default HelperRegisterRates;
