import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import styled from "styled-components";

import { useUser } from "../../contexts/UserContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";

const StyledLink = styled(Link)`
  font-weight: 700;
  text-decoration: none;
`;

const maskEmail = (email) => {
  const matches = /^\w(.*)\w@/.exec(email);
  let replaceStr = "";

  for (let i = 0; i < matches[1].length; i++) {
    replaceStr += "*";
  }

  return email.replace(matches[1], replaceStr);
};

const Unsubscribe = () => {
  const { user } = useUser();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const { formData, onChange } = useFormData({
    email: "",
    marketing: false,
  });

  useEffect(() => {
    const params = Object.fromEntries(searchParams);
    const raw = params.email
      ? params.email.trim().toLowerCase().replace(/\s/g, "+")
      : "";

    if (raw === "") {
      toast.error("Invalid link was followed.");
      navigate("/sign-in");
    }

    onChange({
      target: {
        name: "email",
        value: raw,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const submitPost = useApiPost(
    "/contact/unsubscribe",
    (res) => {
      setSuccess(true);
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const onSubmit = (e) => {
    e.preventDefault();

    submitPost.mutate({
      userId: user ? user._id : null,
      email: formData.email,
      marketing: formData.marketing,
    });
  };

  const content = (
    <>
      {success ? (
        <>
          <h3>You've successfully unsubscribed from marketing messages.</h3>
          <hr />{" "}
          <StyledLink
            to="/account"
            className="btn-link d-block mt-4 text-center"
          >
            MANAGE PREFERENCES
          </StyledLink>
        </>
      ) : (
        <>
          <h3>Select the emails you'd no longer like to receive.</h3>
          <form onSubmit={onSubmit}>
            <p className="lead mb-4">
              Email: {formData.email ? maskEmail(formData.email) : "..."}
            </p>
            <Form.Group>
              <Form.Check
                id="marketing"
                name="marketing"
                type="checkbox"
                label="Marketing"
                value="marketing"
                onChange={(e) =>
                  onChange({
                    target: { name: "marketing", value: e.target.checked },
                  })
                }
              />
            </Form.Group>
            <hr />
            <Button
              type="submit"
              variant="primary"
              disabled={submitPost.isLoading || !formData.marketing}
              style={{ width: "100%" }}
            >
              {submitPost.isLoading ? "..." : "UNSUBSCRIBE"}
            </Button>
            <StyledLink
              to="/account"
              className="btn-link d-block mt-4 text-center"
            >
              MANAGE PREFERENCES
            </StyledLink>
          </form>
        </>
      )}
    </>
  );

  return (
    <RegisterLayout
      title="We're sorry to see you go."
      titleStyle={{ marginTop: "20%" }}
    >
      <Helmet>
        <title>Unsubscribe| Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default Unsubscribe;
