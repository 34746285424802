import React, { useEffect, useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import { useAdmin } from "../../contexts/AdminContext";
import AdminLayout from "../layout/AdminLayout";
import { useApiGet } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import Loading from "../common/Loading";
import DataTable from "../common/DataTable";
import SimplePagination from "../common/SimplePagination";
import { InternalResponsiveColumn } from "../common/Common.elements";
import FiltersPanel from "./components/FiltersPanel";

const AdminLanding = () => {
  const { user, signOut } = useUser();
  const { options, setOptions } = useAdmin();
  const { formData, onChange, setData } = useFormData({
    ...options.filters,
  });
  const [filters, setFilters] = useState({ ...options.filters });
  const navigate = useNavigate();

  const { isLoading, data } = useApiGet(
    "admin-users",
    "/admin/users",
    { ...filters },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const updateFilters = () => {
    const newFilters = {};
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        newFilters[key] = formData[key];
      }
    });

    setFilters({
      ...newFilters,
      page: 1,
      sortBy: formData.sortBy,
      sortDir: formData.sortDir,
    });
  };

  useEffect(() => {
    if (user.userType !== "admin") {
      signOut();
      navigate("/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setOptions({ ...options, filters: { ...options.filters, ...formData } });
    setFilters({
      ...filters,
      page: formData.page,
      sortBy: formData.sortBy,
      sortDir: formData.sortDir,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const onSubmit = (e) => {
    e.preventDefault();

    updateFilters();
  };

  const renderFilterLabels = () => {
    const labels = [];

    if (!data || !data.match) {
      return null;
    }

    if (formData.search && data.match["$or"]) {
      labels.push(`Search: ${formData.search}`);
    }

    if (formData.userType && data.match.userType) {
      labels.push(`User Type: ${formData.userType}`);
    }

    if (formData.zipCode && data.match["address.postal"]) {
      if (data.match["address.postal"]["$in"]) {
        // show the list
        labels.push(`Zip: ${data.match["address.postal"]["$in"].join(", ")}`);
      } else {
        // show the single
        labels.push(`Zip: ${formData.zipCode}`);
      }
    }

    if (labels.length === 0) {
      return null;
    }

    return (
      <div
        style={{
          borderBottom: "1px solid #ccc",
          paddingBottom: "1rem",
          marginBottom: "1rem",
        }}
      >
        {labels.map((label, idx) => (
          <Badge key={idx} bg="secondary" className="me-2">
            {label}
          </Badge>
        ))}
      </div>
    );
  };

  const renderList = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <Row>
        <Col xs={12} sm={4} md={3} lg={2}>
          <FiltersPanel
            formData={formData}
            onChange={onChange}
            onSubmit={onSubmit}
          />
        </Col>
        <Col>
          {renderFilterLabels()}
          <DataTable
            columns={[
              {
                header: "Name",
                key: "name",
                sortable: true,
                render: (row) => {
                  return <Link to={`/admin/users/${row._id}`}>{row.name}</Link>;
                },
              },
              {
                header: "Type",
                key: "userType",
                sortable: true,
              },
              {
                header: "Email",
                key: "email",
                sortable: true,
                render: (row) => {
                  return (
                    <a
                      href={`mailto:${row.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {row.email}
                    </a>
                  );
                },
              },
              {
                header: "Mobile",
                key: "settings.mobile",
                sortable: true,
              },
              {
                header: "Registered On",
                key: "createdOn",
                sortable: true,
                render: (row) => {
                  return row.createdOn
                    ? moment(row.createdOn).format("MM/DD/YYYY")
                    : "Unknown";
                },
              },
              {
                header: "Last Login",
                key: "lastLogin",
                sortable: true,
                render: (row) => {
                  return row.lastLogin
                    ? moment(row.lastLogin).format("MM/DD/YYYY")
                    : "Unknown";
                },
              },
            ]}
            data={data ? data.data : []}
            busy={isLoading}
            defaultSort={`${formData.sortBy}:${formData.sortDir}`}
            onSortChange={(newSortBy, newSortDir) => {
              setData({ sortBy: newSortBy, sortDir: newSortDir });
            }}
          />
          {data && data.totalRows > 25 ? (
            <SimplePagination
              page={formData.page}
              totalRows={data.totalRows}
              rowsPerPage={25}
              onBack={() => {
                setData({ ...formData, page: formData.page - 1 });
              }}
              onForward={() => {
                setData({ ...formData, page: formData.page + 1 });
              }}
            />
          ) : null}
        </Col>
      </Row>
    );
  };

  return (
    <AdminLayout>
      <Helmet>
        <title>Admin Dashboard | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container fluid>
        <Row>
          <InternalResponsiveColumn>
            <h2 className="mb-5">Admin Area</h2>
            {renderList()}
          </InternalResponsiveColumn>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default AdminLanding;
