import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import moment from "moment-timezone";
import { chunk } from "lodash";
import styled from "styled-components";

import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";

const StyledCalendar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;

  & > div {
    flex: 1;
  }
`;

const StyledWeek = styled.div`
  display: flex;
  flex-direction: row;

  &.header > div {
    text-align: center;
    color: #857070;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
`;

const StyledDay = styled.div`
  flex: 1;
  margin: 1rem;

  & > * {
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin: auto;
    border-radius: 20px;
    padding-top: 10px;
    cursor: default;
    color: #979797;
  }

  & > *.selected {
    background-color: #205b68;
    color: #fff;
  }

  & > *.clickable {
    cursor: pointer;
    color: black;
  }

  & > *.highlighted {
    background-color: #f8f2f1;
    color: #4a4a4a;
  }

  & > *.not-in-month {
    color: #979797;
  }

  @media only screen and (max-width: 430px) {
    margin: 1rem 0;
  }
`;

const StyledLoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding-top: 15%;
  font-size: 1.15rem;
  font-weight: 700;
`;

const getCalendarDates = (dt) => {
  const moStart = moment(dt).startOf("month");
  const moEnd = moment(dt)
    .endOf("month")
    .set({ hour: 0, minutes: 0, seconds: 0 });
  const daysInMonth = parseInt(moEnd.format("D"));
  const startDayOfWeek = moStart.day();
  const endDayOfWeek = moEnd.day();
  const fillDays = 6 - endDayOfWeek;

  const dates = [];

  // prefill days of the previous months
  if (startDayOfWeek > 0) {
    for (let i = startDayOfWeek; i > 0; i--) {
      dates.push(moment(moStart).subtract(i, "days").format("YYYY-MM-DD"));
    }
  }

  // fill in the current month days
  for (let i = 0; i < daysInMonth; i++) {
    dates.push(moment(moStart).add(i, "days").format("YYYY-MM-DD"));
  }

  // fill in the overflow for the next month
  if (fillDays > 0) {
    for (let i = 1; i <= fillDays; i++) {
      dates.push(moment(moEnd).add(i, "days").format("YYYY-MM-DD"));
    }
  }

  return dates;
};

const Calendar = ({
  date,
  isLoading = false,
  selectedDates = [],
  highlightDates = [],
  setDateClasses,
  allowPastDateSelection = false,
  disableBeforeDate = null,
  onDateSelect = () => {},
  onDateRangeChange = () => {},
}) => {
  const [curDate, setCurDate] = useState(
    moment(date).startOf("month").toDate()
  );
  const [moDates, setMoDates] = useState(getCalendarDates(curDate));

  useEffect(() => {
    if (!moDates || moDates.length === 0) {
      return;
    }

    const first = moDates[0];
    const last = moDates[moDates.length - 1];

    onDateRangeChange(first, last, curDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moDates]);

  const updateCurDate = (months = 1) => {
    const dt = moment(curDate).add(months, "month");
    const dates = getCalendarDates(dt.toDate());

    setCurDate(dt.toDate());
    setMoDates(dates);
  };

  const getDateClasses = (day, defaults = []) => {
    const classes = [...defaults];
    const dt = moment(day, "YYYY-MM-DD").format("MMMM-YYYY");
    const cdt = moment(curDate).format("MMMM-YYYY");

    if (selectedDates.includes(day)) {
      classes.push("selected");
    } else if (highlightDates.includes(day)) {
      classes.push("highlighted");
    } else if (dt !== cdt) {
      classes.push("not-in-month");
    }

    return classes;
  };

  const renderDay = (day, dayIndex) => {
    const dt = moment(day, "YYYY-MM-DD");
    const classes = setDateClasses ? setDateClasses(day) : getDateClasses(day);
    const checkDt = disableBeforeDate ? moment(disableBeforeDate) : moment();
    const isClickable =
      allowPastDateSelection || dt.endOf("day").valueOf() >= checkDt.valueOf();

    if (isClickable) {
      classes.push("clickable");
    }

    return (
      <StyledDay
        className="manana-calendar__week__day"
        key={dayIndex}
        onClick={() => {
          if (isClickable) {
            onDateSelect(day);
          }
        }}
        data-testId={
          classes.includes("highlighted") ? "highlighted-day" : "regular-day"
        }
      >
        <div className={classes.join(" ")}>{dt.format("D")}</div>
      </StyledDay>
    );
  };

  const renderWeek = (week, weekIndex) => {
    return (
      <StyledWeek className="manana-calendar__week" key={weekIndex}>
        {week.map((day, dayIndex) => {
          return renderDay(day, dayIndex);
        })}
      </StyledWeek>
    );
  };

  const renderCalendar = () => {
    const weeks = chunk(moDates, 7);

    return (
      <StyledCalendar className="manana-calendar">
        {isLoading ? (
          <StyledLoadingScreen>Loading...</StyledLoadingScreen>
        ) : null}
        <StyledHeader>
          <div>
            <Button variant="link" onClick={() => updateCurDate(-1)}>
              <ChevronLeft />
            </Button>
          </div>
          <div className="text-center">
            <p className="subtitle2">
              {moment(curDate).format("MMMM YYYY").toUpperCase()}
            </p>
          </div>
          <div className="text-end">
            <Button variant="link" onClick={() => updateCurDate()}>
              <ChevronRight />
            </Button>
          </div>
        </StyledHeader>
        <StyledWeek className="manana-calendar__week header">
          <StyledDay>S</StyledDay>
          <StyledDay>M</StyledDay>
          <StyledDay>T</StyledDay>
          <StyledDay>W</StyledDay>
          <StyledDay>TH</StyledDay>
          <StyledDay>F</StyledDay>
          <StyledDay>SA</StyledDay>
        </StyledWeek>
        {weeks.map((week, weekIndex) => {
          return renderWeek(week, weekIndex);
        })}
      </StyledCalendar>
    );
  };

  return <>{renderCalendar()}</>;
};

Calendar.propTypes = {
  date: PropTypes.instanceOf(Date),
  isLoading: PropTypes.bool,
  selectedDates: PropTypes.array,
  highlightDates: PropTypes.array,
  allowPastDateSelection: PropTypes.bool,
  onDateSelect: PropTypes.func,
  onDateRangeChange: PropTypes.func,
  setDateClasses: PropTypes.func,
};

export default Calendar;
