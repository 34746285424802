import React, { createContext, useContext, useState } from "react";

const JobContext = createContext();

export const defaultJobValues = {
  isNewCustomer: true,
  customer: null,
  serviceArea: null,
  helper: null,
  requestFor: null,
  requestedHelpers: [],
  jobType: "One-Time",
  declinedBy: [],
  servicesRequested: [
    /*{
      service: "60c3fd06573f8b1868c89ef4",
      options: [{ key: "tasks", value: ["Minor home repairs"] }],
    },*/
  ],
  dates: {
    startDateTime: null,
    endTime: null,
    schedule: null,
    endDate: null,
  },
  availability: null,
  specializedCare: [],
  vehicleRequired: false,
  hours: 3,
  isOvernight: false,
  _helpers: [],
};

const getJobFromStorage = () => {
  let saved = window.localStorage.getItem("_job");

  if (!saved) {
    saved = { ...defaultJobValues };
    window.localStorage.setItem("_job", JSON.stringify(saved));
  } else {
    saved = JSON.parse(saved);
  }

  return saved;
};

const JobProvider = ({ children }) => {
  const [job, setJob] = useState(getJobFromStorage());

  const saveJob = (updates) => {
    const newJob = { ...job, ...updates };
    window.localStorage.setItem("_job", JSON.stringify(newJob));
    setJob(newJob);
  };

  const resetJob = (updates = {}) => {
    const newJob = { ...defaultJobValues, ...updates };
    window.localStorage.setItem("_job", JSON.stringify(newJob));
    setJob(newJob);
  };

  const value = { job, saveJob, resetJob };

  return <JobContext.Provider value={value}>{children}</JobContext.Provider>;
};

const useJob = () => {
  const context = useContext(JobContext);
  if (context === undefined) {
    throw new Error("useJob must be used within a JobProvider");
  }
  return context;
};

export { JobProvider, useJob };
