import React, { useState } from "react";
import { Modal, Badge, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import AddCircle from "@mui/icons-material/AddCircle";
import Delete from "@mui/icons-material/Delete";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import CreditCardForm from "../../common/CreditCardForm";
import { useApi, useApiGet } from "../../../hooks/useApi";

const CustomerPaymentSettings = () => {
  const { user } = useUser();
  const [showCC, setShowCC] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data } = useApiGet(
    "customerPaymentMethods",
    "/payments/payment-methods",
    null,
    {
      staleTime: 1000,
      onError: (err) => toast.error(err.message),
    }
  );

  const deleteMethod = useApi({
    onSuccess: () => {
      toast.success("Your changes have been successfully saved.");
      queryClient.invalidateQueries("customerPaymentMethods");
    },
    onError: (err) => toast.error(err.message),
  });

  return (
    <SubInternalLayout title={`Payment Settings`}>
      <Helmet>
        <title>Payment Settings | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          {data.map((method, methodIndex) => {
            const isDefault = method.id === user.billing.paymentMethod;

            return (
              <div className="field-list-item" key={methodIndex}>
                <div className="float-end">
                  <Button
                    size="sm"
                    variant="link"
                    style={{ textDecoration: "none" }}
                    disabled={deleteMethod.isLoading}
                    onClick={() => {
                      if (data.length === 1) {
                        alert(
                          "You cannot delete your only payment method. Please add a replacement and try again."
                        );
                        return;
                      }

                      if (
                        window.confirm(
                          "Are you sure you want to delete this payment method?"
                        )
                      ) {
                        deleteMethod.call(
                          `/payments/methods/${method.id}`,
                          "delete"
                        );
                      }
                    }}
                  >
                    <Delete />
                  </Button>
                </div>
                <p className="body2 mb-1">
                  {method.brand.toUpperCase()} Ending In {method.last4}
                  {isDefault ? (
                    <Badge bg="secondary" className="ms-3">
                      DEFAULT
                    </Badge>
                  ) : null}
                </p>
                <p className="body1 text-muted mb-0">
                  Exp: {method.exp_month}/{method.exp_year}
                </p>
              </div>
            );
          })}
        </>
      )}
      <Button
        variant="link"
        onClick={() => setShowCC(true)}
        style={{ textDecoration: "none" }}
        className="ps-0"
      >
        <AddCircle /> ADD A PAYMENT METHOD
      </Button>

      <br />
      <br />

      <Modal show={showCC} onHide={() => setShowCC(false)}>
        <Modal.Body>
          <CreditCardForm
            buttonLabel="Save Payment Method"
            redirectUrl="/account/recipient-payment-settings"
            onComplete={(details) => console.log("DETAILS: ", details)}
          />
        </Modal.Body>
      </Modal>
    </SubInternalLayout>
  );
};

export default CustomerPaymentSettings;
