import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import CalendarToday from "@mui/icons-material/CalendarToday";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import AccessTime from "@mui/icons-material/AccessTime";
import Edit from "@mui/icons-material/Edit";

import ContentBlock from "../common/ContentBlock";
import HelperShortProfile from "../helper/HelperShortProfile";
import JobRequestTotalView from "./JobRequestTotalView";
import { displayTime } from "../../lib/dateHelpers";
import { getAvailabilityWindowTitle } from "../../lib/jobRequestHelpers";
import { useApiGet } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";

const JobRequestStickyReview = ({ children, onHelperClick }) => {
  const { job } = useJob();
  const navigate = useNavigate();

  const { isLoading, data: services } = useApiGet(
    "services",
    "/services",
    null,
    {
      staleTime: 300000,
    }
  );

  const renderServicesList = () => {
    let names = [];

    Object.keys(job.services).forEach((serviceId) => {
      const service = services.find((s) => s._id === serviceId);
      if (service) {
        names.push(service.name);
      }
    });

    if (job.specializedCare.length > 0) {
      names = [...names, ...job.specializedCare];
    }

    return names.join(", ");
  };

  const triggerAvailability = () => {
    if (onHelperClick) {
      onHelperClick();
    } else {
      navigate("/find-help/helpers");
    }
  };

  return (
    <ContentBlock>
      <h3 className="mb-4">Help request details</h3>

      {job.availability && Object.keys(job.availability).length > 0 ? (
        <div className="b-bottom mb-3">
          <div className="float-end">
            <Button
              variant="link"
              className="m-0 p-0"
              onClick={() => triggerAvailability()}
            >
              <Edit />
            </Button>
          </div>
          <Row>
            <Col xs={2} sm={2} md={1} className="text-center">
              <CalendarToday />
            </Col>
            <Col className="pt-1">
              <p className="body2">{getAvailabilityWindowTitle(job)}</p>
              <p className="body1" style={{ color: "var(--muted-text-color)" }}>
                Between {displayTime(job.availability.startTime)} and{" "}
                {displayTime(job.availability.endTime)}
              </p>
            </Col>
          </Row>
        </div>
      ) : null}

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => navigate("/find-help/services")}
          >
            <Edit />
          </Button>
        </div>
        <Row>
          <Col xs={2} sm={2} md={1} className="text-center">
            <AccessTime />
          </Col>
          <Col className="pt-1">
            <p className="body2">Duration</p>
            <p className="body1" style={{ color: "var(--muted-text-color)" }}>
              {job.hours} hours
            </p>
          </Col>
        </Row>
      </div>

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => navigate("/find-help/services")}
          >
            <Edit />
          </Button>
        </div>
        <Row>
          <Col xs={2} sm={2} md={1} className="text-center">
            <AssignmentTurnedInOutlinedIcon />
          </Col>
          <Col className="pt-1">
            <p className="body2">Help requested</p>
            <p className="body1" style={{ color: "var(--muted-text-color)" }}>
              {isLoading ? (
                <span>Loading services data...</span>
              ) : (
                renderServicesList()
              )}
            </p>
          </Col>
        </Row>
      </div>

      {job._helpers && job._helpers.length > 1
        ? job._helpers.map((helper) => {
            return (
              <div key={helper._id} className="mb-3">
                <div className="float-end">
                  <Button
                    variant="link"
                    className="m-0 p-0"
                    onClick={() => navigate("/find-help/helpers")}
                  >
                    <Edit />
                  </Button>
                </div>
                <HelperShortProfile
                  user={helper}
                  specialityCare={job.specializedCare.length > 0}
                  pricing={job.requestedHelpers.length > 1}
                  onSelect={() => navigate(`/find-help/helpers/${helper._id}`)}
                />
              </div>
            );
          })
        : null}

      {job._helpers && job._helpers.length > 0 ? (
        <div className="mt-3 mb-3">
          <JobRequestTotalView />
        </div>
      ) : null}

      {children}
    </ContentBlock>
  );
};

export default JobRequestStickyReview;
