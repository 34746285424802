import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import rrulePlugin from "@fullcalendar/rrule";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";

import { useUser } from "../../../contexts/UserContext";
import { useApiGet } from "../../../hooks/useApi";
import { CalendarWrapper, LoadingScreen } from "../../common/Calendar.elements";
import FullCalendarEventFormModal from "./components/FullCalendarEventFormModal";

const HelperCalendar = ({ user }) => {
  const { updateUser } = useUser();
  const [calDateRange, setCalDateRange] = useState({});
  const [edit, setEdit] = useState(null);
  const [events, setEvents] = useState([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, data } = useApiGet(
    "calendar",
    "/calendar",
    {
      jobStatus:
        "New help request,Booking Confirmed,Booking Complete,Booking Canceled,Booking Expired",
      ...calDateRange,
    },
    {
      enabled: Object.keys(calDateRange).length > 0,
      staleTime: 1000,
      onSuccess: (res) => {
        if (!res) {
          return;
        }

        const newEvents = {
          bookings: { color: "#356f7c", events: [] },
          availability: { color: "#ffe2d7", textColor: "#333", events: [] },
          canceled: {
            color: "#000",
            textColor: "#fff",
            classNames: "strike-through",
            events: [],
          },
        };

        res.availabilityCache.forEach((row) => {
          newEvents.availability.events.push({
            id: `availability::${row.availability._id}`,
            title: "Open Availability",
            start: moment(row.dates.start).toDate(),
            end: moment(row.dates.end).toDate(),
          });
        });

        res.bookings.forEach((row) => {
          const start = moment(
            row.event ? row.event.startDate : row.dates.startDateTime
          );
          const endTime = moment(start).add(row.hours, "hours");
          const key = user.userType === "customer" ? "helper" : "customer";

          if (
            row.jobStatus === "Booking Canceled" ||
            (row.event && row.event.isCanceled)
          ) {
            newEvents.canceled.events.push({
              id: `booking::${row._id}`,
              title: `Canceled with ${row[key].name}`,
              start: start.toDate(),
              end: endTime.toDate(),
            });

            return;
          }

          newEvents.bookings.events.push({
            id: `booking::${row._id}${row.event ? `/${row.event._id}` : ""}`,
            title: `Booked With ${row[key].name}`,
            start: start.toDate(),
            end: endTime.toDate(),
          });
        });

        setEvents(Object.values(newEvents));

        // keeping the flag updated for the onboarding/welcome screen
        if (res.availabilityCache.length > 0 && !user.helper._hasAvailability) {
          const updatedProfile = { ...user };
          updatedProfile.helper._hasAvailability = true;
          updateUser(updatedProfile);
        }
      },
    }
  );

  return (
    <CalendarWrapper>
      {isLoading ? (
        <LoadingScreen>
          <span>Loading calendar...</span>
        </LoadingScreen>
      ) : null}
      <FullCalendar
        plugins={[
          rrulePlugin,
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ]}
        initialView={window.innerWidth <= 430 ? "timeGridDay" : "dayGridMonth"}
        headerToolbar={{
          start: "prev,next",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventSources={events}
        dateClick={(info) => {
          const diff = moment(info.dateStr).diff(moment(), "days");

          if (diff < 0) {
            toast.error("You cannot add availability in the past.");
            return;
          }

          setEdit({
            date: info.dateStr,
            availability: null,
          });
        }}
        eventClick={(info) => {
          const [eventType, eventId, eventSubId] = info.event.id.split("::");

          if (eventType === "booking") {
            navigate(
              `/bookings/${eventId}${
                eventSubId ? `/${eventSubId}` : ""
              }?back=/calendar`
            );
            return;
          }

          const cache = data.availabilityCache.find(
            (a) => a.availability._id === eventId
          );

          setEdit({
            date: moment(info.event.start).format("YYYY-MM-DD"),
            id: info.event.id,
            availability: cache.availability,
          });
        }}
        datesSet={(dateInfo) => {
          setCalDateRange({
            start: moment.utc(dateInfo.start).format("YYYY-MM-DD"),
            end: moment.utc(dateInfo.end).format("YYYY-MM-DD"),
          });
        }}
      />
      <FullCalendarEventFormModal
        show={edit !== null}
        toggle={() => {
          queryClient.invalidateQueries("calendar");
          setEdit(null);
        }}
        event={edit}
        onSuccess={() => {
          if (user._welcomeStatus && !user._welcomeStatus.hasAvailability) {
            /* window._gtag("event", "conversion", {
              send_to: "AW-11127239856/hwFTCN2m0JgYELDp8Lkp",
            }); */

            updateUser({
              _welcomeStatus: { ...user._welcomeStatus, hasAvailability: true },
            });
          }
        }}
      />
    </CalendarWrapper>
  );
};

export default HelperCalendar;
