import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";

export const SearchMessages = styled.div`
  position: relative;
  padding: 16px 20px;
  margin: 2rem 0;
  gap: 10px;
  width: 100%;
  height: 65px;
  background: #ffffff;
  border: 1px solid #cebaba;
  border-radius: 32px;
  div {
    width: 5%;
  }
`;

export const SearchIcon = styled.div`
  position: absolute;
  top: 16px;
  left: 20px;
  width: 50px;
`;

export const SearchInput = styled.input`
  position: absolute;
  top: 16px;
  left: 65px;
  right: 20px;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const StyledListItem = styled.div`
  padding: 12px 2px;
  border-bottom: 1px solid #ebe0de;
  margin-bottom: 12px;
  cursor: pointer;

  &:hover {
    background: rgba(248, 242, 241, 0.4);
  }

  & .caption1,
  & label {
    color: #857070;
  }

  &.no-hover {
    cursor: default;
  }
`;

export const StyledListItemCol = styled(Col)`
  & > p.body1 {
    color: #857070;
  }

  & > p.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.unread > p.body1 {
    font-weight: 700;
    color: #000;
  }

  &.unread .dot {
    display: inline-block;
  }
`;

export const StyledDot = styled.span`
  display: none;
  width: 16px;
  height: 16px;
  background: #fd7060;
  border-radius: 8px;
  margin-left: 12px;
`;
