import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useBlocker } from "react-router-dom";

const Prompt = ({
  when = false,
  message = "Hey there! It looks like you have some unsaved changes. Are you sure you want to leave the page?",
}) => {
  const blocker = useBlocker(when);

  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => {
        return window.confirm(message);
      };
    }

    return () => {
      window.onbeforeunload = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when]);

  return (
    <Modal show={blocker.state === "blocked"}>
      <Modal.Body>
        <p className="lead mb-3">{message}</p>

        <div>
          <Button variant="primary" onClick={() => blocker.proceed()}>
            Continue
          </Button>
          <Button variant="link" onClick={() => blocker.reset()}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Prompt;
