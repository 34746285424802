import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import set from "lodash.set";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";

import {
  SettingGroup,
  SettingRow,
  HeadingRow,
} from "../components/NotificationSettings.elements";
import {
  HELPER_OPTIONS as OPTIONS,
  getInitialFormData,
  findNotificationOptionByKey,
} from "../notificationsHelpers";

const NotificationSettings = () => {
  const { user, updateUser } = useUser();
  const { formData, setData } = useFormData(getInitialFormData(user));
  const [marketing, setMarketing] = useState({
    sms:
      user.settings.marketingTerms && user.settings.marketingTerms.sms
        ? true
        : false,
    email:
      user.settings.marketingTerms && user.settings.marketingTerms.email
        ? true
        : false,
  });

  const onCheckChange = (e) => {
    const updates = { ...formData };
    const optKey = e.target.name.replace(/\.(sms|email)$/, "");
    const opt = findNotificationOptionByKey(OPTIONS, optKey);

    set(updates, e.target.name, e.target.checked);

    if (opt.associated && opt.associated.length > 0) {
      const subKey = e.target.name.indexOf(".sms") > -1 ? "sms" : "email";

      opt.associated.forEach((k) => {
        set(updates, `${k}.${subKey}`, e.target.checked);
      });
    }

    setData({ ...updates });
  };

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
  });

  return (
    <SubInternalLayout
      title={`Notification Settings`}
      className="notification-settings-container"
    >
      <Helmet>
        <title>Notification Settings | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>

      <SettingGroup>
        <HeadingRow>
          <Col xs={8} className="p-0">
            <span className="subtitle1">Marketing</span>
          </Col>
          <Col xs={2}>
            <span className="label">TEXT</span>
          </Col>
          <Col xs={2}>
            <span className="label">EMAIL</span>
          </Col>
        </HeadingRow>
        <SettingRow className="setting-row">
          <Col>
            <span className="subtitle2">
              General Announcements &amp; Job Opportunities
            </span>
          </Col>
          <Col xs={2}>
            <Form.Check
              type="checkbox"
              id="marketing-sms"
              label=""
              name="marketing-sms"
              checked={marketing.sms}
              onChange={(e) =>
                setMarketing({ ...marketing, sms: e.target.checked })
              }
            />
          </Col>
          <Col xs={2}>
            <Form.Check
              type="checkbox"
              id="marketing-email"
              label=""
              name="marketing-email"
              checked={marketing.email}
              onChange={(e) =>
                setMarketing({ ...marketing, email: e.target.checked })
              }
            />
          </Col>
        </SettingRow>
      </SettingGroup>

      {OPTIONS.map((setGroup, setGroupIndex) => {
        return (
          <SettingGroup key={setGroupIndex}>
            <HeadingRow>
              <Col xs={8} className="p-0">
                <span className="subtitle1">{setGroup.subTitle}</span>
              </Col>
              <Col xs={2}>
                <span className="label">TEXT</span>
              </Col>
              <Col xs={2}>
                <span className="label">EMAIL</span>
              </Col>
            </HeadingRow>
            {setGroup.children.map((set, setIndex) => {
              return (
                <SettingRow key={setIndex} className="setting-row">
                  <Col>
                    <span className="subtitle2">{set.header}</span>
                  </Col>
                  <Col xs={2}>
                    <Form.Check
                      type="checkbox"
                      id={`cb-${set.key}-me`}
                      label=""
                      name={`${set.key}.sms`}
                      checked={
                        formData[set.key] && formData[set.key].sms === true
                      }
                      onChange={onCheckChange}
                    />
                  </Col>
                  <Col xs={2}>
                    <Form.Check
                      type="checkbox"
                      id={`cb-${set.key}-me`}
                      label=""
                      name={`${set.key}.email`}
                      checked={
                        formData[set.key] && formData[set.key].email === true
                      }
                      onChange={onCheckChange}
                    />
                  </Col>
                </SettingRow>
              );
            })}
          </SettingGroup>
        );
      })}
      <div className="d-flex justify-content-between text-end mt-4">
        <Button
          type="reset"
          variant="light"
          onClick={() => window.location.reload()}
          disabled={save.isLoading}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "helper.notifications": formData,
              "settings.marketingTerms": { ...marketing },
            })
          }
          disabled={save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default NotificationSettings;
