import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Remove from "@mui/icons-material/Remove";
import Add from "@mui/icons-material/Add";

import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import ServicesList from "../common/ServicesList";
import { useFormData } from "../../hooks/useFormData";
import VehicleForm from "../common/VehicleForm";
import { ServiceBackdrop, HideOnMobile } from "../common/Registration.elements";
import { SetupTitle } from "../common/Title.elements";
import { PaddedForm } from "../common/PaddedForm";
import { useJob } from "../../contexts/JobContext";

/*
  STEP 2
*/

const VEHICLE_SERVICES = [
  "60c3fd06573f8b1868c89ef2",
  "6286461e68f0c5cd07ad833f",
  "6286461e68f0c5cd07ad8338",
];

const JobRequestServices = () => {
  const { job, saveJob, resetJob } = useJob();
  const { title, setTitle } = usePage();
  const { formData, setData } = useFormData({
    services: job.services || {},
    vehicleRequired: job.vehicleRequired || false,
    dementiaCare: job.dementiaCare || false,
    hours: job.hours || 3,
    vehicleCovers: null,
  });
  const [backdropOpen, setBackdropOpen] = useState(false);
  const navigate = useNavigate();
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  useEffect(() => {
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle("We're in your area! Let's get started.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkServiceAndUpdate = (updatedForm) => {
    VEHICLE_SERVICES.forEach((serviceId) => {
      if (updatedForm.services.hasOwnProperty(serviceId)) {
        updatedForm.services[serviceId].accommodate = updatedForm.vehicleCovers;
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const toSubmit = { ...formData };
    checkServiceAndUpdate(toSubmit);
    saveJob({
      ...toSubmit,
      specializedCare: toSubmit.dementiaCare ? ["Dementia Care"] : [],
    });
    navigate("/find-help/helpers");
  };

  const onChange = (e) => {
    const updates = { ...formData, [e.target.name]: e.target.value };
    setData(updates);
  };

  const updateVehicles = (value, hasVehicle) => {
    const updates = {
      ...formData,
      vehicleCovers: value,
      vehicleAvailable: hasVehicle,
    };
    setData(updates);
  };

  const showVehicleForm =
    formData.services.hasOwnProperty(VEHICLE_SERVICES[0]) ||
    formData.services.hasOwnProperty(VEHICLE_SERVICES[1]) ||
    formData.services.hasOwnProperty(VEHICLE_SERVICES[2]);

  const content = (
    <PaddedForm onSubmit={onSubmit} style={{ position: "relative" }}>
      {backdropOpen ? <ServiceBackdrop /> : null}
      <HideOnMobile>
        <Button
          type="button"
          variant="link"
          onClick={() => navigate("/find-help")}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
        >
          <ArrowBack />
        </Button>
      </HideOnMobile>

      <ServicesList
        role="customer"
        values={formData.services}
        onChange={(selections) => {
          onChange({ target: { name: "services", value: selections } });
        }}
        toggleBackdrop={setBackdropOpen}
      />
      <br />

      {showVehicleForm ? (
        <>
          <VehicleForm
            setVehicles={updateVehicles}
            previousValues={job.vehicleCovers || []}
          />
          <br />
        </>
      ) : null}

      {Object.entries(formData.services).length > 0 ? (
        <>
          <SetupTitle>Will you need any specialized care?</SetupTitle>
          <p className="text-muted body1">
            Specialized care will be billed at a higher rate.
          </p>
          <div className="service-item mb-5">
            <Row>
              <Col>
                <span>
                  <span
                    style={{
                      display: "inline-block",
                      width: 50,
                      textAlign: "center",
                      marginRight: "1rem",
                    }}
                  >
                    <img
                      src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Dementia_48px.svg`}
                      alt="Dementia Care"
                      title="Dementia Care"
                    />
                  </span>
                  Dementia Care
                </span>
              </Col>
              <Col xs={2} sm={3} className="text-end text-muted pt-1">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    id="cb-dementia-care"
                    value="Dementia Care"
                    checked={formData.dementiaCare}
                    size="lg"
                    onChange={(e) =>
                      onChange({
                        target: {
                          name: "dementiaCare",
                          value: e.target.checked,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>

          <Form.Group className="mb-5">
            <h3>How long would you like this help?</h3>
            <p className="text-muted body1">
              A minimum of 3 hours is required per booking.
            </p>
            <Row>
              <Col className="text-center">
                <Button
                  variant="secondary"
                  className="btn-circle-icon"
                  disabled={formData.hours <= 1}
                  onClick={() => {
                    setData(
                      {
                        hours: formData.hours - 0.5,
                      },
                      true
                    );
                  }}
                >
                  <Remove />
                </Button>
              </Col>
              <Col>
                <SetupTitle className="text-center mt-2">
                  {formData.hours} hours
                </SetupTitle>
              </Col>
              <Col className="text-center">
                <Button
                  variant="secondary"
                  className="btn-circle-icon"
                  disabled={formData.hours >= 23}
                  onClick={() => {
                    setData(
                      {
                        hours: formData.hours + 0.5,
                      },
                      true
                    );
                  }}
                >
                  <Add />
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </>
      ) : null}

      <div className="mt-4">
        <Button
          type="submit"
          variant="primary"
          className="w-100 mb-4"
          style={{ fontFamily: "Noto Sans" }}
          disabled={Object.entries(formData.services).length === 0}
        >
          NEXT
        </Button>
        <Button
          type="button"
          variant="link"
          className="w-100"
          style={{ fontFamily: "Noto Sans" }}
          onClick={() => {
            resetJob();
            navigate("/bookings");
          }}
        >
          CANCEL REQUEST
        </Button>
      </div>
    </PaddedForm>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Select Help"}
      onStepBack={() => navigate("/find-help")}
    >
      <Helmet>
        <title>Services | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestServices;
