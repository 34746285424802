import styled from "styled-components";

export const CardContainer = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 48px;
  margin-top: 3rem;
  background-color: #fff;

  & h1 {
    font-size: 44px !important;
  }

  & h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    letter-spacing: 0.721154px;
    color: #4a4a4a;
  }

  & h2 {
    font-family: "Cabin";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 135%;
    letter-spacing: 0.721154px;
    color: #4a4a4a;
  }

  @media only screen and (max-width: 430px) {
    box-shadow: none;
    padding: 1rem;
    margin-top: 0;

    & h1 {
      font-size: 28px !important;
    }
  }
`;

export const InteralLayoutStyled = styled.div`
  & .welcome-background {
    background-color: #f8f2f1;
  }

  @media only screen and (max-width: 430px) {
    & .welcome-background {
      background-color: #fff;
    }
  }
`;

export const ButtonFooter = styled.div`
  & > .row > div:first-child {
    text-align: left;
  }

  & > .row > div:last-child {
    text-align: right;
  }

  @media only screen and (max-width: 430px) {
    & > .row > div {
      text-align: center !important;
      padding-bottom: 2rem;
    }
  }
`;

export const Photo = styled.img`
  display: block;
  height: 200px;
  width: 200px;
  margin: auto;
  margin-bottom: 2rem;
  padding: 0;
  border-radius: 50%;
`;
