import React from "react";
import { Link } from "react-router-dom";
import { DropdownButton, ButtonGroup, Dropdown } from "react-bootstrap";
import PermIdentity from "@mui/icons-material/PermIdentity";

const NavDropdown = ({ signOut, links }) => {
  return (
    <DropdownButton
      as={ButtonGroup}
      id={`dropdown-button-drop-start`}
      drop="start"
      variant="link"
      title={<PermIdentity />}
      className="ms-3"
    >
      {links.map((link, key) => {
        if (link.divider) {
          return <Dropdown.Divider key={key} />;
        }
        if (link.label === "Sign Out") {
          return (
            <Dropdown.Item
              key={key}
              eventKey={key + 1}
              as="button"
              varinat="link"
              onClick={() => signOut()}
            >
              {link.label}
            </Dropdown.Item>
          );
        }
        return (
          <Dropdown.Item
            key={key}
            eventKey={key + 1}
            as={Link}
            to={link.url}
            onClick={() => {
              if (typeof link.onClick === "function") {
                link.onClick();
              }
            }}
          >
            {link.label}
          </Dropdown.Item>
        );
      })}
    </DropdownButton>
  );
};

export default NavDropdown;
