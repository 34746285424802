import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Button } from "react-bootstrap";
import moment from "moment-timezone";

import ProfilePicture from "../common/ProfilePicture";
import StarRating from "../common/StarRating";

const HelperShortProfile = ({
  user,
  specialityCare = false,
  overnight = false,
  pricing = true,
  onSelect,
}) => {
  const rating = user.helper._ratings || { score: 0, count: 0 };

  return (
    <div
      className="helper-profile mb-4"
      style={{
        borderBottom: "1px solid var(--border-color)",
        paddingBottom: "1rem",
      }}
    >
      <Row>
        <Col xs={2}>
          <ProfilePicture user={user} />
        </Col>
        <Col>
          <h5 className="mb-1">
            <Button
              variant="link"
              className="m-0 p-0"
              style={{
                fontSize: "1.25rem",
                fontWeight: 700,
                textDecoration: "none",
              }}
              onClick={() => onSelect()}
            >
              {user.name}
            </Button>
          </h5>
          <div className="mb-1 d-flex justify-content-flex align-items-center gap-2">
            <StarRating
              rating={rating.score || 0}
              totalRatings={rating.count || 0}
            />

            <small className="text-muted">
              ~{Math.floor(user._approxDistance)} mi. away
            </small>
          </div>
          {user._availability ? (
            <p className="text-muted m-0">
              Available on {moment(user._availability[0]).format("MMM D")}
            </p>
          ) : null}
        </Col>
        {pricing ? (
          <Col xs={3}>
            <div className="text-end">
              <h5>
                ${user.helper.hourlyRates[overnight ? "overnight" : "standard"]}
              </h5>
              <p className="text-muted m-0">per hour</p>
            </div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

HelperShortProfile.propTypes = {
  user: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default HelperShortProfile;
