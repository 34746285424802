import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";

const ImageActionsModal = ({
  show,
  toggle,
  hasPhoto = false,
  onUpload,
  onRemove,
}) => {
  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h3>Change Profile Photo</h3>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="outline-primary"
          className="w-100 mb-3"
          onClick={() => {
            toggle();
            onUpload();
          }}
        >
          Upload Photo
        </Button>
        {!hasPhoto ? null : (
          <Button
            variant="outline-danger"
            className="w-100 mb-3"
            onClick={() => {
              toggle();
              onRemove();
            }}
          >
            Remove Current Photo
          </Button>
        )}
        <Button
          variant="outline-dark"
          className="w-100 mb-3"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
      </Modal.Body>
    </Modal>
  );
};

ImageActionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  hasPhoto: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default ImageActionsModal;
