import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";

const JoinMailingList = ({
  title,
  message,
  userType,
  onComplete,
  additionalData,
}) => {
  const navigate = useNavigate();
  const { formData, onChange } = useFormData({
    email: "",
    meta: additionalData || { zip: "" },
  });
  const saveEmail = useApiPost("/service-area/add-email", (res) => {
    if (userType === "helper") {
      window.gtag("event", "conversion", {
        send_to: "AW-11127239856/-iMQCOyWqPMYELDp8Lkp",
      });
    } else {
      window.gtag("event", "conversion", {
        send_to: "AW-11127239856/shTICNzNo_MYELDp8Lkp",
      });
    }

    if (typeof onComplete === "function") {
      onComplete(res);
    }
  });

  useEffect(() => {
    if (saveEmail.isSuccess) {
      alert("You have been successfully added to the mailing list.");
      navigate("/");
    }
  }, [saveEmail.isSuccess, navigate]);

  const onSubmit = (e) => {
    e.preventDefault();
    saveEmail.mutate({ ...formData, userType });
  };

  return (
    <form onSubmit={onSubmit}>
      <h3>{title || "Join our mailing list."}</h3>
      <p>
        {message ||
          "We are working on getting set up in your area. Join our mailing list to know when sign up is possible."}
      </p>
      {saveEmail.isError ? (
        <Alert variant="danger">{saveEmail.error.message}</Alert>
      ) : null}
      <FormGroup>
        <FormControl
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={onChange}
          required
        />
      </FormGroup>

      {!additionalData || !additionalData.zip ? (
        <FormGroup className="mt-3">
          <FormLabel>Your zip code:</FormLabel>
          <FormControl
            name="meta.zip"
            placeholder="Zip Code"
            value={formData.meta.zip}
            onChange={onChange}
            maxLength={6}
            style={{ maxWidth: 200 }}
            required
          />
        </FormGroup>
      ) : null}
      <hr />

      <div>
        <Button
          type="submit"
          variant="primary"
          className="w-100"
          style={{ fontFamily: "Noto Sans" }}
          disabled={
            formData.email.length < 5 ||
            saveEmail.isLoading ||
            saveEmail.isSuccess
          }
        >
          {saveEmail.isLoading ? "..." : "NEXT"}
        </Button>
      </div>
    </form>
  );
};

JoinMailingList.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  userType: PropTypes.string.isRequired,
  onComplete: PropTypes.func,
  additionalData: PropTypes.object,
};

export default JoinMailingList;
