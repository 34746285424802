import React, { useEffect } from "react";
import { Form, Modal, Button, Alert } from "react-bootstrap";
import moment from "moment-timezone";

import { useFormData } from "../../../hooks/useFormData";
import { useApi } from "../../../hooks/useApi";
import { useUser } from "../../../contexts/UserContext";
import { nl2br } from "../../../lib/stringHelpers";

const CancelRecurringModal = ({ show, toggle, job, onSave }) => {
  const user = useUser();
  const { formData, onChange, setData } = useFormData({
    cancelSelection: "single",
    jobRequestEventId: "",
    notes: "",
    events: [],
    ready: false,
  });

  const api = useApi({
    onError: (err) => alert(err.message),
  });

  useEffect(() => {
    if (!job) {
      return;
    }

    setData({
      cancelSelection: "single",
      jobRequestEventId: "",
      notes: "",
      events: [],
      ready: false,
    });

    api.call(`/bookings/${job._id}/events`, "get", {}, undefined, (res) => {
      setData({ events: [...res], ready: true }, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave({
      cancelSelection: formData.cancelSelection,
      jobRequestEventId: formData.jobRequestEventId,
      notes: formData.notes,
    });
    toggle();
  };

  const renderWarning = () => {
    if (formData.jobRequestEventId === "") {
      return null;
    }

    const event = formData.events.find(
      (e) => e._id === formData.jobRequestEventId
    );

    if (!event) {
      return null;
    }

    if (moment(event.startDate).diff(moment(), "hours") > 24) {
      return null;
    }

    const msg =
      user.userType === "helper"
        ? `Wait - our community is counting on you! Too many cancellations outside of our 24 hour cancellation window will ban you from being a Helper. Please call Manana at (615) 212-9609 or directly notify the care recipient you will not be able to help.\n\nAre you sure you want to continue with canceling?`
        : `Wait - our community is counting on you! Too many cancellations outside of our 24 hour cancellation window will ban you from being a customer.\n\nAre you sure you want to continue with canceling?`;

    return <Alert variant="warning">{nl2br(msg)}</Alert>;
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h3>Cancel Booking</h3>
      </Modal.Header>
      <Modal.Body>
        <p>Do you want to cancel a single occurance or the entire series?</p>
        <form id="cancel-modal-form" onSubmit={onSubmit} className="mt-3">
          <Form.Group className="mb-3 mt-3">
            <Form.Check
              type="radio"
              id="cancel-single"
              label="Single Occurance"
              value="single"
              onChange={() =>
                onChange({
                  target: { name: "cancelSelection", value: "single" },
                })
              }
              checked={formData.cancelSelection === "single"}
            />

            <Form.Check
              type="radio"
              label="Entire Series/Booking"
              id="cancel-series"
              value="series"
              onChange={() =>
                setData(
                  { cancelSelection: "series", jobRequestEventId: "" },
                  true
                )
              }
              checked={formData.cancelSelection === "series"}
            />
          </Form.Group>

          <Form.Group
            className={
              formData.cancelSelection === "single" ? "mb-3" : "d-none"
            }
          >
            <Form.Select
              name="jobRequestEventId"
              value={formData.jobRequestEventId}
              onChange={onChange}
              required={formData.cancelSelection === "single"}
            >
              <option value="">Select a date to cancel.</option>
              {formData.events.map((event, eventIdx) => {
                return (
                  <option key={eventIdx} value={event._id}>
                    {moment(event.startDate).format("MM/DD/YYYY")}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          <hr />

          <Form.Group className="mb-3">
            <Form.Label>Additional Notes/Comments</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={formData.notes}
              onChange={onChange}
              placeholder="Additional notes/comments..."
              style={{ minHeight: 150 }}
            />
          </Form.Group>
        </form>
        {renderWarning()}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          type="submit"
          form="cancel-modal-form"
          variant="primary"
          style={{ width: 200 }}
        >
          CANCEL BOOKING
        </Button>
        <Button
          variant="link"
          onClick={() => {
            toggle();
          }}
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelRecurringModal;
