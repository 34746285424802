import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import ButtonList from "../../common/ButtonList";
import { useApiGet } from "../../../hooks/useApi";

const SkillsAndExperience = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  const { isLoading: isServicesLoading, data: services } = useApiGet(
    "services",
    "/services"
  );

  const getServicesLabel = () => {
    if (isServicesLoading || !services) {
      return "Loading...";
    }

    const list = [];

    user.helper.servicesOffered.forEach((offered) => {
      const service = services.find((s) => offered.service === s._id);
      if (!service) {
        return;
      }
      list.push(service.name);
    });

    user.helper.specializedCare.forEach((s) => list.push(s));

    return list.join(", ").toLowerCase();
  };

  const getLicensesLabel = () => {
    if (
      !user.helper.profile ||
      !user.helper.profile.licenses ||
      user.helper.profile.licenses.length === 0
    ) {
      return "select your licenses";
    }

    return user.helper.profile.licenses.map((l) => l.title);
  };

  const getWorkExperienceLabel = () => {
    if (
      !user.helper.profile ||
      !user.helper.profile.workExperience ||
      user.helper.profile.workExperience.length === 0
    ) {
      return "enter your work experience";
    }

    return user.helper.profile.workExperience.map((work, i) => {
      return (
        <div key={i} className="mt-3">
          <h5 style={{ color: "#000", fontSize: "1.15rem" }}>
            {work.employer}, {work.title}
          </h5>
          <p className="mb-0">
            {moment.utc(work.startDate).format("MM/DD/YYYY")} -{" "}
            {work.currentJob
              ? "present"
              : moment.utc(work.endDate).format("MM/DD/YYYY")}
          </p>
          <p className="text-muted">{work.description}</p>
        </div>
      );
    });
  };

  const getEducationLabel = () => {
    const parts = [];
    if (user.helper.profile.education) {
      parts.push(user.helper.profile.education);
    }
    if (user.helper.profile.educationLocation) {
      parts.push(user.helper.profile.educationLocation);
    }
    return parts.join(" at ");
  };

  return (
    <SubInternalLayout
      onBack={() => navigate("/profile/skill-experience")}
      title={`Skills & Experience`}
    >
      <Helmet>
        <title>Skills &amp; Experiences | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <ButtonList
        buttons={[
          {
            label: "Services I'll Provide",
            desc: getServicesLabel(),
            hasContent: true,
            onClick: () => navigate("/profile/skills-experience/services"),
          },
          {
            label: "Licenses",
            desc: getLicensesLabel(),
            hasContent: true,
            onClick: () => navigate("/profile/skills-experience/licenses"),
          },
          {
            label: "Languages",
            desc:
              user.helper.profile && user.helper.profile.languages
                ? user.helper.profile.languages.join(", ").toLowerCase()
                : "select your languages spoken",
            hasContent: true,
            onClick: () => navigate("/profile/skills-experience/languages"),
          },
          {
            label: "Work Experience",
            desc: getWorkExperienceLabel(),
            hasContent: true,
            onClick: () =>
              navigate("/profile/skills-experience/work-experience"),
          },
          {
            label: "Education",
            desc: getEducationLabel(),
            hasContent: true,
            onClick: () => navigate("/profile/skills-experience/education"),
          },
        ]}
      />
    </SubInternalLayout>
  );
};

export default SkillsAndExperience;
