import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";

import { useUser } from "../../contexts/UserContext";
import { useApiPost } from "../../hooks/useApi";
import useEffectOnce from "../../hooks/useEffectOnce";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import {
  InteralLayoutStyled,
  CardContainer,
} from "./components/Welcome.elements";

const WelcomeBackgroundPaymentComplete = () => {
  const { user, updateUser } = useUser();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const submit = useApiPost(
    "/users/background-check/payment",
    (res) => {
      updateUser({
        ...res,
        _welcomeStatus: {
          ...user._welcomeStatus,
          backgroundCheckSubmitted: true,
          backgroundCheckPaid: true,
        },
      });

      window.gtag("event", "conversion", {
        send_to: "AW-11127239856/QgIBCO6n1pgYELDp8Lkp",
      });

      toast.success("Your background check has been successfully submitted.");

      navigate("/welcome/list");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffectOnce(() => {
    const query = Object.fromEntries(searchParams);

    if (query.redirect_status !== "succeeded") {
      navigate("/welcome/background-check/pay");
    } else if (query.redirect_status === "succeeded") {
      submit.mutate({ ...query });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const render = () => {
    if (submit.isError) {
      return (
        <>
          <h2 className="mb-4">Unexpected Error</h2>
          <p className="body1">
            Unfortunately, we encountered an error while trying to submit your
            background check.
          </p>
          <div>
            <Button
              variant="primary"
              onClick={() =>
                submit.mutate({ ...Object.fromEntries(searchParams) })
              }
            >
              Try Again
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <h2 className="mb-4">Completing your payment...</h2>
        <p className="body1">
          Please be patient while we complete your payment.
        </p>
      </>
    );
  };

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Background Check Submit | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5 text-center">
          <WelcomeSteps
            currentStep={4}
            totalSteps={4}
            onBack={() => navigate("/welcome/background/pay")}
          />
          {render()}
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeBackgroundPaymentComplete;
