import moment from "moment-timezone";
import numeral from "numeral";

export const getUserRateFromJobRequest = (user, jobRequest) => {
  switch (true) {
    case jobRequest.isOvernight === true:
      return user.helper.hourlyRates.overnight || false;
    case jobRequest.specializedCare && jobRequest.specializedCare.length > 0:
      return user.helper.hourlyRates.specialized || false;
    default:
      return user.helper.hourlyRates.standard;
  }
};

export const getMessageTitle = (msg, convoWith, user) => {
  switch (msg.communicationType) {
    case "system":
      return "Manana Support";
    case "job":
      return msg.helper === user._id || msg.helper === convoWith._id
        ? `Booking on ${moment(msg.date).format("ddd, MMMM D")}`
        : "New help request";
    default:
      return `${moment(msg.date).format("ddd, MMMM D")} with ${convoWith.name}`;
  }
};

export const getAvailabilityWindowTitle = (job) => {
  if (job.availability.jobType === "Recurring" && job.availability.rrule) {
    let str = `${job.availability.rrule} starting on ${moment(
      job.availability.startDate,
      "YYYY-MM-DD"
    ).format("ddd, MMM Do")}`;

    if (job.availability.endDate) {
      str += ` until ${moment(job.availability.endDate, "YYYY-MM-DD").format(
        "ddd, MMM Do"
      )}`;
    }

    return str;
  }

  return `${job.isOvernight ? "Overnight on " : ""}${moment(
    job.availability.startDate,
    "YYYY-MM-DD"
  ).format("ddd, MMM Do")}`;
};

export const getNotOpenReason = (job, user) => {
  const d = job.declinedBy.find((by) => by.helper === user._id);

  if (job.canceledOn !== null) {
    return "This request was canceled by the care recipient.";
  } else if (job.helper && job.helper !== user._id) {
    return "This request was accepted by another helper.";
  } else if (d) {
    return "You declined this request.";
  } else {
    return "This request has expired.";
  }
};

export const getIsOpen = (job, user) => {
  const startDt = moment.utc(job.dates.startDateTime);
  const endDt = moment(startDt).set({
    hour: job.dates.endTime.split(":")[0],
    minutes: job.dates.endTime.split(":")[1],
  });

  // job request has expired
  if (moment.utc().valueOf() >= endDt.valueOf()) {
    return false;
  }

  // job has been accepted by another helper
  if (job.helper && job.helper._id !== user._id) {
    return false;
  }

  // user declined the job
  const d = job.declinedBy.find((id) => id === user._id);

  if (d) {
    return false;
  }

  return true;
};

const getServiceFeeRate = (job, isHelper) => {
  if (isHelper) {
    return job.serviceFee && job.serviceFee.helper
      ? job.serviceFee.helper
      : 0.2;
  }

  return job.serviceFee && job.serviceFee.customer
    ? job.serviceFee.customer
    : 0.2;
};

const getCostDetails = (job, rate, isHelper = false, isOvernight = false) => {
  const subTotal = isOvernight ? rate : rate * job.hours;
  const serviceFeeRate = getServiceFeeRate(job, isHelper);
  const fee = subTotal * serviceFeeRate;
  const total = isHelper ? subTotal - fee : subTotal + fee;

  return {
    rate,
    hours: job.hours,
    subTotal,
    fee,
    total,
    display: numeral(total).format("0,0.00"),
  };
};

export const getPayoutDetails = (user, job) => {
  // quick math if the user is the helper
  if (user.userType === "helper") {
    const cachedRate = job.requestedHelperRates.find(
      (r) => r.helperId === user._id
    );
    return getCostDetails(job, cachedRate.helperRate, true);
  }

  // if we have a helper that's accepted
  if (job.helper) {
    const cachedRate = job.requestedHelperRates.find(
      (r) => r.helperId === job.helper._id
    );
    return getCostDetails(job, cachedRate.helperRate, false, job.isOvernight);
  }

  // so we have multiple helpers requested and need to get a price range
  let low = 0;
  let high = 0;

  job.requestedHelpers.forEach((h) => {
    const cachedRate = job.requestedHelperRates.find(
      (r) => r.helperId === h._id
    );
    const rate = cachedRate.helperRate;

    if (rate < low || low === 0) {
      low = rate;
    }

    if (rate > high || high === 0) {
      high = rate;
    }
  });

  low = getCostDetails(job, low, false, job.isOvernight);
  high = getCostDetails(job, high, false, job.isOvernight);

  if (low.display === high.display) {
    return low;
  }

  return [low, high];
};

export const getJobStatusForUser = (user, job) => {
  // has been canceled
  if (job.jobStatus === "Booking Canceled") {
    const canceledDate =
      job.event && job.event.canceledOn ? job.event.canceledOn : job.canceledOn;
    return [
      "canceled",
      `This booking was canceled on ${moment(canceledDate).format(
        "MM/DD/YYYY"
      )}.`,
    ];
  }

  // has been completed
  if (
    job.jobStatus === "Booking Complete" ||
    (job.event && job.event.actualCompletionDatetime)
  ) {
    return ["complete", "This booking has been completed."];
  }

  // is currently active/in progress
  if (
    job.jobStatus === "Booking Active" ||
    (job.event &&
      moment(job.event.startDate).diff(moment(), "minutes") < 15 &&
      !job.event.actualCompletionDatetime &&
      !job.event.completedTransactionId)
  ) {
    return ["active", "This booking is currently in progress."];
  }

  // has expired without a helper accepting
  if (job.jobStatus === "Booking Expired" && !job.helper) {
    return ["expired", "This booking expired without a helper accepting."];
  }

  // has expired without a helper accepting
  if (job.jobStatus === "Booking Expired") {
    return ["expired", "This booking has expired."];
  }

  // has been rejected by all helpers
  if (job.requestedHelpers.length === job.declinedBy.length) {
    return ["rejected-all", "All selected helpers declined the booking."];
  }

  // has been rejected by the user
  if (job.declinedBy.includes(user._id)) {
    return ["rejected", "You rejected this booking."];
  }

  // has been accepted by a different helper
  if (user.userType === "helper" && job.helper && job.helper._id !== user._id) {
    return ["accepted-other", "This booking was accepted by another helper."];
  }

  // has been accepted and is within 15 minutes of start
  if (
    (job.jobStatus === "Booking Ready" ||
      job.jobStatus === "Booking Confirmed") &&
    (job.customer._id === user._id || job.helper._id === user._id)
  ) {
    // if no events are in the queue, then it is likely still processing
    if (!job.event) {
      return [
        "processing",
        "This booking is currently being processed. Please check back momentarily.",
      ];
    }

    // if recurring and the next job event is canceled
    if (job.event && job.event.isCanceled) {
      return [
        "canceled-instance",
        "This instance is canceled and unavailable to start.",
      ];
    }

    // get the time difference for making sure it's within the time slot start
    const diff = moment().diff(moment(job.event.startDate), "minutes");

    // ready to start
    if (diff >= -60 && diff <= 120) {
      return ["ready", "This booking is ready to start."];
    }

    return ["accepted", "You accepted this booking."];
  }

  return ["pending", "This booking is waiting on a helper to accept it."];
};
