import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Uppy from "@uppy/core";
import AwsS3 from "@uppy/aws-s3";
import { DashboardModal, useUppy } from "@uppy/react";

import { makeRequest } from "../../hooks/useApi";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { Button } from "react-bootstrap";

const FileUploadButton = ({
  btnLabel = "Add File",
  className,
  style = {},
  onUpload,
  forwardRef = null,
}) => {
  const [open, setOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const uppy = useUppy(() => {
    return new Uppy({
      id: "uppy",
      restrictions: {
        maxFileSize: 10000000, //10MB
        allowedFileTypes: [
          "image/*",
          ".pdf",
          ".doc",
          ".docx",
          ".json",
          ".csv",
          ".xls",
          ".xlsx",
        ],
        maxNumberOfFiles: 1,
      },
      autoProceed: false,
      debug: true,
    })
      .use(AwsS3, {
        getUploadParameters(file) {
          return makeRequest("post", "/uploads/presign-upload", {
            fileName: file.name,
            contentType: file.type,
          }).then((res) => {
            return { method: "PUT", url: res.url, fields: [] };
          });
        },
      })
      .on("upload-success", (file, response) => {
        setFileUrl(response.uploadURL);
        // uppy.reset();
        setOpen(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      try {
        uppy.close({ reason: "unmount" });
      } catch (err) {
        // console.log("UNMOUNT ERROR: ", err);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fileUrl && typeof onUpload === "function") {
      onUpload(fileUrl);
    }
  }, [fileUrl, onUpload]);

  return (
    <>
      <Button
        variant="link"
        onClick={() => {
          setOpen(true);
        }}
        className={className || "p-0 m-0"}
        style={{ cursor: "pointer", ...style }}
        ref={forwardRef}
      >
        {btnLabel}
      </Button>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside={true}
        autoOpenFileEditor={true}
        open={open}
        onRequestClose={() => setOpen(false)}
        metaFields={[{ id: "name", name: "Name", placeholder: "File Name" }]}
      />
    </>
  );
};

FileUploadButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  cropperOpts: PropTypes.object,
  imgQuality: PropTypes.number,
};

export default FileUploadButton;
