import styled from "styled-components";

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;
export const ProfilePhoto = styled.div`
  flex: 30%;
  @media (max-width: 768px) {
    flex: 100%;
  }
`;

export const ProfileTitle = styled.div`
  flex: 70%;
  @media (max-width: 768px) {
    flex: 100%;
  }
`;

export const ProfileAvailability = styled.div`
  font-family: "Cabin";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.32px;
  color: #4a4a4a;
  padding: 2rem 0;
`;
