import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import PublicLayout from "./PublicLayout";
import ContentBlock from "../common/ContentBlock";
import StepProgress from "../common/StepProgress";
import MobileSteps from "../helper/components/MobileSteps";
import JobRequestProgressBar from "../jobRequest/JobRequestProgressBar";
import {
  RegistrationTitle,
  HideOnMobile,
} from "../common/Registration.elements";

const WizardPublicLayout = styled.div`
  & .header-wrapper {
    background-position: top right;
    background-size: auto 1400px;
  }

  & .content-block {
    padding: 3rem;
  }

  @media only screen and (max-width: 430px) {
    & .content-block {
      margin: 0 -1.5rem;
      margin-bottom: -2rem;
      border-radius: 0;
      padding: 1rem 0.5rem !important;
    }

    & .mobile-progress-bar {
      margin: 0 -1.5rem;
    }
  }

  @media only screen and (max-width: 1350px) {
    & .header-wrapper {
      background-position: top right;
      background-size: auto 1200px;
    }
  }
`;

const RegisterLayout = ({
  title,
  steps,
  totalSteps = 0,
  activeStep = 0,
  onStepBack,
  children,
  titleStyle = {},
  isJobRequest = false,
  wrapInContentBlock = true,
  bodyContent = null,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <WizardPublicLayout>
      <PublicLayout
        backgroundImage={`${process.env.REACT_APP_AWS_BUCKET_URL}/wizard_layout_bg.svg`}
        backgroundImageMobile={`${process.env.REACT_APP_AWS_BUCKET_URL}/hero-banner-bg-2.svg`}
        header={
          <Container>
            <Row>
              <Col sm={12} md={6}>
                <RegistrationTitle style={titleStyle}>
                  {title}
                </RegistrationTitle>
              </Col>
              <Col sm={12} md={6}>
                {steps || isJobRequest ? (
                  <HideOnMobile>
                    <ContentBlock className="p-3">
                      {!isJobRequest ? (
                        <StepProgress steps={steps} active={activeStep} />
                      ) : (
                        <JobRequestProgressBar stage={activeStep} />
                      )}
                    </ContentBlock>
                  </HideOnMobile>
                ) : null}

                {isJobRequest ? (
                  <JobRequestProgressBar
                    isMobile={true}
                    stage={activeStep}
                    onBack={() => {
                      if (typeof onStepBack === "function") {
                        onStepBack();
                      }
                    }}
                  />
                ) : null}

                {steps ? (
                  <MobileSteps
                    stepsTotal={steps ? steps.length : totalSteps}
                    step={activeStep}
                    onBack={() => {
                      if (typeof onStepBack === "function") {
                        onStepBack();
                      }
                    }}
                  />
                ) : null}

                {wrapInContentBlock ? (
                  <ContentBlock style={{ padding: "40px 1rem" }}>
                    {children}
                  </ContentBlock>
                ) : (
                  <>{children}</>
                )}
              </Col>
            </Row>
          </Container>
        }
      >
        {bodyContent}
      </PublicLayout>
    </WizardPublicLayout>
  );
};

export default RegisterLayout;
