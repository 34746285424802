/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import { toast } from "react-toastify";

import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";

import { useUser } from "../../contexts/UserContext";
import { useFormData } from "../../hooks/useFormData";
import InternalLayout from "../layout/InternalLayout";
import { InternalResponsiveColumn } from "../common/Common.elements";
import { useApiGet, useApiPut, useApiPost } from "../../hooks/useApi";
import RawUploadButton from "../common/RawUploadButton";
import Tabs from "../common/Tabs";

const StyledUploadWrapper = styled.div`
  width: 100%;
  border: 2px dashed #ccc;
  background: #fdfdfd;
  padding: 6px;
  text-align: center;
  margin-bottom: 1rem;

  & > div {
    margin: 2rem 1rem;
  }

  & > img {
    width: 100%;
  }
`;

const StripeStatus = () => {
  const [images, setImages] = useState({ front: null, back: null });
  const { user, updateUser } = useUser();
  const { formData, onChange } = useFormData({
    ssn: "",
    accountNumber: "",
    routingNumber: "",
    accountType: "individual",
    street1: "",
    city: "",
    state: "",
    zip: "",
  });
  const queryClient = useQueryClient();
  const { isLoading, data } = useApiGet(
    "user-profile",
    "/users/profile",
    { docUploadQrCode: 1 },
    {
      staleTime: 5000,
      onError: (err) => toast.error(err.message),
      onSuccess: (res) => {
        updateUser(res);

        if (res._welcomeStatus.isStripeClear) {
          /* window._gtag("event", "conversion", {
            send_to: "AW-11127239856/_nrdCKzs1pgYELDp8Lkp",
          }); */
        }
      },
    }
  );

  const saveStripe = useApiPut(
    "/users/stripe-identity/ssn",
    () => {
      toast.success("Your account identity was successfully updated.");
      queryClient.invalidateQueries("user-profile");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const saveStripeOther = useApiPut(
    "/users/stripe-identity/other",
    () => {
      toast.success("Your account identity was successfully updated.");
      queryClient.invalidateQueries("user-profile");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const saveAddress = useApiPut(
    "/users/stripe-identity/address",
    () => {
      toast.success("Your account address was successfully updated.");
      queryClient.invalidateQueries("user-profile");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const saveBankAccount = useApiPost(
    "/payments/connect-bank-accounts",
    () => {
      toast.success("Your bank account was successfully added.");
      queryClient.invalidateQueries("user-profile");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const renderSsnField = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveStripe.mutate({ ssn: formData.ssn });
        }}
      >
        <p className="mb-5">
          Please update your SSN or government issued tax ID number.
        </p>
        <Row>
          <Col>
            <Form.Control
              type="text"
              name="ssn"
              value={formData.ssn}
              onChange={onChange}
              placeholder="SSN or Tax ID"
            />
          </Col>
          <Col>
            <Button
              type="submit"
              variant="primary"
              style={{ width: 200 }}
              disabled={saveStripe.isLoading}
            >
              Save
            </Button>
          </Col>
        </Row>
        <hr />
      </form>
    );
  };

  const renderIdUploads = () => {
    return (
      <div>
        <p className="fw-bold mb-5">
          Please upload a picture of the front and back of a government issued
          ID.
        </p>
        <Row>
          <Col xs={12} sm={8}>
            <Row>
              <Col xs={12}>
                <StyledUploadWrapper>
                  {images.front ? (
                    <img
                      src={images.front}
                      title="Front Image"
                      alt="Front Image"
                    />
                  ) : (
                    <div>
                      <RawUploadButton
                        btnLabel="Upload Front Image"
                        // eslint-disable-next-line no-undef
                        endpoint={`${process.env.REACT_APP_API_URL}/users/stripe-documents/front`}
                        onUpload={(res) => {
                          setImages({ ...images, front: res?.file?.preview });
                        }}
                        cropperOpts={{ viewMode: 1 }}
                        imgQuality={0.5}
                      />
                    </div>
                  )}
                </StyledUploadWrapper>
              </Col>
              <Col xs={12}>
                <StyledUploadWrapper>
                  {images.back ? (
                    <img
                      src={images.back}
                      title="Back Image"
                      alt="Back Image"
                    />
                  ) : (
                    <div>
                      <RawUploadButton
                        btnLabel="Upload Back Image"
                        // eslint-disable-next-line no-undef
                        endpoint={`${process.env.REACT_APP_API_URL}/users/stripe-documents/back`}
                        onUpload={(res) => {
                          setImages({ ...images, back: res?.file?.preview });
                        }}
                        cropperOpts={{ viewMode: 1 }}
                        imgQuality={0.5}
                      />
                    </div>
                  )}
                </StyledUploadWrapper>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4} className="d-xs-none text-center">
            <p>
              Or, upload using your mobile device by scanning the QR Code below.
            </p>
            <img
              src={data?._docUploadQrCode?.qr}
              title="Scan With Mobile Device"
              alt="Scan With Mobile Device"
            />
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  const renderExternalAccount = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveBankAccount.mutate({
            accountNumber: formData.accountNumber,
            routingNumber: formData.routingNumber,
            accountType: formData.accountType,
          });
        }}
      >
        <p className="mb-5">
          Add an external bank account for your payouts. Please use a checking
          account and not a savings account.
        </p>

        <Row>
          <Col xs={12} sm={3}>
            <Form.Group className="mb-3">
              <Form.Label>Account type</Form.Label>
              <Form.Select
                name="accountType"
                value={formData.accountType}
                onChange={onChange}
              >
                <option value="individual">Individual</option>
                <option value="company">Company</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={4}>
            <Form.Group className="mb-3">
              <Form.Label>Routing Number</Form.Label>
              <Form.Control
                name="routingNumber"
                onChange={onChange}
                value={formData.routingNumber}
                placeholder="Routing Number"
                required={true}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={5}>
            <Form.Group className="mb-3">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                name="accountNumber"
                onChange={onChange}
                value={formData.accountNumber}
                placeholder="Account Number"
                required={true}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          type="submit"
          className="mt-3 mb-3"
          variant="primary"
          style={{ width: 200 }}
          disabled={
            saveBankAccount.isLoading ||
            formData.routingNumber.length < 6 ||
            formData.accountNumber < 6
          }
        >
          Save Bank Account
        </Button>
        <hr />
      </form>
    );
  };

  const renderAddressForm = () => {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          saveAddress.mutate({
            street1: formData.street1,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
          });
        }}
      >
        <p>
          Please enter your address below. This must be a physical address and
          cannot be a PO Box.
        </p>

        <Form.Group className="mb-3">
          <Form.Label>Street Address</Form.Label>
          <Form.Control
            type="text"
            name="street1"
            placeholder="Street Address"
            value={formData.street1}
            required={true}
            onChange={onChange}
          />
        </Form.Group>
        <Row>
          <Col xs={12} sm={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="City"
                value={formData.city}
                required={true}
                onChange={onChange}
              />
            </Form.Group>
          </Col>
          <Col xs={7} sm={4} md={4}>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select
                size="lg"
                name="state"
                value={formData.state}
                onChange={onChange}
                required={true}
                style={{
                  flex: 1,
                  fontSize: "1rem",
                }}
              >
                <option value="">State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={5} sm={4} md={2}>
            <Form.Group className="mb-3">
              <Form.Label>Zip Code</Form.Label>
              <Form.Control
                type="text"
                name="zip"
                placeholder="Zip Code"
                value={formData.zip}
                required={true}
                onChange={onChange}
                maxLength={5}
              />
            </Form.Group>
          </Col>
        </Row>

        <Button
          type="submit"
          className="mt-3 mb-3"
          variant="primary"
          style={{ width: 200 }}
          disabled={
            saveAddress.isLoading ||
            formData.street1.length < 6 ||
            formData.state.length < 2 ||
            formData.zip.length < 5
          }
        >
          Save Address
        </Button>
        <hr />
      </form>
    );
  };

  const renderInternalIssues = () => {
    return (
      <div>
        <p>
          It looks like your payment account has encountered an unexpected issue
          during verification.
        </p>
        <div>
          <Button
            variant="primary"
            onClick={() => {
              saveStripeOther.mutate({
                business_profile: {
                  url: `https://www.mananahelp.com/`,
                },
              });
            }}
            disabled={saveStripeOther.isLoading}
          >
            {saveStripeOther.isLoading
              ? "Submitting..."
              : "Resubmit Verification"}
          </Button>
        </div>
      </div>
    );
  };

  const render = () => {
    if (isLoading || !data) {
      return <p>Loading...</p>;
    }

    if (user?._welcomeStatus?.isStripeClear) {
      return (
        <Alert variant="success">
          <CheckCircle />
          <span className="ms-3">
            Your payments and tax information are all set and ready for
            payments!
          </span>
        </Alert>
      );
    }

    if (!user?.helper?.backgroundCheck?.checkrId) {
      return (
        <Alert variant="warning">
          <Warning />
          <span className="ms-3">
            Please complete the background check submission form to continue.
          </span>
        </Alert>
      );
    }

    if (
      !user?._welcomeStatus?.stripeRequirements ||
      user?._welcomeStatus?.stripeRequirements.length === 0
    ) {
      return (
        <Alert variant="warning">
          <Warning />
          <span className="ms-3">
            Please contact support about your account. There appears to be an
            issue with your payment account.
          </span>
        </Alert>
      );
    }

    const tabs = [];
    const has = {};

    user._welcomeStatus.stripeRequirements.forEach((need) => {
      switch (true) {
        case need === "external_account":
          tabs.push({
            label: "Bank Account",
            content: renderExternalAccount(),
          });
          break;

        case need === "individual.verification.document":
          tabs.push({
            label: "Identity Verification Document",
            content: renderIdUploads(),
          });
          break;

        case need === "individual.id_number" ||
          need === "individual.ssn_last_4":
          tabs.push({ label: "SSN Verification", content: renderSsnField() });
          break;

        case need.indexOf("address") > -1:
          if (!has.address) {
            has.address = true;
            tabs.push({
              label: "Address",
              content: renderAddressForm(),
            });
          }
          break;

        case need.indexOf("invalid_url_website_") > -1:
          tabs.push({
            label: "Internal Issues",
            content: renderInternalIssues(),
          });
          break;

        default:
        // do nothing
      }
    });

    if (tabs.length === 0) {
      return (
        <div>
          <p>
            Your payment status cannot be properly identified. Please contact
            support.
          </p>
        </div>
      );
    }

    return (
      <div>
        <Tabs tabs={tabs} />
      </div>
    );
  };

  return (
    <InternalLayout>
      <Container>
        <Row>
          <InternalResponsiveColumn>
            <h2 className="mb-5">Ready For Payments</h2>
            {render()}
          </InternalResponsiveColumn>
        </Row>
      </Container>
    </InternalLayout>
  );
};

export default StripeStatus;
