import styled from "styled-components";

export const RegistrationTitle = styled.h1`
  margin-top: 40%;
  padding-right: 5rem;

  @media only screen and (max-width: 430px) {
    padding: 0;
    margin: 0 -0.5rem 4rem -0.5rem;
    text-align: center;
    font-size: 28px !important;
    font-weight: 700 !important;
    line-height: 37.52px !important;
    text-align: left !important;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const HideOnMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SubmitButton = styled.button`
  display: inline-block;
  height: 48px;
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  border: 1px solid #205b68;
  border-radius: 25px;
  color: white;
  background-color: #205b68;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? ".65" : "1")};
  width: 200px;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ServiceBackdrop = styled.div`
  background: #ffffff;
  opacity: 0.6;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
`;
