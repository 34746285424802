import moment from "moment-timezone";
import { RRule } from "rrule";
import { rruleDayOfWeekByWeekday } from "../../../../lib/dateHelpers";

export const getApiPayload = (payload) => {
  const { _id, ...data } = payload;
  const dt = moment(payload.date);
  const startTime = data.startTime.split(":");

  // set the time
  dt.set({ hour: startTime[0], minutes: startTime[1] });
  data.startDateTime = dt.toDate();
  data.rrule = null;

  // add in the recurring rrule stuff
  if (payload.repeats) {
    const dt = moment(payload.date);
    const startTime = data.startTime.split(":");

    // set the time
    dt.set({ hour: startTime[0], minutes: startTime[1] });
    data.startDateTime = dt.toDate();

    // setart the config
    let rruleConfig = {
      dtstart: moment.utc(dt).toDate(),
      wkst: RRule.SU,
      interval: 1,
      byweekday: data.repeatsOn.map((wd) => rruleDayOfWeekByWeekday(wd)),
    };

    if (payload.endDate) {
      rruleConfig.until = moment.utc(payload.endDate, "YYYY-MM-DD").toDate();
    }

    switch (true) {
      // eslint-disable-next-line no-fallthrough
      case payload.repeatsSchedule === "weekly":
        rruleConfig = { ...rruleConfig, freq: RRule.WEEKLY };
        break;

      case payload.repeatsSchedule === "every 2 weeks":
        rruleConfig = { ...rruleConfig, freq: RRule.WEEKLY, interval: 2 };
        break;

      case payload.repeatsSchedule === "every month":
        rruleConfig = { ...rruleConfig, freq: RRule.MONTHLY };
        break;

      default:
      // does not really repeat
    }

    // update the payload
    data.rrule = new RRule(rruleConfig).toString();
  }

  return data;
};
