import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { Helmet } from "react-helmet-async";

import useHelper from "../../hooks/useHelper";
import RegisterLayout from "../layout/RegisterLayout";
import { HideOnMobile, SubmitButton } from "../common/Registration.elements";
import { SetupTitle } from "../common/Title.elements";
import JoinMailingList from "../common/JoinMailingList";
import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import { PaddedForm } from "../common/PaddedForm";
import WhyBecomeAHelper from "../common/WhyBecomeAHelper";

const HelperRegisterServiceArea = () => {
  const { helper, saveHelper } = useHelper();
  const { formData, onChange } = useFormData({
    zip: helper.zip || "",
    miles: helper.miles || "20",
    serviceArea: helper.serviceArea || null,
  });
  const navigate = useNavigate();

  const checkZip = useApiPost("/service-area/check-zip", (res) => {
    saveHelper({
      serviceArea: res,
      zip: formData.zip,
      miles: formData.miles,
    });
    navigate("/become-a-helper/services");
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    checkZip.mutate({ ...formData });
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const renderForm = () => {
    return (
      <PaddedForm onSubmit={onSubmit} style={{ marginTop: "5px" }}>
        <HideOnMobile>
          <Button
            type="button"
            variant="link"
            onClick={() => navigate(-1)}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          >
            <ArrowBack />
          </Button>
        </HideOnMobile>

        <FormGroup>
          <SetupTitle>What's your zip code?</SetupTitle>
          <FormControl
            type="text"
            maxLength={5}
            name="zip"
            placeholder="Zip code"
            style={{ width: 150 }}
            value={formData.zip}
            onChange={onChange}
            data-testid="zip-code"
            required
          />
        </FormGroup>
        <hr />

        <FormGroup>
          <SetupTitle>
            How far are you willing to travel to provide help?
          </SetupTitle>
          <FormControl
            type="text"
            maxLength={3}
            name="miles"
            placeholder="Miles"
            style={{ width: 150, display: "inline-block" }}
            value={formData.miles}
            onChange={onChange}
            data-testid="distance"
            required
          />{" "}
          <span
            style={{
              fontSize: "1.25rem",
              fontWeight: 600,
              paddingLeft: "24px",
            }}
          >
            miles
          </span>
        </FormGroup>
        <hr />

        <div className="text-end">
          <SubmitButton
            type="submit"
            name="next"
            disabled={
              formData.zip.length < 5 ||
              formData.miles.length === 0 ||
              parseInt(formData.miles) <= 0 ||
              checkZip.isLoading
            }
          >
            {checkZip.isLoading ? "..." : "NEXT"}
          </SubmitButton>
        </div>
      </PaddedForm>
    );
  };

  return (
    <RegisterLayout
      title="Where are you located?"
      steps={["Location", "Services", "Business", "Account"]}
      activeStep={0}
      onStepBack={() => navigate("/")}
      bodyContent={<WhyBecomeAHelper />}
    >
      <Helmet>
        <title>Become A Helper | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {checkZip.isError ? (
        <JoinMailingList
          userType="helper"
          additionalData={{
            zip: formData.zip,
            miles: formData.miles,
          }}
        />
      ) : (
        renderForm()
      )}
    </RegisterLayout>
  );
};

export default HelperRegisterServiceArea;
