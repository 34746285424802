import React from "react";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import InternalLayout from "../layout/InternalLayout";
import HelperFullCalendar from "./helper/HelperFullCalendar";
import CustomerCalendar from "./customer/CustomerCalendar";

const Calendar = () => {
  const { user } = useUser();

  return (
    <InternalLayout noMaxWidth={true}>
      <Helmet>
        <title>Calendar | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container fluid>
        <h2 className="mb-5">Calendar</h2>

        {user.helper ? (
          <HelperFullCalendar user={user} />
        ) : (
          <CustomerCalendar user={user} />
        )}
      </Container>
    </InternalLayout>
  );
};

export default Calendar;
