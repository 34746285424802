import React from "react";
import { Row, Col } from "react-bootstrap";
import numeral from "numeral";
import styled from "styled-components";

import { useUser } from "../../../contexts/UserContext";
import { getPayoutDetails } from "../../../lib/jobRequestHelpers";

const FooterWrapper = styled.div`
  border-bottom: none !important;

  & > div {
    border-bottom: 1px solid var(--border-color);
    padding: 1rem 0;
  }

  & > div:last-child {
    border-bottom: none;
  }

  & span {
    color: #4a4a4a;
    font-family: Cabin;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.5625px;
    text-align: left;
  }
`;

const DetailsFooter = ({ job }) => {
  const { user } = useUser();
  const payoutDetails = getPayoutDetails(user, job);

  const renderKey = (key) => {
    if (!Array.isArray(payoutDetails)) {
      return numeral(payoutDetails[key]).format("$0,0.00");
    }

    return `${numeral(payoutDetails[0][key]).format("$0,0.00")} - ${numeral(
      payoutDetails[1][key]
    ).format("$0,0.00")}`;
  };

  const renderRateLabel = () => {
    if (job.isOvernight) {
      return `Overnight for ${job.hours} hours`;
    }

    return Array.isArray(payoutDetails)
      ? `${job.hours} hours`
      : `${job.hours} hours @ ${numeral(payoutDetails.rate).format(
          "$0,0.00"
        )}/hr`;
  };

  return (
    <FooterWrapper>
      <h3>{user.userType === "helper" ? "Payout details" : "Total cost"}</h3>
      <div>
        <Row>
          <Col>
            <span>{renderRateLabel()}</span>
          </Col>
          <Col className="text-end">{renderKey("subTotal")}</Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col>
            <span>Service Fee</span>
          </Col>
          <Col className="text-end">{renderKey("fee")}</Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col>
            <h3>Total</h3>
          </Col>
          <Col className="text-end">
            <h3>{renderKey("total")}</h3>
          </Col>
        </Row>
      </div>
    </FooterWrapper>
  );
};

export default DetailsFooter;
