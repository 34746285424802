import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormControl, FormCheck } from "react-bootstrap";

import "./css/ServicesList.css";

const ServiceListItemSubForm = ({ service, values, role, onChange }) => {
  const getQuestion = (question, index) => {
    switch (question.questionType) {
      case "checkboxes":
        let vals = values[question.key] || [];

        return (
          <div key={index}>
            {/* <h3 className='mb-3'>{question.question[role]}</h3> */}
            {question.options[role].map((o, i) => {
              return (
                <FormGroup
                  key={i}
                  className="mb-3"
                  controlId={`cb-${question._id}-${i}`}
                  style={{
                    borderBottom: "1px solid var(--border-color)",
                    paddingBottom: "1rem",
                  }}
                >
                  <FormCheck
                    type="checkbox"
                    label={o}
                    value={o}
                    checked={vals.includes(o)}
                    size="lg"
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (
                          o === "I don't have a car" ||
                          o === "I do not use any of these"
                        )
                          vals = [o];
                        else {
                          vals = (
                            vals.filter(
                              (v) =>
                                v !== "I don't have a car" &&
                                v !== "I do not use any of these"
                            ) || []
                          ).concat([o]);
                        }
                      } else {
                        vals = vals.filter((v) => v !== o);
                      }

                      const newAnswers = { ...values, [question.key]: vals };
                      onChange(newAnswers);
                    }}
                  />
                </FormGroup>
              );
            })}
          </div>
        );
      default:
        const val = values[question.key] || "";

        return (
          <FormGroup>
            <h3>{question.question[role]}</h3>
            <FormControl
              type="text"
              name={question.key}
              value={val || ""}
              onChange={(e) => {
                const newAnswers = {
                  ...values,
                  [question.key]: e.target.value,
                };
                onChange(newAnswers);
              }}
            />
          </FormGroup>
        );
    }
  };

  return (
    <div style={{ padding: "2rem 0 0 5rem" }}>
      {service.additionalQuestions.map((q, i) => {
        return getQuestion(q, i);
      })}
    </div>
  );
};

ServiceListItemSubForm.propTypes = {
  service: PropTypes.object.isRequired,
  values: PropTypes.object,
  role: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
};

export default ServiceListItemSubForm;
