import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PersonOutline from "@mui/icons-material/PersonOutline";
import get from "lodash.get";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import InternalLayout from "../layout/InternalLayout";
import ButtonList from "../common/ButtonList";
import { useApiGet, useApiPut } from "../../hooks/useApi";
import { useProfile } from "../../hooks/useProfile";
import useOnboard from "../../hooks/useOnboard";
import UploadButton from "../common/UploadButton";
import ImageActionsModal from "../common/ImageActionsModal";

const Profile = () => {
  const uploadBtnRef = useRef();
  const { user, updateUser } = useUser();
  const { setTitle } = usePage();
  const [showImageActions, setShowImageActions] = useState(false);
  const navigate = useNavigate();
  const { showOnBoarding } = useOnboard();
  const { profileId } = useParams();
  const profile = useProfile(profileId);

  // this is just a call to pre-cache the services list
  useApiGet("services", "/services", null, {
    staleTime: 300000,
  });

  const updateProfile = useApiPut(
    "/users/profile",
    (res) => {
      updateUser(res);

      // flag the google tag if the user just submitted their about us AND already have a profile picture
      if (user.helper.profile.description) {
        /* window._gtag("event", "conversion", {
          send_to: "AW-11127239856/kiONCOSc3JgYELDp8Lkp",
        }); */
      }
    },
    {
      onError: (err) => {
        alert(err.message);
      },
    }
  );

  useEffect(() => {
    if (
      user.customer &&
      !profileId &&
      (!user.customer.profiles || user.customer.profiles.length === 0)
    ) {
      navigate(`/profile/add`);
    } else if (user.customer && !profileId) {
      navigate(`/profile/${user.customer.profiles[0]._id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  useEffect(() => {
    setTitle("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasContent = (fields) => {
    if (!Array.isArray(fields) || fields.length === 0) {
      return false;
    }

    let hasVal = false;
    const obj = user.helper ? user.helper : profile;

    fields.forEach((f) => {
      const v = get(obj, f, null);
      if (v !== null) {
        hasVal = true;
      }
    });

    return hasVal;
  };

  const links = user.helper
    ? [
        {
          label: "Personal information",
          hasContent: true,
          onClick: () => navigate("/profile/personal-information"),
        },
        {
          label: "Skills and experience",
          hasContent: true,
          onClick: () => navigate("/profile/skills-experience"),
        },
        {
          label: "About me",
          hasContent: hasContent(["profile.description"]),
          onClick: () => navigate("/profile/about-me"),
        },
        {
          label: "Emergency Contacts",
          hasContent: hasContent(["profile.emergencyContacts"]),
          onClick: () => navigate(`/profile/emergency-contacts`),
        },
        {
          label: "Reviews",
          hasContent: true,
          onClick: () => navigate(`/profile/reviews`),
        },
      ]
    : [
        {
          label: "About Me",
          hasContent: hasContent(["description"]),
          onClick: () => navigate(`/profile/${profileId}/about-me`),
        },
        {
          label: "Personal information",
          hasContent: true,
          onClick: () => navigate(`/profile/${profileId}/personal-information`),
        },
        {
          label: "Emergency Contacts",
          hasContent: hasContent(["emergencyContacts"]),
          onClick: () => navigate(`/profile/${profileId}/emergency-contacts`),
        },
        {
          label: "Health Information",
          hasContent: hasContent(["healthInformation"]),
          onClick: () => navigate(`/profile/${profileId}/health-information`),
        },
        {
          label: "Reviews",
          hasContent: true,
          onClick: () => navigate(`/profile/${profileId}/reviews`),
        },
      ];

  if (user.customer && !profile) {
    return <p>Redirecting...</p>;
  }

  return (
    <InternalLayout>
      <Helmet>
        <title>Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>
        {showOnBoarding ? (
          <Button
            type="button"
            variant="link"
            onClick={() => navigate(-1)}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          >
            <ArrowBack />
          </Button>
        ) : null}

        <h2 className="mb-3">Profile</h2>

        <Row>
          <Col xs={12} sm={3}>
            {profile.profilePicture ? (
              <img
                src={profile.profilePicture}
                alt={profile.name}
                className="profile-photo"
                style={{ cursor: "pointer" }}
                onClick={() => setShowImageActions(true)}
              />
            ) : (
              <>
                <div
                  className="profile-photo"
                  onClick={() => setShowImageActions(true)}
                  style={{ cursor: "pointer" }}
                >
                  <PersonOutline />
                </div>
                <Button
                  variant="link"
                  onClick={() => setShowImageActions(true)}
                  style={{
                    fontWeight: 500,
                    textDecoration: "none",
                    textTransform: "uppercase",
                    width: "100%",
                    maxWidth: 150,
                    margin: 0,
                    padding: 0,
                    textAlign: "center",
                  }}
                >
                  Upload Photo
                </Button>
              </>
            )}
          </Col>
          <Col xs={12} sm={9}>
            <h2 style={{ marginTop: 75 }}>
              {user.customer ? profile.name : user.name}
            </h2>
          </Col>
        </Row>

        <ButtonList className="mt-5 mb-5" buttons={links} />
      </Container>
      <ImageActionsModal
        show={showImageActions}
        toggle={() => setShowImageActions(false)}
        hasPhoto={profile.profilePicture ? true : false}
        onUpload={() => {
          uploadBtnRef.current.click();
        }}
        onRemove={() => {
          if (
            window.confirm(
              "Are you sure you want to remove the current picture?"
            )
          ) {
            const payload = {};

            if (user.helper) {
              payload["helper.profile.profilePicture"] = null;
            } else {
              payload["customer.profiles"] = [...user.customer.profiles];

              // get the profile currently selected by id
              const p = payload["customer.profiles"].find(
                (pro) => pro._id === profile._id
              );

              // update the record from the spread above
              p.profilePicture = null;
            }

            updateProfile.mutate(payload);
          }
        }}
      />
      <UploadButton
        forwardRef={uploadBtnRef}
        btnLabel="Upload Photo"
        className="d-none"
        onUpload={(url) => {
          const payload = {};

          if (user.helper) {
            payload["helper.profile.profilePicture"] = url;
          } else {
            payload["customer.profiles"] = [...user.customer.profiles];

            // get the profile currently selected by id
            const p = payload["customer.profiles"].find(
              (pro) => pro._id === profile._id
            );

            // update the record from the spread above
            p.profilePicture = url;
          }

          updateProfile.mutate(payload);
        }}
      />
    </InternalLayout>
  );
};

export default Profile;
