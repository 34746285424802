import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { useUser } from "../../contexts/UserContext";
import { useApiPost } from "../../hooks/useApi";
import useEffectOnce from "../../hooks/useEffectOnce";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import ContentBlock from "../common/ContentBlock";
import StepProgress from "../common/StepProgress";
import CreditCardPaymentForm from "../common/CreditCardPaymentForm";
import {
  InteralLayoutStyled,
  CardContainer,
} from "./components/Welcome.elements";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const WelcomeBackgroundPay = () => {
  const { user } = useUser();
  const [paymentIntent, setPaymentIntent] = useState({
    paymentIntentId: null,
    clientSecret: null,
  });
  const navigate = useNavigate();

  const createPaymentIntent = useApiPost(
    "/payments/background-check-payment-intent",
    (res) => {
      setPaymentIntent({
        paymentIntentId: res.paymentIntentId,
        clientSecret: res.clientSecret,
      });
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    if (user.helper?.backgroundCheck?.stripeTransactionId) {
      navigate("/welcome");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnce(() => {
    if (!paymentIntent.paymentIntentId) {
      createPaymentIntent.mutate();
    }
  });

  const content = (
    <>
      <h2 className="mb-4">There is a $35 fee to run a background check.</h2>
      <p className="body1">
        Manana will reimburse helpers after you've earned $150 in bookings. The
        average helper rate in your area is $18/hour, so you only need to work
        8.5 hours to receive your money back.
      </p>
      <br />

      {!paymentIntent.paymentIntentId ? (
        <p className="text-center lead">Setting up your payment form...</p>
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: paymentIntent.clientSecret }}
        >
          <CreditCardPaymentForm
            buttonLabel="SUBMIT"
            redirectTo={"/welcome/background/pay-complete"}
          />
        </Elements>
      )}
      <br />

      <div className="d-flex justify-content-between">
        <LockOutlinedIcon style={{ marginRight: "0.5rem" }} />
        <p className="body1 text-grey">
          Your personal information is securely encrypted and never shared
          without your expressed consent.
        </p>
      </div>
    </>
  );

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Background Check Pay | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5 text-center">
          <WelcomeSteps
            currentStep={4}
            totalSteps={4}
            onBack={() => navigate("/welcome/background/legal")}
          />

          <ContentBlock>
            <StepProgress
              steps={["Information", "Legal", "$35 Fee"]}
              active={2}
            />
          </ContentBlock>

          <ContentBlock style={{ padding: "3rem 1rem" }}>
            {content}
          </ContentBlock>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeBackgroundPay;
