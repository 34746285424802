import React from "react";
import { Form, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import styled from "styled-components";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import human from "humanparser";

import { useUser } from "../../../contexts/UserContext";
import { useApiPost } from "../../../hooks/useApi";
import { useFormData } from "../../../hooks/useFormData";
import { nl2br } from "../../../lib/stringHelpers";
import { StyledDot } from "./Messages.elements";

const StyledButtonWrapper = styled.div`
  text-align: right;
`;

const StyledButton = styled(Button)`
  width: 200px;
  margin: 12px 0;
`;

const StyledChat = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  & .messages {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  & .message {
    border-radius: 20px;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: inline-block;
    min-width: 200px;
  }

  & .yours {
    align-items: flex-start;
  }

  & .yours .message {
    margin-right: 25%;
    background-color: #eee;
    position: relative;
  }

  & .yours .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: -7px;
    height: 20px;
    width: 20px;
    background: #eee;
    border-bottom-right-radius: 15px;
  }

  & .yours .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-right-radius: 10px;
  }

  & .mine {
    align-items: flex-end;
  }

  & .mine .message {
    color: #333;
    margin-left: 25%;
    /* background: linear-gradient(to bottom, #00d0ea 0%, #0085d1 100%); */
    background: linear-gradient(
      to bottom,
      var(--med-secondary-color) 0%,
      var(--med-secondary-color) 100%
    );
    background-attachment: fixed;
    position: relative;
  }

  & .mine .message.last:before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background: linear-gradient(
      to bottom,
      var(--med-secondary-color) 0%,
      var(--med-secondary-color) 100%
    );
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }

  & .mine .message.last:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: white;
    border-bottom-left-radius: 10px;
  }

  & .message > strong {
    display: block;
  }
`;

const UserMessages = ({ withUser, messages }) => {
  const { user } = useUser();
  const { formData, onChange } = useFormData({ message: "" });
  const queryClient = useQueryClient();

  const sendMessage = useApiPost(
    `/communications/to/${withUser._id}`,
    () => {
      toast.success("Your message has been successfully sent.");
      queryClient.invalidateQueries(`messages-${withUser._id}`);
      onChange({ target: { name: "message", value: "" } });
    },
    { onError: (err) => toast.error(err.message) }
  );

  const onSubmit = (e) => {
    e.preventDefault();
    sendMessage.mutate({ comment: formData.message });
  };

  const displayUserName = (u) => {
    if (u.userType === "system") {
      return "Manana";
    }

    const { firstName } = human.parseName(u.name);

    return firstName;
  };

  const renderChatList = () => {
    // we need to break the messages up into groups of yours vs mine
    // so that they were blocked out togetehr
    const groups = [];
    let lastUserId = null;

    messages.forEach((msg) => {
      if (lastUserId !== msg.fromUser._id) {
        groups.push([]);
      }

      const curIndex = groups.length - 1;

      groups[curIndex].push(msg);
    });

    return groups.map((group, groupIdx) => {
      return (
        <div
          key={groupIdx}
          className={`messages ${
            group[0].fromUser._id === user._id ? "mine" : "yours"
          }`}
        >
          {group.map((msg, msgIdx) => {
            return (
              <div
                key={msgIdx}
                className={`message ${
                  msgIdx === group.length - 1 ? "last" : ""
                }`}
              >
                <div className="float-end">
                  <span className="caption1" style={{ fontSize: "0.75rem" }}>
                    {moment(msg.date).format("MMM D").toUpperCase()}
                  </span>
                  <StyledDot className="dot" />
                </div>
                <strong className="caption1">
                  {displayUserName(msg.fromUser)}
                </strong>
                <div className="mb-0">{nl2br(msg.comment)}</div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div>
      <StyledChat>{renderChatList()}</StyledChat>
      <br />
      <form onSubmit={onSubmit}>
        <Form.Control
          as="textarea"
          name="message"
          value={formData.message}
          onChange={onChange}
          placeholder="Type your reply"
        />
        <StyledButtonWrapper>
          <StyledButton
            type="submit"
            variant="primary"
            disabled={
              sendMessage.isLoading || formData.message.trim().length <= 1
            }
          >
            {sendMessage.isLoading ? "Sending..." : "Send"}
          </StyledButton>
        </StyledButtonWrapper>
      </form>
    </div>
  );
};

export default UserMessages;
