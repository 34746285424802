import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import Calendar from "../../common/Calendar";

const StyledAvail = styled.div`
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

const CalendarPicker = ({
  calDateSel,
  data,
  onDateSelect,
  date,
  highlightPast = true,
}) => {
  const renderAvailability = () => {
    return (
      <Row>
        {calDateSel.timeslots.map((row, index) => {
          const start = moment(row.dates.start);
          const end = moment(row.dates.end);
          return (
            <Col key={index} xs={12} sm={6} md={4}>
              <StyledAvail key={index}>
                <Row>
                  <Col>
                    <Link to={`/admin/users/${row.user._id}`}>
                      {row.user.name}
                    </Link>
                    <br />
                    <span>
                      From {start.format("h:mm A")} to {end.format("h:mm A")}
                    </span>
                  </Col>
                  <Col>
                    <span>
                      <a
                        href={`mailto:${row.user.email}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {row.user.email}
                      </a>
                    </span>
                    <br />
                    <span>
                      {row.user.settings.mobile ? (
                        <a
                          href={`tel:+1${row.user.settings.mobile.replace(
                            /[^\d]/g,
                            ""
                          )}`}
                        >
                          {row.user.settings.mobile}
                        </a>
                      ) : (
                        "No mobile number on file"
                      )}
                    </span>
                  </Col>
                </Row>
              </StyledAvail>
            </Col>
          );
        })}
      </Row>
    );
  };

  const getFormattedData = () => {
    const dates = [];
    const today = moment().startOf("day").valueOf();

    if (data) {
      data.forEach((row) => {
        const dt = moment(row.dates.start);

        if (!highlightPast && today > dt.valueOf()) {
          return;
        }

        dates.push(dt.format("YYYY-MM-DD"));
      });
      return dates;
    }
  };

  return (
    <>
      <Calendar
        date={date}
        isLoading={false}
        selectedDates={calDateSel.day ? [calDateSel.day] : []}
        highlightDates={getFormattedData()}
        onDateSelect={onDateSelect}
      />

      {renderAvailability()}
    </>
  );
};

CalendarPicker.propTypes = {
  calDateSel: PropTypes.object.isRequired,
  data: PropTypes.array,
  onDateSelect: PropTypes.func.isRequired,
  date: PropTypes.object.isRequired,
};

CalendarPicker.defaultProps = {
  data: [],
};

export default CalendarPicker;
