import React from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";

const FiltersPanel = ({ formData, onChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Search</Form.Label>
        <Form.Control
          type="search"
          name="search"
          value={formData.search || ""}
          placeholder="Search..."
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>User Type</Form.Label>
        <Form.Select
          name="userType"
          value={formData.userType}
          onChange={onChange}
        >
          <option value="">All Types</option>
          <option value="helper">Helper</option>
          <option value="customer">Customer</option>
          <option value="admin">Admin</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Zip Code</Form.Label>
        <Form.Control
          name="zipCode"
          value={formData.zipCode}
          onChange={onChange}
          placeholder="Zip Code"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Zip Code Radius</Form.Label>
        <Form.Control
          type="number"
          step={1}
          name="zipRadius"
          value={formData.zipRadius}
          onChange={onChange}
          placeholder="Zip Codes In Radius"
        />
        <small className="text-muted">Optional Radius To Search</small>
      </Form.Group>
      <hr />
      <Button type="submit" variant="primary" className="w-100">
        SEARCH
      </Button>
    </form>
  );
};

export default FiltersPanel;
