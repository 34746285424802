import React, { useEffect } from "react";
import { Row, Col, Form, Modal, Button } from "react-bootstrap";
import moment from "moment-timezone";

import { useFormData } from "../../../hooks/useFormData";

const ArrivalModal = ({ show, toggle, job, onSave }) => {
  const { formData, onChange, setData } = useFormData({
    arrivalTime: null,
    endTime: null,
    enabled: true,
    start: moment(),
    end: moment(),
    notes: "",
  });

  useEffect(() => {
    if (!job) {
      return;
    }

    const dt = job.dates.arrivalDateTime
      ? moment(job.dates.arrivalDateTime)
      : moment(job.dates.startDateTime);

    const start = moment(job.dates.startDateTime);
    const end = moment(start).add(job.hours, "hours");

    setData({
      arrivalTime: dt.format("HH:mm"),
      arrivalDateTime: dt.format(),
      start,
      end,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const onSubmit = (e) => {
    e.preventDefault();
    onSave({ ...formData });
    toggle();
  };

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h3>Set Your Arrival Time</h3>
      </Modal.Header>
      <Modal.Body>
        <form id="arrival-modal-form" onSubmit={onSubmit} className="mt-3">
          <p>
            Please enter a start time between {formData.start.format("h:mm a")}{" "}
            and {formData.end.format("h:mm a")}.
          </p>
          <Row>
            <Col>
              <Form.Control
                name="arrivalTime"
                type="time"
                value={formData.arrivalTime}
                onChange={(e) => {
                  if (e.target.value === "") {
                    setData({
                      ...formData,
                      arrivalTime: "",
                      arrivalDateTime: formData.start.format(),
                      enabled: false,
                    });
                    return;
                  }

                  const parts = e.target.value.split(":");
                  const dt = moment(job.dates.startDateTime).set({
                    hour: parts[0],
                    minute: parts[1],
                  });

                  setData({
                    ...formData,
                    arrivalTime: e.target.value,
                    arrivalDateTime: dt.format(),
                    enabled:
                      dt.valueOf() >= formData.start.valueOf() &&
                      dt.valueOf() <= formData.end.valueOf(),
                  });
                }}
                required
                size="lg"
              />
            </Col>
            <Col xs={1}>
              <h3 className="text-center mt-2">-</h3>
            </Col>
            <Col>
              <h3 className="mt-2">
                {moment(formData.arrivalDateTime)
                  .add(job.hours, "hours")
                  .format("h:mm a")}
              </h3>
            </Col>
          </Row>

          <Form.Group className="mb-3 mt-3">
            <Form.Label>Additional Notes/Comments</Form.Label>
            <Form.Control
              as="textarea"
              name="notes"
              value={formData.notes}
              onChange={onChange}
              placeholder="Additional notes/comments..."
              style={{ minHeight: 150 }}
            />
          </Form.Group>
        </form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          type="submit"
          form="arrival-modal-form"
          variant="primary"
          style={{ width: 200 }}
          disabled={!formData.enabled}
        >
          SET ARRIVAL TIME
        </Button>
        <Button
          variant="link"
          onClick={() => {
            toggle();
          }}
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArrivalModal;
