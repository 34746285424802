import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import { useApiPut } from "../../hooks/useApi";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import {
  InteralLayoutStyled,
  CardContainer,
  ButtonFooter,
  Photo,
} from "./components/Welcome.elements";
import UploadButton from "../common/UploadButton";

const WelcomePhoto = () => {
  const { user, updateUser } = useUser();
  const [photo, setPhoto] = useState(
    get(user, "helper.profile.profilePicture", null)
  );
  const { showOnBoarding } = useOnboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfile = useApiPut(
    "/users/profile",
    (res) => {
      updateUser(res);
      navigate("/welcome/about");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const save = () => {
    const payload = { "helper.profile.profilePicture": photo };

    updateProfile.mutate(payload);
  };

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Photo | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5">
          <WelcomeSteps
            currentStep={1}
            totalSteps={4}
            onBack={() => navigate("/welcome")}
          />

          <h2 className="mb-3">Upload a profile photo</h2>

          <p className="body1">
            Please upload a clear picture of yourself. Using anime and your pets
            on social media is okay, but care recipients like seeing the face of
            the person who will be helping them.
          </p>

          <div className="mb-5 mt-5 text-center">
            {photo ? <Photo src={photo} alt="" title="" /> : null}

            {photo ? (
              <UploadButton
                btnLabel="CHANGE PHOTO"
                className={
                  photo
                    ? "btn btn-lg btn-outline-primary w-90"
                    : "btn btn-lg btn-primary w-90"
                }
                onUpload={(url) => {
                  setPhoto(url);
                }}
              />
            ) : null}
          </div>

          <ButtonFooter>
            <Row>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
                <Link
                  to="/welcome/about"
                  className="btn btn-link btn-lg mb-5"
                  style={{
                    textDecoration: "none",
                    fontWeight: 700,
                    margin: 0,
                    padding: 0,
                  }}
                >
                  I'LL DO THIS LATER
                </Link>
              </Col>

              <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
                {photo ? (
                  <Button
                    variant="primary"
                    size="lg"
                    style={{ width: "90%" }}
                    disabled={!photo || updateProfile.isLoading}
                    onClick={() => save()}
                    className="mb-3"
                  >
                    {updateProfile.isLoading
                      ? "SAVING..."
                      : "SAVE AND CONTINUE"}
                  </Button>
                ) : (
                  <UploadButton
                    btnLabel="ADD PHOTO"
                    btnVariant="primary"
                    className={
                      photo
                        ? "btn btn-lg btn-outline-primary w-90"
                        : "btn btn-lg btn-primary w-90"
                    }
                    onUpload={(url) => {
                      setPhoto(url);
                    }}
                  />
                )}
              </Col>
            </Row>
          </ButtonFooter>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomePhoto;
