import styled from "styled-components";

export const Container = styled.div`
  font-size: 1rem;
  font-weight: 750;
  text-align: center;
  line-height: 3rem;
  text-transform: uppercase;
  color: #857070;
  background-color: transparent;
`