import React, { useState } from "react";
import PropTypes from "prop-types";

import VehicleFormItem from "./VehicleFormItem";

const VehicleForm = ({ isHelper, setVehicles, previousValues = [] }) => {
  const [formData, setFormData] = useState({
    wheelchair: previousValues.includes("A wheelchair") ? true : false,
    walker: previousValues.includes("A walker") ? true : false,
    cane: previousValues.includes("A cane") ? true : false,
    vehicleAvailable: previousValues.includes("I don't have a car")
      ? true
      : false,
  });

  const onChange = (e) => {
    const updatedForm = { ...formData, [e.target.name]: e.target.value };
    let value = [];
    if (e.target.name === "vehicleAvailable" && e.target.value) {
      updatedForm.wheelchair = false;
      updatedForm.walker = false;
      updatedForm.cane = false;
      value = ["I don't have a car"];
    } else {
      updatedForm.vehicleAvailable = false;
      value = [
        ...(updatedForm.wheelchair ? ["A wheelchair"] : []),
        ...(updatedForm.walker ? ["A walker"] : []),
        ...(updatedForm.cane ? ["A cane"] : []),
      ];
    }
    const hasVehicle = value.length >= 1 && value[0] !== "I don't have a car";
    value = value.length < 1 ? null : value;

    // Require that at least 1 option is selected
    setFormData(updatedForm);
    setVehicles(value, hasVehicle);
  };

  return (
    <div>
      <h3 className="mb-3">
        {isHelper
          ? "Which of the following can your car accommodate?"
          : "Do you use any of the following?"}
      </h3>
      <VehicleFormItem
        item={{
          label: "A wheelchair",
          icon: "Wheelchair.svg",
          name: "wheelchair",
        }}
        formData={formData}
        onChange={onChange}
      />
      <VehicleFormItem
        item={{
          label: "A walker",
          icon: "Walker.svg",
          name: "walker",
        }}
        formData={formData}
        onChange={onChange}
      />
      <VehicleFormItem
        item={{
          label: "A cane",
          icon: "Cane.svg",
          name: "cane",
        }}
        formData={formData}
        onChange={onChange}
      />
      {isHelper ? (
        <VehicleFormItem
          item={{
            label: "I don't have a car",
            icon: "Transportation_48px.svg",
            name: "vehicleAvailable",
          }}
          formData={formData}
          onChange={onChange}
        />
      ) : null}
    </div>
  );
};

VehicleForm.propTypes = {
  isHelper: PropTypes.bool,
};

export default VehicleForm;
