import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "react-bootstrap";

import { useApiGet } from "../../hooks/useApi";
import ServiceListItem from "./ServiceListItem";

import "./css/ServicesList.css";

const ServicesList = ({ role = "helper", values = {}, onChange }) => {
  const [formData, setFormData] = useState({});
  const [selections, setSelections] = useState({});
  const [openSubList, setOpenSubList] = useState(null);

  const { isLoading, data: services } = useApiGet(
    "services",
    "/services",
    null,
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const fd = {};

    Object.keys(values).forEach((s) => (fd[s] = true));

    setFormData(fd);
    setSelections(values);
  }, [values]);

  const onSelectionChange = (e) => {
    if (e.target.additionalQuestions) {
      return;
    }

    let newFormData = { ...formData, [e.target.name]: e.target.value };
    let newSelections = { ...selections, [e.target.name]: e.target.answers };

    // remove the selection if it is unchecked (value === false)
    if (!e.target.value) {
      delete newFormData[e.target.name];
      delete newSelections[e.target.name];
    }

    setFormData(newFormData);
    setSelections(newSelections);

    const combined = {};

    Object.keys(newFormData).forEach((k) => {
      combined[k] = newSelections[k] || null;
    });

    onChange(combined);

    if (e.target.value) {
      setOpenSubList(e.target.name);
    } else {
      setOpenSubList(null);
    }
  };

  return (
    <FormGroup>
      <h3 className="mb-3">
        {role === "customer"
          ? "Select the type of help you need"
          : "Select the types of help you can provide"}
      </h3>
      {isLoading ? (
        <p>Loading services...</p>
      ) : (
        services.map((service, i) => {
          return (
            <ServiceListItem
              service={service}
              isSubListOpen={openSubList === service._id}
              key={i}
              isChecked={formData[service._id] || false}
              onSelectionChange={onSelectionChange}
              selections={selections[service._id] || {}}
              toggleSelection={() => setOpenSubList(null)}
              toggleSubForm={() =>
                setOpenSubList(openSubList ? null : service._id)
              }
              role={role}
            />
          );
        })
      )}
    </FormGroup>
  );
};

ServicesList.propTypes = {
  role: PropTypes.oneOf(["helper", "customer"]),
  values: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default ServicesList;
