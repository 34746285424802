import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: columns;
  }
`

export const SubmitButton = styled.button`
  display: inline-block;
  height: 48px;
  font-family: Noto Sans, sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  border: 1px solid #205b68;
  border-radius: 25px;
  color: white;
  background-color: #205b68;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${props => props.disabled ? '.65' : '1'};
  width: 200px;

  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const NewHelperContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: none;
  }
`

export const NewHelperCol = styled.div`
  flex: 1 0;
`

export const NewHelperSideText = styled.div`
  minHeight: 300px;
  padding: 35% 72px 0 0;
  vertical-align: center;
`;

export const NewHelperContentMobile = styled.div`
  background-color: white;
  padding: 2rem;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const HideOnMobile = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

export const ServiceBackdrop = styled.div`
  background: #FFFFFF;
  opacity: 0.6;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
`