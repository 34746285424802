import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";
import InputMask from "react-input-mask";

import AddCircle from "@mui/icons-material/AddCircle";
import Edit from "@mui/icons-material/Edit";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Prompt from "../../common/Prompt";

const WorkExperienceForm = ({ data, onComplete, onBack }) => {
  const { user, updateUser } = useUser();
  const [redirect, setRedirect] = useState(false);
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    employer: data.employer || "",
    title: data.title || "",
    currentJob: data.currentJob || false,
    startDate: data.startDate
      ? moment.utc(data.startDate).format("MM/YYYY")
      : "",
    endDate: data.endDate ? moment.utc(data.endDate).format("MM/YYYY") : "",
    description: data.description || "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
    setRedirect(true);
  });

  useEffect(() => {
    if (redirect) {
      onBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirect]);

  const checkDate = (dt, label) => {
    if (!/\d{4}-\d{2}-\d{2}/.test(dt)) {
      toast.error(`Invalid date for ${label}.`);
      return false;
    }

    if (
      moment.utc(dt, "YYYY-MM-DD").valueOf() <
      moment.utc().subtract(50, "years").valueOf()
    ) {
      toast.error(`The ${label} cannot be more than 50 years ago.`);
      return false;
    }

    if (moment.utc(dt, "YYYY-MM-DD").valueOf() > moment.utc().valueOf()) {
      toast.error(`The ${label} cannot be greater than the current date.`);
      return false;
    }

    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const workExperience = get(user, "helper.profile.workExperience") || [];

    // update the data in the user profile
    const payload = {
      "helper.profile.workExperience": [...workExperience],
    };

    // format the dates
    const fData = { ...formData };
    fData.startDate = fData.startDate
      ? moment.utc(fData.startDate, "MM/YYYY").format("YYYY-MM-DD")
      : null;
    fData.endDate = fData.endDate
      ? moment.utc(fData.endDate, "MM/YYYY").format("YYYY-MM-DD")
      : null;

    // verify the dates
    if (!checkDate(fData.startDate, "start date")) {
      return;
    } else if (!checkDate(fData.endDate, "end date")) {
      return;
    }

    // merge in the data
    if (data._id) {
      const index = workExperience.findIndex((w) => w._id === data._id);
      if (index === undefined) {
        alert("Could not find the record in your profile any longer.");
        onBack();
        return;
      } else {
        workExperience[index] = { ...workExperience[index], ...fData };
        payload["helper.profile.workExperience"] = workExperience;
      }
    } else {
      payload["helper.profile.workExperience"].push(fData);
    }

    // update the user profile
    save.mutate(payload);
  };

  const onDelete = () => {
    const workExperience = get(user, "helper.profile.workExperience") || [];

    // update the data in the user profile
    const payload = {
      "helper.profile.workExperience": [...workExperience].filter(
        (w) => w._id !== data._id
      ),
    };

    if (payload["helper.profile.workExperience"].length === 0) {
      payload["helper.profile.workExperience"] = null;
    }

    save.mutate(payload);
  };

  return (
    <SubInternalLayout title={`Work Experience`} onBack={() => onBack()}>
      <Helmet>
        <title>Work Experience | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Employer</Form.Label>
          <Form.Control
            name="employer"
            placeholder="employer name"
            required
            onChange={onChange}
            value={formData.employer}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            name="title"
            placeholder="job title"
            required
            onChange={onChange}
            value={formData.title}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            id="currentJob"
            label="This is my current job."
            onChange={(e) =>
              onChange({
                target: { name: "currentJob", value: !formData.currentJob },
              })
            }
            checked={formData.currentJob}
          />
        </Form.Group>

        <Row className="mb-3">
          <Col xs={5} md={3}>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control
                as={InputMask}
                mask="99/9999"
                name="startDate"
                placeholder="start date"
                required
                onChange={onChange}
                value={formData.startDate}
              />
            </Form.Group>
          </Col>
          <Col xs={2} md={1} className="text-center">
            <Form.Group className="mt-4">
              <Form.Text className="text-center">
                <strong>-</strong>
              </Form.Text>
            </Form.Group>
          </Col>
          <Col xs={5} md={3}>
            <Form.Group className="mb-3">
              <Form.Label>End Date</Form.Label>
              <Form.Control
                as={InputMask}
                mask="99/9999"
                name="endDate"
                placeholder="end date"
                required={formData.currentJob === false}
                disabled={formData.currentJob !== false}
                onChange={onChange}
                value={formData.endDate}
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group className="mb-3">
          <div className="float-end">
            <small className="text-muted">
              {formData.description.length}/250 Characters
            </small>
          </div>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            name="description"
            placeholder="job description"
            required
            onChange={onChange}
            value={formData.description}
            maxLength={250}
            style={{ minHeight: 125 }}
          />
        </Form.Group>

        <div className="d-flex justify-content-between align-items-center mt-3">
          {data._id ? (
            <Button
              type="button"
              variant="danger"
              disabled={save.isLoading}
              onClick={() => {
                if (
                  window.confirm(
                    "Are you sure you want to delete this work experience?"
                  )
                ) {
                  onDelete();
                }
              }}
            >
              Delete
            </Button>
          ) : (
            <div />
          )}
          <Button type="submit" disabled={!hasChanged || save.isLoading}>
            {save.isLoading ? <SpinIcon /> : "Save Changes"}
          </Button>
        </div>
      </form>

      <br />
      <br />
    </SubInternalLayout>
  );
};

const WorkExperience = () => {
  const { user } = useUser();
  const [edit, setEdit] = useState(null);

  const renderWorkExperience = () => {
    if (!user.helper.profile || !user.helper.profile.workExperience) {
      return null;
    }

    return user.helper.profile.workExperience.map((work, i) => {
      return (
        <div key={i}>
          <Row>
            <Col xs={10} sm={11}>
              <h5 className="m-0 p-0">
                {work.employer}, {work.title}
              </h5>
            </Col>
            <Col xs={2} sm={1}>
              <Button variant="link" onClick={() => setEdit(work)}>
                <Edit />
              </Button>
            </Col>
          </Row>
          <p className="text-muted mb-0" style={{ fontWeight: 700 }}>
            {moment.utc(work.startDate).format("MM/YYYY")} -{" "}
            {work.currentJob
              ? "present"
              : moment.utc(work.endDate).format("MM/YYYY")}
          </p>
          <p className="text-muted">{work.description}</p>
        </div>
      );
    });
  };

  if (edit !== null) {
    return (
      <WorkExperienceForm
        data={edit}
        onComplete={() => setEdit(null)}
        onBack={() => setEdit(null)}
      />
    );
  }

  return (
    <SubInternalLayout title={`Work Experience`}>
      {renderWorkExperience()}
      <hr />
      <Button
        variant="link"
        style={{ textDecoration: "none" }}
        onClick={() => setEdit({})}
      >
        <AddCircle /> ADD ANOTHER
      </Button>
    </SubInternalLayout>
  );
};

WorkExperienceForm.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    employer: PropTypes.string,
    title: PropTypes.string,
    currentJob: PropTypes.bool,
    startDate: PropTypes.string, // date string
    endDate: PropTypes.string, // date string
  }),
  onComplete: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default WorkExperience;
