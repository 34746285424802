import styled from "styled-components";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export const TabLinks = styled.div`
  margin: 0;
  margin-bottom: 1rem;
  padding: 0;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid var(--border-color);
  display: ${(props) => (props.justifyLinks ? "flex" : "block")};
  justify-content: ${(props) =>
    props.justifyLinks ? "space-between" : "none"};
`;

export const TabButton = styled(Button)`
  display: inline-block;
  color: #857070 !important;
  margin-right: ${(props) => (props.justifyLinks ? "0" : "2rem")};
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none !important;
  margin-bottom: -10px;
  padding-bottom: 0.5rem;

  border-radius: 0px !important;

  @media (max-width: 430px) {
    margin-bottom: 0px;
  }

  &.active {
    color: var(--primary-color) !important;
    border-bottom: 2px solid var(--primary-color);
  }
`;

export const TabLink = styled(Link)`
  display: inline-block;
  color: #857070 !important;
  margin-right: ${(props) => (props.justifyLinks ? "0" : "2rem")};
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none !important;
  margin-top: 14px;
  margin-bottom: -10px;
  padding-bottom: 0.5rem;

  @media (max-width: 430px) {
    /* margin-bottom: 0px; */
  }

  &.active {
    color: var(--primary-color) !important;
    border-bottom: 2px solid var(--primary-color);
  }
`;
