import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useQueryClient } from "react-query";
import moment from "moment-timezone";

import { useFormData } from "../../../hooks/useFormData";
import UserSelect from "./UserSelect";
import ServicesList from "../../common/ServicesList";
import { useApi } from "../../../hooks/useApi";
import { ucwords } from "../../../lib/stringHelpers";
import { toast } from "react-toastify";

const DEFAULT_VALUES = {
  helper: null,
  customer: null,
  profile: null,
  paymentMethod: null,
  services: {},
  startDate: moment().add(3, "days").format("YYYY-MM-DD"),
  startTime: "07:00",
  endTime: "10:00",
  hours: 3,
  helperServiceFee: 20,
  customerServiceFee: 20,
  additionalNotes: "",
  _methods: [],
};

const VEHICLE_SERVICES = [
  "60c3fd06573f8b1868c89ef2",
  "6286461e68f0c5cd07ad833f",
  "6286461e68f0c5cd07ad8338",
];

const CreateBookingSidebar = ({ toggle, defaultDate = null }) => {
  const { formData, onChange, setData } = useFormData({ ...DEFAULT_VALUES });
  const queryClient = useQueryClient();

  const { call: callApi, isLoading } = useApi({
    onError: (err) => toast.error(err.message),
  });

  const getUser = (userId) => {
    callApi(`/admin/users/${userId}`, "get", undefined, undefined, (res) => {
      setData({ _methods: res._paymentMethods }, true);
    });
  };

  const createJob = (payload) => {
    callApi(`/admin/job-requests`, "post", undefined, payload, (res) => {
      toggle();
      toast.success("Your job request has been successfully created.");
      queryClient.invalidateQueries({ queryKey: ["admin-bookings"] });
    });
  };

  useEffect(() => {
    if (defaultDate) {
      setData({ ...DEFAULT_VALUES, startDate: defaultDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate]);

  useEffect(() => {
    if (formData.customer) {
      getUser(formData.customer.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.customer]);

  const onTimeChange = (e) => {
    const isStart = e.target.name === "startTime";
    let startDt = moment(
      `${formData.startDate} ${formData.startTime}`,
      "YYYY-MM-DD HH:mm"
    );
    let endDt = moment(
      `${formData.startDate} ${formData.endTime}`,
      "YYYY-MM-DD HH:mm"
    );
    let updates = {};

    if (isStart) {
      // we want to move the end time forward to maintain the hour difference
      startDt = moment(
        `${startDt.format("YYYY-MM-DD")} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      endDt = moment(startDt).add(formData.hours, "hours");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
      };
    } else {
      // we want to calculate the new hour difference between start and end
      startDt = moment(startDt);
      endDt = moment(
        `${endDt.format("YYYY-MM-DD")} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      const mins = endDt.diff(startDt, "minutes");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
        hours: mins / 60,
      };
    }

    setData({ ...updates }, true);
  };

  const onHourChange = (e) => {
    let endDt = moment(
      `${formData.startDate} ${formData.startTime}`,
      "YYYY-MM-DD HH:mm"
    ).add(e.target.value, "hours");
    setData({ endTime: endDt.format("HH:mm"), hours: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const startDt = moment(
      `${formData.startDate} ${formData.startTime}`,
      "YYYY-MM-DD HH:mm"
    );

    let vehicleRequired = false;

    // build the final payload to send to the api
    const payload = {
      customer: formData.customer.value,
      serviceArea: formData.customer.record.customer.serviceArea,
      helper: null,
      requestFor: formData.profile,
      requestedHelpers: [formData.helper.value],
      jobType: "One-Time",
      declinedBy: [],
      servicesRequested: Object.keys(formData.services).map((serviceId) => {
        if (VEHICLE_SERVICES.includes(serviceId)) {
          vehicleRequired = true;
        }

        return {
          service: serviceId,
          options: Object.keys(formData.services[serviceId]).map((option) => {
            return {
              key: option,
              value: formData.services[serviceId][option],
            };
          }),
        };
      }),
      dates: {
        startDateTime: startDt.format(),
        endTime: moment(startDt).add(formData.hours, "hours").format("HH:mm"),
        schedule: null,
        endDate: moment(startDt)
          .add(formData.hours, "hours")
          .format("YYYY-MM-DD"),
      },
      specializedCare: [],
      vehicleRequired: vehicleRequired,
      hours: formData.hours,
      isOvernight: false,
      isRecurring: false,
      viewedBy: [],
      paymentMethod: formData.paymentMethod,
      acceptedOn: null,
      canceledOn: null,
      additionalNotes: formData.additionalNotes || null,
      serviceFee: {
        helper: Number(formData.helperServiceFee) * 0.01,
        customer: Number(formData.customerServiceFee) * 0.01,
      },
    };

    createJob(payload);
  };

  return (
    <>
      <h3>Create A New Booking</h3>
      <p>
        Creating a booking here will ignore all helper-specific search criteria
        such as availability or service preferences.
      </p>
      <hr />
      <form onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Helper</Form.Label>
          <UserSelect
            value={formData.helper}
            onChange={(val) =>
              onChange({ target: { name: "helper", value: val } })
            }
            userType="helper"
            showDefaultOptions={true}
            params={{ clearOnly: "1" }}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Customer</Form.Label>
          <UserSelect
            value={formData.customer}
            onChange={(val) => {
              setData(
                {
                  customer: val,
                  profile: null,
                  paymentMethod: null,
                },
                true
              );
            }}
            userType="customer"
            showDefaultOptions={true}
          />
        </Form.Group>

        <div className={formData.customer ? "" : "d-none"}>
          <Form.Group className={formData.customer ? `mb-3` : "mb-3 d-none"}>
            <Form.Label>Customer Profile</Form.Label>
            <Form.Select
              name="profile"
              value={formData.profile}
              onChange={onChange}
              required={true}
            >
              <option value="">Select a profile</option>
              {formData.customer
                ? formData.customer.record.customer.profiles.map((p, idx) => {
                    return (
                      <option key={idx} value={p._id}>
                        {p.name}
                      </option>
                    );
                  })
                : null}
            </Form.Select>
          </Form.Group>

          <Form.Group className={formData.customer ? `mb-3` : "mb-3 d-none"}>
            <Form.Label>Payment Methods</Form.Label>
            <Form.Select
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={onChange}
              required={true}
            >
              {isLoading ? (
                <option value="">Loading...</option>
              ) : (
                <option value="">Select a payment method</option>
              )}
              {formData._methods.length > 0
                ? formData._methods.map((m, idx) => {
                    return (
                      <option key={idx} value={m.id}>{`${ucwords(
                        m.card.display_brand
                      )} ${m.card.last4}`}</option>
                    );
                  })
                : null}
            </Form.Select>
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={formData.startDate}
                  onChange={onChange}
                  required={true}
                  format="MM/DD/YYYY"
                  min={moment().format("YYYY-MM-DD")}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Hours</Form.Label>
                <Form.Control
                  type="number"
                  min={2}
                  max={24}
                  step={0.5}
                  name="hours"
                  value={formData.hours}
                  onChange={onHourChange}
                  required={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Start Time</Form.Label>
                <Form.Control
                  type="time"
                  name="startTime"
                  value={formData.startTime}
                  onChange={onTimeChange}
                  required={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>End Time</Form.Label>
                <Form.Control
                  type="time"
                  name="endTime"
                  value={formData.endTime}
                  onChange={onTimeChange}
                  required={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Helper Service Fee %</Form.Label>
                <Form.Control
                  type="number"
                  step={5}
                  min={0}
                  max={100}
                  name="helperServiceFee"
                  value={formData.helperServiceFee}
                  onChange={onChange}
                  required={true}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Customer Service Fee %</Form.Label>
                <Form.Control
                  type="number"
                  step={5}
                  min={0}
                  max={100}
                  name="customerServiceFee"
                  value={formData.customerServiceFee}
                  onChange={onChange}
                  required={true}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Additional Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="additionalNotes"
              value={formData.additionalNotes}
              onChange={onChange}
              style={{ minHeight: 200 }}
            />
          </Form.Group>
        </div>
        <ServicesList
          role="customer"
          values={formData.services}
          onChange={(vals) => {
            onChange({ target: { name: "services", value: vals } });
          }}
        />
        <div className="mb-3 mt-3">
          <Button
            type="submit"
            variant="primary"
            disabled={
              !formData.helper ||
              !formData.customer ||
              !formData.profile ||
              !formData.paymentMethod ||
              Object.keys(formData.services).length === 0 ||
              isLoading
            }
          >
            Create Booking
          </Button>
          <Button type="button" variant="link" onClick={() => toggle()}>
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreateBookingSidebar;
