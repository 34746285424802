import React from "react";
import MainNav from "./Navs/Main";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  background-image: url(${(props) => props.backgroundImage});
  background-color: #ffe3d8;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: auto 800px;
  padding: 2rem 0;
  ${(props) => props.headerCss || ""}

  @media only screen and (max-width: 430px) {
    & {
      background-image: url(${(props) =>
        props.backgroundImageMobile || props.backgroundImage});
      background-position: top center;
      padding: 0 0 2rem 0;
      ${(props) => props.headerMobileCss || ""}
    }
  }
`;

const HeaderVideoWrapper = styled.div`
  position: relative;
  padding: 2rem 0;
  background-color: rgba(225, 231, 232, 0.75);

  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  @media only screen and (max-width: 430px) {
    & {
      padding: 0 0 2rem 0;
    }
  }
`;

const PageHeader = ({
  children,
  backgroundImage = `${process.env.REACT_APP_AWS_BUCKET_URL}/HeroBanner4.png`,
  backgroundImageMobile = null,
  backgroundVideo = null,
  showLogo = true,
  headerCss = "",
  headerMobileCss = "",
}) => {
  if (backgroundVideo) {
    return (
      <HeaderVideoWrapper>
        <video autoPlay loop muted playsInline poster={backgroundImage}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <Container>
          <MainNav showHeaderLogo={showLogo} />
          {children}
        </Container>
      </HeaderVideoWrapper>
    );
  }

  return (
    <HeaderWrapper
      className="header-wrapper"
      backgroundImage={backgroundImage}
      backgroundImageMobile={backgroundImageMobile}
      headerCss={headerCss}
      headerMobileCss={headerMobileCss}
    >
      <Container>
        <MainNav showHeaderLogo={showLogo} />
        {children}
      </Container>
    </HeaderWrapper>
  );
};

export default PageHeader;
