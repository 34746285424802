import React from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { useUser } from "../../../contexts/UserContext";
import Loading from "../../common/Loading";
import ProfilePicture from "../../common/ProfilePicture";
import { ucwords } from "../../../lib/stringHelpers";
import {
  StyledListItem,
  StyledListItemCol,
  StyledDot,
} from "./Messages.elements";

const ConversationList = ({ conversations, wrapText = true }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  if (!conversations) {
    return <Loading />;
  }

  const displayUserName = (conversation) => {
    if (conversation.user.userType === "system") {
      return "Manana";
    }

    return ucwords(conversation.user.name);
  };

  const renderListItem = (conversation, conversationIdx) => {
    const unread = conversation.messages.find(
      (msg) => !msg.readOn && msg.toUser === user._id
    );
    const isUnread = unread ? true : false;

    return (
      <StyledListItem
        key={conversationIdx}
        onClick={() => navigate(`/messages/${conversation.user._id}`)}
      >
        <Row>
          <Col xs={2} sm={2} md={1}>
            <ProfilePicture user={conversation.user} />
          </Col>
          <StyledListItemCol
            xs={10}
            sm={10}
            md={11}
            className={isUnread ? "unread" : ""}
          >
            <div className="float-end">
              <span className="caption1">
                {moment(conversation.messages[0].date)
                  .format("MMM D")
                  .toUpperCase()}
              </span>
              <StyledDot className="dot" />
            </div>
            <p className="body2">{displayUserName(conversation)}</p>
            <p className={wrapText ? "body ellipsis" : "body1"}>
              {conversation.messages[0].comment}
            </p>
          </StyledListItemCol>
        </Row>
      </StyledListItem>
    );
  };

  return (
    <div>
      {conversations.map((row, rowIdx) => {
        return renderListItem(row, rowIdx);
      })}
    </div>
  );
};

export default ConversationList;
