import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FormGroup, FormLabel, FormControl, Alert } from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import { SubmitButton } from "../helper/components/NewHelper.elements";
import { ResponsiveBtnGroup, ResendBtn } from "../common/Responsive.elements";

const VerificationCode = () => {
  const inputRef = useRef();
  const { user, token, verificationToken, verified, signOut } = useUser();
  const { setTitle } = usePage();
  const [resendBtnDisabled, setResendBtnDisabled] = useState(true);
  const { formData, onChange } = useFormData({ code: "" });
  const navigate = useNavigate();

  const checkHelperBackgroundCheck = (user) => {
    if (
      user.userType === "helper" &&
      (!user.helper.backgroundCheck || !user.helper.backgroundCheck.checkrId)
    ) {
      navigate("/welcome", { from: "login" });
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (user && token) {
      if (user.userType === "helper") {
        checkHelperBackgroundCheck(user);
      } else if (user.userType === "admin") {
        navigate("/admin");
      } else {
        navigate("/bookings");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, navigate]);

  const submitPost = useApiPost("/auth/verify", (res) => {
    verified(res);
  });

  const resendCode = useApiPost("/auth/resend-auth-code", () => {
    toast.success("Your verification code has been resent.");
  });

  useEffect(() => {
    if (user !== null && user.userType === "helper") {
      checkHelperBackgroundCheck(user);
    } else if (user !== null) {
      navigate("/calendar");
    } else if (!verificationToken) {
      navigate("/sign-in");
    }
    setTitle(
      "We've sent you a verification link to the email address provided."
    );
    disableResendBtn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    submitPost.mutate({ token: verificationToken, code: formData.code });
  };

  const disableResendBtn = () => {
    setResendBtnDisabled(true);
    setTimeout(() => {
      setResendBtnDisabled(false);
    }, 1000 * 10); // 10 seconds;
  };

  const onResendCode = (e) => {
    disableResendBtn();
    resendCode.mutate({ verificationToken });
  };

  const onCancel = () => {
    signOut();
    navigate("/sign-in");
  };

  const content = (
    <>
      <h3>Verification Code</h3>
      <form onSubmit={onSubmit}>
        <p className="lead">
          Please enter the verification code we sent you to continue.
        </p>
        {submitPost.isError ? (
          <Alert variant="danger">{submitPost.error.message}</Alert>
        ) : null}
        <FormGroup>
          <FormLabel>Code</FormLabel>
          <FormControl
            name="code"
            type="text"
            placeholder="Code"
            value={formData.code}
            required={true}
            onChange={onChange}
            ref={inputRef}
          />
        </FormGroup>
        <hr />
        <ResponsiveBtnGroup>
          <ResendBtn
            type="button"
            onClick={() => onResendCode()}
            disabled={resendBtnDisabled || submitPost.isLoading}
          >
            RESEND CODE
          </ResendBtn>
          <ResendBtn type="button" onClick={() => onCancel()}>
            CANCEL
          </ResendBtn>
          <SubmitButton
            type="submit"
            disabled={submitPost.isLoading || formData.code.length < 6}
          >
            {submitPost.isLoading ? "..." : "NEXT"}
          </SubmitButton>
        </ResponsiveBtnGroup>
      </form>
    </>
  );

  return (
    <RegisterLayout
      title="Enter your verification code."
      titleStyle={{ marginTop: "20%" }}
    >
      <Helmet>
        <title>Verify Your Email | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default VerificationCode;
