import React from "react";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";

import { useUser } from "../../contexts/UserContext";

const StripeHeaderNotice = () => {
  const { user } = useUser();

  if (user.userType !== "helper" || user?._welcomeStatus?.isStripeClear) {
    return null;
  }

  return (
    <Alert variant="warning" className="mb-5">
      <h3>Payment Needs</h3>
      <p>
        It looks like we may be missing one or more items to allow us to collect
        payments on your behalf. Please use the link below to resolve these
        issues.
      </p>
      <p>
        <Link to="/stripe-status">
          Update your information to accept payments.
        </Link>
      </p>
    </Alert>
  );
};

export default StripeHeaderNotice;
