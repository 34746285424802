import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, FormGroup, FormCheck, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { SubmitButton, HideOnMobile } from "../common/Registration.elements";
import { SetupTitle } from "../common/Title.elements";
import ServicesList from "../common/ServicesList";
import VehicleForm from "../common/VehicleForm";
import useHelper from "../../hooks/useHelper";
import RegisterLayout from "../layout/RegisterLayout";
import { PaddedForm } from "../common/PaddedForm";

const VEHICLE_SERVICES = [
  "60c3fd06573f8b1868c89ef2",
  "6286461e68f0c5cd07ad833f",
  "6286461e68f0c5cd07ad8338",
];

const HelperRegisterServices = () => {
  const { helper, saveHelper } = useHelper();
  const [formData, setFormData] = useState({
    services: helper.services || {},
    vehicleCovers: helper.vehicleCovers || null,
    vehicleAvailable: helper.vehicleAvailable || false,
    dementiaCare: helper.dementiaCare || false,
  });
  const navigate = useNavigate();

  const checkServiceAndUpdate = (updatedForm) => {
    VEHICLE_SERVICES.forEach((serviceId) => {
      if (updatedForm.services.hasOwnProperty(serviceId)) {
        updatedForm.services[serviceId].accommodate = updatedForm.vehicleCovers;
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const toSubmit = { ...formData };
    checkServiceAndUpdate(toSubmit);
    saveHelper({
      ...toSubmit,
    });
    navigate("/become-a-helper/rates");
  };

  const onChange = (e) => {
    const updates = { ...formData, [e.target.name]: e.target.value };
    setFormData(updates);
  };

  const isDisabled = () => {
    if (
      Object.keys(formData.services).length === 0 ||
      (showVehicleForm && !formData.vehicleCovers)
    ) {
      return true;
    }

    return false;
  };

  const updateVehicles = (value, hasVehicle) => {
    const updates = {
      ...formData,
      vehicleCovers: value,
      vehicleAvailable: hasVehicle,
    };

    setFormData(updates);
    saveHelper(updates);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const showVehicleForm =
    formData.services.hasOwnProperty(VEHICLE_SERVICES[0]) ||
    formData.services.hasOwnProperty(VEHICLE_SERVICES[1]) ||
    formData.services.hasOwnProperty(VEHICLE_SERVICES[2]);

  return (
    <RegisterLayout
      title="What help are you willing to provide?"
      steps={["Location", "Services", "Business", "Account"]}
      activeStep={1}
      onStepBack={() => navigate("/become-a-helper")}
    >
      <Helmet>
        <title>Services | Become A Helper | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <PaddedForm onSubmit={onSubmit} style={{ position: "relative" }}>
        <HideOnMobile>
          <Button
            type="button"
            variant="link"
            onClick={() => navigate("/become-a-helper")}
            style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          >
            <ArrowBack />
          </Button>
        </HideOnMobile>

        <div className="mb-5">
          <ServicesList
            values={formData.services}
            onChange={(selections) =>
              onChange({ target: { name: "services", value: selections } })
            }
          />
        </div>

        {showVehicleForm ? (
          <div className="mb-5">
            <VehicleForm
              isHelper
              setVehicles={updateVehicles}
              previousValues={formData.vehicleCovers || []}
            />
          </div>
        ) : null}

        <SetupTitle>
          Do you have experience in providing specialized care?
        </SetupTitle>
        <div className="service-item mb-5">
          <Row>
            <Col>
              <span>
                <span
                  style={{
                    display: "inline-block",
                    width: 52,
                    textAlign: "center",
                    marginRight: "0.7rem",
                  }}
                >
                  <img
                    src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Dementia_48px.svg`}
                    alt="Dementia Care"
                    title="Dementia Care"
                  />
                </span>
                Dementia Care
              </span>
            </Col>
            <Col xs={2} sm={3} className="text-end text-muted pt-1">
              <FormGroup controlId="formBasicCheckbox">
                <FormCheck
                  type="checkbox"
                  value="Dementia Care"
                  checked={formData.dementiaCare}
                  size="lg"
                  onChange={(e) =>
                    onChange({
                      target: {
                        name: "dementiaCare",
                        value: e.target.checked,
                      },
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
        </div>

        <div className="text-end">
          <SubmitButton type="submit" disabled={isDisabled()}>
            NEXT
          </SubmitButton>
        </div>
      </PaddedForm>
    </RegisterLayout>
  );
};

export default HelperRegisterServices;
