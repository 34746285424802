import React, { useEffect } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import moment from "moment-timezone";
import Toggle from "react-toggle";
import { toast } from "react-toastify";

import { useFormData } from "../../../../hooks/useFormData";
import { useApi } from "../../../../hooks/useApi";
import { getApiPayload } from "../lib/apiPayloadHelpers";

import TimeSelect from "../../../common/TimeSelect";

import "react-toggle/style.css";

export const defaultValues = {
  date: null,
  startTime: "08:00", // not in payload
  endTime: "12:00",
  repeatsOn: [],
  repeatsSchedule: "weekly",
  endDate: "",
  repeats: false, // not in payload
};

export const WEEKDAY = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "TH", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "SA", value: "Saturday" },
];

const FullCalendarEventFormModal = ({ show, toggle, event, onSuccess }) => {
  const { formData, onChange, setData, toggleCheckboxValue } = useFormData({
    ...defaultValues,
    date:
      event && event.date
        ? moment(event.date, "YYYY-MM-DD").toDate()
        : moment().toDate(),
  });

  useEffect(() => {
    if (!event) {
      return;
    }

    const startDt = event.availability
      ? moment(event.availability.startDateTime)
      : moment(event.date, "YYYY-MM-DD").set({ hour: 8, minutes: 0 });

    setData({
      date: moment(event.date).toDate(),
      startTime: startDt.format("HH:mm"),
      endTime: event.availability ? event.availability.endTime : "12:00",
      endDate:
        event.availability && event.availability.endDate
          ? moment.utc(event.availability.endDate).format("YYYY-MM-DD")
          : "",
      repeatsOn: event.availability ? event.availability.repeatsOn : [],
      repeatsSchedule: event.availability
        ? event.availability.repeatsSchedule
        : "weekly",
      repeats:
        event.availability &&
        event.availability.repeatsOn &&
        event.availability.repeatsOn.length > 0
          ? true
          : false,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const { call, isLoading: isSaving } = useApi({
    onError: (err) => toast.error(err.message),
  });

  const save = (payload) => {
    const url =
      event.availability && event.availability._id
        ? `/calendar/availability/${event.availability._id}`
        : `/calendar/availability`;
    const method = event.availability?._id ? "put" : "post";
    const data = getApiPayload({ _id: event.availability, ...payload });

    // make sure the window is at least 3 hours
    const start = moment(data.startDateTime);
    const end = moment(
      `${start.format("YYYY-MM-DD")} ${data.endTime}`,
      "YYYY-MM-DD H:mm"
    );
    const diff = end.diff(start, "hours");

    if (diff < 3) {
      toast.error(
        "Please select a time window of at least 3 hours to match the minimum booking length."
      );
      return;
    }

    call(url, method, null, data, (resp) => {
      toast.success("Your availability has been successfully saved.");
      onSuccess();
      toggle();
    });
  };

  const deleteAvail = () => {
    call(
      `/calendar/availability/${event.availability._id}`,
      "delete",
      null,
      undefined,
      () => {
        toast.success("Your availability has been successfully deleted.");
        toggle();
      }
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    save(formData);
  };

  if (!show) {
    return null;
  }

  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Body>
        <form onSubmit={onSubmit}>
          <h3>
            {event.date
              ? moment(event.date, "YYYY-MM-DD").format("MMMM D, YYYY")
              : null}
          </h3>
          <hr />
          <div className="mb-3">
            <Row>
              <Col>
                <TimeSelect
                  selectName="startTime"
                  value={formData.startTime}
                  onChange={onChange}
                />
              </Col>
              <Col xs={1} className="text-center">
                -
              </Col>
              <Col>
                <TimeSelect
                  selectName="endTime"
                  value={formData.endTime}
                  onChange={onChange}
                />
              </Col>
            </Row>
          </div>
          <hr />
          <Row>
            <Col>
              <p
                id="repeats-label"
                className="subtitle1"
                style={{ fontWeight: 400 }}
              >
                Repeats
              </p>
            </Col>
            <Col className="text-end">
              <Toggle
                id="repeats"
                name="repeats"
                checked={formData.repeats}
                aria-labelledby="repeats-label"
                onChange={(e) => {
                  const wkday = moment(formData.date).format("dddd");
                  setData(
                    {
                      repeats: e.target.checked,
                      repeatsSchedule: e.target.checked ? "weekly" : null,
                      repeatsOn: e.target.checked ? [wkday] : [],
                    },
                    true
                  );
                }}
              />
            </Col>
          </Row>

          <div className={formData.repeats ? "mt-3" : "d-none"}>
            <p className="subtitle1" style={{ fontWeight: 400 }}>
              Which day(s)?
            </p>
            <div
              className="d-flex justify-content-between"
              style={{
                borderBottom: "1px solid #EBE0DE",
                paddingBottom: "1rem",
                marginBottom: "1rem",
              }}
            >
              {WEEKDAY.map(({ label, value }) => (
                <div
                  className={`recurring-weekday ${
                    formData.repeatsOn.includes(value) ? "active" : ""
                  }`}
                  onClick={() => toggleCheckboxValue("repeatsOn", value)}
                  key={value}
                >
                  {label}
                </div>
              ))}
            </div>

            {[
              { label: "Every week", value: "weekly" },
              { label: "Every other week", value: "every 2 weeks" },
              { label: "Every month", value: "every month" },
            ].map((opt, optIndex) => {
              return (
                <Form.Group
                  key={optIndex}
                  style={{
                    borderBottom: "1px solid #EBE0DE",
                    paddingBottom: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Form.Check
                    type="radio"
                    id={`rb-repeats-schedule-${opt.value.replace(/\s/, "-")}`}
                    name="repeatsSchedule"
                    label={opt.label}
                    value={opt.value}
                    checked={formData.repeatsSchedule === opt.value}
                    onChange={onChange}
                  />
                </Form.Group>
              );
            })}
            <div>
              <p className="mb-3 subtitle1" style={{ fontWeight: 400 }}>
                Repeats until (optional)
              </p>
              <Row>
                <Col xs={5}>
                  <Form.Control
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={formData.endDate || ""}
                    required={false}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </div>
          </div>
          <hr />
          <Row>
            <Col>
              <Button variant="link" onClick={() => toggle()}>
                CANCEL
              </Button>
              {event.availability && event.availability._id ? (
                <Button
                  variant="link"
                  className="ml-3"
                  onClick={() => deleteAvail()}
                  disabled={isSaving}
                >
                  DELETE
                </Button>
              ) : null}
            </Col>
            <Col className="text-end">
              <Button type="submit" variant="primary" disabled={isSaving}>
                {isSaving ? "..." : "SAVE"}
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default FullCalendarEventFormModal;
