import React from "react";
import PropTypes from "prop-types";
import { FormGroup, FormCheck, Row, Col } from "react-bootstrap";

const VehicleFormItem = ({ item, formData, onChange }) => {
  return (
    <div className="service-item">
      <Row>
        <Col>
          <span>
            <span
              style={{
                display: "inline-block",
                width: 52,
                textAlign: "center",
                marginRight: "0.7rem",
              }}
            >
              <img
                src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/${item.icon}`}
                alt={item.label}
                title={item.label}
              />
            </span>
            {item.label}
          </span>
        </Col>
        <Col xs={2} sm={3} className="text-end text-muted">
          <FormGroup controlId={`item-${item.label}`}>
            <FormCheck
              name={item.name}
              type="checkbox"
              checked={formData[item.name]}
              size="lg"
              onChange={(e) => {
                onChange({
                  target: { name: item.name, value: e.target.checked },
                });
              }}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

VehicleFormItem.propTypes = {
  item: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default VehicleFormItem;
