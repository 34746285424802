import { RRule } from "rrule";
import moment from "moment-timezone";

export const rruleDayOfWeek = (momentDayOfWeek) => {
  switch (momentDayOfWeek) {
    case 1:
      return RRule.MO;
    case 2:
      return RRule.TU;
    case 3:
      return RRule.WE;
    case 4:
      return RRule.TH;
    case 5:
      return RRule.FR;
    case 6:
      return RRule.SA;
    default:
      return RRule.SU;
  }
};

export const rruleDayOfWeekByIndex = (rruleDayOfWeek) => {
  switch (rruleDayOfWeek) {
    case 0:
      return "Monday";
    case 1:
      return "Tuesday";
    case 2:
      return "Wednesday";
    case 3:
      return "Thursday";
    case 4:
      return "Friday";
    case 5:
      return "Saturday";
    default:
      return "Sunday";
  }
};

export const rruleDayOfWeekByWeekday = (weekday) => {
  switch (weekday) {
    case "Monday":
      return RRule.MO;
    case "Tuesday":
      return RRule.TU;
    case "Wednesday":
      return RRule.WE;
    case "Thursday":
      return RRule.TH;
    case "Friday":
      return RRule.FR;
    case "Saturday":
      return RRule.SA;
    default:
      return RRule.SU;
  }
};

export const displayTime = (time) => {
  if (!time) {
    return null;
  }

  const p = time.split(":");

  return moment()
    .set({ hour: Number(p[0]), minutes: Number(p[1]) })
    .format("h:mm A");
};
