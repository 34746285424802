import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import ChevronRight from "@mui/icons-material/ChevronRight";

import InternalLayout from "../layout/InternalLayout";
import useOnboard from "../../hooks/useOnboard";
import UploadButton from "../common/UploadButton";
import { useUser } from "../../contexts/UserContext";
import { useProfile } from "../../hooks/useProfile";
import { useParams } from "react-router";
import { useApiPut } from "../../hooks/useApi";


const ContentWrapper = styled.div`
  margin: 0 auto;
  max-width: 80%;

  @media only screen and (max-width: 630px) {
    max-width: 90%;
  }

  @media only screen and (max-width: 430px) {
    max-width: 100%;
    margin: 0 1rem;
  }
`;

const WelcomeOnboarding = () => {
  const navigate = useNavigate();
  const { showOnBoarding, welcomeNav } = useOnboard();
  const { user, updateUser } = useUser();
  const { profileId } = useParams();
  const profile = useProfile(profileId);

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfile = useApiPut(
    "/users/profile",
    (res) => {
      updateUser(res);
    },
    {
      onError: (err) => {
        alert(err.message);
      },
    }
  );

  return (
    <InternalLayout>
      <Helmet>
        <title>On Boarding | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>
        <ContentWrapper>
          <h3 className="mt-5 mb-5">
            Great, you've taken the first step by signing-up. Complete the
            following information to become a Helper.
          </h3>

          <div>
            {welcomeNav.map((btn, btnIndex) => {
              const content =
                btn.url === "/profile" ? (
                  <div
                    className="service-item cursor-pointer"
                    style={{ border: "2px solid #EBE0DE" }}
                    key={btnIndex}
                  >
                    <Row>
                      <Col xs={2} className="text-center">
                        {btn.icon}
                      </Col>
                      <Col>
                        <UploadButton
                          btnLabel={btn.label}
                          className="subtitle1 p-0 text-decoration-none btn-dark"
                          onUpload={(url) => {
                            const payload = {};

                            if (user.helper) {
                              payload["helper.profile.profilePicture"] = url;
                            } else {
                              payload["customer.profiles"] = [
                                ...user.customer.profiles,
                              ];

                              // get the profile currently selected by id
                              const p = payload["customer.profiles"].find(
                                (pro) => pro._id === profile._id
                              );

                              // update the record from the spread above
                              p.profilePicture = url;
                            }

                            updateProfile.mutate(payload);
                          }}
                        />
                      </Col>
                      <Col xs={2} className="text-end">
                        {btn.isComplete ? (
                          <img
                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Complete.svg`}
                            height="24"
                            alt="Task completed"
                          />
                        ) : (
                          <ChevronRight />
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    className="service-item cursor-pointer"
                    style={{ border: "2px solid #EBE0DE" }}
                    key={btnIndex}
                    onClick={() => navigate(btn.url)}
                  >
                    <Row>
                      <Col xs={2} className="text-center">
                        {btn.icon}
                      </Col>
                      <Col>
                        <p className="subtitle1 mb-0">{btn.label}</p>
                      </Col>
                      <Col xs={2} className="text-end">
                        {btn.isComplete ? (
                          <img
                            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Complete.svg`}
                            height="24"
                            alt="Task completed"
                          />
                        ) : (
                          <ChevronRight />
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              return content;
            })}
          </div>
        </ContentWrapper>
      </Container>
    </InternalLayout>
  );
};

export default WelcomeOnboarding;
