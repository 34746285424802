import React, { useEffect } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import axios from "axios";

import { useUser } from "../../contexts/UserContext";
import { getCookie } from "../../lib/cookies";
import { isLocalEnv } from "../../lib/envHelpers";

import PrivateRoute from "./PrivateRoute";

import Unsubscribe from "../unsubscribe/Unsubscribe";

import Login from "../auth/Login";
import VerificationCode from "../auth/VerificationCode";

import JobRequestArea from "../jobRequest/JobRequestArea";
import JobRequestServices from "../jobRequest/JobRequestServices";
import JobRequestHelpers from "../jobRequest/JobRequestHelpers";
import JobRequestHelperProfile from "../jobRequest/JobRequestHelperProfile";
import JobRequestRegister from "../jobRequest/JobRequestRegister";
import JobRequestRegisterVerify from "../jobRequest/JobRequestRegisterVerify";
import JobRequestProfile from "../jobRequest/JobRequestProfile";
import JobRequestReview from "../jobRequest/JobRequestReview";
import JobRequestPayment from "../jobRequest/JobRequestPayment";

import WelcomeIntro from "../welcome/WelcomeIntro";
import WelcomePhoto from "../welcome/WelcomePhoto";
import WelcomeAbout from "../welcome/WelcomeAbout";
import WelcomeAvailability from "../welcome/WelcomeAvailability";
import WelcomeBackground from "../welcome/WelcomeBackground";
import WelcomeBackgroundLegal from "../welcome/WelcomeBackgroundLegal";
import WelcomeBackgroundPay from "../welcome/WelcomeBackgroundPay";
import WelcomeBackgroundPaymentComplete from "../welcome/WelcomeBackgroundPaymentComplete";

import WelcomeOnboarding from "../dashboard/WelcomeOnboarding";
import StripeStatus from "../helper/StripeStatus";
import Dashboard from "../dashboard/Dashboard";
import Calendar from "../calendar/Calendar";

import Messages from "../communications/Messages";
import ConversationProfile from "../communications/ConversationProfile";

import Payments from "../payments/Payments";

import Profile from "../profile/Profile";
import SkillsAndExperience from "../profile/helper/SkillsAndExperience";
import PersonalInformation from "../profile/helper/PersonalInformation";
import Services from "../profile/helper/Services";
import Licenses from "../profile/helper/Licenses";
import Languages from "../profile/helper/Languages";
import AboutMe from "../profile/helper/AboutMe";
import WorkExperience from "../profile/helper/WorkExperience";
import Education from "../profile/helper/Education";
import CustomerReviews from "../profile/customer/Reviews";
import HelperReviews from "../profile/helper/Reviews";
import HelperEmergencyContacts from "../profile/helper/EmergencyContacts";

import { default as AboutMeCustomer } from "../profile/customer/AboutMe";
import { default as PersonalInformationCustomer } from "../profile/customer/PersonalInformation";
import EmergencyContacts from "../profile/customer/EmergencyContacts";
import HealthInformation from "../profile/customer/HealthInformation";
import DailyCare from "../profile/customer/healthInformation/DailyCare";
import HealthConditions from "../profile/customer/healthInformation/HealthConditions";
import Medications from "../profile/customer/healthInformation/Medications";
import Providers from "../profile/customer/healthInformation/Providers";

import Account from "../account/Account";
import AccountSettings from "../account/helper/AccountSettings";
import PaymentSettings from "../account/helper/PaymentSettings";
import TaxInfo from "../account/helper/TaxInfo";
import PersonalInfo from "../account/customer/PersonalInfo";
import CustomerPaymentSettings from "../account/customer/CustomerPaymentSettings";
import NotificationSettings from "../account/customer/NotificationSettings";
import AddProfile from "../account/customer/AddProfile";
import HelperNotificationSettings from "../account/helper/NotificationSettings";
import BackgroundCheckStatus from "../backgroundCheck/BackgroundCheckStatus";

import HelperRegisterServiceArea from "../helperRegister/HelperRegisterServiceArea";
import HelperRegisterServices from "../helperRegister/HelperRegisterServices";
import HelperRegisterRates from "../helperRegister/HelperRegisterRates";
import HelperRegisterAccount from "../helperRegister/HelperRegisterAccount";
import HelperRegisterVerification from "../helperRegister/HelperRegisterVerification";
import HelperFullPublicProfile from "../helper/HelperFullPublicProfile";

import Conference from "../conference/Conference";

import Bookings from "../bookings/Bookings";
import BookingDetails from "../bookings/BookingDetails";
import BookAgain from "../bookings/BookAgain";
import UserPublicProfile from "../userPublicProfile/UserPublicProfile";
import LeaveReview from "../review/LeaveReview";
import StripeQRScreen from "../helper/StripeQRScreen";

import AdminLanding from "../admin/AdminLanding";
import AdminUserDetails from "../admin/AdminUserDetails";
import AdminCalendar from "../admin/AdminCalendar";
import AdminBookings from "../admin/AdminBookings";

const MainRouter = () => {
  const { user, signOut } = useUser();
  const isLocal = isLocalEnv();

  useEffect(() => {
    if (!window.location || isLocal) {
      return;
    }

    window.gtag("event", "page_view", {
      page_location: `${window.location.origin}${window.location.pathname}${window.location.search}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  axios.interceptors.request.use(
    function (config) {
      // we use the token here instead of from useUser above because of
      // a caching issue with the function
      const token = getCookie("token") || null;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (err) {
      if (err.response.data && err.response.data.msg) {
        err.message = err.response.data.msg;
      }

      if (err.response.status === 401 || err.response.status === 403) {
        signOut();
      }
      return Promise.reject(err);
    }
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="/become-a-helper/verify"
          element={<HelperRegisterVerification />}
        />
        <Route
          path="/become-a-helper/account"
          element={<HelperRegisterAccount />}
        />
        <Route
          path="/become-a-helper/rates"
          element={<HelperRegisterRates />}
        />
        <Route
          path="/become-a-helper/services"
          element={<HelperRegisterServices />}
        />
        <Route
          path="/become-a-helper"
          element={<HelperRegisterServiceArea />}
        />
        <Route path="/use-token/:qrCodeToken" element={<StripeQRScreen />} />

        <Route path="/find-help/services" element={<JobRequestServices />} />
        <Route
          path="/find-help/helpers/:helperId"
          element={<JobRequestHelperProfile />}
        />
        <Route path="/find-help/helpers" element={<JobRequestHelpers />} />
        <Route path="/find-help/register" element={<JobRequestRegister />} />
        <Route
          path="/find-help/verify"
          element={<JobRequestRegisterVerify />}
        />
        <Route
          path="/find-help/profile"
          element={<PrivateRoute as={JobRequestProfile} />}
        />
        <Route
          path="/find-help/review"
          element={<PrivateRoute as={JobRequestReview} />}
        />
        <Route
          path="/find-help/payment"
          element={<PrivateRoute as={JobRequestPayment} />}
        />
        <Route
          path="/find-help/confirm"
          element={
            <PrivateRoute as={JobRequestReview} finalConfirmation={true} />
          }
          opts={{ finalConfirmation: true }}
        />
        <Route path="/find-help" element={<JobRequestArea />} />

        <Route path="/sign-in/verify" element={<VerificationCode />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />

        <Route
          path="/users/profile/:userId"
          element={<PrivateRoute as={UserPublicProfile} />}
        />

        {/* WELCOME SCREENS */}
        <Route
          path="/welcome/photo"
          element={<PrivateRoute as={WelcomePhoto} />}
        />
        <Route
          path="/welcome/about"
          element={<PrivateRoute as={WelcomeAbout} />}
        />
        <Route
          path="/welcome/availability"
          element={<PrivateRoute as={WelcomeAvailability} />}
        />
        <Route
          path="/welcome/background"
          element={<PrivateRoute as={WelcomeBackground} />}
        />
        <Route
          path="/welcome/background/legal"
          element={<PrivateRoute as={WelcomeBackgroundLegal} />}
        />
        <Route
          path="/welcome/background/pay"
          element={<PrivateRoute as={WelcomeBackgroundPay} />}
        />
        <Route
          path="/welcome/background/pay-complete"
          element={<PrivateRoute as={WelcomeBackgroundPaymentComplete} />}
        />
        <Route path="/welcome" element={<PrivateRoute as={WelcomeIntro} />} />

        <Route
          path="/welcome/list"
          element={<PrivateRoute as={WelcomeOnboarding} />}
        />
        <Route
          path="/stripe-status"
          element={<PrivateRoute as={StripeStatus} />}
        />
        <Route path="/dashboard" element={<PrivateRoute as={Dashboard} />} />

        <Route path="/calendar" element={<PrivateRoute as={Calendar} />} />

        {/* BOOKINGS INTERFACES */}
        <Route path="/bookings" element={<PrivateRoute as={Bookings} />} />
        <Route
          path="/bookings/:jobId/again/:helperId"
          element={<PrivateRoute as={BookAgain} />}
        />
        <Route
          path="/bookings/:jobId/:eventId"
          element={<PrivateRoute as={BookingDetails} />}
        />
        <Route
          path="/bookings/:jobId"
          element={<PrivateRoute as={BookingDetails} />}
        />

        {/* MESSAGES */}
        <Route
          path="/messages/:userId"
          element={<PrivateRoute as={ConversationProfile} />}
        />
        <Route path="/messages" element={<PrivateRoute as={Messages} />} />

        <Route path="/payments" element={<PrivateRoute as={Payments} />} />

        <Route
          path="/conference/:withUser"
          element={<PrivateRoute as={Conference} />}
        />

        {/* HELPER PROFILE */}
        <Route
          path="/profile/personal-information"
          element={<PrivateRoute as={PersonalInformation} />}
        />
        <Route
          path="/profile/skills-experience/services"
          element={<PrivateRoute as={Services} />}
        />
        <Route
          path="/profile/skills-experience/licenses"
          element={<PrivateRoute as={Licenses} />}
        />
        <Route
          path="/profile/skills-experience/languages"
          element={<PrivateRoute as={Languages} />}
        />
        <Route
          path="/profile/skills-experience/work-experience"
          element={<PrivateRoute as={WorkExperience} />}
        />
        <Route
          path="/profile/skills-experience/education"
          element={<PrivateRoute as={Education} />}
        />
        <Route
          path="/profile/skills-experience"
          element={<PrivateRoute as={SkillsAndExperience} />}
        />
        <Route
          path="/profile/emergency-contacts"
          element={<PrivateRoute as={HelperEmergencyContacts} />}
        />
        <Route
          path="/profile/about-me"
          element={<PrivateRoute as={AboutMe} />}
        />
        <Route path="/profile/add" element={<PrivateRoute as={AddProfile} />} />
        <Route
          path="/profile/reviews"
          element={<PrivateRoute as={HelperReviews} />}
        />

        {/* CUSTOMER PROFILE */}
        <Route
          path="/profile/:profileId/about-me"
          element={
            <PrivateRoute
              as={AboutMeCustomer}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/personal-information"
          element={
            <PrivateRoute
              as={PersonalInformationCustomer}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/emergency-contacts"
          element={
            <PrivateRoute
              as={EmergencyContacts}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/health-information/daily-care"
          element={
            <PrivateRoute
              as={DailyCare}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/health-information/health-conditions-diseases"
          element={
            <PrivateRoute
              as={HealthConditions}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/health-information/medications"
          element={
            <PrivateRoute
              as={Medications}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/health-information/healthcare-providers"
          element={
            <PrivateRoute
              as={Providers}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/health-information"
          element={
            <PrivateRoute
              as={HealthInformation}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId/reviews"
          element={
            <PrivateRoute
              as={CustomerReviews}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/profile/:profileId"
          element={
            <PrivateRoute
              as={Profile}
              authenticated={user !== null && user.customer}
            />
          }
        />

        <Route path="/profile" element={<PrivateRoute as={Profile} />} />

        {/** HELPER ACCOUNT ITEMS */}
        <Route
          path="/account/settings"
          element={
            <PrivateRoute as={AccountSettings} authenticated={user !== null} />
          }
        />
        <Route
          path="/account/payment-settings"
          element={
            <PrivateRoute as={PaymentSettings} authenticated={user !== null} />
          }
        />
        <Route
          path="/account/tax-info"
          element={<PrivateRoute as={TaxInfo} authenticated={user !== null} />}
        />
        <Route
          path="/account/tax-info"
          element={<PrivateRoute as={TaxInfo} authenticated={user !== null} />}
        />
        <Route
          path="/account/helper-notification-settings"
          element={
            <PrivateRoute
              as={HelperNotificationSettings}
              authenticated={user !== null}
            />
          }
        />
        <Route
          path="/background-check/status"
          element={
            <PrivateRoute
              as={BackgroundCheckStatus}
              authenticated={user !== null && user.helper}
            />
          }
        />

        {/** CUSTOMER ACCOUNT ITEMS */}
        <Route
          path="/account/personal-info"
          element={
            <PrivateRoute
              as={PersonalInfo}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/account/recipient-payment-settings"
          element={
            <PrivateRoute
              as={CustomerPaymentSettings}
              authenticated={user !== null && user.customer}
            />
          }
        />
        <Route
          path="/account/notification-settings"
          element={
            <PrivateRoute
              as={NotificationSettings}
              authenticated={user !== null && user.customer}
            />
          }
        />

        <Route path="/account" element={<PrivateRoute as={Account} />} />

        {/** REVIEWS */}
        <Route
          path="/reviews/:jobId"
          element={<PrivateRoute as={LeaveReview} />}
        />

        {/** ADMIN AREA */}
        <Route path="/admin" element={<PrivateRoute as={AdminLanding} />} />
        <Route
          path="/admin/users/:userId"
          element={<PrivateRoute as={AdminUserDetails} />}
        />
        <Route
          path="/admin/calendar"
          element={<PrivateRoute as={AdminCalendar} />}
        />
        <Route
          path="/admin/bookings"
          element={<PrivateRoute as={AdminBookings} />}
        />

        {/** PUBLIC AREA */}
        <Route path="/" element={<Login />} />
        <Route
          path="/helpers/:helperId"
          element={<HelperFullPublicProfile />}
        />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default MainRouter;
