import React from "react";
import styled from "styled-components";

import PageHeader from "./components/PageHeader";
import Footer from "./components/Footer";

const PublicLayoutStyles = styled.div``;

const PublicLayout = ({
  children,
  header,
  showNavLogo = true,
  backgroundImage = `${process.env.REACT_APP_AWS_BUCKET_URL}/HeroBanner4.png`,
  backgroundImageMobile = null,
  backgroundVideo = null,
  headerCss = "",
  headerMobileCss = "",
}) => {
  return (
    <PublicLayoutStyles>
      <PageHeader
        showLogo={showNavLogo}
        backgroundImage={backgroundImage}
        backgroundImageMobile={backgroundImageMobile}
        backgroundVideo={backgroundVideo}
        headerCss={headerCss}
        headerMobileCss={headerMobileCss}
      >
        {header ? header : null}
      </PageHeader>
      {children}
      <Footer />
    </PublicLayoutStyles>
  );
};

export default PublicLayout;
