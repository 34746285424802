import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, Button } from "react-bootstrap";
import styled from "styled-components";
import Menu from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";

import logo from "../../../../logo.png";
import UserNotifications from "../Internal/UserNotifications";

const NavWrapper = styled.div`
  position: relative;
  height: 90%;
`;

const NavMenu = styled.div`
  padding: 10px;
`;

const NavLinkWrapper = styled.div`
  display: block;
  padding: 0px 15px 20px 15px;

  &.is-sub-nav {
    padding-bottom: 8px;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & .child-nav a {
    font-size: 0.75rem !important;
    font-weight: 400 !important;
    color: #333 !important;
  }
`;

const NavLink = styled(Link)`
  color: #205b68 !important;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const NavBtn = styled(Button)`
  color: #205b68 !important;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 0;
`;

const NavLogo = styled.img`
  max-width: 200px;
  margin-top: 18px;
`;

const Mobile = ({ user, signOut, links, showLogo = true }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);

  const closeSideBar = () => {
    setSideMenuOpen(false);
  };

  const renderItem = (link) => {
    if (link.onClick) {
      return (
        <NavBtn
          variant="link"
          onClick={() => {
            if (typeof link.onClick === "function") {
              link.onClick();
            }
            closeSideBar();
          }}
        >
          {link.label}
        </NavBtn>
      );
    }

    return (
      <NavLink
        to={link.url}
        onClick={() => {
          if (typeof link.onClick === "function") {
            link.onClick();
          }
          closeSideBar();
        }}
      >
        {link.label}
      </NavLink>
    );
  };

  const renderList = (list, isSubNav = false) => {
    return list
      .filter((l) => typeof l.mobile === "undefined" || l.mobile)
      .map((link, linkIdx) => {
        return (
          <NavLinkWrapper
            key={linkIdx}
            className={isSubNav ? "is-sub-nav" : ""}
          >
            {link.divider ? <hr /> : renderItem(link)}
            {link.children && link.children.length > 0 ? (
              <div className="child-nav">{renderList(link.children, true)}</div>
            ) : null}
          </NavLinkWrapper>
        );
      });
  };

  return (
    <div className="mobile-navigation">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          {showLogo ? (
            <Link to="/">
              <NavLogo src={logo} alt="Manana" title="Manana" />
            </Link>
          ) : null}
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {user ? <UserNotifications /> : null}
          <Button variant="link" onClick={() => setSideMenuOpen(true)}>
            <Menu style={{ fontSize: "3rem" }} />
          </Button>
        </div>
      </div>
      <Offcanvas
        show={sideMenuOpen}
        onHide={() => closeSideBar()}
        placement="end"
      >
        <Offcanvas.Body>
          <div className="text-end mb-3">
            <Button variant="link" onClick={() => closeSideBar()}>
              <Close />
            </Button>
          </div>
          <NavWrapper>
            <NavMenu>
              {renderList(links.links)}
              {links.dropdown ? (
                <>
                  <hr />
                  {renderList(links.dropdown)}
                </>
              ) : null}
            </NavMenu>
          </NavWrapper>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Mobile;
