import React from "react";
import { useHMSActions, useHMSStore, selectPeers } from "@100mslive/react-sdk";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

import { useUser } from "../../../contexts/UserContext";
import useEffectOnce from "../../../hooks/useEffectOnce";
import Loading from "../../common/Loading";

import Peer from "./Peer";

const PreviewConference = ({ withUser, appToken }) => {
  const { user } = useUser();
  const hmsActions = useHMSActions();
  const peers = useHMSStore(selectPeers);
  const peer = peers.find((p) => p.isLocal);
  const navigate = useNavigate();

  useEffectOnce(() => {
    hmsActions.preview({
      userName: user.name,
      authToken: appToken,
    });
  });

  const leavePreview = async () => {
    await hmsActions.leave();
    navigate(`/messages/${withUser._id}`);
  };

  const joinConference = async () => {
    await hmsActions.join({
      userName: user.name,
      authToken: appToken,
    });
  };

  if (!peer) {
    return <Loading msg="Setting up your video stream..." />;
  }

  return (
    <div>
      <Row>
        <Col>
          <Peer peer={peer} />
        </Col>
        <Col>
          <p>
            {withUser.name}
            {peers.length > 1 ? ` is connected.` : ` is not connected.`}
          </p>
          <Button variant="secondary" onClick={() => joinConference()}>
            Join Call
          </Button>
          <Button variant="secondary" onClick={() => leavePreview()}>
            Leave
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PreviewConference;
