import React, { useState } from "react";
import { Container, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Search from "@mui/icons-material/Search";

import InternalLayout from "../layout/InternalLayout";
import { useApiGet } from "../../hooks/useApi";
import Tabs from "../common/Tabs";
import {
  SearchMessages,
  SearchIcon,
  SearchInput,
} from "./components/Messages.elements";
import ConversationList from "./components/ConversationList";

const Messages = () => {
  const [filter, setFilter] = useState({
    active: true,
    searchString: "",
  });
  const [search, setSearch] = useState("");

  const { data, isError, error } = useApiGet(
    "messages",
    "/communications/conversations",
    {
      active: filter.active,
      search: filter.searchString,
    },
    { staleTime: 5000, enabled: true }
  );

  const onSearchSubmit = (e) => {
    e.preventDefault();
    setFilter({ ...filter, searchString: search });
  };

  const contentHeader = (
    <form onSubmit={onSearchSubmit}>
      <SearchMessages>
        <SearchIcon>
          <Search />
        </SearchIcon>
        <SearchInput
          placeholder="Search by client, date, or keyword"
          type="search"
          name="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </SearchMessages>
    </form>
  );

  const renderContent = () => {
    return (
      <div>
        <h2 className="mb-5">Messages</h2>
        <Tabs
          tabs={[
            {
              label: "Active",
              content: <ConversationList conversations={data} />,
            },
            {
              label: "Archived",
              content: (
                <ConversationList
                  conversations={
                    data ? data.filter((row) => row._archived) : null
                  }
                />
              ),
            },
          ]}
          contentHeader={contentHeader}
        />
      </div>
    );
  };

  return (
    <InternalLayout>
      {isError ? (
        <Alert variant="danger" className="header-error">
          {error.message}
        </Alert>
      ) : null}
      <Helmet>
        <title>Messages | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>{renderContent()}</Container>
    </InternalLayout>
  );
};

export default Messages;
