import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import { useApiPost } from "../../../hooks/useApi";
import { useFormData } from "../../../hooks/useFormData";

const AddBankAccountModal = ({ show, onToggle, onSuccess = () => {} }) => {
  const defaultVals = {
    accountNumber: "",
    routingNumber: "",
    accountType: "individual",
  };
  const { formData, onChange, setData } = useFormData(defaultVals);

  const saveBankAccount = useApiPost(
    "/payments/connect-bank-accounts",
    (res) => {
      onToggle();
      window.gtag("event", "conversion", {
        send_to: "AW-11127239856/_nrdCKzs1pgYELDp8Lkp",
      });
      onSuccess();
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    setData(defaultVals);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (e) => {
    e.preventDefault();
    saveBankAccount.mutate({ ...formData });
  };

  return (
    <Modal show={show} onHide={() => onToggle()}>
      <Modal.Body>
        <p>
          <strong>Add a direct deposit account</strong>
        </p>
        <form onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Account Type</Form.Label>
            <Form.Select
              name="accountType"
              onChange={onChange}
              value={formData.accountType}
            >
              <option value="individual">Individual</option>
              <option value="company">Company</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Routing Number</Form.Label>
            <Form.Control
              name="routingNumber"
              onChange={onChange}
              value={formData.routingNumber}
              placeholder="Routing Number"
              required={true}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              name="accountNumber"
              onChange={onChange}
              value={formData.accountNumber}
              placeholder="Account Number"
              required={true}
            />
          </Form.Group>

          <Row>
            <Col>
              <Button variant="link" onClick={() => onToggle()}>
                CANCEL
              </Button>
            </Col>
            <Col className="text-end">
              <Button
                variant="primary"
                type="submit"
                disabled={
                  saveBankAccount.isLoading ||
                  formData.routingNumber.length < 6 ||
                  formData.accountNumber < 6
                }
                style={{ minWidth: 200 }}
              >
                SAVE
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

AddBankAccountModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
};

export default AddBankAccountModal;
