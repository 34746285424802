import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Texture from "@mui/icons-material/Texture";
import human from "humanparser";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import moment from "moment-timezone";
import styled from "styled-components";

import { useUser } from "../../../contexts/UserContext";
import StarRating from "../../common/StarRating";
import ProfilePicture from "../../common/ProfilePicture";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 430px) {
    align-items: flex-start;
  }
`;

const StyledProfile = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: start;

  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const DetailsHeader = ({ job }) => {
  const { user } = useUser();
  const profile = !job.requestFor
    ? job.customer.profiles[0]
    : job.customer.profiles.find((p) => p._id === job.requestFor);

  const getStarRating = (u) => {
    const ratings = u._ratings || { score: 0, count: 0 };
    return <StarRating rating={ratings.score} totalRatings={ratings.count} />;
  };

  const renderBadges = (u) => {
    if (!u.helper) {
      return null;
    }

    return (
      <>
        {u.helper.profile.licenses && u.helper.profile.licenses.length > 0
          ? u.helper.profile.licenses.map((l, lIndex) => {
              let matches = /\s\((.*)\)$/.exec(l.title);
              const abbr = matches ? matches[1] : l.title;
              return (
                <Badge
                  key={lIndex}
                  bg="secondary"
                  className="mr-1"
                  style={{ marginRight: "5px" }}
                >
                  {abbr}
                </Badge>
              );
            })
          : null}
      </>
    );
  };

  const getNameWithPreferred = (name, preferred) => {
    const { lastName } = human.parseName(name);
    return `${preferred} ${lastName}`;
  };

  const renderUser = (u) => {
    const url = `/users/profile/${u._id}${
      u.userType === "customer" && job.requestFor
        ? `?profile=${job.requestFor}`
        : ""
    }`;

    // we need to get the name of this user within context
    // because customer profiles can have preferred names
    let name = u.name;

    if (u.userType === "customer") {
      name = profile.preferredName
        ? getNameWithPreferred(profile.name, profile.preferredName)
        : profile.name;
    }

    return (
      <StyledWrapper>
        <div className="print-hide">
          <Link to={url}>
            <ProfilePicture
              user={
                u.userType === "customer"
                  ? { ...profile, customer: { profile } }
                  : u
              }
              size={window.innerWidth <= 435 ? "md" : "lg"}
            />
          </Link>
        </div>
        <StyledProfile>
          <div>
            <h3>
              <Link to={url}>{name}</Link>
            </h3>
            {getStarRating(u)}
            {renderBadges(u)}
          </div>
          <div>
            {job._ical && job.jobStatus === "Booking Confirmed" ? (
              <AddToCalendarButton
                name={job._ical.summary}
                startDate={moment(job._ical.start).format("YYYY-MM-DD")}
                startTime={moment(job._ical.start).format("HH:mm")}
                endTime={moment(job._ical.end).format("HH:mm")}
                options={["Apple", "Google", "Yahoo", "Outlook.com", "iCal"]}
                timeZone={job._ical.timezone}
                status={job._ical.status}
                organizer={`${job._ical.organizer.name}|${job._ical.organizer.email}`}
                attendee={`${job._ical.attendees[0].name}|${job._ical.attendees[0].email}`}
                recurrence={job._ical.rrule ? `RRULE:${job._ical.rrule}` : ""}
                buttonStyle="round"
                size="2|3|4"
              />
            ) : null}
          </div>
        </StyledProfile>
      </StyledWrapper>
    );
  };

  if (user.userType === "customer" && job.helper) {
    return renderUser(job.helper);
  } else if (user.userType === "customer") {
    return (
      <Row>
        <Col xs={4} sm={3} md={1}>
          <Texture />
        </Col>
        <Col>
          <strong className="d-block mb-3">Requested Helpers</strong>
          <Row>
            {job.requestedHelpers.map((helper, helperIdx) => {
              const { firstName } = human.parseName(helper.name);
              return (
                <Col key={helperIdx} xs={6} sm={4} md={3}>
                  <Link
                    to={`/users/profile/${helper._id}`}
                    style={{
                      textDecoration: "none",
                      textAlign: "center",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <ProfilePicture
                      user={helper}
                      size="md"
                      style={{ margin: "auto" }}
                    />
                    <p className="text-muted text-center">{firstName}</p>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    );
  }

  return renderUser(job.customer);
};

export default DetailsHeader;
