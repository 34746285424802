import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { Https } from "@mui/icons-material";

const CreditCardPaymentForm = ({
  redirectTo,
  buttonLabel = "Complete Payment",
  isSetupIntent = false,
  additionalContent = null,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const http = window.location.protocol;
  const baseUrl = window.location.hostname;
  const returnUrl = redirectTo
    ? `${http}//${baseUrl}${redirectTo}`
    : `${http}//${baseUrl}/welcome/background/pay-complete`;

  const runPayment = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let result;

    if (isSetupIntent) {
      result = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
    } else {
      result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: returnUrl,
        },
      });
    }

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      setIsBusy(false);
      toast.error(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  useEffect(() => {
    if (isBusy) {
      runPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusy]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setIsBusy(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type="submit"
        variant="primary"
        className="mt-5 mb-3 w-100"
        disabled={!stripe || isBusy}
      >
        <Https /> {buttonLabel}
      </Button>
      {additionalContent ? (
        <>
          <br />
          {additionalContent}
        </>
      ) : null}
    </form>
  );
};

export default CreditCardPaymentForm;
