import React from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import numeral from "numeral";

import ServicesDisplayList from "./ServicesDisplayList";

const AvailabilityDetailsModal = ({ show, toggle, availabilityCache }) => {
  const render = () => {
    if (!show || !availabilityCache) {
      return null;
    }

    return (
      <>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Helper</Form.Label>
              <p>
                <Link to={`/admin/user/${availabilityCache.user._id}`}>
                  {availabilityCache.user.name}
                </Link>
              </p>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Services</Form.Label>
              <ServicesDisplayList
                selectedServices={availabilityCache.user.helper.servicesOffered}
                specializedCare={availabilityCache.user.helper.specializedCare}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Vehicle Available</Form.Label>
              <p>
                {availabilityCache.user.helper.vehicleAvailable ? "Yes" : "No"}
              </p>
            </Form.Group>

            {availabilityCache.user.helper.vehicleAvailable ? (
              <Form.Group className="mb-3">
                <Form.Label>Vehicle Covers</Form.Label>
                <p>{availabilityCache.user.helper.vehicleCovers.join(", ")}</p>
              </Form.Group>
            ) : null}

            <Form.Group className="mb-3">
              <Form.Label>Max Travel Distance</Form.Label>
              <p>{availabilityCache.user.helper.maxTravelDistance} miles</p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Available</Form.Label>
              <p>
                {moment(availabilityCache.dates.start).format(
                  "MM/DD/YYYY h:mm A"
                )}{" "}
                - {moment(availabilityCache.dates.end).format("h:mm A")}
              </p>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Available Overnight</Form.Label>
              <p>
                {availabilityCache.user.helper.availableOvernight
                  ? "Yes"
                  : "No"}
              </p>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Hourly Rates</Form.Label>
              <p>
                Standard:{" "}
                {numeral(
                  availabilityCache.user.helper.hourlyRates.standard
                ).format("$0.00")}
              </p>
              <p>
                Specialized:{" "}
                {numeral(
                  availabilityCache.user.helper.hourlyRates.specialized
                ).format("$0.00")}
              </p>
              <p>
                Overnight:{" "}
                {numeral(
                  availabilityCache.user.helper.hourlyRates.overnight
                ).format("$0.00")}
              </p>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal show={show} onHide={() => toggle()} size="lg">
      <Modal.Body>{render()}</Modal.Body>
    </Modal>
  );
};

export default AvailabilityDetailsModal;
