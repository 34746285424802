import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, ButtonGroup, Button, Form } from "react-bootstrap";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import Remove from "@mui/icons-material/Remove";

import { useFormData } from "../../../hooks/useFormData";
import TimeSelect from "../../common/TimeSelect";

const defaultSelections = {
  jobType: "One-Time",
  startDate: moment().add(3, "days").format("YYYY-MM-DD"),
  endDate: "",
  startTime: "08:00",
  endTime: "20:00",
  rrule: "",
};
const WEEKDAY = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "TH", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "SA", value: "Saturday" },
];

const HelperAvailabilityForm = ({
  show,
  toggle,
  selections,
  onApply,
  style = {},
}) => {
  const { formData, onChange, setData, resetData } = useFormData(
    selections || defaultSelections
  );
  const [weekDay, setWeekDay] = useState([]);
  const getRRuleText = () => {
    let res = "Every week on ";
    weekDay.forEach((day, index) => {
      res += day;
      if (index < weekDay.length - 1) {
        res += ", ";
      }
    });
    return res;
  };

  useEffect(() => {
    resetData(selections || defaultSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections]);

  useEffect(() => {
    setData({ ...formData, rrule: getRRuleText() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weekDay]);

  const onSubmit = () => {
    // validate the start date
    if (formData.startDate) {
      const dt = moment(formData.startDate);
      if (dt.isValid() && dt.valueOf() < moment().valueOf()) {
        toast.error("An invalid start date was selected.");
        return;
      }
    }

    // it's all good
    onApply(formData);
    toggle();
  };

  if (!show) {
    return null;
  }

  return (
    <div className="wizard-overlay absolute" style={{ ...style }}>
      <Button
        type="button"
        variant="link"
        onClick={() => {
          toggle();
        }}
        className="wizard-overlay__close-icon"
      >
        &times;
      </Button>

      <Form.Group className="mb-4">
        <h3>How often would you like this help?</h3>
        <ButtonGroup className="full-width">
          <Button
            onClick={() =>
              onChange({ target: { name: "jobType", value: "Recurring" } })
            }
            variant={
              formData.jobType === "Recurring"
                ? "secondary"
                : "outline-secondary"
            }
          >
            RECURRING
          </Button>{" "}
          <Button
            onClick={() =>
              onChange({ target: { name: "jobType", value: "One-Time" } })
            }
            variant={
              formData.jobType === "One-Time"
                ? "secondary"
                : "outline-secondary"
            }
          >
            ONE TIME
          </Button>
        </ButtonGroup>
      </Form.Group>

      {formData.jobType === "One-Time" && (
        <Form.Group className="mb-4">
          <h3>Which day?</h3>
          <Form.Control
            name="startDate"
            type="date"
            size="sm"
            onChange={onChange}
            value={formData.startDate}
          />
        </Form.Group>
      )}

      {formData.jobType === "Recurring" && (
        <>
          <h3>Which day(s)?</h3>
          <div className="d-flex justify-content-between">
            {WEEKDAY.map(({ label, value }) => (
              <div
                className={`recurring-weekday ${
                  weekDay.includes(value) ? "active" : ""
                }`}
                onClick={() => {
                  if (!weekDay.includes(value)) {
                    setWeekDay([...weekDay, value]);
                  } else {
                    setWeekDay(weekDay.filter((d) => d !== value));
                  }
                }}
                key={value}
              >
                {label}
              </div>
            ))}
          </div>
        </>
      )}

      <hr />

      <Form.Group className="mb-4">
        <h3>Select a window of time that works for you.</h3>
        <p className="text-muted body1">
          The larger the window, the more available helpers.
        </p>
        <Row>
          <Col xs={5}>
            <TimeSelect
              selectName="startTime"
              value={formData.startTime}
              onChange={onChange}
            />
          </Col>
          <Col xs={2}>
            <p className="text-center pt-2">
              <Remove />
            </p>
          </Col>
          <Col xs={5}>
            <TimeSelect
              selectName="endTime"
              value={formData.endTime}
              onChange={onChange}
              hasOvernight={true}
            />
          </Col>
        </Row>
      </Form.Group>

      <Button
        variant="primary"
        className="d-block w-100"
        onClick={() => {
          onSubmit();
        }}
      >
        APPLY FILTERS
      </Button>
    </div>
  );
};

HelperAvailabilityForm.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  selections: PropTypes.object,
  onApply: PropTypes.func.isRequired,
};

export default HelperAvailabilityForm;
