import React, { useEffect } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectIsConnectedToRoom,
} from "@100mslive/react-sdk";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

import { useApi } from "../../hooks/useApi";
import useEffectOnce from "../../hooks/useEffectOnce";
import Loading from "../common/Loading";

import InConference from "./components/InConference";
import PreviewConference from "./components/PreviewConference";

const Conference = () => {
  const { withUser } = useParams();
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  const { call, data } = useApi({
    onError: (err) => toast.error(err.message),
  });

  const leaveConference = async () => {
    await hmsActions.leave();
  };

  useEffect(() => {
    if (isConnected) {
      window.onunload = () => {
        hmsActions.leave();
      };
    }
  }, [hmsActions, isConnected]);

  useEffectOnce(() => {
    call("/conference", "post", null, { toUser: withUser });
  });

  const renderView = () => {
    if (isConnected) {
      return <InConference onEndCall={() => leaveConference()} />;
    }

    if (data && data.appToken) {
      return (
        <PreviewConference
          withUser={{ _id: withUser, name: "Not Actual User" }}
          appToken={data.appToken}
        />
      );
    }

    return <Loading msg="Preparing your call..." />;
  };

  return (
    <div>
      <Helmet>
        <title>Video Call | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {renderView()}
    </div>
  );
};

export default Conference;
