import React from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { useApi } from "../../../hooks/useApi";

const BookingCancelForm = ({ booking, onSuccess, onCancel }) => {
  const { call, isLoading } = useApi({
    onError: (err) => toast.error(err.message),
  });

  const cancelBooking = () => {
    call(
      `/admin/job-requests/${booking.jobRequest._id}/cancel`,
      "put",
      undefined,
      undefined,
      () => {
        onSuccess();
      }
    );
  };

  const cancelEvent = () => {
    call(
      `/admin/job-requests/${booking.jobRequest._id}/events/${booking._id}/cancel`,
      "put",
      undefined,
      undefined,
      () => {
        onSuccess();
      }
    );
  };

  return (
    <div className="d-flex justify-content-start align-items-center gap-2">
      <Button
        variant="outline-danger"
        onClick={() => cancelBooking()}
        disabled={isLoading}
      >
        Cancel Job
      </Button>
      {booking.jobRequest.jobType[0] === "Recurring" ? (
        <Button
          variant="outline-danger"
          onClick={() => cancelEvent()}
          disabled={isLoading}
        >
          Cancel Only This Event
        </Button>
      ) : null}
      <Button variant="link" onClick={() => onCancel()} disabled={isLoading}>
        Cancel
      </Button>
    </div>
  );
};

export default BookingCancelForm;
