import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useQueryClient } from "react-query";

import InternalLayout from "../layout/InternalLayout";
import { useApiGet } from "../../hooks/useApi";
import ProfilePicture from "../common/ProfilePicture";
import Tabs from "../common/Tabs";
import Loading from "../common/Loading";
import StarRating from "../common/StarRating";
import { ucwords } from "../../lib/stringHelpers";
import { isProdEnv } from "../../lib/envHelpers";

import UserMessages from "./components/UserMessages";
import JobRequestList from "./components/JobRequestList";

const ConversationProfile = () => {
  const { userId } = useParams();
  const isProd = isProdEnv();
  const queryClient = useQueryClient();

  const { isLoading, data, isError, error } = useApiGet(
    `messages-${userId}`,
    `/communications/conversations/${userId}`,
    { markAsRead: 1 },
    { staleTime: 1000, enabled: true }
  );

  useApiGet("services", "/services", null, {
    staleTime: 300000,
  });

  useEffect(() => {
    if (data) {
      queryClient.invalidateQueries("notifications");
      queryClient.invalidateQueries("nav-counts");

      setTimeout(() => {
        window.scrollTo(0, window.document.body.scrollHeight);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const displayUserName = (user) => {
    if (user.userType === "system") {
      return "Manana";
    }

    return ucwords(user.name);
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    const tabsList = [
      {
        label: "Messages",
        content: <UserMessages withUser={data.user} messages={data.messages} />,
      },
    ];

    if (data.user.userType !== "system") {
      tabsList.push({
        label: "Job History",
        content: <JobRequestList jobs={data.jobs} />,
      });
    }

    return (
      <div>
        <Row>
          <Col>
            <Link to="/messages">
              <ArrowBack />
            </Link>
          </Col>
          <Col className="text-end">
            {!isProd ? (
              <Link
                to={`/conference/${data.user._id}`}
                className="btn btn-primary"
                style={{ width: 200 }}
              >
                Join/Start Video Call
              </Link>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col xs={6} sm={4} md={3}>
            <Link
              to={`/users/profile/${data.user._id}`}
              style={{ textDecoration: "none" }}
            >
              <ProfilePicture user={data.user} size="lg" />
            </Link>
          </Col>
          <Col xs={6} sm={8} md={9}>
            <Link to={`/users/profile/${data.user._id}`}>
              <h2 className="mt-4">{displayUserName(data.user)}</h2>
            </Link>
            {data.user.userType !== "system" ? (
              <StarRating
                rating={data.user._ratings.score}
                totalRatings={data.user._ratings.count}
              />
            ) : null}
          </Col>
        </Row>
        <br />
        <br />
        <Tabs tabs={tabsList} />
      </div>
    );
  };

  return (
    <InternalLayout>
      {isError ? (
        <Alert variant="danger" className="header-error">
          {error.message}
        </Alert>
      ) : null}
      <Helmet>
        <title>Conversation | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>{renderContent()}</Container>
    </InternalLayout>
  );
};

export default ConversationProfile;
