import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FormGroup,
  FormLabel,
  FormControl,
  Alert,
  Button,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";

const Login = () => {
  const { user, verificationToken, signIn, verified } = useUser();
  const { setTitle } = usePage();
  const { formData, onChange } = useFormData({ email: "" });
  const navigate = useNavigate();
  const [googleSignError, setGoogleSignError] = useState(false);

  const checkHelperBackgroundCheck = (res = null) => {
    let userInfo = res ? res : user;
    if (
      userInfo.userType === "helper" &&
      (!userInfo.helper.backgroundCheck ||
        !userInfo.helper.backgroundCheck.checkrId)
    ) {
      navigate("/background-check", { from: "login" });
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    if (verificationToken) {
      navigate("/sign-in/verify");
    }
  }, [verificationToken, navigate]);

  const submitPost = useApiPost("/auth/login", (res) => {
    signIn(res.token);
  });

  const submitToken = useApiPost("/auth/login-with-google", (res) => {
    verified(res);
    checkHelperBackgroundCheck(res);
  });

  useEffect(() => {
    if (user !== null) {
      checkHelperBackgroundCheck();
    } else if (verificationToken !== null) {
      navigate("/sign-in/verify");
    }
    setTitle("Sign into your account.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    setGoogleSignError(false);
    submitPost.mutate({ email: formData.email });
  };

  const content = (
    <>
      <h3>Sign In</h3>
      <form onSubmit={onSubmit}>
        <p className="lead">
          Please enter your email address in the field below to start your sign
          in.
        </p>
        {googleSignError ? (
          <Alert variant="danger">
            Failed to sign in with Google. Please try again.
          </Alert>
        ) : null}
        {submitPost.isError ? (
          <Alert variant="danger">{submitPost.error.message}</Alert>
        ) : null}
        {submitToken.isError ? (
          <Alert variant="danger">{submitToken.error.message}</Alert>
        ) : null}
        <FormGroup>
          <FormLabel>Email</FormLabel>
          <FormControl
            name="email"
            type="email"
            placeholder="your@email.com"
            value={formData.email}
            required={true}
            onChange={onChange}
          />
        </FormGroup>
        <hr />
        <Button
          type="submit"
          variant="primary"
          disabled={submitPost.isLoading}
          style={{ width: 200 }}
        >
          {submitPost.isLoading ? "..." : "Sign In"}
        </Button>
      </form>
    </>
  );

  return (
    <RegisterLayout
      title="Sign into your account."
      titleStyle={{ marginTop: "20%" }}
    >
      <Helmet>
        <title>User Login | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default Login;
