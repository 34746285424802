import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import AdminLayout from "../layout/AdminLayout";
import { useApiGet, useApi } from "../../hooks/useApi";
import Loading from "../common/Loading";
import { ucwords } from "../../lib/stringHelpers";
import Calendar from "../common/Calendar";

import { InternalResponsiveColumn } from "../common/Common.elements";

const StyledAvail = styled.div`
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.75rem;
  margin-bottom: 1rem;
`;

const AdminUserDetails = () => {
  const { user, signOut, verified } = useUser();
  const { userId } = useParams();
  const [selDates, setSelDates] = useState({ day: null, avail: null });
  const navigate = useNavigate();

  const { isLoading, data } = useApiGet(
    `admin-users-${userId}`,
    `/admin/users/${userId}`,
    null,
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const { isLoading: isLoadingImpersonate, call: impersonateUser } = useApi({
    onSuccess: (res) => {
      verified({ ...res, _impersonatedBy: user._id });
    },
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (user.userType !== "admin" && user._impersonatedBy) {
      const redirectTo =
        user.userType === "customer" ? "/bookings" : "/dashboard";
      navigate(redirectTo);
    } else if (user.userType !== "admin") {
      signOut();
      navigate("/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const renderRecurring = (row) => {
    const start = moment(row.dates.start);
    const end = moment(row.dates.end);

    return (
      <StyledAvail>
        <h5>
          Between {start.format("h:mm A")} and {end.format("h:mm A")}
        </h5>
        <span>
          {ucwords(row.availability.repeatsSchedule)} on{" "}
          {row.availability.repeatsOn.join(", ")}
        </span>
      </StyledAvail>
    );
  };

  const renderSingle = (row) => {
    return (
      <StyledAvail>
        <h5>
          Between {moment(row.dates.start).format("h:mm A")} until{" "}
          {moment(row.dates.end).format("h:mm A")}
        </h5>
        <span>Single Slot</span>
      </StyledAvail>
    );
  };

  const renderSelectedDate = () => {
    if (!selDates.day) {
      return (
        <p>Select a highlighted date on the calendar to see more details.</p>
      );
    }

    if (selDates.dates.length === 0) {
      return (
        <div>
          <h3>{moment(selDates.day, "YYYY-MM-DD").format("MM/DD/YYYY")}</h3>
          <hr />
          <p>Selected date does not have any availability.</p>
        </div>
      );
    }

    return (
      <div>
        <h3>{moment(selDates.day, "YYYY-MM-DD").format("MM/DD/YYYY")}</h3>
        <hr />
        {selDates.dates.map((avail, availIdx) => {
          return (
            <div key={availIdx}>
              {avail.recurring ? renderRecurring(avail) : renderSingle(avail)}
            </div>
          );
        })}
      </div>
    );
  };

  const getHightlightedDates = () => {
    const today = moment().startOf("day").valueOf();
    const formattedData = [];

    data._availability?.forEach((avail) => {
      const dt = moment(avail.dates?.start);

      if (today > dt.valueOf()) {
        return;
      }

      formattedData.push(dt.format("YYYY-MM-DD"));
    });

    return formattedData;
  };

  const selectHighlightedDate = (date) => {
    const dt = moment(date).format("YYYY-MM-DD");
    const available = data._availability.filter(
      (avail) => moment(avail.dates?.start).format("YYYY-MM-DD") === dt
    );
    setSelDates({ day: dt, dates: available || [] });
  };

  const renderHelper = () => {
    return (
      <>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Service Area</Form.Label>
              <p>
                {data._serviceArea ? data._serviceArea.metroArea : "Unknown"}
              </p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Postal Code</Form.Label>
              <p>{data.address.postal}</p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Created On</Form.Label>
              <p>
                {data.createdOn
                  ? moment(data.createdOn).format("MM/DD/YYYY")
                  : "Unknown"}
              </p>
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <Form.Label>Services Offered</Form.Label>
            <ul>
              {data.helper.servicesOffered.map((row, rowIdx) => {
                const service = data._servicesList.find(
                  (s) => s._id === row.service
                );
                return <li key={rowIdx}>{service.name}</li>;
              })}
            </ul>
            {data.helper.specializedCare.length > 0 ? (
              <>
                <Form.Label>Specialized Care</Form.Label>
                <ul>
                  {data.helper.specializedCare.map((care, careIdx) => {
                    return <li key={careIdx}>{care}</li>;
                  })}
                </ul>
              </>
            ) : null}
          </Col>
          <Col>
            <Form.Label>Job Types</Form.Label>
            <p>{data.helper.jobTypes.join(", ")}</p>

            <Form.Label>Overnight</Form.Label>
            <p>{data.helper.availableOvernight ? "Yes" : "No"}</p>

            <Form.Label>Hourly Rates</Form.Label>
            <ul>
              <li>Standard: ${data.helper.hourlyRates.standard}</li>
              <li>Specialized: ${data.helper.hourlyRates.specialized}</li>
              <li>Overnight: ${data.helper.hourlyRates.overnight || 0}</li>
            </ul>
          </Col>
          <Col>
            <Form.Label>Account Health</Form.Label>
            <ul>
              <li>
                Background Check:{" "}
                {data.helper.backgroundCheck.status || "Not submitted"}
              </li>
              <li>
                Background Check Payment:{" "}
                {data.helper.backgroundCheck.stripePaymentDate
                  ? moment(
                      data.helper.backgroundCheck.stripePaymentDate
                    ).format("MM/DD/YYYY")
                  : "Not Made"}
              </li>
              <li>
                Has Availability:{" "}
                {data._accountStatus.hasAvailability ? "Yes" : "No"}
              </li>
              <li>
                Has Payment Account:{" "}
                {data._accountStatus.hasBankAccount ? "Yes" : "No"}
              </li>
              <li>
                Stripe Ready: {data._accountStatus.isStripeClear ? "Yes" : "No"}
              </li>
            </ul>
          </Col>
        </Row>
        <hr />
        <Form.Label>Emergency Contacts</Form.Label>
        <Row>
          {data.helper.profile.emergencyContacts &&
          data.helper.profile.emergencyContacts.length > 0 ? (
            data.helper.profile.emergencyContacts.map((contact, idx) => {
              return (
                <Col key={idx} xs={12} sm={6} md={4} lg={3}>
                  <div>
                    <h5>
                      {contact.name},{" "}
                      <small>
                        {contact.relationship === "Other"
                          ? contact.relationshipOther
                          : contact.relationship}
                      </small>
                    </h5>
                    <p className="mb-0">{contact.phone}</p>
                    <p>
                      {contact.primaryContact ? (
                        <Badge bg="secondary">Primary</Badge>
                      ) : null}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <p>No emergency contacts provided.</p>
          )}
        </Row>
        <hr />
        <Form.Label>Availability</Form.Label>
        {data._availability && data._availability.length === 0 ? (
          <p>None entered for the next 3 months.</p>
        ) : (
          <Row>
            <Col xs={12} sm={12} md={8}>
              <Calendar
                date={moment().toDate()}
                selectedDates={selDates.day ? [selDates.day] : []}
                highlightDates={getHightlightedDates()}
                onDateSelect={(dt) => selectHighlightedDate(dt)}
              />
            </Col>
            <Col xs={12} sm={12} md={4}>
              {renderSelectedDate()}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const renderCustomer = () => {
    return (
      <>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Service Area</Form.Label>
              <p>
                {data._serviceArea ? data._serviceArea.metroArea : "Unknown"}
              </p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Postal Code</Form.Label>
              <p>{data.address.postal}</p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Created On</Form.Label>
              <p>
                {data.createdOn
                  ? moment(data.createdOn).format("MM/DD/YYYY")
                  : "Unknown"}
              </p>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  const renderInDepth = () => {
    switch (data.userType) {
      case "helper":
        return renderHelper();
      case "customer":
        return renderCustomer();
      default:
        return null;
    }
  };

  const render = () => {
    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <p>{data.name}</p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <p>
                <a
                  href={`mailto:${data.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.email}
                </a>
              </p>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <p>{data.settings.mobile || "-"}</p>
            </Form.Group>
          </Col>
        </Row>
        {renderInDepth()}
      </div>
    );
  };

  return (
    <AdminLayout>
      <Helmet>
        <title>User Details | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container fluid>
        <Row>
          <InternalResponsiveColumn>
            <Link to="/admin">
              <ArrowBack />
            </Link>
            <Row>
              <Col>
                <h2 className="mb-5">
                  User Details{data ? `: ${data.name}` : ""}
                  {data ? (
                    <small className="ps-3">{data.userType}</small>
                  ) : null}
                </h2>
              </Col>
              <Col>
                <div className="text-end">
                  <Button
                    variant="primary"
                    style={{ width: 200 }}
                    onClick={() =>
                      impersonateUser(`/admin/users/${userId}/impersonate`)
                    }
                    disabled={isLoadingImpersonate}
                  >
                    {isLoadingImpersonate ? "..." : "IMPERSONATE"}
                  </Button>
                </div>
              </Col>
            </Row>
            <hr />
            {render()}
          </InternalResponsiveColumn>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default AdminUserDetails;
