import React from "react";
import PropTypes from "prop-types";
import Async from "react-select/async";
import { components } from "react-select";
import axios from "axios";

const UserSelect = ({
  value,
  isMulti = false,
  onChange,
  showDefaultOptions = false,
  userType = "helper",
  params = {},
}) => {
  const Input = (props) => {
    return <components.Input {...props} />;
  };

  const Option = (props) => {
    const record = props.data.record;
    return (
      <components.Option {...props}>
        <p className="mb-0">{props.children}</p>
        <small className="text-muted">
          {record.email} / {record.userType}
        </small>
      </components.Option>
    );
  };

  const loadOptions = async (term) => {
    const endpoint = `${process.env.REACT_APP_API_URL}/admin/users`;
    const results = await axios.get(endpoint, {
      params: {
        ...params,
        userType,
        search: term,
      },
    });
    const opts = [];

    results.data.data.forEach((item) => {
      opts.push({
        value: item._id,
        label: item.name,
        record: item,
      });
    });

    return opts;
  };

  return (
    <Async
      components={{ Option, Input }}
      isMulti={isMulti}
      isClearable={true}
      isSearchable={true}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      defaultOptions={showDefaultOptions}
      placeholder={`Search ${userType}s...`}
    />
  );
};

UserSelect.propTypes = {
  value: PropTypes.any,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showDefaultOptions: PropTypes.bool,
  userType: PropTypes.string.isRequired,
};

export default UserSelect;
