import React from "react";

import { Container } from "./StepProgressMobile.elements";

const StepProgressMobile = ({ stepsTotal, active }) => {
  const activeItem = active || 0;

  return (
    <Container>
      STEP {activeItem + 1} OF {stepsTotal}
    </Container>
  );
};

export default StepProgressMobile;
