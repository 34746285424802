import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { useApi } from "../../../hooks/useApi";
import { CalendarWrapper, LoadingScreen } from "../../common/Calendar.elements";

const CustomerCalendar = ({ user }) => {
  const [calDateRange, setCalDateRange] = useState(null);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const api = useApi({
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (!calDateRange) {
      return;
    }

    api.call(
      "/bookings",
      "get",
      {
        jobStatus:
          "New help request,Booking Confirmed,Booking Complete,Booking Canceled,Booking Expired",
        limit: 500,
        startDate: calDateRange.start,
        endDate: calDateRange.end,
      },
      undefined,
      (res) => {
        const key = user.userType === "customer" ? "helper" : "customer";

        const sources = {
          open: {
            color: "#ff0000",
            textColor: "#fff",
            events: res
              .filter((row) => row.jobStatus === "New help request")
              .map((job) => {
                return {
                  id: `booking::${job._id}`,
                  title: `Open Job`,
                  start: moment(job.dates.startDateTime).toDate(),
                  end: moment(job.dates.startDateTime)
                    .add(job.hours, "hours")
                    .toDate(),
                };
              }),
          },
          booked: {
            color: "#356f7c",
            events: res
              .filter((row) =>
                ["Booking Confirmed", "Booking Complete"].includes(
                  row.jobStatus
                )
              )
              .map((booking) => {
                return {
                  id: `booking::${booking._id}::${booking.event._id}`,
                  title: `Booked with ${booking[key].name}`,
                  start: moment(booking.event.startDate).toDate(),
                  end: moment(booking.event.endDate).toDate(),
                };
              }),
          },
          canceled: {
            color: "#000",
            textColor: "#fff",
            classNames: "strike-through",
            events: res
              .filter(
                (row) =>
                  row.jobStatus === "Booking Canceled" ||
                  row.jobStatus === "Booking Expired"
              )
              .map((job) => {
                return {
                  id: `booking::${job._id}`,
                  title: `${
                    job.jobStatus === "Booking Canceled"
                      ? "Canceled"
                      : "Expired"
                  }${job[key] ? ` with ${job[key].name}` : ""}`,
                  start: moment(job.dates.startDateTime).toDate(),
                  end: moment(job.dates.startDateTime)
                    .add(job.hours, "hours")
                    .toDate(),
                };
              }),
          },
        };

        setEvents(Object.values(sources));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calDateRange]);

  return (
    <CalendarWrapper>
      {api.isLoading ? (
        <LoadingScreen>
          <span>Loading calendar...</span>
        </LoadingScreen>
      ) : null}
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={window.innerWidth <= 430 ? "timeGridDay" : "dayGridMonth"}
        headerToolbar={{
          start: "prev,next",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        eventSources={events}
        eventClick={(info) => {
          const [eventType, jobId, eventId] = info.event.id.split("::");

          if (eventType === "booking" && !eventId) {
            navigate(`/bookings/${jobId}?back=/calendar`);
            return;
          }

          if (eventType === "booking") {
            navigate(`/bookings/${jobId}/${eventId}?back=/calendar`);
            return;
          }
        }}
        datesSet={(dateInfo) => {
          setCalDateRange({
            start: moment.utc(dateInfo.start).format("YYYY-MM-DD"),
            end: moment.utc(dateInfo.end).format("YYYY-MM-DD"),
          });
        }}
      />
    </CalendarWrapper>
  );
};

export default CustomerCalendar;
