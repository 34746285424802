import React from "react";
import { Row, Col, Form } from "react-bootstrap";
import Toggle from "react-toggle";
import moment from "moment-timezone";

import TimeSelect from "../../../common/TimeSelect";

import "react-toggle/style.css";

const WEEKDAY = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "TH", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "SA", value: "Saturday" },
];

const FullCalendarEventForm = ({
  formData,
  onChange,
  setData,
  toggleCheckboxValue,
}) => {
  return (
    <>
      <div className="mb-3">
        <Row>
          <Col>
            <TimeSelect
              selectName="startTime"
              value={formData.startTime}
              onChange={onChange}
            />
          </Col>
          <Col xs={1} className="text-center">
            -
          </Col>
          <Col>
            <TimeSelect
              selectName="endTime"
              value={formData.endTime}
              onChange={onChange}
            />
          </Col>
        </Row>
      </div>
      <hr />
      <Row>
        <Col>
          <p
            id="repeats-label"
            className="subtitle1"
            style={{ fontWeight: 400 }}
          >
            Repeats
          </p>
        </Col>
        <Col className="text-end">
          <Toggle
            id="repeats"
            name="repeats"
            checked={formData.repeats}
            aria-labelledby="repeats-label"
            onChange={(e) => {
              const wkday = moment(formData.date).format("dddd");
              setData(
                {
                  repeats: e.target.checked,
                  repeatsSchedule: e.target.checked ? "weekly" : null,
                  repeatsOn: e.target.checked ? [wkday] : [],
                },
                true
              );
            }}
          />
        </Col>
      </Row>

      <div className={formData.repeats ? "mt-3" : "d-none"}>
        <p className="subtitle1" style={{ fontWeight: 400 }}>
          Which day(s)?
        </p>
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: "1px solid #EBE0DE",
            paddingBottom: "1rem",
            marginBottom: "1rem",
          }}
        >
          {WEEKDAY.map(({ label, value }) => (
            <div
              className={`recurring-weekday ${
                formData.repeatsOn.includes(value) ? "active" : ""
              }`}
              onClick={() => toggleCheckboxValue("repeatsOn", value)}
              key={value}
            >
              {label}
            </div>
          ))}
        </div>

        {[
          { label: "Every week", value: "weekly" },
          { label: "Every other week", value: "every 2 weeks" },
          { label: "Every month", value: "every month" },
        ].map((opt, optIndex) => {
          return (
            <Form.Group
              key={optIndex}
              style={{
                borderBottom: "1px solid #EBE0DE",
                paddingBottom: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Form.Check
                type="radio"
                id={`rb-repeats-schedule-${opt.value.replace(/\s/, "-")}`}
                name="repeatsSchedule"
                label={opt.label}
                value={opt.value}
                checked={formData.repeatsSchedule === opt.value}
                onChange={onChange}
              />
            </Form.Group>
          );
        })}
        <div>
          <p className="mb-3 subtitle1" style={{ fontWeight: 400 }}>
            Repeats until (optional)
          </p>
          <Row>
            <Col xs={5}>
              <Form.Control
                type="date"
                name="endDate"
                id="endDate"
                value={formData.endDate || ""}
                required={false}
                onChange={onChange}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FullCalendarEventForm;
