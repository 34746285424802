import React from "react";
import { Form, Button } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Delete from "@mui/icons-material/Delete";
import { getLanguageNames } from "../../../lib/languageHelpers";
import ButtonList from "../../common/ButtonList";
import Prompt from "../../common/Prompt";

const getOptions = () => {
  const names = getLanguageNames();
  return names.map((n) => {
    return { label: n, value: n };
  });
};

const Languages = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    languages: get(user, "helper.profile.languages"),
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  return (
    <SubInternalLayout title={`Languages`}>
      <Helmet>
        <title>Languages | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <Form.Group>
        <Select
          options={getOptions()}
          isMulti={false}
          isSearchable={true}
          value={[]}
          onChange={(sel) => {
            const changes = [...formData.languages, sel.value];
            onChange({ target: { name: "languages", value: changes } });
          }}
          styles={{
            menu: (base) => ({ ...base, zIndex: "700 !important" }),
          }}
        />
      </Form.Group>

      <ButtonList
        className="mt-4"
        buttons={formData.languages.map((l, i) => {
          return {
            label: l,
            icon: <Delete />,
            onClick: () => {
              const changes = [...formData.languages].filter(
                (lang) => lang !== l
              );
              onChange({ target: { name: "languages", value: changes } });
            },
          };
        })}
        emptyMsg="Please select one or more languages."
      />

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "helper.profile.languages": formData.languages,
            })
          }
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default Languages;
