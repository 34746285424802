import React from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import PaymentVerificationForm from "./PaymentVerificationForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Payment3ds = ({ clientSecret, onSuccess }) => {
  return (
    <div>
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <PaymentVerificationForm
          clientSecret={clientSecret}
          onSuccess={onSuccess}
        />
      </Elements>
    </div>
  );
};

export default Payment3ds;
