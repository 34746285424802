import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  FormGroup,
  FormCheck,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import ExpandLessOutlined from "@mui/icons-material/ExpandLessOutlined";
import ServiceListItemSubForm from "./ServiceListItemSubForm";

import "./css/ServicesList.css";

const checkIfAllEmpty = (items) => {
  let hasContent = false;

  Object.keys(items).forEach((key) => {
    const vals = items[key];

    if (!vals) {
      return;
    }

    switch (true) {
      case Array.isArray(vals) && vals.length > 0:
      case typeof vals === "string" && vals.length > 0:
      case typeof vals === "object" && Object.keys(vals).length > 0:
        hasContent = true;
        break;
      default:
        return;
    }
  });

  return hasContent ? false : true;
};

const ServiceListItem = ({
  service,
  isSubListOpen,
  isChecked,
  onSelectionChange,
  selections,
  toggleSelection,
  toggleSubForm,
  role,
}) => {
  const getSelectionList = () => {
    if (
      Object.values(selections).length >= 1 &&
      Object.keys(selections)[0] !== "accommodate"
    ) {
      return (
        <p
          style={{ color: "#857070", fontSize: "0.95rem", marginLeft: "63px" }}
        >
          {Object.values(selections)[0].join(", ")}
        </p>
      );
    }
  };

  return (
    <div className="service-item">
      <Row
        onClick={() => {
          if (service.additionalQuestions) {
            toggleSubForm();
          }
        }}
      >
        <Col>
          <span>
            <span
              style={{
                display: "inline-block",
                width: 52,
                textAlign: "center",
                marginRight: "0.7rem",
              }}
            >
              <img src={service.icon} alt={service.name} title={service.name} />
            </span>
            {service.name}
            <span style={{ paddingLeft: "5px" }}>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="left"
                overlay={
                  <Popover id={`popover-${service._id}`}>
                    <Popover.Body>{service.tooltip}</Popover.Body>
                  </Popover>
                }
              >
                <InfoOutlined />
              </OverlayTrigger>
            </span>
          </span>
          {getSelectionList()}
        </Col>
        <Col xs={2} sm={3} className="text-end text-muted">
          {isSubListOpen && service.additionalQuestions ? (
            <Button variant="link" onClick={() => toggleSubForm()}>
              <ExpandLessOutlined />
            </Button>
          ) : (
            <FormGroup controlId={`service-${service._id}`}>
              <FormCheck
                name={service._id}
                type="checkbox"
                value={service._id}
                checked={isChecked}
                size="lg"
                onChange={(e) => {
                  onSelectionChange({
                    target: {
                      name: service._id,
                      value: e.target.checked,
                      answers: {},
                      additionalQuestions: service.additionalQuestions,
                    },
                  });
                }}
              />
            </FormGroup>
          )}
        </Col>
      </Row>
      {isSubListOpen && service.additionalQuestions ? (
        <ServiceListItemSubForm
          service={service}
          values={selections}
          role={role}
          onChange={(vals) => {
            onSelectionChange({
              target: {
                name: service._id,
                value: checkIfAllEmpty(vals) ? false : true,
                answers: vals,
              },
            });
          }}
          onSave={(vals) => {
            onSelectionChange({
              target: {
                name: service._id,
                value: true,
                answers: vals,
              },
            });
            toggleSelection();
          }}
          onClear={() =>
            onSelectionChange({
              target: {
                name: service._id,
                value: false,
                answers: {},
              },
            })
          }
          onToggle={() => {
            toggleSubForm();
          }}
        />
      ) : null}
    </div>
  );
};

ServiceListItem.propTypes = {
  service: PropTypes.object.isRequired,
  isSubListOpen: PropTypes.bool,
  isChecked: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  selections: PropTypes.object,
  toggleSelection: PropTypes.func.isRequired,
  toggleSubForm: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
};

export default ServiceListItem;
