import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Star from "@mui/icons-material/Star";
import ArrowForward from "@mui/icons-material/ArrowForward";

const StarContainer = styled.div`
  color: var(--primary-color);
  font-size: 0.5rem;
`;

const CircledNumber = styled.div`
  height: 100px;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 4px solid var(--primary-color);
  border-radius: 50%;
  margin: auto;
  margin-bottom: 2rem;
  background: #fff;

  & > h1 {
    margin: auto;
  }
`;

const SectionWithLightBg = styled.div`
  background-image: url("https://mh-website-assets.s3.us-east-2.amazonaws.com/WhiteSectionBackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 0.5rem;
`;

const CommunitySection = styled.div`
  background-image: url("https://mh-website-assets.s3.us-east-2.amazonaws.com/Background_Blue_Bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(249, 242, 241);
`;

const QuotesSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;

  & > div {
    flex: 1;
  }

  @media only screen and (max-width: 430px) {
    flex-direction: column;
  }
`;

const WhyBecomeAHelper = () => {
  return (
    <>
      <div style={{ background: "#fff", margin: 0, padding: "3rem 0" }}>
        <Container>
          <div className="pt-5 pb-4 text-center">
            <h1 className="mb-5">Benefits of becoming a Manana Helper</h1>
            <Row>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <img
                    src="https://mh-website-assets.s3.us-east-2.amazonaws.com/Income.png"
                    alt="Set Your Own Rate"
                    style={{ maxWidth: 248, maxHeight: 248 }}
                  />
                  <h2>Set your own rate</h2>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <img
                    src="https://mh-website-assets.s3.us-east-2.amazonaws.com/Calendar.png"
                    alt="Set your own schedule"
                    style={{ maxWidth: 248, maxHeight: 248 }}
                  />
                  <h2>Set your own schedule</h2>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <img
                    src="https://mh-website-assets.s3.us-east-2.amazonaws.com/Payment.png"
                    alt="Get paid upfront"
                    style={{ maxWidth: 248, maxHeight: 248 }}
                  />
                  <h2>Get paid upfront</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <div style={{ background: "#f9f2f1", margin: 0, padding: "3rem 0" }}>
        <Container>
          <div className="pt-5 pb-4 text-center">
            <h1 className="mb-5">Requirements</h1>
            <Row>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <CircledNumber>
                    <h1>1</h1>
                  </CircledNumber>
                  <h2>Pass a background check</h2>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <CircledNumber>
                    <h1>2</h1>
                  </CircledNumber>
                  <h2>Add your availability</h2>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="mb-3">
                  <CircledNumber>
                    <h1>3</h1>
                  </CircledNumber>
                  <h2>Create your profile</h2>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>

      <CommunitySection style={{ margin: 0, padding: "3rem 0" }}>
        <Container>
          <div className="pt-5 pb-4 text-center">
            <h1 className="mb-5">We're building our community</h1>
            <p className="body1" style={{ maxWidth: 700, margin: "auto" }}>
              If you love to help people, we can't wait to meet you. Manana
              Helpers provide direct care to recipients and see the impact
              immediately. A great team of Helpers allows Manana to deliver on
              our promise of accessible, affordable, and exceptional care.
            </p>
            <br />
            <br />
          </div>
        </Container>
      </CommunitySection>

      <div style={{ background: "#f9f2f1", margin: 0, padding: "3rem 0" }}>
        <Container>
          <div className="pt-5 pb-4">
            <h1 className="mb-5">What other Helpers are saying</h1>
            <QuotesSection>
              <div className="mb-3">
                <StarContainer>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </StarContainer>
                <p className="body1">
                  "As a full-time Occupational Therapy Doctorate Student at
                  Belmont University, I wanted to find a job in healthcare that
                  offered a flexible schedule and fulfilled my passion for
                  working with older adults. I had previously worked in home
                  health for 2 years and when I heard about Manana I knew it
                  would be the perfect fit!"
                </p>
                <p className="subtitle1">- Lexi S.</p>
              </div>
              <div className="mb-3">
                <StarContainer>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </StarContainer>
                <p className="body1">
                  "I've always been a caregiver at heart, but I was lucky enough
                  to be able to help my parents take care of my grandfather in
                  the last few years before he passed. Taking him to
                  appointments and just spending time with him asking questions
                  and listening to his stories was such a gift and I know he'd
                  be grateful that I'm now choosing to give my time to others in
                  this way."
                </p>
                <p className="subtitle1">- Gillian W.</p>
              </div>
              <div className="mb-3">
                <StarContainer>
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                  <Star />
                </StarContainer>
                <p className="body1">
                  "I wanted to provide those in need of help with consistent and
                  reliable resources. Caring for my sick grandmother when I was
                  younger, I know firsthand the burden that good help removes. I
                  want to pass that along to those that need it most!"
                </p>
                <p className="subtitle1">- Cortney W.</p>
              </div>
            </QuotesSection>
          </div>
        </Container>
      </div>

      <SectionWithLightBg style={{ padding: "3rem 0" }}>
        <Container>
          <div className="pt-5 pb-4 text-center">
            <h1 className="mb-5">Have other questions?</h1>
            <StyledLink to="/faq#helper">
              <span>LEARN MORE</span> <ArrowForward />
            </StyledLink>
            <br />
            <br />
          </div>
        </Container>
      </SectionWithLightBg>
    </>
  );
};

export default WhyBecomeAHelper;
