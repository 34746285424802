import React from "react";
import { Row, Col } from "react-bootstrap";

import ProfilePicture from "../common/ProfilePicture";
import StarRating from "../common/StarRating";
import Tabs from "../common/Tabs";
import ReviewsList from "../common/ReviewsList";
import { ucwords } from "../../lib/stringHelpers";

const CustomerProfile = ({ user, profileId = null }) => {
  const profile = profileId
    ? user.profiles.find((p) => p._id === profileId)
    : user.profiles[0];

  return (
    <>
      <Row>
        <Col xs={6} sm={4} md={3}>
          <ProfilePicture user={user} size="lg" />
        </Col>
        <Col xs={6} sm={8} md={9}>
          <h2 className="mt-4">{ucwords(user.name)}</h2>
          {user.userType !== "system" ? (
            <StarRating
              rating={user._rating.score || 0}
              totalRatings={user._rating.count || 0}
            />
          ) : null}
        </Col>
      </Row>
      <br />
      <Tabs
        tabs={[
          {
            label: "Profile",
            content: (
              <div>
                <h5>Who Is This For</h5>
                <p className="text-muted">{profile.name}</p>
                <div className="divider" />

                <h5>Preferred Name</h5>
                <p className="text-muted">{profile.preferredName}</p>
                <div className="divider" />

                <h5>Preferred Language</h5>
                <p className="text-muted">
                  {profile.preferredLanguage || "Unknown"}
                </p>
                <div className="divider" />

                <h5>Relationship With Account Owner</h5>
                <p className="text-muted">{ucwords(profile.relationship)}</p>
                <div className="divider" />
              </div>
            ),
          },
          {
            label: "Reviews",
            content: (
              <ReviewsList
                userId={user._id}
                emptyMsg={<p className="text-center">No reviews were found.</p>}
              />
            ),
          },
        ]}
      />
    </>
  );
};

export default CustomerProfile;
