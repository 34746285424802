import React from "react";
import Select from "react-select";

const options = [
  "HIV/AIDS",
  "Multiple Sclerosis",
  "Psoriasis",
  "Alzheimer's Disease",
  "Amyotrophic Lateral Sclerosis (ALS)",
  "Brain Tumor",
  "Dementia",
  "Epilepsy",
  "Meningitis",
  "Multiple Sclerosis",
  "Vascular Dementia",
  "Parkinson's Disease",
  "Arthritis",
  "Osteoarthritis",
  "Psoriatic Arthritis",
  "Rheumatoid Arthritis",
  "Bursitis",
  "Fibromyalgia",
  "Gout",
  "Osteoporosis",
  "Scoliosis",
  "Brain Cancer",
  "Breast Cancer",
  "Cervical Cancer",
  "Childhood Cancer",
  "Colon Cancer",
  "Endometrial Cancer",
  "Lung Cancer",
  "Oral Cancer",
  "Ovarian Cancer",
  "Pancreatic Cancer",
  "Prostate Cancer",
  "Skin Cancer",
  "Thyroid Cancer",
  "Blood Cancer",
  "Multiple Myeloma",
  "Leukemia",
  "Chronic Lymphocytic Leukemia",
  "Acute Lymphoblastic Leukemia",
  "Lymphoma",
  "Hodgkin Lymphoma",
  "Prediabetes",
  "Type 1 Diabetes",
  "Type 2 Diabetes",
  "Gestational Diabetes",
  "Crohn's Disease",
  "Constipation",
  "GERD",
  "Irritable Bowel Syndrome",
  "Ulcerative Colitis",
  "Peptic Ulcers",
  "All Digestive Diseases",
  "Anorexia Nervosa",
  "Binge Eating Disorder",
  "Bulimia Nervosa",
  "Conjunctivitis (Pinkeye)",
  "Glaucoma",
  "Macular Degeneration",
  "Cardiomyopathy",
  "Congenital Heart Disease",
  "Congestive Heart Failure",
  "Coronary Artery Disease",
  "Enlarged Heart",
  "Heart Arrhythmias",
  "Heart Attack",
  "High Blood Pressure",
  "End Stage Renal Failure",
  "Kidney Reflux",
  "Hepatitis A",
  "Hepatitis B",
  "Hepatitis C",
  "ADHD",
  "Bipolar Disorder",
  "Depression",
  "PTSD",
  "Complex PTSD",
  "OCD",
  "Schizophrenia",
  "Headache",
  "Migraine",
  "Knee Pain",
  "Muscle Pain",
  "Pain Management",
  "Allergies",
  "Asthma",
  "Bronchitis",
  "COPD",
  "Emphysema",
  "Pneumonia",
  "Walking Pneumonia",
  "Pulmonary Fibrosis",
  "Tuberculosis",
  "Eczema",
  "Psoriasis",
  "Plaque Psoriasis",
  "Scalp Psoriasis",
  "Shingles",
  "All Skin Diseases",
  "Sleep Apnea",
  "Narcolepsy",
  "Insomnia",
  "Pregnancy Insomnia",
  "All Sleep Disorders",
  "Erectile Dysfunction",
  "Bariatric Surgery",
  "Cataract Surgery",
  "All Types of Surgery",
  "Hyperkalemia",
  "Obesity",
  "Norovirus",
  "Raynaud's Syndrome",
];

const HealthConditionsSelect = ({ value, onChange }) => {
  return (
    <Select
      options={options.map((o) => {
        return { value: o, label: o };
      })}
      onChange={onChange}
      value={value}
      placeholder="Enter name..."
      styles={{
        menu: (base) => ({ ...base, zIndex: "700 !important" }),
      }}
    />
  );
};

export default HealthConditionsSelect;
