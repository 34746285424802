import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import moment from "moment-timezone";
import styled from "styled-components";

import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import Twitter from "@mui/icons-material/Twitter";

import logo from "../../../Logo-White.svg";

const ColStyled = styled(Col)`
  @media only screen and (max-width: 576px) {
    &.text-center,
    &.text-end {
      text-align: left !important;
    }
  }
`;

const FooterLinkStyled = styled(Link)`
  display: inline-block;
  padding: 1rem 0;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
`;

const FooterAStyled = styled.a`
  display: inline-block;
  padding: 1rem 0;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
`;

const Footer = ({ includeHelpText = true }) => {
  return (
    <footer>
      <Container>
        {includeHelpText ? (
          <>
            <h3 className="text-center">
              Questions? Call (615) 212-9609 or{" "}
              <a
                href="mailto:info@mananahelp.com"
                target="_blank"
                style={{ color: "#fff" }}
                rel="noreferrer"
              >
                &#105;&#110;&#102;&#111;&#64;&#109;&#97;&#110;&#97;&#110;&#97;&#104;&#101;&#108;&#112;&#46;&#99;&#111;&#109;
              </a>
            </h3>
            <hr />
          </>
        ) : null}
        <Row>
          <Col xs={12} sm>
            <FooterLinkStyled to="https://www.mananahelp.com/terms-of-service">
              Terms Of Service
            </FooterLinkStyled>
          </Col>
          <ColStyled className="text-center" xs={12} sm>
            <FooterLinkStyled to="https://www.mananahelp.com/privacy-policy">
              Privacy Policy
            </FooterLinkStyled>
          </ColStyled>
          <ColStyled className="text-center" xs={12} sm>
            <FooterLinkStyled
              to="https://www.mananahelp.com/faq"
              style={{ textTransform: "none" }}
            >
              FAQs
            </FooterLinkStyled>
          </ColStyled>
          <ColStyled className="text-center" xs={12} sm>
            <FooterLinkStyled to="https://www.mananahelp.com/contact-us">
              Contact Us
            </FooterLinkStyled>
          </ColStyled>
          <ColStyled className="text-end" xs={12} sm>
            <FooterAStyled
              href="https://www.facebook.com/MananaHelp/"
              target="_blank"
              rel="noreferrer"
            >
              <Facebook />
            </FooterAStyled>
            <FooterAStyled
              href="https://www.instagram.com/mananahelp/"
              target="_blank"
              className="ms-5"
              rel="noreferrer"
            >
              <Instagram />
            </FooterAStyled>
            <FooterAStyled
              href="https://twitter.com/mananahelp_com"
              target="_blank"
              className="ms-5"
              rel="noreferrer"
            >
              <Twitter />
            </FooterAStyled>
          </ColStyled>
        </Row>

        {!includeHelpText ? <hr /> : null}
        {/* <h2 className={`mt-${includeHelpText ? 5 : 3}`}>manana</h2> */}
        <div className={`mt-${includeHelpText ? 5 : 3}`}>
          <a href="/">
            <img src={logo} alt="manana" title="manana" />
          </a>
        </div>
        <p>&copy; {moment().format("YYYY")} Manana. All rights reserved.</p>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  includeHelpText: PropTypes.bool,
};

export default Footer;
