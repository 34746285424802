import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormGroup, FormCheck } from "react-bootstrap";

const VehicleFormProfile = ({ currentData = [], setVehicles }) => {
  const [formData, setFormData] = useState({
    wheelchair: currentData.includes("A wheelchair") || false,
    walker: currentData.includes("A walker") || false,
    vehicleAvailable: currentData.includes("I don't have a car") ? true : false,
  });

  const onChange = (e) => {
    const updatedForm = { ...formData, [e.target.name]: e.target.value };
    let value = [];
    if (e.target.name === "vehicleAvailable" && e.target.value) {
      updatedForm.wheelchair = false;
      updatedForm.walker = false;
      value = ["I don't have a car"];
    } else {
      updatedForm.vehicleAvailable = false;
      value = [
        ...(updatedForm.wheelchair ? ["A wheelchair"] : []),
        ...(updatedForm.walker ? ["A walker"] : []),
      ];
    }
    const hasVehicle = value.length >= 1 && value[0] !== "I don't have a car";
    value = value.length < 1 ? null : value;

    if (
      updatedForm.walker ||
      updatedForm.wheelchair ||
      updatedForm.vehicleAvailable
    ) {
      // Require that at least 1 option is selected
      setFormData(updatedForm);
      setVehicles(value, hasVehicle);
    }
  };

  return (
    <div>
      <h3 className="mb-3">Your car accommodates</h3>
      <div className="service-item mb-3">
        <FormGroup controlId="formBasicCheckbox">
          <FormCheck
            id="wheelchair"
            type="checkbox"
            label={
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                A wheelchair
              </span>
            }
            value="wheelchair"
            checked={formData.wheelchair}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "wheelchair", value: e.target.checked },
              })
            }
          />
          <FormCheck
            id="walker"
            type="checkbox"
            label={
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                A walker
              </span>
            }
            value="walker"
            checked={formData.walker}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "walker", value: e.target.checked },
              })
            }
          />

          <FormCheck
            id="cane"
            type="checkbox"
            label={
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                A cane
              </span>
            }
            value="cane"
            checked={formData.cane}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "cane", value: e.target.checked },
              })
            }
          />
          <FormCheck
            id="none"
            type="checkbox"
            label={
              <span
                style={{
                  marginLeft: "1rem",
                }}
              >
                I don't have a car
              </span>
            }
            value="none"
            checked={formData.vehicleAvailable}
            size="lg"
            onChange={(e) =>
              onChange({
                target: { name: "vehicleAvailable", value: e.target.checked },
              })
            }
          />
        </FormGroup>
      </div>
    </div>
  );
};

VehicleFormProfile.propTypes = {
  currentData: PropTypes.array.isRequired,
};

export default VehicleFormProfile;
