import React, { useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import { useApi } from "../../../hooks/useApi";
import { useFormData } from "../../../hooks/useFormData";
import FileUploadButton from "../../common/FileUploadButton";

const DEFAULT_DATE = moment().add(3, "days");

const DEFAULT_VALS = {
  startDate: DEFAULT_DATE.format("YYYY-MM-DD"),
  startTime: "10:00",
  endTime: "12:00",
  hours: 2,
  mananaNotes: "",
};

const JobRequestEditForm = ({ job, onSuccess, onCancel }) => {
  const { formData, onChange, setData } = useFormData({ ...DEFAULT_VALS });

  const { call, isLoading } = useApi({
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (job) {
      const start = moment(job.dates.startDateTime);

      setData({
        startDate: start.format("YYYY-MM-DD"),
        startTime: start.format("HH:mm"),
        endTime: start.add(job.hours, "hours").format("HH:mm"),
        hours: job.hours,
        mananaNotes: job.mananaNotes || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const onSubmit = (e) => {
    e.preventDefault();

    const parts = formData.startTime.split(":");
    const startDt = moment(formData.startDate).set({
      hour: Number(parts[0]),
      minute: Number(parts[1]),
    });

    const payload = {
      startDate: startDt.toDate(),
      endTime: moment(startDt).add(formData.hours, "hours").format("HH:mm"),
      hours: Number(formData.hours),
      notes: formData.notes,
    };

    call(`/admin/job-requests/${job._id}`, "put", undefined, payload, () => {
      onSuccess();
    });
  };

  const onTimeChange = (e) => {
    const isStart = e.target.name === "startTime";
    let startDt = moment(
      `${formData.startDate} ${formData.startTime}`,
      "YYYY-MM-DD HH:mm"
    );
    let endDt = moment(
      `${formData.startDate} ${formData.endTime}`,
      "YYYY-MM-DD HH:mm"
    );
    let updates = {};

    if (isStart) {
      // we want to move the end time forward to maintain the hour difference
      startDt = moment(
        `${formData.startDate} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      endDt = moment(startDt).add(formData.hours, "hours");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
      };
    } else {
      // we want to calculate the new hour difference between start and end
      endDt = moment(
        `${endDt.format("YYYY-MM-DD")} ${e.target.value}`,
        "YYYY-MM-DD HH:mm"
      );
      const mins = endDt.diff(startDt, "minutes");

      updates = {
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
        hours: mins / 60,
      };
    }

    setData({ ...updates }, true);
  };

  const onHourChange = (e) => {
    let startDt = moment(
      `${formData.startDate} ${formData.startTime}`,
      "YYYY-MM-DD HH:mm"
    );
    let endDt = startDt.add(e.target.value, "hours");
    setData({ endTime: endDt.format("HH:mm"), hours: e.target.value });
  };

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={onChange}
              format="MM/DD/YYYY"
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>Hours</Form.Label>
            <Form.Control
              type="number"
              name="hours"
              min={1}
              max={23}
              step={0.5}
              value={formData.hours}
              onChange={onHourChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>Start Time</Form.Label>
            <Form.Control
              type="time"
              name="startTime"
              value={formData.startTime}
              onChange={onTimeChange}
              required
            />
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-3 required">
            <Form.Label>End Time</Form.Label>
            <Form.Control
              type="time"
              name="endTime"
              value={formData.endTime}
              onChange={onTimeChange}
              required
            />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              name="mananaNotes"
              onChange={onChange}
              value={formData.mananaNotes}
              placeholder="Optional notes..."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Additional File</Form.Label>
            {formData.fileUrl ? (
              <p>
                <a href={formData.fileUrl} target="_blank" rel="noreferrer">
                  View Uploaded File
                </a>
              </p>
            ) : null}
            <div>
              <FileUploadButton
                onUpload={(url) =>
                  onChange({ target: { name: "fileUrl", value: url } })
                }
              />
            </div>
          </Form.Group>
        </Col>
      </Row>

      <div className="mt-4">
        <Button type="submit" variant="primary" disabled={isLoading}>
          Save Changes
        </Button>
        <Button variant="link" onClick={() => onCancel()} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default JobRequestEditForm;
