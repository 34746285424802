import React from "react";
import { Modal, Button } from "react-bootstrap";
import { nl2br } from "../../lib/stringHelpers";

const ConfirmModal = ({ show, toggle, msg, onConfirm, onCancel }) => {
  return (
    <Modal show={show} onHide={() => toggle()}>
      <Modal.Header>
        <h3>Confirm Action</h3>
      </Modal.Header>
      <Modal.Body>{nl2br(msg)}</Modal.Body>
      <Modal.Footer style={{ justifyContent: "flex-start" }}>
        <Button
          variant="primary"
          style={{ width: 200 }}
          onClick={() => {
            toggle();
            onConfirm();
          }}
        >
          CONFIRM
        </Button>
        <Button
          variant="link"
          onClick={() => {
            toggle();
            onCancel();
          }}
        >
          CANCEL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
