import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Select from "react-select";
import { get, set } from "idb-keyval";

import AdminLayout from "../layout/AdminLayout";
import { useApiGet } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import Loading from "../common/Loading";
import HelperSelect from "./components/HelperSelect";
import { InternalResponsiveColumn } from "../common/Common.elements";

import AvailabilityDetailsModal from "./components/AvailabilityDetailsModal";
import BookingDetailsModal from "./components/BookingDetailsModal";
import JobDetailsModal from "./components/JobDetailsModal";
import CreateBookingSidebar from "./components/CreateBookingSidebar";

const CalendarWrapper = styled.div`
  position: relative;
  & .fc-daygrid-event {
    cursor: pointer;
  }

  & .fc-daygrid-event.strike-through {
    text-decoration: line-through;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
`;

const DEFUALT_VALS = {
  helpers: [],
  view: [],
  start: null,
  end: null,
};

const AdminBookings = () => {
  const [filters, setFilters] = useState({ enabled: false });
  const { formData, onChange, setData } = useFormData({});
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showNewBooking, setShowNewBooking] = useState(null);

  const { isLoading, data } = useApiGet(
    `admin-bookings`,
    `/admin/job-requests`,
    filters,
    {
      onError: (err) => toast.error(err.message),
      enabled: filters.enabled,
    }
  );

  useEffect(() => {
    (async () => {
      const res = await get("adminFormData");
      const saved = res || {};
      setData({ ...DEFUALT_VALS, ...saved });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData && typeof formData.helpers !== "undefined") {
      set("adminFormData", formData);

      // window.localStorage.setItem("adminFormData", JSON.stringify(formData));
      setFilters({
        helpers: formData.helpers.map((h) => h.value),
        start: formData.start,
        end: formData.end,
        enabled: true,
      });
    }
  }, [formData]);

  const getEventSourceData = () => {
    if (!data) {
      return [];
    }

    const newEvents = {
      bookings: {
        color: "#356f7c",
        events: data.activeBookings
          .filter((row) => row.isCanceled === false)
          .map((booking) => {
            return {
              id: `booking::${booking._id}`,
              title: `${booking.customer.name} x ${booking.helper.name}`,
              start: moment(booking.startDate).toDate(),
              end: moment(booking.endDate).toDate(),
            };
          }),
      },
      availability: {
        color: "#ffe2d7",
        textColor: "#333",
        events: data.availability.map((avail) => {
          return {
            id: `availability::${avail._id}`,
            title: `Helper: ${avail.user.name}`,
            start: moment(avail.dates.start).toDate(),
            end: moment(avail.dates.end).toDate(),
          };
        }),
      },
      requests: {
        color: "#ff0000",
        textColor: "#fff",
        events: data.jobRequests
          .filter((row) => row.jobStatus === "New help request")
          .map((job) => {
            return {
              id: `job::${job._id}`,
              title: `Open Job: ${job.customer.name}`,
              start: moment(job.dates.startDateTime).toDate(),
              end: moment(job.dates.startDateTime)
                .add(job.hours, "hours")
                .toDate(),
            };
          }),
      },
      canceled: {
        color: "#000",
        textColor: "#fff",
        classNames: "strike-through",
        events: [
          ...data.jobRequests
            .filter(
              (row) =>
                (row.jobStatus === "Booking Canceled" ||
                  row.jobStatus === "Booking Expired") &&
                !row.helper
            )
            .map((job) => {
              return {
                id: `job::${job._id}`,
                title: `${
                  job.jobStatus === "Booking Expired" ? "" : "Expired: "
                }${job.customer.name}`,
                start: moment(job.dates.startDateTime).toDate(),
                end: moment(job.dates.startDateTime)
                  .add(job.hours, "hours")
                  .toDate(),
              };
            }),
          ...data.activeBookings
            .filter((row) => row.isCanceled)
            .map((job) => {
              return {
                id: `booking::${job._id}`,
                title: job.customer.name,
                start: moment(job.startDate).toDate(),
                end: moment(job.endDate).toDate(),
              };
            }),
        ],
      },
    };

    let eventsArr = [];

    if (formData.view && formData.view.length > 0) {
      formData.view.forEach((v) => {
        switch (v.value) {
          case "availability":
            eventsArr.push(newEvents.availability);
            break;
          case "booked":
            eventsArr.push(newEvents.bookings);
            break;
          case "open_jobs":
            eventsArr.push(newEvents.requests);
            break;
          case "canceled":
            eventsArr.push(newEvents.canceled);
            break;
          default:
          // do nothing
        }
      });
    } else {
      eventsArr = Object.values(newEvents);
    }

    return eventsArr;
  };

  const render = () => {
    return (
      <div>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Helper</Form.Label>
              <HelperSelect
                value={formData.helpers}
                onChange={(vals) =>
                  onChange({ target: { name: "helpers", value: vals } })
                }
                isMulti={true}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>View</Form.Label>
              <Select
                isMulti={true}
                options={[
                  {
                    label: "Availability",
                    value: "availability",
                  },
                  {
                    label: "Booked",
                    value: "booked",
                  },
                  {
                    label: "Open Jobs",
                    value: "open_jobs",
                  },
                  {
                    label: "Canceled",
                    value: "canceled",
                  },
                ]}
                value={formData.view}
                onChange={(vals) =>
                  onChange({ target: { name: "view", value: vals } })
                }
              />
            </Form.Group>
          </Col>
          <Col>
            <div className="mt-4 text-end">
              <Button
                variant="primary"
                onClick={() =>
                  setShowNewBooking(
                    moment().add(3, "days").format("YYYY-MM-DD")
                  )
                }
              >
                New Booking
              </Button>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            {!filters.enabled ? (
              <Loading />
            ) : (
              <CalendarWrapper>
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    start: "prev,next",
                    center: "title",
                    end: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  eventSources={getEventSourceData()}
                  dateClick={(info) => {
                    setShowNewBooking(info.dateStr);
                  }}
                  eventClick={(info) => {
                    const [eventType, eventId] = info.event.id.split("::");

                    switch (eventType) {
                      case "availability":
                        const avail = data.availability.find(
                          (a) => a._id === eventId
                        );
                        setSelectedAvailability(avail);
                        break;
                      case "booking":
                        const booking = data.activeBookings.find(
                          (b) => b._id === eventId
                        );
                        setSelectedBooking(booking);
                        break;
                      case "job":
                        const job = data.jobRequests.find(
                          (b) => b._id === eventId
                        );
                        setSelectedJob(job);
                        break;
                      default:
                        toast.error(`Unknown event type: ${eventType}`);
                    }
                  }}
                  datesSet={(dateInfo) => {
                    setData(
                      {
                        start: moment(dateInfo.start).format(),
                        end: moment(dateInfo.end).format(),
                      },
                      true
                    );
                  }}
                />
              </CalendarWrapper>
            )}
            {isLoading ? (
              <LoadingWrapper>
                <Loading />
              </LoadingWrapper>
            ) : null}
          </Col>
          {showNewBooking !== null ? (
            <Col xs={12} sm={6} md={5} lg={4} xl={4}>
              <CreateBookingSidebar
                toggle={() => setShowNewBooking(null)}
                defaultDate={showNewBooking}
              />
            </Col>
          ) : null}
        </Row>
      </div>
    );
  };

  return (
    <AdminLayout>
      <Helmet>
        <title>Bookings | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container fluid>
        <Row>
          <InternalResponsiveColumn>
            <h2 className="mb-5">Bookings Calendar</h2>
            {render()}
          </InternalResponsiveColumn>
        </Row>
        <AvailabilityDetailsModal
          availabilityCache={selectedAvailability}
          show={selectedAvailability !== null}
          toggle={() => setSelectedAvailability(null)}
        />
        <BookingDetailsModal
          booking={selectedBooking}
          show={selectedBooking !== null}
          toggle={() => setSelectedBooking(null)}
        />
        <JobDetailsModal
          job={selectedJob}
          show={selectedJob !== null}
          toggle={() => setSelectedJob(null)}
        />
      </Container>
    </AdminLayout>
  );
};

export default AdminBookings;
