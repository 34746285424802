import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import StepProgressMobile from "../../common/StepProgressMobile";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

import {
  Main,
  Container,
  BackButton,
  ProgressBar,
} from "./WizardHeader.elements";

const MobileSteps = ({ step, stepsTotal, onBack }) => {
  const navigate = useNavigate();

  return (
    <Main className="mobile-progress-bar">
      <Container>
        <BackButton onClick={() => (onBack ? onBack() : navigate(-1))}>
          <ArrowBack />
        </BackButton>
        <StepProgressMobile stepsTotal={stepsTotal} active={step} />
        <BackButton
          onClick={() => navigate("/")}
          style={{ paddingRight: "15px" }}
        >
          <Close />
        </BackButton>
      </Container>
      <ProgressBar width={((step + 1) * 100) / stepsTotal} />
    </Main>
  );
};

MobileSteps.propTypes = {
  step: PropTypes.number.isRequired,
  stepsTotal: PropTypes.number.isRequired,
  onBack: PropTypes.func,
};

export default MobileSteps;
