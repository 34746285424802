import React, { useState, useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";
import CalendarToday from "@mui/icons-material/CalendarToday";
import Edit from "@mui/icons-material/Edit";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FilterList from "@mui/icons-material/FilterList";
import { toast } from "react-toastify";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import ContentBlock from "../common/ContentBlock";
import JobRequestStickyReview from "./JobRequestStickyReview";
import { displayTime } from "../../lib/dateHelpers";
import { getAvailabilityWindowTitle } from "../../lib/jobRequestHelpers";
import { useApi } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";
import useEffectOnce from "../../hooks/useEffectOnce";

import HelperAvailabilityForm from "./search/HelperAvailabilityForm";
import HelperSearchFiltersForm from "./search/HelperSearchFiltersForm";
import { HideOnMobile } from "../helper/components/NewHelper.elements";
import HelperShortProfile from "../helper/HelperShortProfile";

const defaultFilters = {
  gender: [],
  age: false,
  ageMin: 25,
  ageMax: 40,
  languages: [],
  licenses: [],
  overnight: false,
};

const getFiltersCount = (filters) => {
  let count = 0;

  if (filters.gender.length > 0) {
    count += 1;
  }

  if (filters.age) {
    count += 1;
  }

  if (filters.languages.length > 0) {
    count += 1;
  }

  if (filters.licenses.length > 0) {
    count += 1;
  }

  if (filters.overnight) {
    count += 1;
  }

  return count;
};

const reducer = (state, action) => {
  const { type, ...payload } = action;
  let updates = {};

  switch (type) {
    case "filters:submit":
      updates.filters = payload.newFilters || { ...defaultFilters };
      updates.overnight =
        (state.availability && state.availability.isOvernight) ||
        payload.newFilters.overnight
          ? true
          : false;
      updates.sort = payload.newSort || "price:1";
      updates.filtersCount = getFiltersCount(updates.filters);
      break;

    case "filters:clear":
      updates.filters = { ...defaultFilters };
      updates.overnight = state.availability
        ? state.availability.isOvernight
        : false;
      updates.sort = "price:1";
      updates.filtersCount = 0;
      break;

    case "availability:submit":
      updates.availability = payload.newAvailability;
      updates.overnight =
        payload.newAvailability.isOvernight || state.filters.overnight
          ? true
          : false;
      break;

    case "availability:clear":
      updates.availability = null;
      updates.overnight = state.filters.overnight;
      break;

    default:
    // do nothing
  }

  return { ...state, ...updates };
};

function getApiFilters(filters) {
  const apiFilters = {};

  if (!filters) {
    return apiFilters;
  }

  if (filters.age) {
    apiFilters.age = { min: filters.ageMin, max: filters.ageMax };
  }
  if (filters.gender && filters.gender.length > 0) {
    apiFilters.gender = filters.gender;
  }
  if (filters.languages && filters.languages.length > 0) {
    apiFilters.languages = filters.languages;
  }
  if (filters.licenses && filters.licenses.length > 0) {
    apiFilters.licenses = filters.licenses;
  }

  return apiFilters;
}

/*
  STEP 3
*/
const JobRequestHelpers = () => {
  const { user } = useUser();
  const { title, setTitle } = usePage();
  const { job, saveJob, resetJob } = useJob();
  const [state, dispatch] = useReducer(reducer, {
    availability:
      job.availability && Object.keys(job.availability).length > 0
        ? job.availability
        : null,
    sort: "price:1",
    filters: { ...defaultFilters },
    filtersCount: 0,
    overnight: job.isOvernight || false,
  });
  const navigate = useNavigate();
  const [showAvail, setShowAvail] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [list, setList] = useState([]);
  const [helpers, setHelpers] = useState(job._helpers || []);
  const [curState, setCurState] = useState(JSON.stringify(state));
  const stepsTotal = job.isNewCustomer ? 4 : 2;
  const hasSpecialityCare = job.specializedCare.length > 0;

  const apiSearch = useApi({
    onError: (err) => toast.error(err.message),
    onSuccess: (resp) => {
      setList(resp);
    },
  });

  const searchHelpers = () => {
    const params = getApiFilters(state.filters);
    apiSearch.call(
      `/helpers/search/${job?.serviceArea?._id}`,
      "post",
      undefined,
      {
        availability: state.availability ? { ...state.availability } : null,
        filters: params,
        sort: state.sort,
        services: job.services,
        timezone: moment.tz.guess(),
        isOvernight: state.overnight,
        postal: job.zip,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const toggleHelper = (helper) => {
    const found = helpers.find((h) => h._id === helper._id);
    let newHelpers;

    if (found) {
      newHelpers = helpers.filter((h) => h._id !== helper._id);
    } else {
      newHelpers = [...helpers, helper];
    }

    setHelpers(newHelpers);
    saveJob({
      helpers: newHelpers.map((h) => h._id),
      _helpers: newHelpers,
    });
  };

  useEffectOnce(() => {
    if (!job.serviceArea) {
      navigate("/find-help");
    } else if (user && !user.customer) {
      navigate("/dashboard");
    }

    setTitle("Who would you like to send a help request to?");
    window.scrollTo({ top: 0 });

    searchHelpers();
  });

  useEffect(() => {
    if (curState === JSON.stringify(state)) {
      return;
    }
    setCurState(JSON.stringify(state));
    searchHelpers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const onSubmit = (e) => {
    e.preventDefault();

    const updates = {
      requestedHelpers: helpers.map((h) => h._id),
      availability: state.availability,
      _helpers: helpers,
      isOvernight: state.overnight,
    };

    saveJob(updates);

    let redirectTo = "/find-help/register";

    if (user && user.customer) {
      redirectTo = "/find-help/profile";
    }

    /* if (user && user.customer && user.customer.profiles.length > 0) {
      redirectTo = "/find-help/review";
    } else if (user) {
      redirectTo = "/find-help/profile";
    } */

    navigate(redirectTo);
  };

  const content = (
    <>
      <form
        onSubmit={onSubmit}
        style={{
          position: "relative",
          display: showFilters ? "none" : "block",
        }}
      >
        <ContentBlock>
          <Row>
            <Col>
              <HideOnMobile>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => navigate("/find-help/services")}
                  style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
                >
                  <ArrowBack />
                </Button>
              </HideOnMobile>
            </Col>
            <Col className="text-end">
              <Button
                variant="link"
                onClick={() => setShowFilters(!showFilters)}
                style={{ fontWeight: 700, textDecoration: "none" }}
              >
                FILTERS
                {state.filtersCount > 0 ? (
                  <span style={{ marginLeft: "0.5rem" }}>
                    &middot; {state.filtersCount}
                  </span>
                ) : null}{" "}
                <FilterList />
              </Button>
            </Col>
          </Row>

          <h2 className="mb-3">Select a helper</h2>

          <div className="mb-3">
            {state.availability === null ? (
              <Button
                variant="outline-primary"
                onClick={() => setShowAvail(!showAvail)}
                style={{ width: "100%" }}
              >
                FILTER BY AVAILABILITY
              </Button>
            ) : (
              <div
                onClick={() => setShowAvail(!showAvail)}
                style={{ cursor: "pointer" }}
              >
                <Row>
                  <Col xs={1}>
                    <CalendarToday />
                  </Col>
                  <Col>
                    <div className="float-end">
                      <Edit />
                    </div>
                    <h5>
                      {getAvailabilityWindowTitle({
                        ...state,
                        isOvernight: state.overnight,
                      })}
                    </h5>
                    <p className="text-muted">
                      Between {displayTime(state.availability.startTime)} and{" "}
                      {displayTime(state.availability.endTime)}
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
          <HelperAvailabilityForm
            show={showAvail}
            toggle={() => setShowAvail(!showAvail)}
            selections={state.availability}
            sort={state.sort}
            onApply={(newAvailability) => {
              dispatch({ type: "availability:submit", newAvailability });
            }}
            onClear={() => {
              dispatch({ type: "availability:clear" });
            }}
          />
          <br />

          {apiSearch.isLoading ? (
            <p>Loading...</p>
          ) : (
            <div className="profile-list">
              {list.length > 0 ? (
                <>
                  {list.map((helper, helperIndex) => {
                    return (
                      <div key={helperIndex}>
                        <div className="float-end">
                          {state.availability === null ? (
                            <Button
                              variant="link"
                              size="lg"
                              className="m-0 p-0"
                              onClick={() =>
                                navigate(`/find-help/helpers/${helper._id}`)
                              }
                            >
                              <ChevronRight />
                            </Button>
                          ) : (
                            <Form.Group>
                              <Form.Check
                                label=""
                                checked={helpers.find(
                                  (h) => h._id === helper._id
                                )}
                                onChange={() => toggleHelper(helper)}
                              />
                            </Form.Group>
                          )}
                        </div>

                        <HelperShortProfile
                          user={helper}
                          specialityCare={hasSpecialityCare}
                          overnight={state.overnight}
                          onSelect={() =>
                            navigate(`/find-help/helpers/${helper._id}`)
                          }
                        />
                      </div>
                    );
                  })}

                  {list.length < 25 ? null : (
                    <div className="text-center">
                      <Button
                        variant="link"
                        onClick={() => alert("NEXT")}
                        style={{
                          fontWeight: 700,
                          textDecoration: "none",
                          textTransform: "uppercase",
                        }}
                        disabled={list.length < 25}
                      >
                        View More Helpers
                      </Button>
                    </div>
                  )}
                </>
              ) : (
                <p>No helpers match your criteria or availability.</p>
              )}
            </div>
          )}
        </ContentBlock>

        <JobRequestStickyReview onHelperClick={() => setShowAvail(true)}>
          <div className="mt-4">
            <Button
              type="submit"
              variant="primary"
              className="w-100 mb-4"
              style={{ fontFamily: "Noto Sans" }}
              disabled={helpers.length === 0}
            >
              NEXT
            </Button>
            <Button
              type="button"
              variant="link"
              className="w-100"
              style={{ fontFamily: "Noto Sans" }}
              onClick={() => {
                resetJob();
                navigate("/bookings");
              }}
            >
              CANCEL REQUEST
            </Button>
          </div>
        </JobRequestStickyReview>
      </form>
      <HelperSearchFiltersForm
        show={showFilters}
        toggle={() => setShowFilters(!showFilters)}
        filters={state.filters}
        sort={state.sort}
        onApply={(newFilters, newSort) => {
          dispatch({ type: "filters:submit", newFilters, newSort });
        }}
        onClear={() => {
          dispatch({ type: "filters:clear" });
        }}
      />
    </>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Select Help"}
      onStepBack={() => navigate("/find-help/services")}
      wrapInContentBlock={false}
    >
      <Helmet>
        <title>Helpers | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestHelpers;
