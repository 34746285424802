import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form } from "react-bootstrap";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import styled from "styled-components";
import Toggle from "react-toggle";

import Remove from "@mui/icons-material/Remove";

import { useFormData } from "../../../hooks/useFormData";
import TimeSelect from "../../common/TimeSelect";
import { capitalizeFirstWord } from "../../../lib/stringHelpers";

import "react-toggle/style.css";

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 15px;

  @media only screen and (max-width: 430px) {
    margin: 0 -1rem;
    border-radius: 0;
  }
`;

const defaultSelections = {
  jobType: "One-Time",
  startDate: moment().add(3, "days").format("YYYY-MM-DD"),
  endDate: "",
  startTime: "08:00",
  endTime: "20:00",
  rrule: "",
  weekdays: [],
  frequency: "weekly",
  isOvernight: false,
};
const WEEKDAY = [
  { label: "S", value: "Sunday" },
  { label: "M", value: "Monday" },
  { label: "T", value: "Tuesday" },
  { label: "W", value: "Wednesday" },
  { label: "TH", value: "Thursday" },
  { label: "F", value: "Friday" },
  { label: "SA", value: "Saturday" },
];

const HelperAvailabilityForm = ({
  show,
  toggle,
  selections,
  onApply,
  onClear,
}) => {
  const { formData, onChange, resetData } = useFormData({
    ...defaultSelections,
    ...selections,
  });

  const getRRuleText = () => {
    let res = `${capitalizeFirstWord(formData.frequency)} on `;
    formData.weekdays.forEach((day, index) => {
      res += day;
      if (index < formData.weekdays.length - 1) {
        res += ", ";
      }
    });
    return res;
  };

  useEffect(() => {
    resetData(selections || defaultSelections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections]);

  useEffect(() => {
    if (formData.startDate) {
      const diff = moment(formData.startDate, "YYYY-MM-DD").diff(
        moment(),
        "days"
      );
      if (diff < 0) {
        onChange({
          target: { name: "startDate", value: moment().format("YYYY-MM-DD") },
        });
        toast.error("You cannot select a date in the past.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.startDate]);

  const timeToNumber = (time) => {
    const parts = time.split(":");
    const hours = Number(parts[0]);
    return hours + Number(parts[1]) / 60;
  };

  useEffect(() => {
    const start = timeToNumber(formData.startTime);
    const end = timeToNumber(formData.endTime);
    const isOvernight = end < start;

    if (isOvernight !== formData.isOvernight) {
      onChange({ target: { name: "isOvernight", value: isOvernight } });
    }
  }, [formData.startTime, formData.endTime]);

  const onSubmit = () => {
    onApply({ ...formData, rrule: getRRuleText() });
    toggle();
  };

  const onClearAvailability = () => {
    onClear();
  };

  if (!show) {
    return null;
  }

  return (
    <>
      <StyledBackdrop />
      <div className="wizard-overlay absolute">
        <Button
          type="button"
          variant="link"
          onClick={() => {
            toggle();
          }}
          className="wizard-overlay__close-icon"
        >
          &times;
        </Button>

        <h2 className="mb-5">Search by availability</h2>

        <Form.Group className="mb-5">
          <h3>When would you like to start?</h3>
          <Row>
            <Col xs={12} sm={5}>
              <Form.Control
                name="startDate"
                type="date"
                onChange={onChange}
                value={formData.startDate}
                min={moment().add(24, "hours").format("YYYY-MM-DD")}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-5">
          <h3>Select a window of time</h3>
          <p className="text-muted body1">
            The larger the window, the more available helpers.
          </p>
          <Row>
            <Col xs={5}>
              <TimeSelect
                selectName="startTime"
                value={formData.startTime}
                onChange={onChange}
                size={""}
              />
            </Col>
            <Col xs={2}>
              <p className="text-center pt-2">
                <Remove />
              </p>
            </Col>
            <Col xs={5}>
              <TimeSelect
                selectName="endTime"
                value={formData.endTime}
                onChange={onChange}
                hasOvernight={false}
                size={""}
              />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className="mb-3">
          <Row>
            <Col>
              <h3>Recurring</h3>
            </Col>
            <Col className="text-end">
              <Toggle
                id="repeats"
                name="repeats"
                checked={formData.jobType === "Recurring"}
                aria-labelledby="repeats-label"
                onChange={(e) => {
                  onChange({
                    target: {
                      name: "jobType",
                      value: e.target.checked ? "Recurring" : "One-Time",
                    },
                  });
                }}
              />
            </Col>
          </Row>
        </Form.Group>

        {formData.jobType === "Recurring" && (
          <>
            <div className="mb-5">
              {[
                { label: "Every week", value: "weekly" },
                { label: "Every other week", value: "every 2 weeks" },
                { label: "Every month", value: "every month" },
              ].map((opt, optIndex) => {
                return (
                  <Form.Group key={optIndex}>
                    <Form.Check
                      type="radio"
                      id={`rb-repeats-schedule-${opt.value.replace(/\s/, "-")}`}
                      name="frequency"
                      label={opt.label}
                      value={opt.value}
                      checked={formData.frequency === opt.value}
                      onChange={onChange}
                    />
                  </Form.Group>
                );
              })}
            </div>

            <div className="mb-5">
              <h3>Which day(s)?</h3>
              <div className="d-flex justify-content-between">
                {WEEKDAY.map(({ label, value }) => (
                  <div
                    className={`recurring-weekday ${
                      formData.weekdays.includes(value) ? "active" : ""
                    }`}
                    onClick={() => {
                      if (!formData.weekdays.includes(value)) {
                        onChange({
                          target: {
                            name: "weekdays",
                            value: [...formData.weekdays, value],
                          },
                        });
                      } else {
                        const newDays = formData.weekdays.filter(
                          (d) => d !== value
                        );
                        onChange({
                          target: { name: "weekdays", value: newDays },
                        });
                      }
                    }}
                    key={value}
                  >
                    {label}
                  </div>
                ))}
              </div>
            </div>

            <div className="mb-5">
              <h3>Repeats until (optional)</h3>
              <Row>
                <Col xs={12} sm={5}>
                  <Form.Control
                    type="date"
                    name="endDate"
                    id="endDate"
                    value={formData.endDate || ""}
                    required={false}
                    onChange={onChange}
                  />
                </Col>
              </Row>
            </div>
          </>
        )}

        <hr />

        <Row>
          <Col xs={12} sm={6}>
            <Button
              variant="link"
              className="ms-0 me-0 ps-0 pe-0 mb-3 text-start"
              onClick={() => onClearAvailability()}
              style={{ fontWeight: 700, textDecoration: "none" }}
            >
              CANCEL
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Button
              variant="primary"
              className="d-block w-100 mb-3"
              onClick={onSubmit}
            >
              UPDATE RESULTS
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

HelperAvailabilityForm.propTypes = {
  show: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  selections: PropTypes.object,
  onApply: PropTypes.func.isRequired,
};

export default HelperAvailabilityForm;
