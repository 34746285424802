import React, { useState } from "react";
import {
  FormGroup,
  FormLabel,
  FormControl,
  FormSelect,
  FormCheck,
  Button,
} from "react-bootstrap";
import Edit from "@mui/icons-material/Edit";
import InputMask from "react-input-mask";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Prompt from "../../common/Prompt";

import { DateLabel, PhoneLabel, TextLabel } from "../../common/labels";

const PersonalInformation = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange, toggleCheckboxValue, setHasChanged } =
    useFormData({
      name: user.name,
      mobile: user.settings.mobile || "",
      gender: get(user, "helper.profile.gender") || "",
      dob: user.helper.profile.dateOfBirth
        ? moment.utc(user.helper.profile.dateOfBirth).format("MM/DD/YYYY")
        : "",
      vehicleAvailable: get(user, "helper.vehicleAvailable") || false,
      vehicleCovers: get(user, "helper.vehicleCovers") || [],
    });
  const [toggled, setToggled] = useState({
    name: formData.name === "",
    mobile: formData.mobile === "",
    gender: formData.gender === "",
    dob: formData.dob === "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  const toggleBtn = (key) => {
    if (toggled[key]) {
      return null;
    }

    return (
      <div className="float-end">
        <Button
          type="button"
          variant="link"
          onClick={() => setToggled({ ...toggled, [key]: true })}
        >
          <Edit />
        </Button>
      </div>
    );
  };

  return (
    <SubInternalLayout title={`Personal Information`}>
      <Helmet>
        <title>Personal Information | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <div className="field-list-item">
        <FormGroup>
          {toggleBtn("name")}
          <FormLabel>Your Name</FormLabel>
          {!toggled.name ? (
            <TextLabel val={user.name} />
          ) : (
            <FormControl
              name="name"
              placeholder="Your Name"
              onChange={onChange}
              value={formData.name}
              required={true}
            />
          )}
        </FormGroup>
      </div>
      <div className="field-list-item">
        <FormGroup>
          {toggleBtn("mobile")}
          <FormLabel>Mobile Phone Number</FormLabel>
          {!toggled.mobile ? (
            <PhoneLabel val={formData.mobile} />
          ) : (
            <InputMask
              type="tel"
              name="mobile"
              mask="999-999-9999"
              className="form-control"
              placeholder="123-123-1234"
              onChange={onChange}
              value={formData.mobile}
            />
          )}
        </FormGroup>
      </div>
      <div className="field-list-item">
        <FormGroup>
          {toggleBtn("gender")}
          <FormLabel>Gender</FormLabel>
          {!toggled.gender ? (
            <TextLabel val={formData.gender} />
          ) : (
            <FormSelect
              name="gender"
              onChange={onChange}
              value={formData.gender}
            >
              {[
                {
                  label: "Select your gender identity",
                  value: "",
                },
                {
                  label: "Female",
                  value: "Female",
                },
                {
                  label: "Male",
                  value: "Male",
                },
                {
                  label: "Non-binary",
                  value: "Non-binary",
                },
                {
                  label: "Prefer not to respond",
                  value: "Prefer not to respond",
                },
              ].map((opt, i) => {
                return (
                  <option key={i} value={opt.value}>
                    {opt.label}
                  </option>
                );
              })}
            </FormSelect>
          )}
        </FormGroup>
      </div>
      <div className="field-list-item">
        <FormGroup>
          {toggleBtn("dob")}
          <FormLabel>Date of Birth</FormLabel>
          {!toggled.dob ? (
            <DateLabel val={formData.dob} />
          ) : (
            <FormControl
              as={InputMask}
              mask="99/99/9999"
              name="dob"
              onChange={onChange}
              value={formData.dob}
            />
          )}
        </FormGroup>
      </div>
      <div className="field-list-item">
        <FormGroup>
          <FormLabel>Vehicle Information</FormLabel>
          {[
            {
              label: "A wheelchair",
              value: "A wheelchair",
            },
            {
              label: "A walker",
              value: "A walker",
            },
            {
              label: "I don't have a car",
              value: "I don't have a car",
            },
          ].map((opt, i) => {
            return (
              <FormCheck
                type="checkbox"
                key={i}
                id={`vehicle-info-${i}`}
                label={opt.label}
                value={opt.value}
                checked={formData.vehicleCovers.includes(opt.value)}
                onChange={(e) =>
                  toggleCheckboxValue("vehicleCovers", e.target.value)
                }
              />
            );
          })}
        </FormGroup>
      </div>

      <div className="text-end">
        <Button
          type="button"
          onClick={() => {
            save.mutate({
              name: formData.name || undefined,
              "settings.mobile": formData.mobile || undefined,
              "helper.profile.gender": formData.gender || undefined,
              "helper.profile.dateOfBirth": formData.dob
                ? moment.utc(formData.dob, "MM/DD/YYYY").format("YYYY-MM-DD")
                : undefined,
              "helper.vehicleAvailable": formData.vehicleAvailable,
              "helper.vehicleCovers": formData.vehicleCovers,
            });
          }}
          disabled={
            !hasChanged ||
            formData.name.length === 0 ||
            (formData.mobile && !/\d{3}-\d{3}-\d{4}/.test(formData.mobile)) ||
            (formData.dob && !/\d{2}\/\d{2}\/\d{4}/.test(formData.dob))
          }
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default PersonalInformation;
