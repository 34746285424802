import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import set from "lodash.set";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import { useFormData } from "../../hooks/useFormData";
import { useApi } from "../../hooks/useApi";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import {
  InteralLayoutStyled,
  CardContainer,
  ButtonFooter,
} from "./components/Welcome.elements";

import FullCalendarEventForm from "../calendar/helper/forms/FullCalendarEventForm";
import { defaultValues } from "../calendar/helper/components/FullCalendarEventFormModal";
import { getApiPayload } from "../calendar/helper/lib/apiPayloadHelpers";

const WelcomeAvailability = () => {
  const { user, updateUser } = useUser();
  const { formData, onChange, setData, toggleCheckboxValue, hasChanged } =
    useFormData({
      ...defaultValues,
      date: moment().add(1, "week").format("YYYY-MM-DD"),
    });
  const { showOnBoarding } = useOnboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { call, isLoading: isSaving } = useApi({
    onError: (err) => toast.error(err.message),
  });

  const save = (payload) => {
    const url = `/calendar/availability`;
    const method = "post";
    const data = getApiPayload({ ...payload });

    // make sure the window is at least 3 hours
    const start = moment(data.startDateTime);
    const end = moment(
      `${start.format("YYYY-MM-DD")} ${data.endTime}`,
      "YYYY-MM-DD H:mm"
    );
    const diff = end.diff(start, "hours");

    if (diff < 3) {
      toast.error(
        "Please select a time window of at least 3 hours to match the minimum booking length."
      );
      return;
    }

    call(url, method, null, data, () => {
      const newUser = { ...user };
      set(newUser, "_welcomeStatus.hasAvailability", true);
      updateUser(newUser);

      window.gtag("event", "conversion", {
        send_to: "AW-11127239856/hwFTCN2m0JgYELDp8Lkp",
      });

      navigate("/welcome/background");
    });
  };

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Availability | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5">
          <WelcomeSteps
            currentStep={3}
            totalSteps={4}
            onBack={() => navigate("/welcome/background")}
          />

          <h2 className="mb-3">Add your availability</h2>

          <p className="body1 mb-5">
            Add in time slots of when you're available to work.
          </p>

          <div className="mb-5 text-start">
            <Row>
              <Col
                xs={12}
                sm={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 2 }}
              >
                <Form.Group className="mb-3 required">
                  <Form.Label>Availability Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={onChange}
                  />
                </Form.Group>

                <Form.Label className="required">Timeslot</Form.Label>
                <FullCalendarEventForm
                  formData={formData}
                  setData={setData}
                  onChange={onChange}
                  toggleCheckboxValue={toggleCheckboxValue}
                />
              </Col>
            </Row>
          </div>

          <ButtonFooter>
            <Row>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
                <Link
                  to="/welcome/background"
                  className="btn btn-link btn-lg"
                  style={{
                    textDecoration: "none",
                    fontWeight: 700,
                    margin: 0,
                    marginTop: 10,
                    padding: 0,
                  }}
                >
                  I'LL DO THIS LATER
                </Link>
              </Col>
              <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
                <Button
                  variant="primary"
                  size="lg"
                  style={{ width: "90%" }}
                  disabled={!hasChanged || isSaving}
                  onClick={() => save({ ...formData })}
                >
                  {isSaving ? "SAVING..." : "SAVE"}
                </Button>
              </Col>
            </Row>
          </ButtonFooter>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeAvailability;
