import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { RRule } from "rrule";
import moment from "moment-timezone";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import ContentBlock from "../common/ContentBlock";
import { useApiGet, useApiPost } from "../../hooks/useApi";

import HelperAvailabilityForm from "../customer/components/HelperAvailabilityForm";
import HelperFullProfile from "../helper/HelperFullProfile";

const HelperFullPublicProfile = () => {
  const { title, setTitle } = usePage();
  const { helperId } = useParams();
  const navigate = useNavigate();
  const [showAvail, setShowAvail] = useState(false);
  const [showIsAvailable, setShowIsAvailable] = useState(false);
  const [showNotAvailable, setShowNotAvailable] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null);

  const { isLoading, data: user } = useApiGet(
    `helper-${helperId}`,
    `/helpers/${helperId}`,
    null,
    {
      onError: (err) => {
        toast.error(err.message);
        navigate(-1);
      },
    }
  );

  const checkAvailability = useApiPost(
    `/helpers/${helperId}/check-availability`,
    (resp) => {
      setIsAvailable(resp.length > 0);
    }
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTitle("Who would you like to send a help request to?");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAvailable === false) {
      setShowNotAvailable(true);
    } else if (isAvailable === true) {
      setShowIsAvailable(true);
    }
  }, [isAvailable]);

  const renderProfile = () => {
    if (isLoading) {
      return <p>Loading...</p>;
    }

    return (
      <div style={{ position: "relative" }}>
        <HelperFullProfile user={user} />
      </div>
    );
  };

  return (
    <RegisterLayout
      title={title}
      isJobRequest={false}
      onStepBack={() => navigate("/find-help")}
      wrapInContentBlock={false}
    >
      <Helmet>
        <title>Helper Profile | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <ContentBlock>{renderProfile()}</ContentBlock>

      <Modal show={showIsAvailable} onHide={() => setShowIsAvailable(false)}>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-end">
            <Close onClick={() => setShowIsAvailable(false)} />
          </div>
          <p className="subtitle1 text-center bold mb-4">
            {user?.name} is available!
          </p>
          <p className="subtitle2">
            Keep in mind, you can send help requests to more than one helper.
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="primary"
              style={{ minWidth: 150 }}
              onClick={() => {}}
            >
              OKAY
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showNotAvailable} onHide={() => setShowNotAvailable(false)}>
        <Modal.Body className="p-4">
          <div className="d-flex justify-content-end">
            <Close onClick={() => setShowNotAvailable(false)} />
          </div>
          <p className="subtitle1 text-center bold mb-4">
            {user?.name} is not available
          </p>
          <p className="subtitle2">
            Check their availability tab to see when they may be available.
          </p>
          <div className="d-flex justify-content-end">
            <Button
              type="submit"
              variant="primary"
              style={{ minWidth: 150 }}
              onClick={() => {
                setShowNotAvailable(false);
              }}
            >
              OKAY
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </RegisterLayout>
  );
};

export default HelperFullPublicProfile;
