import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import get from "lodash.get";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import Edit from "@mui/icons-material/Edit";
import PhotoCameraOutlined from "@mui/icons-material/PhotoCameraOutlined";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import UploadButton from "../../common/UploadButton";
import Prompt from "../../common/Prompt";

const licenseList = [
  "Certified Nursing Assistant (CNA)",
  "Licensed Practical Nurse (LPN)",
  "Registered Nurse (RN)",
];

const Licenses = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    licenses: get(user, "helper.profile.licenses") || [],
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your licenses have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  const isChecked = (val) => {
    if (!Array.isArray(formData.licenses)) {
      return false;
    }
    const lic = formData.licenses.find((l) => l.title === val);
    return lic ? true : false;
  };

  const toggleCheckbox = (lic) => {
    let sel = !Array.isArray(formData.licenses) ? [] : [...formData.licenses];

    if (isChecked(lic)) {
      sel = sel.filter((s) => s.title !== lic);
    } else {
      sel.push({ title: lic, location: null, image: null });
    }

    onChange({ target: { name: "licenses", value: sel } });
  };

  const onSave = () => {
    let canSave = true;

    formData.licenses.forEach((l) => {
      if (!l.image) {
        canSave = false;
      }
    });

    if (!canSave) {
      toast.error("Please add an image before saving.");
      return;
    }

    save.mutate({
      "helper.profile.licenses": formData.licenses,
    });
  };

  return (
    <SubInternalLayout title={`Licenses`}>
      <Helmet>
        <title>Licenses | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      {licenseList.map((lic, i) => {
        const item = formData.licenses.find((l) => l.title === lic);

        return (
          <div className="field-list-item" key={i}>
            <Form.Group>
              <Form.Check
                value={lic}
                label={lic}
                id={lic}
                checked={item ? true : false}
                onChange={() => toggleCheckbox(lic)}
              />
            </Form.Group>
            {item ? (
              <div className="mt-3">
                <Row>
                  <Col xs={9} sm={10}>
                    <p>
                      {item.image ? (
                        <img
                          style={{ maxWidth: 150, marginLeft: 40 }}
                          src={item.image}
                          alt={item.title}
                        />
                      ) : (
                        <>
                          <PhotoCameraOutlined /> Attach an image
                        </>
                      )}
                    </p>
                  </Col>
                  <Col xs={3} sm={2} className="text-end">
                    <UploadButton
                      btnLabel={<Edit />}
                      className="profile-photo-btn"
                      onUpload={(url) => {
                        const index = formData.licenses.findIndex(
                          (l) => l.title === lic
                        );
                        let payload = formData.licenses;
                        payload[index].image = url;
                        save.mutate({
                          "helper.profile.licenses": formData.licenses,
                        });
                      }}
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
          </div>
        );
      })}

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() => onSave()}
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default Licenses;
