import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

import Star from "@mui/icons-material/Star";
import StarOutline from "@mui/icons-material/StarOutline";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import SubInternalLayout from "../layout/SubInternalLayout";
import { useApiGet, useApi } from "../../hooks/useApi";
import ProfilePicture from "../common/ProfilePicture";

const LeaveReview = () => {
  const { user } = useUser();
  const { title, setTitle } = usePage();
  const [rating, setRating] = useState(5);
  const [publicReview, setPublicReview] = useState("");
  const [privateReview, setPrivateReview] = useState("");
  const [feedback, setFeedback] = useState("");
  const [enjoyType, setEnjoyType] = useState({
    PUNCTUAL: false,
    COMMUNICATION: false,
    CONVERSATION: false,
  });
  const { jobId } = useParams();
  const [alertMsg, setAlertMsg] = useState("");
  const navigate = useNavigate();
  const { isLoading, data } = useApiGet(
    `jobDetail${jobId}`,
    `/job-requests/${jobId}`
  );

  const apiSubmitReview = useApi({
    onError: (err) => setAlertMsg(err.message),
  });

  const isBusy = () => {
    return isLoading || apiSubmitReview.isLoading;
  };

  useEffect(() => {
    if (data) {
      setTitle(
        `Leave a Review for ${
          user.userType === "helper" ? data.customer.name : data.helper.name
        }`
      );
    } else {
      // navigate("/messages");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const RatingWrapper = styled.div`
    color: #205b68;
    .MuiSvgIcon-root {
      font-size: 4rem;
    }
    .star {
      cursor: pointer;
      text-align: center;
    }
  `;
  const EnjoyTypeWrapper = styled.div`
    .d-flex {
      div {
        border: 1px solid #212529;
        border-radius: 30px;
        padding: 10px 20px;
        margin: 10px 0px;
        cursor: pointer;
        &:hover,
        &.active {
          background: #212529;
          color: white;
        }
      }
    }
  `;

  const renderStarRating = () => {
    let stars = [];
    let starLabels = ["TERRIBLE", "BAD", "OKAY", "GOOD", "GREAT"];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <Col
          className="star text-center"
          onMouseOver={() => {
            setRating(i + 1);
          }}
          key={i}
        >
          {rating > i ? <Star key={i} /> : <StarOutline key={i} />}
          <p>{starLabels[i]}</p>
        </Col>
      );
    }
    return <Row>{stars}</Row>;
  };

  const renderEnjoyType = () => {
    let render = [];
    let enjoyTypes = Object.keys(enjoyType);
    for (let i = 0; i < enjoyTypes.length; i++) {
      render.push(
        <div
          className={
            "mx-2 " + (enjoyType[enjoyTypes[i]] === true ? "active" : "")
          }
          onClick={() => {
            let newEnjoyType = Object.assign({}, enjoyType);
            newEnjoyType[enjoyTypes[i]] = !newEnjoyType[enjoyTypes[i]];
            setEnjoyType(newEnjoyType);
          }}
          key={i}
        >
          {enjoyTypes[i]}
        </div>
      );
    }
    return <div className="d-flex flex-wrap my-2">{render}</div>;
  };

  const renderReview = () => {
    let userProfile = null;

    if (user.userType === "customer") {
      userProfile = data.helper;
    } else {
      userProfile = data.requestFor
        ? data.customer.profiles.find((p) => p._id === data.requestFor)
        : data.customer.profiles[0];

      if (!userProfile) {
        userProfile = data.customer.profiles[0];
      }
    }

    return (
      <div className="leave-review">
        {alertMsg !== "" ? (
          <Alert variant="danger" className="text-center mb-4">
            {alertMsg}
          </Alert>
        ) : (
          <></>
        )}
        <div className="profile">
          <Row className="align-items-center">
            <Col xs={3}>
              <ProfilePicture user={userProfile} size="md" />
            </Col>
            <Col>
              <h3>How was working with {userProfile.name}?</h3>
            </Col>
          </Row>
        </div>
        <RatingWrapper className="rating border-bottom mt-4 pb-2">
          {renderStarRating()}
        </RatingWrapper>
        <EnjoyTypeWrapper className="py-4 border-bottom">
          <h3 className="mb-3">
            What are some things you enjoyed about working with{" "}
            {userProfile.name}?
          </h3>
          {renderEnjoyType()}
        </EnjoyTypeWrapper>
        <div className="py-4 border-bottom">
          <h3 className="mb-3">
            Write a public review for {userProfile.name}.
          </h3>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your reply"
            value={publicReview}
            onChange={(e) => setPublicReview(e.target.value)}
          />
        </div>
        <div className="py-4 border-bottom">
          <h3 className="mb-3">Write a private note for {userProfile.name}.</h3>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your reply"
            value={privateReview}
            onChange={(e) => setPrivateReview(e.target.value)}
          />
        </div>
        <div className="py-4">
          <h3 className="mb-3">
            Any feedback to send Manana about {userProfile.name}.
          </h3>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Type your reply"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
        </div>
        <Button
          variant="primary"
          className="w-100 text-uppercase"
          onClick={() => {
            apiSubmitReview.call(
              `/users/${
                user.userType === "helper" ? data.customer._id : data.helper._id
              }/ratings`,
              "post",
              undefined,
              {
                rating: rating,
                publicReview: publicReview,
                privateReview: privateReview,
                feedback: feedback,
                job: jobId,
                enjoyType: enjoyType,
              },
              () => {
                if (user.helper) {
                  navigate("/dashboard");
                } else if (user.customer) {
                  navigate("/messages");
                }
              }
            );
          }}
          disabled={isBusy()}
        >
          Submit Review {apiSubmitReview.isLoading ?? "..."}
        </Button>
        <Button
          variant="secondary"
          className="w-100 text-uppercase mt-3"
          onClick={() => navigate(-1)}
        >
          Cancel
        </Button>
      </div>
    );
  };

  return (
    <SubInternalLayout title={title} onBack={() => navigate(-1)}>
      <Helmet>
        <title>Leave Review | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {isLoading ? <p>Loading</p> : renderReview()}
      <br />
      <br />
    </SubInternalLayout>
  );
};

export default LeaveReview;
