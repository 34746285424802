import React, { useState } from "react";
import { Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import set from "lodash.set";
import { Helmet } from "react-helmet-async";
import humanparser from "humanparser";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import useEffectOnce from "../../../hooks/useEffectOnce";

import {
  SettingGroup,
  SettingRow,
  HeadingRow,
} from "../components/NotificationSettings.elements";
import {
  CUSTOMER_OPTIONS as OPTIONS,
  getInitialFormData,
  findNotificationOptionByKey,
} from "../notificationsHelpers";

const NotificationSettings = () => {
  const { user, updateUser } = useUser();
  const { formData, setData } = useFormData(getInitialFormData(user));
  const [marketing, setMarketing] = useState({
    sms:
      user.settings.marketingTerms && user.settings.marketingTerms.sms
        ? true
        : false,
    email:
      user.settings.marketingTerms && user.settings.marketingTerms.email
        ? true
        : false,
  });
  const [cache, setCache] = useState(null);

  useEffectOnce(() => {
    const newCache = {};

    user.customer.profiles.forEach((p) => {
      const key = p.relationship === "_self" ? "_self" : p._id;
      let { firstName } = humanparser.parseName(p.name);
      if (p.preferredName) {
        firstName = p.preferredName;
      }
      newCache[key] = firstName;
    });

    setCache(newCache);
  });

  const onCheckChange = (e) => {
    const updates = { ...formData };
    set(updates, e.target.name, e.target.checked);

    const [profileId, key, type] = e.target.name.split(".");
    const opt = findNotificationOptionByKey(OPTIONS, key);

    if (opt && opt.associated) {
      opt.associated.forEach((assoc) => {
        set(updates, `${profileId}.${assoc}.${type}`, e.target.checked);
      });
    }

    console.log("updates: ", updates);

    setData({ ...updates });
  };

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
  });

  return (
    <SubInternalLayout title={`Notification Settings`}>
      <Helmet>
        <title>Notification Settings | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>

      <SettingGroup>
        <HeadingRow>
          <Col xs={8} className="p-0">
            <span className="subtitle1">Marketing</span>
          </Col>
          <Col xs={2}>
            <span className="label">TEXT</span>
          </Col>
          <Col xs={2}>
            <span className="label">EMAIL</span>
          </Col>
        </HeadingRow>
        <SettingRow className="setting-row">
          <Col>
            <span className="subtitle2" style={{ color: "#857070" }}>
              Me
            </span>
          </Col>
          <Col xs={2}>
            <Form.Check
              type="checkbox"
              id="marketing-sms"
              label=""
              name="marketing-sms"
              checked={marketing.sms}
              onChange={(e) =>
                setMarketing({ ...marketing, sms: e.target.checked })
              }
            />
          </Col>
          <Col xs={2}>
            <Form.Check
              type="checkbox"
              id="marketing-email"
              label=""
              name="marketing-email"
              checked={marketing.email}
              onChange={(e) =>
                setMarketing({ ...marketing, email: e.target.checked })
              }
            />
          </Col>
        </SettingRow>
      </SettingGroup>

      {cache
        ? OPTIONS.map((setGroup, setGroupIndex) => {
            return (
              <SettingGroup key={setGroupIndex}>
                <HeadingRow>
                  <Col xs={8} className="p-0">
                    <span className="subtitle1">{setGroup.subTitle}</span>
                  </Col>
                  <Col xs={2}>
                    <span className="label">TEXT</span>
                  </Col>
                  <Col xs={2}>
                    <span className="label">EMAIL</span>
                  </Col>
                </HeadingRow>
                {setGroup.children.map((set, setIndex) => {
                  return Object.keys(formData).map((profileId, pIdx) => {
                    const values = formData[profileId][set.key];
                    const name =
                      profileId === "_self" ? "Me" : cache[profileId];
                    return (
                      <div key={`${set.key}-${pIdx}`}>
                        {pIdx === 0 ? (
                          <h5 className="subtitle2 mt-2 ms-3">{set.header}</h5>
                        ) : null}
                        <SettingRow className="setting-row">
                          <Col>
                            <span
                              className="subtitle2"
                              style={{ color: "#857070" }}
                            >
                              {name}
                            </span>
                          </Col>
                          <Col xs={2}>
                            <Form.Check
                              type="checkbox"
                              id={`cb-${set.key}-${profileId}-sms`}
                              label=""
                              name={`${profileId}.${set.key}.sms`}
                              checked={values.sms}
                              onChange={onCheckChange}
                            />
                          </Col>
                          <Col xs={2}>
                            <Form.Check
                              type="checkbox"
                              id={`cb-${set.key}-${profileId}-email`}
                              label=""
                              name={`${profileId}.${set.key}.email`}
                              checked={values.email}
                              onChange={onCheckChange}
                            />
                          </Col>
                        </SettingRow>
                      </div>
                    );
                  });
                })}
              </SettingGroup>
            );
          })
        : null}
      <div className="d-flex justify-content-between text-end mt-4">
        <Button
          type="reset"
          variant="light"
          onClick={() => window.location.reload()}
          disabled={save.isLoading}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "customer.notifications": formData,
              "settings.marketingTerms": { ...marketing },
            })
          }
          disabled={save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default NotificationSettings;
