import React from "react";
import { Row, Col, OverlayTrigger, Popover } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { useJob } from "../../contexts/JobContext";
import { SetupTitle } from "../common/Title.elements";
import { getUserRateFromJobRequest } from "../../lib/jobRequestHelpers";
import get from "lodash.get";

const JobRequestTotalView = () => {
  const { job } = useJob();

  if (job._helpers.length > 1) {
    return null;
  }

  const rate = getUserRateFromJobRequest(job._helpers[0], job);
  const subtotal = job.isOvernight ? rate : rate * job.hours;
  const serviceFeeRate = get(job, `serviceFee.customer`, 0.2);
  const fee = subtotal * serviceFeeRate;
  const total = subtotal + fee;

  return (
    <>
      <Helmet>
        <title>Total Review | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <SetupTitle>Total cost</SetupTitle>

      <div className="pb-3 mb-3 b-bottom">
        <Row>
          <Col>
            {job.isOvernight
              ? `Overnight for ${job.hours} hours`
              : `${job.hours} hours @ $${rate}`}
          </Col>
          <Col className="text-end">${subtotal.toFixed(2)}</Col>
        </Row>
      </div>
      <div className="pb-3 mb-3 b-bottom">
        <Row>
          <Col>
            Service fee{" "}
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="right"
              overlay={
                <Popover id={`popover-service-fee`}>
                  <Popover.Body>
                    Manana charges a 20% transaction fee to cover our platform
                    costs and customer service support.
                  </Popover.Body>
                </Popover>
              }
            >
              <InfoOutlined />
            </OverlayTrigger>
          </Col>
          <Col className="text-end">${fee.toFixed(2)}</Col>
        </Row>
      </div>
      <div className="pb-3 mb-3 b-bottom">
        <Row style={{ fontWeight: 700 }}>
          <Col>Total</Col>
          <Col className="text-end">${total.toFixed(2)}</Col>
        </Row>
      </div>
    </>
  );
};

export default JobRequestTotalView;
