import { useState } from "react";

export const defaultHelperValues = {
  serviceArea: null,
  zip: null,
  miles: null,
  services: null,
  vehicleCovers: null,
  vehicleAvailable: null,
  dementiaCare: null,
  standard: null,
  specialized: null,
  overnight: null,
  availableOvernight: null,
  jobTypes: null,
  email: null,
};

const getHelperFromStorage = () => {
  let saved = window.localStorage.getItem("_helper");

  if (!saved) {
    saved = defaultHelperValues;
    window.localStorage.setItem("_helper", JSON.stringify(saved));
  } else {
    saved = JSON.parse(saved);
  }

  return saved;
};

const useHelper = () => {
  const [helper, setHelper] = useState(getHelperFromStorage());

  const saveHelper = (updates) => {
    const newHelper = { ...helper, ...updates };
    window.localStorage.setItem("_helper", JSON.stringify(newHelper));
    setHelper(newHelper);
  };

  const resetHelper = () => {
    window.localStorage.removeItem("_helper");
    setHelper(defaultHelperValues);
  };

  return { helper, saveHelper, resetHelper };
};

export default useHelper;