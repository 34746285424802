import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ArrowBack from "@mui/icons-material/ArrowBack";
import Close from "@mui/icons-material/Close";

const Bar = styled.div`
  position: relative;
  height: 5px;
  background: #ebe0de;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${(props) => `${props.progress}%`};
    background: #205b68;
  }
`;

const Title = styled.span`
  font-family: "Jost";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  color: #857070;
`;

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  color: #4a4a4a !important;
`;

const WelcomeSteps = ({ currentStep = 1, totalSteps = 1, onBack }) => {
  const navigate = useNavigate();

  return (
    <div className="mb-5">
      <Row className="mb-2">
        <Col className="text-start">
          <StyledButton variant="link" onClick={() => onBack()}>
            <ArrowBack />
          </StyledButton>
        </Col>
        <Col className="text-center">
          <Title>
            Step {currentStep} of {totalSteps}
          </Title>
        </Col>
        <Col className="text-end">
          <StyledButton
            variant="link"
            onClick={() => navigate("/welcome/list")}
          >
            <Close />
          </StyledButton>
        </Col>
      </Row>
      <Bar progress={Math.ceil((currentStep / totalSteps) * 100)} />
    </div>
  );
};

export default WelcomeSteps;
