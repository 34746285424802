import React from "react";
import Star from "@mui/icons-material/Star";
import StarHalf from "@mui/icons-material/StarHalf";
import StarOutline from "@mui/icons-material/StarOutline";
import styled from "styled-components";

const StarWrapper = styled.div`
  color: ${(props) => props.color};
  .MuiSvgIcon-root {
    font-size: ${(props) => props.size};
    margin-left: ${(props) => props.margin * 0.5}rem;
    margin-right: ${(props) => props.margin * 0.5}rem;
  }
`;

const StarRating = ({
  rating = 0,
  totalRatings = 0,
  color = "black",
  size = "1.5rem",
  margin = 0,
  noRatingMsg = "Not Rated",
}) => {
  const full = Math.floor(rating);
  const half = rating >= full + 0.5 ? 1 : 0;
  const empty = 5 - (full + half);

  if (totalRatings === 0) {
    return <span>{noRatingMsg}</span>;
  }

  const stars = [];
  for (let i = 1; i <= full; i++) {
    stars.push(<Star key={i - 1} />);
  }

  if (half > 0) {
    stars.push(<StarHalf key={full} />);
  }

  for (let i = 1; i <= empty; i++) {
    stars.push(<StarOutline key={full + i} />);
  }

  return (
    <StarWrapper
      className="star-rating d-flex align-items-center"
      color={color}
      size={size}
      margin={margin}
    >
      {stars}{" "}
      {totalRatings !== null ? (
        <span className="text-muted">
          ({totalRatings} review{totalRatings === 1 ? "" : "s"})
        </span>
      ) : null}
    </StarWrapper>
  );
};

export default StarRating;
