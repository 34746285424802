import React from "react";
import { Row } from "react-bootstrap";
import styled from "styled-components";

export const SettingGroup = styled.div`
  margin-top: 50px;
`;

export const HeadingRow = styled(Row)`
  border-bottom: solid 1px var(--border-color);
  padding-bottom: 8px;
  margin: 0;
`;

export const SettingRow = styled(Row)`
  &.setting-row {
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: solid 1px var(--border-color);
    padding: 13px 0px;
  }
`;
