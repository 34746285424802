import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";

import { useStripe } from "@stripe/react-stripe-js";

const PaymentVerificationForm = ({ clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    setIsReady(true);

    stripe
      .handleNextAction({ clientSecret })
      .then((res) => {
        if (onSuccess) {
          onSuccess(res);
        }
      })
      .catch((err) => {
        setErrorMessage(err.message);
      });
  }, [stripe]);

  const renderContent = () => {
    if (!isReady) {
      return <p>Loading...</p>;
    }

    return (
      <div>
        {errorMessage ? (
          <Alert variant="danger">{errorMessage}</Alert>
        ) : (
          <p>
            Processing your request. A verification screen should become
            available momentarily...
          </p>
        )}
      </div>
    );
  };

  return <div>{renderContent()}</div>;
};

export default PaymentVerificationForm;
