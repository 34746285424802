import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import ArrowBack from "@mui/icons-material/ArrowBack";

import InternalLayout from "../layout/InternalLayout";
import Loading from "../common/Loading";
import { useApiGet, useApi } from "../../hooks/useApi";
import { useUser } from "../../contexts/UserContext";
import { useJob } from "../../contexts/JobContext";
import HelperFullProfile from "../helper/HelperFullProfile";

const BookAgain = () => {
  const { user } = useUser();
  const { job, resetJob } = useJob();
  const { jobId, helperId } = useParams();
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();

  const { isLoading, data: helper } = useApiGet(
    `helper-${helperId}`,
    `/helpers/${helperId}`,
    null,
    {
      onError: (err) => {
        toast.error(err.message);
        navigate(-1);
      },
    }
  );

  const rruleDaysToFullDays = (daysStr) => {
    return daysStr.split(",").map((str) => {
      switch (str) {
        case "SU":
          return "Sunday";
        case "MO":
          return "Monday";
        case "TU":
          return "Tuesday";
        case "WE":
          return "Wednesday";
        case "TH":
          return "Thursday";
        case "FR":
          return "Friday";
        case "SA":
        default:
          return "Saturday";
      }
    });
  };

  const { call } = useApi({
    onError: (err) => toast.error(err.message),
  });

  useEffect(() => {
    if (!selected) {
      return;
    }

    call(`/job-requests/${jobId}`, "get", undefined, undefined, (res) => {
      const startDt = moment(selected.start);
      const endDt = moment(selected.end);
      const avail = {
        startDate: startDt.format("YYYY-MM-DD"),
        startTime: startDt.format("HH:mm"),
        endTime: endDt.format("HH:mm"),
        jobType: res.jobType[0],
        frequency: "weekly",
        weekdays: [],
      };

      // we need to get the weekdays list from the rrule schedule
      if (res.jobType[0] === "Recurring" && res.dates.schedule) {
        const matches = /BYDAY=(.*);/.exec(res.dates.schedule);
        if (matches && matches[1]) {
          avail.weekdays = rruleDaysToFullDays(matches[1]);
          avail.rrule = `Every week on ${avail.weekdays.join(", ")}`;
        }
      }

      // recreate the services object
      const services = {};

      res.servicesRequested.forEach((service) => {
        services[service.service] = {};
        service.options.forEach((opt) => {
          services[service.service][opt.key] = opt.value;
        });
      });

      // set the values and redirect the user
      resetJob({
        acceptedOn: null,
        canceledOn: null,
        createdOn: null,
        helper: null,
        customer: user._id,
        serviceArea: res.serviceArea,
        services: services,
        requestedHelpers: [helper._id],
        declinedBy: [],
        viewedBy: [],
        jobStatus: "New help request",
        isNewCustomer: false,
        vehicleRequired: res.vehicleRequired,
        specializedCare: res.specializedCare,
        availability: avail,
        callBeforeRequired: res.callBeforeRequired,
        paymentMethod: res.customer._paymentMethods.find(
          (p) => p.id === res.paymentMethod
        ),
        hours: res.hours,
        _helpers: [{ ...res.helper }],
        _rebook: true,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (job && job._rebook) {
      navigate("/find-help/confirm");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const render = () => {
    if (isLoading) {
      return <Loading msg="Loading helper profile..." />;
    }

    return (
      <div>
        <HelperFullProfile
          user={helper}
          useTimeslotCheckboxes={true}
          btnLabel="SELECT TIMESLOT"
          onSelectHelper={(sel) => setSelected(sel.selected)}
          onCancel={() => navigate(`/bookings/${jobId}`)}
        />
      </div>
    );
  };

  return (
    <InternalLayout>
      <Container>
        <Link to={`/bookings/${jobId}`}>
          <ArrowBack />
        </Link>
        <h2 className="mt-3 mb-5">New help request</h2>
        {render()}
      </Container>
      <Modal
        show={selected !== null}
        onHide={() => setSelected(null)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <h3 className="text-center mt-5 mb-5">
            Setting up your new help request...
          </h3>
        </Modal.Body>
      </Modal>
    </InternalLayout>
  );
};

export default BookAgain;
