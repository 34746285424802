import styled from "styled-components";

export const CalendarWrapper = styled.div`
  position: relative;

  @media only screen and (max-width: 430px) {
    & .fc-header-toolbar {
      flex-direction: column;
    }

    & .fc-header-toolbar > .fc-toolbar-chunk {
      order: 0;
      display: block;
      margin-bottom: 1rem;
    }

    & .fc-header-toolbar > .fc-toolbar-chunk:first-child {
      order: 1;
    }

    & .fc-header-toolbar > .fc-toolbar-chunk:last-child {
      order: 2;
    }
  }

  & .fc-daygrid-event {
    cursor: pointer;
  }

  & .fc-daygrid-event.strike-through {
    text-decoration: line-through;
  }
`;

export const LoadingScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(255, 255, 255, 0.75);

  & > span {
    display: block;
    font-weight: 700;
    font-size: 1.15rem;
    text-align: center;
    width: 200px;
    margin: auto;
    padding-top: 25%;
  }
`;
