import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ebe0de;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const BackButton = styled.button`
  display: inline-block;
  font-size: 1rem;
  font-weight: 750;
  text-align: center;
  line-height: 3rem;
  text-transform: uppercase;
  color: var(--primary-color);
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  padding-left: 15px;

  svg {
    padding-bottom: 2px;
  }
`;

export const ProgressBar = styled.div`
  height: 4px;
  background-color: #205b68;
  width: ${props => props.width ? props.width : '0'}%;
  border-radius: 0 25px 25px 0;
  @media (min-width: 768px) {
    display: none;
  }
`;
