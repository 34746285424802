import React from "react";
import { useAVToggle } from "@100mslive/react-sdk";
import { Button } from "react-bootstrap";

const Controls = ({ onEndCall }) => {
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  return (
    <div className="control-bar">
      <Button variant="secondary" className="btn-control" onClick={toggleAudio}>
        {isLocalAudioEnabled ? "Mute" : "Unmute"}
      </Button>
      <Button variant="secondary" className="btn-control" onClick={toggleVideo}>
        {isLocalVideoEnabled ? "Hide" : "Unhide"}
      </Button>
      <Button
        variant="secondary"
        className="btn-control"
        onClick={() => {
          if (typeof onEndCall === "function") {
            onEndCall();
          }
        }}
      >
        Hang Up
      </Button>
    </div>
  );
};

export default Controls;
