import React from "react";

import Users from "./Users";
import Controls from "./Controls";

const InConference = ({ onEndCall }) => {
  return (
    <div>
      <Users />
      <Controls onEndCall={onEndCall} />
    </div>
  );
};

export default InConference;
