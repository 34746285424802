import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";

const createUrl = (url, queryParams, preEncoded) => {
  const params =
    queryParams &&
    Object.keys(queryParams)
      .map((key) => {
        return (
          encodeURIComponent(key) + "=" + (preEncoded ? queryParams[key] : encodeURIComponent(queryParams[key]))
        );
      })
      .join("&");

  return params ? `${url}?${params}` : url;
};

export const makeRequest = async (method, url, payload = null) => {
  const config = {
    method,
    url: `${process.env.REACT_APP_API_URL}${url}`,
  };

  if (method !== "get" && payload) {
    config.data = payload;
  }

  const response = await axios(config);

  return response.data;
};

export const useApiGet = (
  cacheName,
  url,
  queryParams = undefined,
  params = {},
  preEncoded = false,
) => {
  return useQuery(
    [cacheName, url, queryParams],
    async () => {
      const response = await makeRequest("get", createUrl(url, queryParams, preEncoded));
      return response;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000,
      ...params,
    }
  );
};

export const useApiPost = (url, callback, params = {}) => {
  return useMutation(
    async (payload) => {
      const response = await makeRequest("post", url, payload);
      if (typeof callback === "function") {
        callback(response);
      }
      return response;
    },
    { ...params }
  );
};

export const useApiPut = (url, callback) => {
  return useMutation(async (payload) => {
    const response = await makeRequest("put", url, payload);
    if (typeof callback === "function") {
      callback(response);
    }
    return response;
  });
};

export const useApiWrite = (url, method, callback) => {
  return useMutation(async (payload) => {
    const response = await makeRequest(method, url, payload);
    if (typeof callback === "function") {
      callback(response);
    }
    return response;
  });
};

export const useApiDelete = (callback) => {
  return useMutation(async (url) => {
    const response = await makeRequest("delete", url);
    if (typeof callback === "function") {
      callback(response);
    }
    return response;
  });
};

export const useApi = (params = {}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const call = async (
    url,
    method = "get",
    queryParams = undefined,
    payload = undefined,
    callback = undefined
  ) => {
    setIsLoading(true);
    setIsError(false);
    setError(null);
    setData(null);

    try {
      const res = await makeRequest(
        method,
        createUrl(url, queryParams),
        payload
      );

      setData(res);

      if (typeof callback === "function") {
        callback(res);
      }
      if (typeof params.onSuccess === "function") {
        params.onSuccess(res);
      }
    } catch (err) {
      if (params.onError) {
        params.onError(err);
      }
      setError(err);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return { call, isLoading, data, isError, error };
};
