import styled from "styled-components";

export const InternalPageContent = styled.div`
  background: #fff;
  padding: 0;
  margin: 0;
  min-height: 700px;
  display: flex;
  flex-direction: row;

  & .form-label {
    font-size: 1.15rem !important;
    font-weight: 700 !important;
  }

  & .form-control {
    font-size: inherit !important;
  }

  & .form-text {
    font-size: 1.15rem !important;
  }

  & .text-muted {
    color: var(--muted-text-color) !important;
  }

  @media only screen and (max-width: 430px) {
    padding: 0;
    flex-direction: column;

    & .content-block {
      padding: 1rem 0;
      margin: 1rem 0;
    }
  }
`;

export const ContentNav = styled.nav`
  top: 0;
  left: 0;
  bottom: 0;
  max-width: 368px;
  border-right: 1px solid var(--border-color);
  flex: 30%;

  & .brand,
  & .link-item a {
    display: block;
    text-decoration: none;
    font-size: 22px;
    color: #857070;
    line-height: 30px;
    padding: 13px 2.5rem;
  }

  & .link-item a.active {
    color: #4a4a4a;
    background-color: #f8f2f1;
  }

  & .link-item a .nav-icon {
    margin-right: 1rem;
  }

  & .link-item a .nav-label {
    font-weight: 600;
    vertical-align: middle;
  }

  & .link-item a .badge {
    float: right;
    background-color: #fd7060 !important;
    margin-top: 0.25rem;
  }

  & .link-item a.disabled {
    color: #979797 !important;
  }

  & a.sub-profile-link,
  & .child-nav a {
    color: #4a4a4a !important;
    font-size: 18px !important;
    line-height: 27px;
  }

  & a.sub-profile-link.active,
  & .child-nav a.active {
    text-decoration: underline;
  }

  & .child-nav a.disabled {
    color: #979797 !important;
  }

  & .btn-item {
    display: block;
    text-decoration: none;
    padding: 13px 2.5rem;
  }

  & .btn-item a {
    width: 100%;
  }

  @media only screen and (max-width: 430px) {
    flex: 0;
    display: none;
  }
`;

export const MobileNav = styled.div`
  display: none;

  @media only screen and (max-width: 430px) {
    display: block;
    height: 70px;
    background: #fff;
    color: rgb(32, 91, 104);

    & img {
      max-width: 200px;
      margin-top: 18px;
    }
  }
`;

export const ContentBody = styled.div`
  flex: 70%;
  padding: 0.5rem 1.5rem;
  min-height: 80vh;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "800px")};
  margin: auto;

  @media only screen and (max-width: 430px) {
    flex: 100%;
    padding: 1rem 0;
    margin: 0;

    & > .internal-content-nav {
      display: none;
    }
  }
`;
