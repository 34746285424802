import styled from "styled-components";

export const PaddedForm = styled.form`
  padding-right: 2rem;
  padding-left: 2rem;

  @media only screen and (max-width: 430px) {
    padding-right: 0;
    padding-left: 0;
  }
`;
