import React, { useState } from "react";
import { Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment-timezone";

import { useUser } from "../../../contexts/UserContext";
import { useApiPut, useApi } from "../../../hooks/useApi";
import ConfirmModal from "../../common/ConfirmModal";
import ArrivalModal from "./ArrivalModal";
import CancelRecurringModal from "./CancelRecurringModal";

const DetailsActions = ({ job, jobEvent, status, statusMsg, onUpdate }) => {
  const { user } = useUser();
  const [confirm, setConfirm] = useState(null); // {msg: "...", onConfirm: () => {}, onCancel: () => {}}
  const [showArrivalForm, setShowArrivalForm] = useState(false);
  const [showCancelForm, setShowCancelForm] = useState(false);

  const params = { onError: (err) => toast.error(err.message) };

  const cancelBooking = useApiPut(
    `/job-requests/${job._id}/cancel`,
    () => {
      toast.success("The booking has been successfully canceled.");
      onUpdate();
    },
    params
  );

  const acceptBooking = useApiPut(
    `/job-requests/${job._id}/accept`,
    () => {
      toast.success("The booking has been successfully accepted.");
      onUpdate();
    },
    params
  );

  const declineBooking = useApiPut(
    `/job-requests/${job._id}/decline`,
    () => {
      toast.success("The booking has been successfully declined.");
      onUpdate();
    },
    params
  );

  const completeBooking = useApiPut(
    `/job-requests/${job._id}/complete/${jobEvent ? jobEvent._id : ""}`,
    () => {
      toast.success("The booking has been successfully completed.");
      onUpdate();
    },
    params
  );

  const startBooking = useApiPut(
    `/job-requests/${job._id}/start/${jobEvent ? jobEvent._id : ""}`,
    () => {
      toast.success("The booking has been successfully started.");
      onUpdate();
    },
    params
  );

  const cancelEvent = useApi({
    ...params,
    onSuccess: () => {
      toast.success("The single event was successfully canceled.");
      onUpdate();
    },
  });

  const onClick = (eventType, eventData = {}) => {
    switch (eventType) {
      case "cancel":
        // if we have a recurring booking that is active we need to show the special
        // modal for which to cancel
        if (
          job.jobType.includes("Recurring") &&
          job.jobStatus === "Booking Confirmed"
        ) {
          setShowCancelForm(true);
        } else {
          let msg = "Are you sure you want to cancel this booking?";

          if (moment(eventData.startDate).diff(moment(), "hours") <= 24) {
            msg =
              user.userType === "helper"
                ? `Wait - our community is counting on you! Too many cancellations outside of our 24 hour cancellation window will ban you from being a Helper. Please call Manana at (615) 212-9609 or directly notify the care recipient you will not be able to help.\n\nAre you sure you want to continue with canceling?`
                : `Wait - our community is counting on you! Too many cancellations outside of our 24 hour cancellation window will ban you from being a customer.\n\nAre you sure you want to continue with canceling?`;
          }

          setConfirm({
            msg,
            onConfirm: () => cancelBooking.mutate({ ...eventData }),
            onCancel: () => {},
          });
        }
        break;
      case "accept":
        setShowArrivalForm(true);
        break;
      case "decline":
        setConfirm({
          msg: "Are you sure you want to decline this booking?",
          onConfirm: () => declineBooking.mutate({ ...eventData }),
          onCancel: () => {},
        });
        break;
      case "complete":
        setConfirm({
          msg: "Are you sure you are finished with this booking? You will not be able to bill any additional hours after completing.",
          onConfirm: () => completeBooking.mutate({ ...eventData }),
          onCancel: () => {},
        });
        break;
      case "start":
        setConfirm({
          msg: "Are you ready to start this booking?",
          onConfirm: () => startBooking.mutate({ ...eventData }),
          onCancel: () => {},
        });
        break;
      default:
        toast.error(`Unidentified event type ${eventType}.`);
    }
  };

  const CANCEL = (
    <Button
      variant="primary"
      className="d-block w-100 mb-3"
      size="lg"
      onClick={() => onClick("cancel")}
    >
      CANCEL BOOKING
    </Button>
  );

  const getAlertMsg = (color = "warning") => {
    return (
      <Alert variant={color}>
        <p className="text-center">{statusMsg}</p>
      </Alert>
    );
  };

  // canceled, expired, rejected, rejected-all, pending, accepted, accepted-other, authorized, billed, billing-declined, active, complete
  const actions = {
    canceled: () => {
      return (
        <>
          {getAlertMsg()}
          {user.userType === "customer" && job.helper ? (
            <Link
              to={`/bookings/${job._id}/again/${job.helper._id}#availability`}
              className="btn btn-link btn-lg w-100"
            >
              BOOK AGAIN
            </Link>
          ) : null}
        </>
      );
    },

    expired: () => {
      return user.userType === "customer" && job.helper ? (
        <Link
          to={`/bookings/${job._id}/again/${job.helper._id}#availability`}
          className="btn btn-link btn-lg w-100"
        >
          BOOK AGAIN
        </Link>
      ) : (
        getAlertMsg()
      );
    },

    rejected: () => {
      return user.userType === "helper" ? (
        <Button
          variant="link"
          className="w-100"
          size="lg"
          onClick={() => onClick("accept")}
        >
          ACCEPT BOOKING
        </Button>
      ) : (
        getAlertMsg()
      );
    },

    "rejected-all": () => {
      return user.userType === "customer" ? getAlertMsg() : getAlertMsg();
    },

    pending: () => {
      if (user.userType === "customer") {
        return CANCEL;
      }

      let firstBtn = (
        <Button
          variant="primary"
          className="d-block w-100 mb-3"
          size="lg"
          onClick={() => onClick("accept")}
        >
          ACCEPT BOOKING
        </Button>
      );

      if (!user._welcomeStatus.isStripeClear) {
        firstBtn = (
          <Link
            to="/stripe-status"
            className="btn btn-primary btn-lg w-100 mb-3"
          >
            FINISH PAYMENT ACCOUNT
          </Link>
        );
      }

      return (
        <>
          {firstBtn}
          <Button
            variant="link"
            className="w-100"
            size="lg"
            onClick={() => onClick("decline")}
          >
            DECLINE BOOKING
          </Button>
        </>
      );
    },

    accepted: () => {
      return (
        <>
          {CANCEL}
          {user.userType === "customer" ? (
            <Link
              to={`/bookings/${job._id}/again/${job.helper._id}#availability`}
              className="btn btn-link btn-lg w-100"
            >
              BOOK AGAIN
            </Link>
          ) : null}
        </>
      );
    },

    "accepted-other": () => {
      return getAlertMsg("danger");
    },

    "canceled-instance": () => {
      return (
        <>
          <Alert variant="warning">
            <p className="text-center">{statusMsg}</p>
          </Alert>
        </>
      );
    },

    ready: () => {
      return (
        <>
          <Button
            variant="primary"
            className="d-block w-100 mb-3"
            size="lg"
            onClick={() => onClick("start")}
          >
            START BOOKING
          </Button>
          <Button
            variant="link"
            className="d-block w-100 mb-3"
            size="lg"
            onClick={() => onClick("cancel")}
          >
            CANCEL BOOKING
          </Button>
        </>
      );
    },

    active: () => {
      return (
        <>
          <Button
            variant="primary"
            className="d-none w-100 mb-3"
            size="lg"
            onClick={() => onClick("complete")}
          >
            BOOKING IS COMPLETE
          </Button>
          <Button
            variant="link"
            className="d-block w-100 mb-3"
            size="lg"
            onClick={() => onClick("cancel")}
          >
            CANCEL BOOKING
          </Button>
        </>
      );
    },

    complete: () => {
      return user.userType === "customer" ? (
        <Link
          to={`/bookings/${job._id}/again/${job.helper._id}#availability`}
          className="btn btn-link btn-lg w-100"
        >
          BOOK AGAIN
        </Link>
      ) : (
        getAlertMsg("success")
      );
    },

    processing: () => {
      return <p className="text-center lead">{statusMsg}</p>;
    },
  };

  return (
    <>
      {actions[status] ? (
        actions[status]()
      ) : (
        <p>No actions found for status "{status}".</p>
      )}
      <ConfirmModal
        show={confirm}
        toggle={() => setConfirm(null)}
        msg={confirm ? confirm.msg : ""}
        onConfirm={confirm ? confirm.onConfirm : () => {}}
        onCancel={confirm ? confirm.onCancel : () => {}}
      />
      <ArrivalModal
        show={showArrivalForm}
        toggle={() => setShowArrivalForm(false)}
        job={job}
        onSave={(res) => {
          acceptBooking.mutate({
            arrivalDateTime: res.arrivalDateTime,
            notes: res.notes || null,
          });
        }}
      />
      <CancelRecurringModal
        show={showCancelForm}
        toggle={() => setShowCancelForm(false)}
        job={job}
        onSave={(res) => {
          if (res.cancelSelection === "single") {
            cancelEvent.call(
              `/job-requests/${job._id}/events/${res.jobRequestEventId}/cancel`,
              "put"
            );
          } else {
            cancelBooking.mutate({});
          }
        }}
      />
    </>
  );
};

export default DetailsActions;
