import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const WrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  margin-bottom: 1rem;

  & span {
    display: block;
    padding-top: 2px;
    font-weight: 500;
  }

  & .btn {
    padding: 2px 4px;
    margin-left: 0.5rem;
  }
`;

const SimplePagination = ({
  page,
  totalRows,
  rowsPerPage,
  onBack,
  onForward,
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  return (
    <WrapperStyled>
      <div>
        <span>
          Page: {page} of {totalPages}
        </span>
      </div>
      <div>
        <Button
          variant="light"
          size="sm"
          onClick={() => onBack()}
          disabled={page === 1}
        >
          <ChevronLeft />
        </Button>
      </div>
      <div>
        <Button
          variant="light"
          size="sm"
          onClick={() => onForward()}
          disabled={totalPages <= page}
        >
          <ChevronRight />
        </Button>
      </div>
    </WrapperStyled>
  );
};

export default SimplePagination;
