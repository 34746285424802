import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";

import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import CreditCardPaymentForm from "../common/CreditCardPaymentForm";
import JobRequestTotalView from "./JobRequestTotalView";
import { useJob } from "../../contexts/JobContext";
import { useApiGet, useApiPost } from "../../hooks/useApi";
import Loading from "../common/Loading";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

/*
  STEP 9
*/
const JobRequestPayment = () => {
  const { job, saveJob, resetJob } = useJob();
  const { title, setTitle } = usePage();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [setupIntent, setSetupIntent] = useState({
    setupIntentId: null,
    client_secret: null,
  });
  const navigate = useNavigate();
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  const { isLoading: isLoadingPaymentMethods, data: paymentMethods } =
    useApiGet("payment-methods", "/payments/methods", undefined, {
      onSuccess: (res) => {
        console.log("paymentMethods: ", res);
      },
      staleTime: 300000,
    });

  const createSetupIntent = useApiPost(
    "/payments/setup-intent",
    (res) => {
      setSetupIntent({
        setupIntentId: res.setupIntentId,
        clientSecret: res.clientSecret,
      });
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle("We require a payment method on file to secure your request.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!paymentMethods) {
      return;
    }

    if (paymentMethods.length === 0) {
      setPaymentMethod("__NEW__");
    } else {
      setPaymentMethod(paymentMethods[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods]);

  useEffect(() => {
    createSetupIntent.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  const renderSelect = () => {
    if (isLoadingPaymentMethods) {
      return <Loading msg="Loading payment methods..." />;
    }

    return (
      <>
        <Form.Group className="mb-4">
          <Form.Label>Payment Method</Form.Label>
          <Form.Select
            name="paymentMethod"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            {paymentMethods.map((method, methodIdx) => {
              return (
                <option value={method.id} key={methodIdx}>
                  {method.brand} {method.last4}
                </option>
              );
            })}
            <option value="__NEW__">Add a payment method</option>
          </Form.Select>
        </Form.Group>
        {paymentMethod !== "__NEW__" ? (
          <Button
            variant="primary"
            className="w-100"
            onClick={() => {
              const method = paymentMethods.find((p) => p.id === paymentMethod);
              console.log("method: ", method);
              saveJob({ paymentMethod: method });
              navigate("/find-help/confirm");
            }}
          >
            NEXT
          </Button>
        ) : null}
      </>
    );
  };

  const renderForm = () => {
    if (paymentMethod !== "__NEW__") {
      return null;
    }

    if (!setupIntent.setupIntentId) {
      return <Loading msg="Setting up your form..." />;
    }

    return (
      <>
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: setupIntent.clientSecret }}
        >
          <CreditCardPaymentForm
            buttonLabel="NEXT"
            redirectTo={"/find-help/confirm"}
            isSetupIntent={true}
            additionalContent={
              <div className="mt-4">
                <JobRequestTotalView />
              </div>
            }
          />
        </Elements>
      </>
    );
  };

  const render = () => {
    return (
      <>
        {renderSelect()}
        {renderForm()}
      </>
    );
  };

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Payment Method"}
      onStepBack={() => navigate("/find-help/review")}
    >
      <Helmet>
        <title>Payment Method | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <div style={{ padding: "0 2rem 0 2rem" }}>
        <h2 className="mb-4">Payment method</h2>
        <p className="body1">
          You won't be charged until after the service(s) is complete. Payment
          info is only stored to secure your booking request.
        </p>

        {render()}

        <p className="mt-3 body1 text-center">You will not be charged yet.</p>
        <hr />
        <Button
          variant="link"
          className="w-100"
          onClick={() => {
            resetJob();
            navigate("/bookings");
          }}
        >
          CANCEL REQUEST
        </Button>
      </div>
    </RegisterLayout>
  );
};

export default JobRequestPayment;
