import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";

import { useUser } from "../../../contexts/UserContext";
import ProfilePicture from "../../common/ProfilePicture";
import { ucwords } from "../../../lib/stringHelpers";

const WrapperStyled = styled.div`
  padding: 1rem 0;
  padding-bottom: 0;
  border-bottom: 1px solid var(--border-color);

  &:hover {
    background-color: var(--light-secondary-color);
    cursor: pointer;
  }
`;

const JobListItem = ({ jobRequest }) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const renderHelperView = () => {
    let profile = jobRequest.customer.profile || null;

    if (!profile) {
      profile = jobRequest.customer.profiles.find(
        (p) => p._id === jobRequest.requestFor
      );
    }

    return (
      <Col>
        <ProfilePicture
          user={{
            ...jobRequest.customer,
            customer: { ...jobRequest.customer },
          }}
        />
      </Col>
    );
  };

  const renderCustomerView = () => {
    if (jobRequest.helper) {
      return (
        <Col>
          <label className="text-muted d-block">Accepted Helper</label>
          <ProfilePicture
            user={{
              ...jobRequest.helper,
            }}
          />
        </Col>
      );
    }

    return (
      <Col>
        <label className="text-muted">Requsted Helpers</label>
        <Row>
          {jobRequest.requestedHelpers.map((helper, helperIdx) => {
            return (
              <Col xs={3} key={helperIdx}>
                <ProfilePicture
                  user={{
                    ...helper,
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </Col>
    );
  };

  const getStatusColor = () => {
    switch (jobRequest.jobStatus.toLowerCase()) {
      case "new help request":
        return "info";
      case "booking confirmed":
        return "success";
      case "booking canceled":
        return "warning";
      default:
        return "primary";
    }
  };

  const navigateTo = `/bookings/${jobRequest._id}${
    jobRequest.event ? `/${jobRequest.event._id}` : ""
  }`;

  return (
    <WrapperStyled className="mb-3" onClick={() => navigate(navigateTo)}>
      <Row>
        <Col>
          <Badge bg={getStatusColor()}>{ucwords(jobRequest.jobStatus)}</Badge>
          <h4>
            {moment(
              jobRequest.event
                ? jobRequest.event.startDate
                : jobRequest.dates.startDateTime
            ).format("MM/DD h:mm a")}
          </h4>
        </Col>
        <Col>
          <label className="text-muted">Job Type</label>
          <br />
          <p>
            {jobRequest.jobType.join(", ")}
            {jobRequest.isOvernight ? " Overnight" : ""}
          </p>
        </Col>
        {user.userType === "helper" ? renderHelperView() : null}
        {user.userType === "customer" ? renderCustomerView() : null}
      </Row>
    </WrapperStyled>
  );
};

export default JobListItem;
