import React, { useMemo, useState } from "react";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import {
  Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import styled from "styled-components";
import moment from "moment-timezone";
import { RRule } from "rrule";

import ArrowBack from "@mui/icons-material/ArrowBack";
import PersonOutline from "@mui/icons-material/PersonOutline";
import AccessTime from "@mui/icons-material/AccessTime";
import Event from "@mui/icons-material/Event";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import Map from "@mui/icons-material/Map";
import Phone from "@mui/icons-material/Phone";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CreditCard from "@mui/icons-material/CreditCard";

import InternalLayout from "../layout/InternalLayout";
import Loading from "../common/Loading";
import { useApiGet, useApiPut } from "../../hooks/useApi";
import { useUser } from "../../contexts/UserContext";
import { useJob } from "../../contexts/JobContext";
import { ucwords, capitalizeFirstWord } from "../../lib/stringHelpers";
import { getJobStatusForUser } from "../../lib/jobRequestHelpers";
import { rruleDayOfWeekByIndex } from "../../lib/dateHelpers";
import useEffectOnce from "../../hooks/useEffectOnce";
import Payment3ds from "./components/Payment3ds";

import DetailsHeader from "./components/DetailsHeader";
import DetailsFooter from "./components/DetailsFooter";
import DetailsActions from "./components/DetailsActions";

const JobWrapper = styled.div`
  margin-bottom: 8rem;

  & > div {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 1rem;
    padding: 1rem 0;
  }

  & > div p {
    margin-bottom: 0;
  }

  & > div.notes {
    margin-bottom: 1.5rem;
  }

  & strong {
    color: #4a4a4a;
  }
`;

const TimeSection = styled.div`
  & > div {
    border-bottom: 1px solid var(--border-color);
    padding: 1rem 0;
  }

  & > div:first-child {
    padding-top: 0;
  }

  & > div:last-child {
    border: none;
  }
`;

const BookingDetails = () => {
  const { user } = useUser();
  const { jobId, eventId } = useParams();
  const { resetJob } = useJob();
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const queryString = Object.fromEntries(searchParams);
  const url = `/bookings/${jobId}${eventId ? `/${eventId}` : ""}`;
  const queryKey = `booking-${jobId}${eventId ? `-${eventId}` : ""}`;

  // added this in to ensure the "book again" workflow starts
  // off with a clean slate
  useEffectOnce(() => {
    resetJob();
  });

  const { isLoading, data: job } = useApiGet(
    queryKey,
    url,
    user.userType === "helper" ? { markAsViewed: 1 } : null,
    {
      onError: (err) => {
        toast.error(err.message);
        navigate("/bookings");
      },
    }
  );

  const { isLoading: isServicesLoading, data: services } = useApiGet(
    "services",
    "/services"
  );

  const paymentVerified = useApiPut(`${url}/3ds-verified`, () => {
    queryClient.invalidateQueries(queryKey);
  });

  // canceled, expired, rejected, rejected-all, pending, accepted, accepted-other, auhtorized, billed, billing-declined, active, complete
  const [status, statusMsg] = useMemo(() => {
    if (!job) {
      return [null, null];
    }

    queryClient.invalidateQueries({ queryKey: ["nav-counts"] });

    return getJobStatusForUser(user, job);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const isBusy = () => {
    return isLoading || isServicesLoading;
  };

  const getServicesLabel = () => {
    if (isServicesLoading || !services) {
      return "Loading...";
    }

    const list = [];

    job.servicesRequested.forEach((offered) => {
      const service = services.find((s) => offered.service === s._id);
      if (!service) {
        return;
      }
      list.push(service.name);
    });

    return list.join(", ").toLowerCase();
  };

  const getTimeframe = () => {
    let dt = moment(job.dates.startDateTime);
    let endDt = moment(dt).add(job.hours);

    if (job.event) {
      dt = moment(job.event.startDate);
      endDt = moment(job.event.endDate);
    } else if (job.dates.arrivalDateTime) {
      dt = moment(job.dates.arrivalDateTime);
      endDt = moment(dt).add(job.hours, "hours");
    }

    // set the default block as if the arrival hasn't be set yet
    let block = (
      <p className="text-muted">
        Between {dt.format("h:mm a")} - {endDt.format("h:mm a")}
      </p>
    );

    if (job.dates.arrivalDateTime) {
      block = (
        <p className="text-muted">
          Arrives at {dt.format("h:mm a")} until {endDt.format("h:mm a")}
        </p>
      );
    }

    return (
      <>
        <strong>
          One time{job.isOvernight ? " overnight" : ""} on{" "}
          {dt.format("ddd, MMM Do")}
        </strong>
        {block}
      </>
    );
  };

  const getTitle = () => {
    if (!job) {
      return "Booking Details";
    }

    let title = job.event
      ? moment(job.event.startDate).format("ddd, MMM Do")
      : moment(job.dates.startDateTime).format("ddd, MMM Do");

    if (user.userType === "helper") {
      return `${title} with ${job.customer.name}`;
    }

    if (job.helper) {
      title = `${title} with ${job.helper.name}`;
    }

    return title;
  };

  const renderBookedBy = () => {
    const profile = job.customer.profiles.find((p) => p._id === job.requestFor);
    if (!profile || profile.relationship === "_self") {
      return null;
    }

    return (
      <div className="booked-by">
        <Row>
          <Col xs={2} sm={2} md={1}>
            <PersonOutline />
          </Col>
          <Col>
            <strong>Booked By</strong>
            <p>
              <a href={`/users/profile/${job.customer._id}`}>
                {job.customer.name}
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderAddress = () => {
    const profile = job.customer.profiles.find((p) => p._id === job.requestFor);
    if (!profile || !profile.address) {
      return null;
    }

    const addr = profile.address;

    return (
      <div className="address">
        <Row>
          <Col xs={2} sm={2} md={1}>
            <Map />
          </Col>
          <Col>
            <strong>Address</strong>
            <p>
              <a
                href={`https://maps.google.com/?q=${encodeURIComponent(
                  `${addr.street1} ${addr.city} ${addr.state} ${addr.zip}`
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  {addr.street1}
                  {addr.street2 ? ` ${addr.street2}` : null}
                </span>
                <br />
                <span>
                  {addr.city}, {addr.state} {addr.zip}
                </span>
              </a>
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPhone = () => {
    const profile = job.customer.profiles.find((p) => p._id === job.requestFor);
    if (!profile || !profile.phone) {
      return null;
    }

    const matches = /^(\d{3})(\d{3})(\d{4})$/g.exec(profile.phone);
    const formatted = matches
      ? `(${matches[1]}) ${matches[2]}-${matches[3]}`
      : profile.phone;

    return (
      <div className="phone">
        <Row>
          <Col xs={2} sm={2} md={1}>
            <Phone />
          </Col>
          <Col>
            <strong>{profile.phoneType} Phone</strong>
            <p>
              <a href={`tel:+1${profile.phone}`}>{formatted}</a>
            </p>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPaymentVerification = () => {
    // if we don't have a hold/payment intent or there isn't a payment3ds flag, stop here
    if (
      !job.event.holdTransactionId ||
      !job.event.payment3ds ||
      !job.event.payment3ds.isRequired
    ) {
      return null;
    }

    // if it required verification but the user already did it, stop here
    if (job.event.payment3ds.isRequired && job.event.payment3ds.completedOn) {
      return null;
    }

    return (
      <>
        <Alert variant="warning" className="ps-3 pe-3">
          <h3>Payment Verification</h3>
          <p className="mb-3">
            The card on file for this booking,{" "}
            <strong>
              {ucwords(job.paymentMethod.card.brand)}{" "}
              {job.paymentMethod.card.last4}
            </strong>
            , requires additional verification with your bank through 3D Secure
            (3DS). For extra fraud protection, 3DS requires customers to
            complete an additional verification step. You will only have to do
            this once.
          </p>
          <Button
            variant="primary"
            className="ps-3 pe-3"
            onClick={() => setShowVerifyForm(true)}
          >
            Verify The Payment Hold
          </Button>
        </Alert>
        {showVerifyForm ? (
          <Payment3ds
            clientSecret={job.event._paymentIntent.client_secret}
            onSuccess={() => {
              toast.success(
                "Your payment method has been successfully verified!"
              );
              paymentVerified.mutate();
            }}
          />
        ) : null}
      </>
    );
  };

  const renderSchedule = () => {
    if (job.jobType[0] === "One-Time") {
      return getTimeframe();
    }

    const rule = RRule.fromString(job.dates.schedule);

    let startDt = moment(job.dates.startDateTime);
    let endDt = moment(startDt).add(job.hours, "hours");

    if (job.dates.arrivalDateTime) {
      startDt = moment(job.dates.arrivalDateTime);
      endDt = moment(startDt).add(job.hours, "hours");
    }

    return (
      <TimeSection>
        <div>
          <strong>{capitalizeFirstWord(rule.toText())}</strong>
          <p className="text-muted">
            Starting {moment(job.dates.startDateTime).format("MMM D")}
          </p>
        </div>
        {[...rule.options.byweekday].map((dayIdx) => {
          const day = rruleDayOfWeekByIndex(dayIdx);
          return (
            <div key={dayIdx}>
              <strong>{day}s</strong>
              <p className="text-muted">
                Between {startDt.format("h:mm A")} - {endDt.format("h:mm A")}
              </p>
            </div>
          );
        })}
      </TimeSection>
    );
  };

  const renderContent = () => {
    if (isBusy()) {
      return <Loading />;
    }

    return (
      <JobWrapper className="print-mb-3">
        <DetailsHeader job={job} />
        {renderPaymentVerification()}
        {renderBookedBy()}
        {renderAddress()}
        {renderPhone()}
        <div className="schedule">
          <Row>
            <Col xs={2} sm={2} md={1}>
              <Event />
            </Col>
            <Col>{renderSchedule()}</Col>
          </Row>
        </div>
        <div className="duration">
          <Row>
            <Col xs={2} sm={2} md={1}>
              <AccessTime />
            </Col>
            <Col>
              <strong>Duration</strong>
              <p className="text-muted">{job.hours} hours of help</p>
            </Col>
          </Row>
        </div>
        <div className="help-requested">
          <Row>
            <Col xs={2} sm={2} md={1}>
              <AssignmentTurnedInOutlinedIcon />
            </Col>
            <Col>
              <strong>Help requested</strong>
              <p className="text-muted">{ucwords(getServicesLabel())}</p>
              {job.specializedCare.length > 0 ? (
                <>
                  <strong className="d-block mt-3">Specialized Care</strong>
                  <p className="text-muted">{job.specializedCare.join(", ")}</p>
                </>
              ) : null}
            </Col>
          </Row>
        </div>
        {job.paymentMethod && user.userType === "customer" ? (
          <div className="payment-method">
            <Row>
              <Col xs={2} sm={2} md={1}>
                <CreditCard />
              </Col>
              <Col className="pt-1">
                <p className="body2">Payment method</p>
                <p
                  className="body1"
                  style={{ color: "var(--muted-text-color)" }}
                >
                  <p>
                    {ucwords(job.paymentMethod.card.brand)}{" "}
                    {job.paymentMethod.card.last4}
                  </p>
                </p>
              </Col>
            </Row>
          </div>
        ) : null}
        <div className="notes">
          <Row>
            <Col xs={2} sm={2} md={1}>
              <DescriptionOutlinedIcon />
            </Col>
            <Col>
              <strong>Notes</strong>
              <p className="text-muted">{job.additionalNotes || "-"}</p>
            </Col>
          </Row>
        </div>
        <DetailsFooter job={job} />
        <DetailsActions
          job={job}
          jobEvent={job.event}
          status={status}
          statusMsg={statusMsg}
          onUpdate={() => {
            queryClient.invalidateQueries(queryKey);
            queryClient.invalidateQueries(`bookings`);
          }}
        />
      </JobWrapper>
    );
  };

  return (
    <InternalLayout>
      <Container>
        <Link className="print-hide" to={queryString.back || "/bookings"}>
          <ArrowBack />
        </Link>
        <h2 className="mt-3 mb-5 print-hide">{getTitle()}</h2>
        <h2 className="mt-0 mb-3 print-show">{getTitle()}</h2>
        {renderContent()}
      </Container>
    </InternalLayout>
  );
};

export default BookingDetails;
