import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  FloatingLabel,
  ButtonGroup,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Select from "react-select";
import InputMask from "react-input-mask";
import moment from "moment-timezone";
import { toast } from "react-toastify";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useFormData } from "../../hooks/useFormData";
import { useApiPut } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";
import { getLanguageNames } from "../../lib/languageHelpers";
import { HideOnMobile } from "../helper/components/NewHelper.elements";
import { SetupTitle } from "../common/Title.elements";
import { PaddedForm } from "../common/PaddedForm";

const getOptions = () => {
  const names = getLanguageNames();
  return names.map((n) => {
    return { label: n, value: n };
  });
};

const RELATIONSHIPS = [
  {
    label: "My dad",
    value: "dad",
  },
  {
    label: "My mom",
    value: "mom",
  },
  {
    label: "My grandmother",
    value: "grandmother",
  },
  {
    label: "My grandfather",
    value: "grandfather",
  },
  {
    label: "Other family member",
    value: "other",
  },
  {
    label: "My neighbor",
    value: "neighbor",
  },
  {
    label: "My friend",
    value: "friend",
  },
];

const getRelationshipOptions = () => {
  return RELATIONSHIPS.map((n) => {
    return { label: n.label, value: n.value };
  });
};

const getDefaultValues = (user) => {
  const profile =
    user.customer.profiles && user.customer.profiles.length > 0
      ? user.customer.profiles[0]
      : { address: {} };

  return {
    jobRequestFor: profile.jobRequestFor || "", // profile.relationship && profile.relationship !== "_self" ? "other" : "me",
    relationship: profile.relationship || "",
    name: profile.name || "",
    preferredName: profile.preferredName || "",
    email: profile.email || "",
    phone: profile.phone || "",
    phoneType: profile.phoneType || "",
    dateOfBirth: profile.dateOfBirth
      ? moment.utc(profile.dateOfBirth).format("MM/DD/YYYY")
      : "",
    preferredLanguage: profile.preferredLanguage || null,
    street1: profile.address.street1 || "",
    street2: profile.address.street2 || "",
    city: profile.address.city || "",
    state: profile.address.state || "",
    zip: profile.address.zip || "",
  };
};

/*
  STEP 7
*/
const JobRequestProfile = () => {
  const exitAfterSaveInputRef = useRef(null);
  const formRef = useRef(null);
  const { user, updateUser } = useUser();
  const { title, setTitle } = usePage();
  const { job, saveJob, resetJob } = useJob();
  const [existing, setExisting] = useState(
    user && user.customer.profiles && user.customer.profiles.length > 0
      ? user.customer.profiles[0]._id
      : "new"
  );
  const { formData, onChange } = useFormData(getDefaultValues(user));
  const navigate = useNavigate();
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  useEffect(() => {
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle("Create a profile.");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApi = useApiPut("/users/profile", (resp) => {
    // update the global user with the new profile
    updateUser({ ...resp });

    // trigger the on complete
    if (exitAfterSaveInputRef.value === 1) {
      navigate("/dashboard");
    } else {
      saveJob({ requestFor: resp.customer.profiles[0]._id });
      navigate("/find-help/review");
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();

    // we shouldn't overwrite existing profiles
    const profiles =
      user.customer && user.customer.profiles ? user.customer.profiles : [];

    // stop here if we are using an existing profile
    if (existing !== "new") {
      saveJob({ requestFor: existing });
      navigate("/find-help/review");
      return;
    }

    // make sure the phone number is properly formatted
    if (formData.phone && !/\d{3}-\d{3}-\d{4}/.test(formData.phone)) {
      toast.error(
        "Invalid phone number format. Please check your phone number and try again."
      );
      return;
    }

    // load it up as if for the user themselves
    let payload = {
      "customer.profiles": [
        ...profiles,
        {
          name: formData.name,
          relationship:
            formData.jobRequestFor === "me"
              ? "_self"
              : formData.relationship.value,
          email: formData.email || null,
          phone: formData.phone,
          phoneType: formData.phoneType || null,
          address: {
            street1: formData.street1,
            street2: formData.street2 || null,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
          },
          dateOfBirth: formData.dateOfBirth
            ? moment
                .utc(formData.dateOfBirth, "MM/DD/YYYY")
                .format("YYYY-MM-DD")
            : null,
          preferredName: formData.preferredName || null,
          preferredLanguage: formData.preferredLanguage || null,
        },
      ],
    };

    callApi.mutate(payload);
  };

  const content = (
    <PaddedForm onSubmit={onSubmit} ref={formRef}>
      <input
        type="hidden"
        id="exitAfterSave"
        name="exitAfterSave"
        ref={exitAfterSaveInputRef}
        value="0"
        onChange={() => null}
      />

      <HideOnMobile>
        <Button
          type="button"
          variant="link"
          onClick={() => navigate("/find-help/helpers")}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
        >
          <ArrowBack />
        </Button>
      </HideOnMobile>

      <Form.Group className="mb-4">
        <h2>Who is this help request for?</h2>

        {user.customer.profiles && user.customer.profiles.length > 0 ? (
          <div className="mb-3">
            <Form.Select
              name="existing"
              value={existing}
              onChange={(e) => setExisting(e.target.value)}
            >
              {user.customer.profiles.map((p, idx) => {
                return (
                  <option value={p._id} key={idx}>
                    {p.name}
                  </option>
                );
              })}
              <option value="new">New Profile</option>
            </Form.Select>
          </div>
        ) : null}

        {existing === "new" ? (
          <ButtonGroup className="full-width">
            <Button
              onClick={() =>
                onChange({
                  target: { name: "jobRequestFor", value: "me" },
                })
              }
              variant={
                formData.jobRequestFor === "me"
                  ? "secondary"
                  : "outline-secondary"
              }
            >
              ME
            </Button>{" "}
            <Button
              onClick={() =>
                onChange({
                  target: { name: "jobRequestFor", value: "other" },
                })
              }
              variant={
                formData.jobRequestFor === "other"
                  ? "secondary"
                  : "outline-secondary"
              }
            >
              SOMEONE ELSE
            </Button>
          </ButtonGroup>
        ) : null}
      </Form.Group>

      {formData.jobRequestFor === "" ? null : (
        <>
          {formData.jobRequestFor === "other" ? (
            <Select
              name="relationship"
              value={
                formData.relationship
                  ? {
                      label: formData.relationship.label,
                      value: formData.relationship.value,
                    }
                  : null
              }
              options={getRelationshipOptions()}
              isMulti={false}
              isSearchable={true}
              onChange={(sel) => {
                onChange({
                  target: {
                    name: "relationship",
                    value: sel,
                  },
                });
              }}
              placeholder="Select relationship"
              styles={{
                menu: (base) => ({ ...base, zIndex: "700 !important" }),
              }}
            />
          ) : null}

          <hr />

          <SetupTitle>Basic info</SetupTitle>
          <p className="text-muted body1">
            This info will only be shared with a helper after booking is
            confirmed.
          </p>

          <FloatingLabel controlId="name" label="Full name">
            <Form.Control
              type="text"
              name="name"
              size="sm"
              value={formData.name}
              onChange={onChange}
              required={true}
            />
          </FloatingLabel>
          <br />

          <div className="text-end">
            <small className="text-muted">(optional)</small>
          </div>
          <FloatingLabel controlId="preferredName" label="Preferred name">
            <Form.Control
              type="text"
              name="preferredName"
              size="sm"
              value={formData.preferredName}
              onChange={onChange}
            />
          </FloatingLabel>
          <br />

          {formData.jobRequestFor === "me" ? null : (
            <>
              <div className="text-end">
                <small className="text-muted">(optional)</small>
              </div>
              <FloatingLabel controlId="email" label="Email">
                <Form.Control
                  type="email"
                  name="email"
                  size="sm"
                  value={formData.email}
                  onChange={onChange}
                />
              </FloatingLabel>
              <br />
            </>
          )}
          <FloatingLabel controlId="phone" label="Phone number">
            <Form.Control
              type="text"
              name="phone"
              as={InputMask}
              mask="999-999-9999"
              size="sm"
              value={formData.phone}
              onChange={onChange}
              required={true}
            />
          </FloatingLabel>
          <br />

          <div className="text-end">
            <small className="text-muted">(optional)</small>
          </div>
          <FloatingLabel controlId="phoneType" label="Phone type">
            <Form.Select
              size="sm"
              name="phoneType"
              value={formData.phoneType}
              onChange={onChange}
              style={{
                fontSize: "1rem",
                paddingTop: "1.625rem",
                paddingBottom: "0.625rem",
                paddingLeft: "0.75rem",
              }}
            >
              <option value="">Phone type</option>
              <option value="Mobile">Mobile</option>
              <option value="Land">Land</option>
            </Form.Select>
          </FloatingLabel>
          <br />

          <div className="text-end">
            <small className="text-muted">(optional)</small>
          </div>
          <FloatingLabel controlId="dateOfBirth" label="Date of birth">
            <Form.Control
              as={InputMask}
              mask="99/99/9999"
              name="dateOfBirth"
              size="sm"
              value={formData.dateOfBirth}
              onChange={onChange}
            />
          </FloatingLabel>
          <br />

          <div className="text-end">
            <small className="text-muted">(optional)</small>
          </div>
          <Select
            options={getOptions()}
            isMulti={false}
            isSearchable={true}
            value={
              formData.preferredLanguage
                ? {
                    label: formData.preferredLanguage,
                    value: formData.preferredLanguage,
                  }
                : null
            }
            onChange={(sel) => {
              onChange({
                target: {
                  name: "preferredLanguage",
                  value: sel.value,
                },
              });
            }}
            placeholder="Preferred Language"
            styles={{
              menu: (base) => ({ ...base, zIndex: "700 !important" }),
            }}
          />

          <hr />

          <SetupTitle>
            What's {formData.jobRequestFor === "me" ? "your" : "their"} address?
          </SetupTitle>

          <FloatingLabel controlId="street1" label="Street address">
            <Form.Control
              type="text"
              name="street1"
              size="sm"
              value={formData.street1}
              onChange={onChange}
              required={true}
            />
          </FloatingLabel>
          <br />

          <div className="text-end">
            <small className="text-muted">(optional)</small>
          </div>
          <FloatingLabel controlId="street2" label="Apt, suite, etc">
            <Form.Control
              type="text"
              name="street2"
              size="sm"
              value={formData.street2}
              onChange={onChange}
            />
          </FloatingLabel>
          <br />

          <FloatingLabel controlId="city" label="City">
            <Form.Control
              type="text"
              name="city"
              size="sm"
              value={formData.city}
              onChange={onChange}
              required={true}
            />
          </FloatingLabel>
          <br />

          <Row>
            <Col xs={4}>
              <FloatingLabel controlId="state" label="State">
                <Form.Select
                  size="sm"
                  name="state"
                  value={formData.state}
                  onChange={onChange}
                  style={{
                    fontSize: "1rem",
                    paddingTop: "1.625rem",
                    paddingBottom: "0.625rem",
                    paddingLeft: "0.75rem",
                  }}
                >
                  <option value="">Select one</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={8}>
              <FloatingLabel controlId="zip" label="Zip code">
                <Form.Control
                  type="text"
                  name="zip"
                  size="sm"
                  value={formData.zip}
                  onChange={onChange}
                  required={true}
                  maxLength={5}
                />
              </FloatingLabel>
            </Col>
          </Row>
        </>
      )}

      <hr />

      <Button
        type="submit"
        variant="primary"
        size="lg"
        style={{ minWidth: 200, fontFamily: "Noto Sans" }}
        disabled={
          callApi.isLoading ||
          (formData.jobRequestFor === "other" && !formData.relationship) ||
          !formData.name ||
          !formData.street1 ||
          !formData.city ||
          !formData.state ||
          !formData.zip
        }
        className="w-100"
      >
        {callApi.isLoading ? "..." : "NEXT"}
      </Button>
      <hr />
      <Button
        type="button"
        variant="link"
        size="lg"
        style={{ minWidth: 200 }}
        disabled={
          callApi.isLoading ||
          (formData.jobRequestFor === "other" && !formData.relationship) ||
          !formData.name ||
          !formData.street1 ||
          !formData.city ||
          !formData.state ||
          !formData.zip ||
          (formData.dateOfBirth &&
            !/\d{2}\/\d{2}\/\d{4}/.test(formData.dateOfBirth))
        }
        className="w-100 mb-4"
        onClick={() => {
          exitAfterSaveInputRef.current.value = 1;
          if (typeof formRef.current.requestSubmit === "function") {
            formRef.current.requestSubmit();
          } else {
            formRef.current.dispatchEvent(
              new Event("submit", { cancelable: true })
            );
          }
        }}
      >
        {callApi.isLoading ? "..." : <span>SAVE &amp; EXIT</span>}
      </Button>
      <Button
        type="button"
        variant="link"
        className="w-100"
        style={{ fontFamily: "Noto Sans" }}
        onClick={() => {
          resetJob();
          navigate("/bookings");
        }}
      >
        CANCEL REQUEST
      </Button>
    </PaddedForm>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Create Account"}
      onStepBack={() => navigate("/find-help/helpers")}
    >
      <Helmet>
        <title>Care Recipient Profile | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestProfile;
