import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import human from "humanparser";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import InternalLayout from "../layout/InternalLayout";
import {
  InteralLayoutStyled,
  CardContainer,
} from "./components/Welcome.elements";

const IntroImg = styled.img`
  margin: 0 auto;
  margin-bottom: 48px;

  @media only screen and (max-width: 430px) {
    margin-bottom: 24px;
    height: 300px;
  }
`;

const WelcomeIntro = () => {
  const { user } = useUser();
  const { showOnBoarding, next } = useOnboard();
  const navigate = useNavigate();
  const { firstName } = human.parseName(user.name);

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }

    window.gtag("event", "conversion", {
      send_to: "AW-11127239856/NsVNCLXXlpUYELDp8Lkp",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5 text-center">
          <h1 className="mb-4">
            Welcome to Manana,
            <br />
            {firstName}!
          </h1>

          <h3 className="mb-4">
            Start earning money on your own terms in a few quick steps.
          </h3>

          <IntroImg
            src={`${process.env.REACT_APP_AWS_BUCKET_URL}/HelpingAlma.png`}
            alt=""
          />

          <Link
            to={next && next.welcomeUrl ? next.welcomeUrl : "/welcome/photo"}
            className="btn btn-primary btn-lg"
            style={{ width: "90%" }}
          >
            {!next || next._index === 0 ? "GET STARTED" : "CONTINUE"}
          </Link>

          <h3 className="mb-4 mt-4">
            This should take about {5 - (next ? next._index : 0)} minutes.
          </h3>

          <Link
            to="/welcome/list"
            className="btn btn-link btn-lg"
            style={{ textDecoration: "none", fontWeight: 700 }}
          >
            I'LL DO THIS LATER
          </Link>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeIntro;
