import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import { useApiGet } from "../../hooks/useApi";
import StarRating from "./StarRating";
import Pagination from "./Pagination";
import ProfilePicture from "./ProfilePicture";

const ReviewsList = ({ userId, publicRequest = false, emptyMsg = null }) => {
  const [page, setPage] = useState(1);
  const url = publicRequest
    ? `/helpers/${userId}/ratings`
    : `/users/${userId}/ratings`;
  const defaultEmptyMsg = (
    <p className="text-center">No reviews are available.</p>
  );

  const { isLoading, data } = useApiGet(
    `reviews-${userId}`,
    url,
    { page },
    {
      onError: (err) => alert(err.message),
    }
  );

  if (isLoading) {
    return <p className="text-center">Loading...</p>;
  } else if (data.rows.length === 0) {
    return emptyMsg ? emptyMsg : defaultEmptyMsg;
  }

  return (
    <div>
      {data.rows.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className="border-bottom mt-3 pb-3">
            <Row>
              <Col xs={2}>
                <ProfilePicture user={row.fromUser} />
              </Col>
              <Col>
                <div className="float-end d-flex align-items-center">
                  <p className="small text-muted mx-3 mb-0">
                    {moment(row.date).format("MMM. D").toUpperCase()}
                  </p>
                  <StarRating
                    rating={row.rating}
                    totalRatings={null}
                    color={"#205b68"}
                    size={"1rem"}
                    margin={1}
                  />
                </div>
                <p className="body2">{row.fromUser.name}</p>
                <p className="body1 text-muted">{row.publicReview}</p>
              </Col>
            </Row>
          </div>
        );
      })}

      <div className="d-flex justify-content-center">
        <Pagination
          className="pagination-bar"
          currentPage={page}
          totalCount={data.totalCount}
          pageSize={5}
          onPageChange={(p) => setPage(p)}
          siblingCount={0}
        />
      </div>
    </div>
  );
};

ReviewsList.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ReviewsList;
