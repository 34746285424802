import styled from "styled-components";

export const InternalResponsiveColumn = styled.div`
  padding: 2.5rem 0;

  @media (max-width: 768px) {
    padding: 2rem 0;
    background-color: white;
  }

  @media only screen and (max-width: 430px) {
    padding: 2rem 0;

    & .hide-mobile {
      display: none;
    }
  }
`;
