import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Alert,
  FloatingLabel,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import moment from "moment-timezone";
import ReactInputMask from "react-input-mask";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { toast } from "react-toastify";

import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import { useFormData } from "../../hooks/useFormData";
import { useApiPost } from "../../hooks/useApi";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import ContentBlock from "../common/ContentBlock";
import StepProgress from "../common/StepProgress";
import {
  InteralLayoutStyled,
  CardContainer,
} from "./components/Welcome.elements";
import {
  isDevEnv,
  getPassBackgroundData,
  getConsiderBackgroundData,
  getClearWithCanceledBackgroundData,
} from "../../lib/envHelpers";
import { ButtonFooter } from "../welcome/components/Welcome.elements";

const WelcomeBackground = () => {
  const { showOnBoarding } = useOnboard();
  const { user, updateUser } = useUser();
  const { formData, onChange, setData } = useFormData({
    firstName: "",
    lastName: "",
    middleName: "",
    mothersMaidenName: "",
    ssn: "",
    dob: "",
    street1: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    phoneType: "mobile",
    workLocations: [],
  });
  const [formError] = useState(null);
  const navigate = useNavigate();
  const isDev = isDevEnv();

  const submitInfo = useApiPost(
    "/users/background-check/candidate",
    (res) => {
      updateUser({ ...res });
      navigate("/welcome/background/legal");
    },
    {
      onError: (err) => alert(err.message),
    }
  );

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    } else if (user.helper.backgroundCheck.status === "pending payment") {
      navigate("/welcome/background/pay");
    } else if (user.helper.backgroundCheck.formData) {
      setData({
        ...user.helper.backgroundCheck.formData,
        dob: moment
          .utc(user.helper.backgroundCheck.formData.dob)
          .format("MM/DD/YYYY"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    // make sure the phone number is properly formatted
    if (formData.phone && !/\d{3}-\d{3}-\d{4}/.test(formData.phone)) {
      toast.error(
        "Invalid phone number format. Please check your phone number and try again."
      );
      return;
    }

    submitInfo.mutate({
      ...formData,
      dob: moment.utc(formData.dob, "MM/DD/YYYY").format(),
    });
  };

  const content = (
    <form onSubmit={onSubmit}>
      {isDev ? (
        <Alert variant="warning">
          <p>
            <strong>A note about background checks in DEV:</strong>
          </p>
          <p>
            In order to properly test background checks in development, you must
            use a preset data template for pass or fail scenarios.
          </p>
          <Button
            variant="link"
            onClick={() => {
              const data = getPassBackgroundData();
              setData(data, true);
            }}
            className="me-1"
          >
            Enter PASS Data
          </Button>
          <Button
            variant="link"
            onClick={() => {
              const data = getConsiderBackgroundData();
              setData(data, true);
            }}
            className="me-1"
          >
            Enter CONSIDER Data
          </Button>
          <Button
            variant="link"
            onClick={() => {
              const data = getClearWithCanceledBackgroundData();
              setData(data, true);
            }}
          >
            Enter CLEAR WITH CANCELED Data
          </Button>
        </Alert>
      ) : null}
      <h3 className="mb-4">
        In order to run a background check, we need additional information.
      </h3>
      <p className="body1">
        This is the minimum amount of information legally required to perform a
        background check. The cost of a background check is $35, but Manana will
        reimburse you once you achieve $150 in earnings.
      </p>
      <p className="cta">
        <a
          href="https://www.mananahelp.com/become-a-helper"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          WHY DO YOU NEED A BACKGROUND CHECK?
        </a>
      </p>
      {formError ? <Alert variant="danger">{formError}</Alert> : null}

      <Row>
        <Col xs={12} md={4}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="firstName" label="First Name">
              <Form.Control
                type="text"
                name="firstName"
                placeholder="Legal First Name"
                value={formData.firstName}
                required={true}
                onChange={onChange}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="middleName" label="Middle Name">
              <Form.Control
                type="text"
                name="middleName"
                placeholder="Middle Name"
                value={formData.middleName}
                onChange={onChange}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="lastName" label="Last Name">
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Legal Last Name"
                value={formData.lastName}
                required={true}
                onChange={onChange}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <FloatingLabel
              controlId="mothersMaidenName"
              label="Mother's Maiden Name"
            >
              <Form.Control
                type="text"
                name="mothersMaidenName"
                placeholder="Mother's Maiden Name"
                value={formData.mothersMaidenName}
                required={true}
                onChange={onChange}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <InputGroup>
              <FloatingLabel
                controlId="ssn"
                label="Social Security Number"
                style={{ flex: 1 }}
              >
                <Form.Control
                  as={ReactInputMask}
                  mask="999-99-9999"
                  name="ssn"
                  placeholder="Social Security Number"
                  value={formData.ssn}
                  required={true}
                  onChange={onChange}
                  style={{ display: "block" }}
                />
              </FloatingLabel>
              <InputGroup.Text>
                <LockOutlinedIcon />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>

      <Form.Group className="mb-4">
        <FloatingLabel controlId="dob" label="Date of Birth">
          <Form.Control
            as={ReactInputMask}
            mask="99/99/9999"
            name="dob"
            placeholder="Date of Birth"
            required={true}
            onChange={onChange}
            value={formData.dob}
            style={{ display: "block" }}
          />
        </FloatingLabel>
      </Form.Group>

      <p>
        Please enter your address below. This must be a physical address and
        cannot be a PO Box.
      </p>

      <Form.Group className="mb-4">
        <FloatingLabel controlId="street1" label="Street Address">
          <Form.Control
            type="text"
            name="street1"
            placeholder="Street Address"
            value={formData.street1}
            required={true}
            onChange={onChange}
          />
        </FloatingLabel>
      </Form.Group>
      <Form.Group className="mb-4">
        <FloatingLabel controlId="city" label="City">
          <Form.Control
            type="text"
            name="city"
            placeholder="City"
            value={formData.city}
            required={true}
            onChange={onChange}
          />
        </FloatingLabel>
      </Form.Group>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="state" label="State">
              <Form.Select
                size="lg"
                name="state"
                value={formData.state}
                onChange={onChange}
                required={true}
                style={{
                  flex: 1,
                  fontSize: "1rem",
                }}
              >
                <option value="">State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="zip" label="Zip Code">
              <Form.Control
                type="text"
                name="zip"
                placeholder="Zip Code"
                value={formData.zip}
                required={true}
                onChange={onChange}
                maxLength={5}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="phone" label="Phone Number">
              <Form.Control
                as={ReactInputMask}
                mask="999-999-9999"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                required={true}
                onChange={onChange}
                style={{ display: "block" }}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group className="mb-4">
            <FloatingLabel controlId="phoneType" label="Phone Type">
              <Form.Select
                size="lg"
                name="phoneType"
                value={formData.phoneType}
                onChange={onChange}
                style={{
                  flex: 1,
                  fontSize: "1rem",
                }}
              >
                <option value="mobile">Mobile</option>
                <option value="home">Home (Non Mobile)</option>
                <option value="work">Work (Non Mobile)</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <ButtonFooter className="mb-4">
        <Row>
          <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
            <Button
              type="button"
              variant="light"
              className="btn-text-primary w-90"
              onClick={() => {
                navigate("/welcome");
              }}
            >
              SKIP FOR NOW
            </Button>
          </Col>
          <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
            <Button
              type="submit"
              variant="primary"
              className="w-90"
              style={{ minWidth: 200, fontFamily: "Noto Sans" }}
              disabled={
                !formData.ssn ||
                !formData.dob ||
                !formData.zip ||
                formData.firstName.length < 3 ||
                formData.lastName.length < 3 ||
                !/^\d{3}-\d{3}-\d{4}$/.test(formData.phone) ||
                submitInfo.isLoading
              }
            >
              NEXT
            </Button>
          </Col>
        </Row>
      </ButtonFooter>

      <div className="d-flex justify-content-between">
        <LockOutlinedIcon style={{ marginRight: "0.5rem" }} />
        <p className="body1 text-grey">
          Your personal information is securely encrypted and never shared
          without your expressed consent.
        </p>
      </div>
    </form>
  );

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>Background Check | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5 text-center">
          <WelcomeSteps
            currentStep={4}
            totalSteps={4}
            onBack={() => navigate("/welcome/availability")}
          />
          <ContentBlock>
            <StepProgress
              steps={["Information", "Legal", "$35 Fee"]}
              active={0}
            />
          </ContentBlock>

          <ContentBlock>{content}</ContentBlock>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeBackground;
