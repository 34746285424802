import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import styled from "styled-components";

import Settings from "@mui/icons-material/Settings";

import { useUser } from "../../contexts/UserContext";
import InternalLayout from "../layout/InternalLayout";
import Tabs from "../common/Tabs";
import { useApiGet } from "../../hooks/useApi";

const StyledTr = styled.tr`
  cursor: pointer;

  &:hover {
    background: var(--border-color);
  }
`;

const Payments = () => {
  const { user } = useUser();
  const [page] = useState(1);
  const navigate = useNavigate();

  const { isLoading, data } = useApiGet(
    "payments",
    "/payments",
    { page, limit: 10 },
    { staleTime: 5000, onError: (err) => toast.error(err.message) }
  );

  const renderList = (list, emptyMsg) => {
    if (list.total === 0) {
      return <p className="text-center lead">{emptyMsg}</p>;
    }

    return (
      <Table>
        <thead></thead>
        <tbody>
          {list.rows.map((row, index) => {
            const other =
              user.userType === "helper" ? row.customer : row.helper;

            return (
              <StyledTr
                key={index}
                onClick={() => navigate(`/bookings/${row.jobRequest._id}`)}
              >
                <td>
                  {other.profileImage ? (
                    <img
                      src={other.profileImage}
                      alt="User"
                      className="profile-photo-sm"
                    />
                  ) : (
                    <div className="profile-photo-sm">
                      <span>{other.name.substring(0, 1)}</span>
                    </div>
                  )}
                </td>
                <td>
                  <p className="body1 mt-2">{other.name}</p>
                </td>
                <td>
                  <p className="body1 mt-2">
                    {moment(row.startDate).format("MMM D, YYYY")}
                  </p>
                </td>
                <td>
                  <p className="body1 mt-2">${row.payout}</p>
                </td>
              </StyledTr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  return (
    <InternalLayout>
      <Helmet>
        <title>Payments | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>
        <div className="float-end">
          <Link
            to={
              user.helper
                ? "/account/payment-settings"
                : "/account/recipient-payment-settings"
            }
            className="btn btn-link"
          >
            <Settings />
          </Link>
        </div>
        <h2 className="mb-5">Payments</h2>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <Tabs
            tabs={[
              {
                label: "UPCOMING",
                content: renderList(
                  data.upcoming,
                  "You do not have any upcoming payments."
                ),
              },
              {
                label: "PAID",
                content: renderList(
                  data.paid,
                  "You have not received any payments yet."
                ),
              },
            ]}
          />
        )}
      </Container>
    </InternalLayout>
  );
};

export default Payments;
