import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import Select from "react-select";

import { useApiGet } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import { useUser } from "../../contexts/UserContext";

import AdminLayout from "../layout/AdminLayout";
import Loading from "../common/Loading";
import { InternalResponsiveColumn } from "../common/Common.elements";
import HelperSelect from "./components/HelperSelect";
import CalendarPicker from "./components/CalendarPicker";

const AdminCalendar = () => {
  const { user, signOut } = useUser();
  const { formData, onChange } = useFormData({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
    zipCodes: [],
    backgroundCheckStatus: "passed",
    helpers: [],
  });
  const [filters, setFilters] = useState({});
  const [zipOptions, setZipOptions] = useState([]);
  const navigate = useNavigate();

  const [calDateSel, setCalDateSel] = useState({
    day: null,
    timeslots: [],
    selected: null,
  });

  const { isLoading, data } = useApiGet(
    `admin-calendar`,
    `/admin/calendar`,
    { ...filters },
    {
      onError: (err) => toast.error(err.message),
    },
    true
  );

  const { isLoading: serviceAreaLoading, data: serviceAreaList } = useApiGet(
    `service-area`,
    `/service-area/current-zips`,
    null,
    {
      onError: (err) => toast.error(err.message),
    }
  );

  useEffect(() => {
    if (user.userType !== "admin") {
      signOut();
      navigate("/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setCalDateSel({
      day: null,
      timeslots: [],
      selected: null,
    });
  }, [data]);

  useEffect(() => {
    if (!serviceAreaList) {
      return;
    }

    const newZips = [];

    serviceAreaList.forEach((area) => {
      area.postalCodes.forEach((zip) =>
        newZips.push({ label: zip, value: zip })
      );
    });

    setZipOptions(newZips);
  }, [serviceAreaList]);

  useEffect(() => {
    setFilters({
      ...formData,
      zipCodes: formData.zipCodes.map((v) => v.value),
      helpers: formData.helpers.map((h) => h.value),
    });
  }, [formData]);

  const onDateSelect = (day) => {
    const timeslots = [];

    data.forEach((row) => {
      const dt = moment(row.dates.start).format("YYYY-MM-DD");
      if (dt === day) {
        timeslots.push({
          ...row,
        });
      }
    });

    setCalDateSel({ day, timeslots, selected: null });
  };

  return (
    <AdminLayout>
      <Helmet>
        <title>Admin Calendar | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container fluid>
        <Row>
          <InternalResponsiveColumn>
            <h2 className="mb-5">Admin Calendar</h2>

            {isLoading && serviceAreaLoading ? (
              <Loading />
            ) : (
              <>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Zip Codes</Form.Label>
                      <Select
                        options={zipOptions}
                        onChange={(vals) => {
                          onChange({
                            target: { name: "zipCodes", value: vals },
                          });
                        }}
                        isMulti={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Background Check Status</Form.Label>
                      <Form.Select
                        name="backgroundCheckStatus"
                        value={formData.backgroundCheckStatus}
                        onChange={onChange}
                      >
                        <option value="any">Any</option>
                        <option value="passed">Passed</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Helpers</Form.Label>
                      <HelperSelect
                        value={formData.helpers}
                        onChange={(vals) =>
                          onChange({ target: { name: "helpers", value: vals } })
                        }
                        isMulti={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <CalendarPicker
                  calDateSel={calDateSel}
                  onDateSelect={(day) => onDateSelect(day)}
                  data={data}
                  date={moment()}
                  highlightPast={false}
                />
              </>
            )}
          </InternalResponsiveColumn>
        </Row>
      </Container>
    </AdminLayout>
  );
};

export default AdminCalendar;
