import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment-timezone";
import { Helmet } from "react-helmet-async";
import { RRule } from "rrule";
import ArrowBack from "@mui/icons-material/ArrowBack";
import PersonOutline from "@mui/icons-material/PersonOutline";
import CalendarToday from "@mui/icons-material/CalendarToday";
import AccessTime from "@mui/icons-material/AccessTime";
import Edit from "@mui/icons-material/Edit";
import Check from "@mui/icons-material/Check";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CreditCard from "@mui/icons-material/CreditCard";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import ReactGA from "react-ga4";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import HelperShortProfile from "../helper/HelperShortProfile";
import JobRequestTotalView from "./JobRequestTotalView";
import { displayTime } from "../../lib/dateHelpers";
import { useFormData } from "../../hooks/useFormData";
import { useApiGet, useApiPost, useApi } from "../../hooks/useApi";
import useEffectOnce from "../../hooks/useEffectOnce";
import { useJob } from "../../contexts/JobContext";
import { HideOnMobile } from "../helper/components/NewHelper.elements";
import { SetupTitle } from "../common/Title.elements";
import { ucwords } from "../../lib/stringHelpers";
import { rruleDayOfWeekByWeekday } from "../../lib/dateHelpers";
import { getAvailabilityWindowTitle } from "../../lib/jobRequestHelpers";
import { toast } from "react-toastify";
import { PaddedForm } from "../common/PaddedForm";

/*
  STEP 8 & 10
*/
const JobRequestReview = ({ finalConfirmation = false }) => {
  const { user } = useUser();
  const { title, setTitle } = usePage();
  const { job, saveJob, resetJob } = useJob();
  const { formData, onChange } = useFormData({
    additionalNotes: job.additionalNotes || "",
    callBeforeRequired: job.callBeforeRequired || false,
  });
  const navigate = useNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const stepsTotal = job.isNewCustomer ? 4 : 2;
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const profile = job.requestFor
    ? user.customer.profiles.find((p) => p._id === job.requestFor)
    : user.customer.profiles[0];
  const header = finalConfirmation
    ? "Send your help request"
    : "Review your help request";

  useEffect(() => {
    if (!job.serviceArea) {
      navigate("/find-help");
    }
    setTitle(
      finalConfirmation
        ? "You're all set! If everything is correct, send your request."
        : "Make sure everything looks correct."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalConfirmation]);

  const confirmSetupIntent = useApi({
    onError: (err) => toast.error(err.message),
  });

  useEffectOnce(() => {
    const queryString = Object.fromEntries(searchParams);
    if (queryString.setup_intent) {
      confirmSetupIntent.call(
        `/payments/setup-intent/${queryString.setup_intent}`,
        "put"
      );
    }

    if (!job.requestFor) {
      job.requestFor = user.customer.profiles[0]._id;
    }
  });

  const { isLoading, data: services } = useApiGet(
    "services",
    "/services",
    null,
    {
      staleTime: 300000,
    }
  );

  const { isLoading: isLoadingProfiles, data: helperProfiles } = useApiGet(
    "helper-profiles",
    "/helpers/profiles",
    { helperIds: job.requestedHelpers.join(",") },
    {
      staleTime: 300000,
    }
  );

  const { isLoading: isLoadingPaymentMethods, data: paymentMethods } =
    useApiGet("payment-methods", "/payments/methods", undefined, {
      enabled: finalConfirmation,
      staleTime: 300000,
    });

  const apiSubmit = useApiPost(
    "/job-requests",
    () => {
      queryClient.invalidateQueries("job-requests");
      queryClient.invalidateQueries("bookings");

      window.localStorage.removeItem("_job");
      ReactGA.ga("event", "conversion", {
        send_to: "AW-11127239856/NB9gCMGb1uYYELDp8Lkp",
        value: 1,
      });
      navigate("/bookings");
    },
    {
      onError: (err) => alert(err.message),
    }
  );

  const getSchedule = () => {
    const dt = moment(
      `${job.availability.startDate} ${job.availability.startTime}`,
      "YYYY-MM-DD HH:mm"
    );

    // setart the config
    let rruleConfig = {
      dtstart: dt.toDate(),
      wkst: RRule.SU,
      interval: 1,
      byweekday: job.availability.weekdays.map((wd) =>
        rruleDayOfWeekByWeekday(wd)
      ),
    };

    if (job.availability.endDate) {
      rruleConfig.until = moment
        .utc(job.availability.endDate, "YYYY-MM-DD")
        .toDate();
    }

    switch (true) {
      // eslint-disable-next-line no-fallthrough
      case job.availability.frequency === "weekly":
        rruleConfig = { ...rruleConfig, freq: RRule.WEEKLY };
        break;

      case job.availability.frequency === "every 2 weeks":
        rruleConfig = { ...rruleConfig, freq: RRule.WEEKLY, interval: 2 };
        break;

      case job.availability.frequency === "every month":
        rruleConfig = { ...rruleConfig, freq: RRule.MONTHLY };
        break;

      default:
      // does not really repeat
    }

    // update the payload
    return new RRule(rruleConfig).toString();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    saveJob({ ...formData });

    if (finalConfirmation) {
      let payload = {
        customer: job.customer,
        serviceArea: job.serviceArea._id,
        helper: null,
        requestFor: job.requestFor,
        requestedHelpers: job.requestedHelpers,
        jobType: job.availability.jobType,
        declinedBy: [],
        servicesRequested: Object.keys(job.services).map((serviceId) => {
          return {
            service: serviceId,
            options: Object.keys(job.services[serviceId]).map((option) => {
              return {
                key: option,
                value: job.services[serviceId][option],
              };
            }),
          };
        }),
        dates: {
          startDateTime: moment(
            `${job.availability.startDate} ${job.availability.startTime}`,
            "YYYY-MM-DD HH:mm"
          ).format(),
          endTime: job.availability.endTime,
          schedule: getSchedule(),
          endDate: job.availability.endDate || null,
        },
        specializedCare: job.specializedCare,
        vehicleRequired: job.vehicleRequired,
        hours: job.hours,
        isOvernight: job.availability.isOvernight,
        isRecurring: job.availability.jobType === "Recurring",
        viewedBy: [],
        paymentMethod: job.paymentMethod
          ? job.paymentMethod.id
          : paymentMethods[0].id,
        acceptedOn: null,
        canceledOn: null,
        additionalNotes: job.additionalNotes,
      };

      // send the payload off
      apiSubmit.mutate(payload);
    } else {
      navigate("/find-help/payment");
    }
  };

  const renderServicesList = () => {
    let names = [];

    Object.keys(job.services).forEach((serviceId) => {
      const service = services.find((s) => s._id === serviceId);
      if (service) {
        names.push(service.name);
      }
    });

    if (job.specializedCare.length > 0) {
      names = [...names, ...job.specializedCare];
    }

    return names.join(", ");
  };

  const renderPaymentMethod = () => {
    if (isLoadingPaymentMethods) {
      return <p>Loading...</p>;
    } else if (paymentMethods.length === 0) {
      navigate("/find-help/payment");
      return;
    }

    let paymentMethod = paymentMethods[0];

    if (job.paymentMethod) {
      paymentMethod = paymentMethods.find(
        (p) => p.id === job.paymentMethod || p.id === job.paymentMethod.id
      );
    }

    return (
      <p>
        {ucwords(paymentMethod.brand)} {paymentMethod.last4}
      </p>
    );
  };

  const content = (
    <PaddedForm onSubmit={onSubmit}>
      <HideOnMobile>
        <Button
          type="button"
          variant="link"
          onClick={() => navigate("/find-help/helpers")}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
        >
          <ArrowBack />
        </Button>
      </HideOnMobile>

      <h2 className="mb-4">{header}</h2>

      <SetupTitle className="mb-4">Help request details</SetupTitle>

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => setEditProfile(!editProfile)}
          >
            {editProfile ? <Check /> : <Edit />}
          </Button>
        </div>
        <Row>
          <Col xs={4} sm={3} md={2} className="text-center">
            <PersonOutline />
          </Col>
          <Col className="pt-1">
            <p className="body2">Care Recipient</p>
            {editProfile ? (
              <Form.Select
                name="requestFor"
                className="mb-3"
                onChange={(e) => saveJob({ requestFor: e.target.value })}
                value={job.requestFor || ""}
              >
                {user.customer.profiles.map((p, pIdx) => (
                  <option value={p._id} key={pIdx}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <p className="body1" style={{ color: "var(--muted-text-color)" }}>
                {profile.name}
              </p>
            )}
          </Col>
        </Row>
      </div>

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => navigate("/find-help/helpers")}
          >
            <Edit />
          </Button>
        </div>
        <Row>
          <Col xs={4} sm={3} md={2} className="text-center">
            <CalendarToday />
          </Col>
          <Col className="pt-1">
            <p className="body2">{getAvailabilityWindowTitle(job)}</p>
            <p className="body1" style={{ color: "var(--muted-text-color)" }}>
              Between {displayTime(job.availability.startTime)} and{" "}
              {displayTime(job.availability.endTime)}
            </p>
          </Col>
        </Row>
      </div>

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => navigate("/find-help/services")}
          >
            <Edit />
          </Button>
        </div>
        <Row>
          <Col xs={4} sm={3} md={2} className="text-center">
            <AccessTime />
          </Col>
          <Col className="pt-1">
            <p className="body2">Duration</p>
            <p className="body1" style={{ color: "var(--muted-text-color)" }}>
              {job.hours} hours
            </p>
          </Col>
        </Row>
      </div>

      <div className="b-bottom mb-3">
        <div className="float-end">
          <Button
            variant="link"
            className="m-0 p-0"
            onClick={() => navigate("/find-help/services")}
          >
            <Edit />
          </Button>
        </div>
        <Row>
          <Col xs={4} sm={3} md={2} className="text-center">
            <AssignmentTurnedInOutlinedIcon />
          </Col>
          <Col className="pt-1">
            <p className="body2">Help requested</p>
            <p className="body1" style={{ color: "var(--muted-text-color)" }}>
              {isLoading ? (
                <span>Loading services data...</span>
              ) : (
                renderServicesList()
              )}
            </p>
          </Col>
        </Row>
      </div>

      {finalConfirmation ? (
        <div className="b-bottom mb-3">
          <div className="float-end">
            <Button
              variant="link"
              className="m-0 p-0"
              onClick={() => navigate("/find-help/payment")}
            >
              <Edit />
            </Button>
          </div>
          <Row>
            <Col xs={4} sm={3} md={2} className="text-center">
              <CreditCard />
            </Col>
            <Col className="pt-1">
              <p className="body2">Payment method</p>
              <p className="body1" style={{ color: "var(--muted-text-color)" }}>
                {renderPaymentMethod()}
              </p>
            </Col>
          </Row>
        </div>
      ) : null}

      {isLoadingProfiles ? (
        <span>Loading profiles...</span>
      ) : (
        job.requestedHelpers.map((requested) => {
          const helper = helperProfiles.find((h) => h._id === requested);
          return (
            <div key={requested} className="mb-3">
              <div className="float-end">
                <Button
                  variant="link"
                  className="m-0 p-0"
                  onClick={() => navigate("/find-help/helpers")}
                >
                  <Edit />
                </Button>
              </div>
              <HelperShortProfile
                user={helper}
                specialityCare={job.specializedCare.length > 0}
                pricing={job.requestedHelpers.length > 1}
                onSelect={() => navigate(`/find-help/helpers/${helper._id}`)}
              />
            </div>
          );
        })
      )}

      <div className="mb-3 pb-4 b-bottom">
        <Row>
          <Col xs={4} sm={3} md={2} className="text-center">
            <div style={{ marginLeft: 14 }}>
              <DescriptionOutlinedIcon />
            </div>
          </Col>
          <Col>
            <Form.Group>
              <Form.Control
                as="textarea"
                name="additionalNotes"
                value={formData.additionalNotes}
                onChange={onChange}
                placeholder="Additional notes"
                style={{ minHeight: 160 }}
              />
            </Form.Group>
          </Col>
        </Row>
      </div>

      <JobRequestTotalView />

      <Form.Group controlId="cb-call-required" className="mt-4 mb-4">
        <Form.Check
          type="checkbox"
          id="cb-call-required"
          label="I'd like to schedule a call with helper prior to confirming booking."
          checked={formData.callBeforeRequired}
          size="lg"
          onChange={(e) =>
            onChange({
              target: {
                name: "callBeforeRequired",
                value: e.target.checked,
              },
            })
          }
        />
      </Form.Group>

      <div className="mt-4">
        <Button
          type="submit"
          variant="primary"
          className="w-100"
          disabled={apiSubmit.isLoading && isLoadingPaymentMethods}
        >
          {finalConfirmation ? "SEND HELP REQUEST" : "PROCEED TO PAYMENT"}
        </Button>
      </div>
      <hr />
      <Button
        variant="link"
        className="w-100"
        onClick={() => {
          resetJob();
          navigate("/bookings");
        }}
      >
        CANCEL REQUEST
      </Button>
    </PaddedForm>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Payment Method"}
      onStepBack={() => navigate("/find-help/helpers")}
    >
      <Helmet>
        <title>Review | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestReview;
