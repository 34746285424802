import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import useOnboard from "../../hooks/useOnboard";
import { useApiPut } from "../../hooks/useApi";
import InternalLayout from "../layout/InternalLayout";
import WelcomeSteps from "./components/WelcomeSteps";
import {
  InteralLayoutStyled,
  CardContainer,
  ButtonFooter,
} from "./components/Welcome.elements";

const WelcomeAbout = () => {
  const { user, updateUser } = useUser();
  const [about, setAbout] = useState(
    get(user, "helper.profile.description", "")
  );
  const { showOnBoarding } = useOnboard();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showOnBoarding) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfile = useApiPut(
    "/users/profile",
    (res) => {
      updateUser(res);
      navigate("/welcome/availability");
    },
    {
      onError: (err) => toast.error(err.message),
    }
  );

  const save = () => {
    const payload = { "helper.profile.description": about };

    updateProfile.mutate(payload);
  };

  return (
    <InteralLayoutStyled>
      <Helmet>
        <title>About | Welcome | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <InternalLayout showNav={false} internalPageClass="welcome-background">
        <CardContainer className="mb-5">
          <WelcomeSteps
            currentStep={2}
            totalSteps={4}
            onBack={() => navigate("/welcome/photo")}
          />

          <h2 className="mb-3">Introduce yourself</h2>

          <p className="body1 mb-3">
            Let care recipients know who you are and why you're here to help. Do
            you have a personal caregiving story? Why should someone choose you
            as their helper? What do you like to do for fun? Do you have hobbies
            or interests?
          </p>

          <Form.Group className="mb-5">
            <Form.Control
              as="textarea"
              value={about}
              onChange={(e) => setAbout(e.target.value)}
              placeholder="Tell us a bit about you."
              style={{ minHeight: 200 }}
            />
          </Form.Group>

          <ButtonFooter>
            <Row>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 0 }}>
                <Link
                  to="/welcome/availability"
                  className="btn btn-link btn-lg"
                  style={{
                    textDecoration: "none",
                    fontWeight: 700,
                    margin: 0,
                    marginTop: 10,
                    padding: 0,
                  }}
                >
                  I'LL DO THIS LATER
                </Link>
              </Col>
              <Col xs={{ span: 12, order: 0 }} md={{ span: 6, order: 1 }}>
                <Button
                  variant="primary"
                  size="lg"
                  style={{ width: "90%" }}
                  disabled={
                    !about || about.length < 5 || updateProfile.isLoading
                  }
                  onClick={() => save()}
                >
                  {updateProfile.isLoading ? "SAVING..." : "SAVE"}
                </Button>
              </Col>
            </Row>
          </ButtonFooter>
        </CardContainer>
      </InternalLayout>
    </InteralLayoutStyled>
  );
};

export default WelcomeAbout;
