import React from "react";

export const ucwords = (str) => {
  return (str + "").replace(/^([a-z])|\s+([a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

export const capitalizeFirstWord = (str) => {
  // Now convert first character to upper case
  let firstCharacter = str.charAt(0).toUpperCase();
  // Now combine firstCharacter and lowerStr and return
  return firstCharacter + str.slice(1);
};

export const nl2br = (text) => {
  if (!text || text.length === 0) {
    return null;
  }

  return text.split("\n").map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });
};
