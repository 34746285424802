import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { components } from "react-select";

const PreviousHelperSelect = ({ value, onChange, options }) => {
  const Input = (props) => {
    return <components.Input {...props} />;
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <p className="mb-0">{props.children}</p>
      </components.Option>
    );
  };

  return (
    <Select
      components={{ Option, Input }}
      isMulti={false}
      isClearable={true}
      isSearchable={true}
      options={options}
      onChange={onChange}
      value={value}
      defaultOptions={true}
    />
  );
};

PreviousHelperSelect.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.any,
};

export default PreviousHelperSelect;
