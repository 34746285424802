import React from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import InternalLayout from "../layout/InternalLayout";
import { useApiGet } from "../../hooks/useApi";
import HelperFullProfile from "../helper/HelperFullProfile";
import CustomerProfile from "../customer/CustomerProfile";

/**
 * This is NOT a public-public profile view for any user. This
 * is a view for users based on who needs to know what about specific
 * users. For example: customers can view the public profile of any
 * active helper. Helper's can view the public data of customers
 * associated with their job requests.
 */
const UserPublicProfile = () => {
  const { user } = useUser();
  const { userId } = useParams();
  const [searchParams] = useSearchParams();
  const endpoint =
    user.userType === "helper"
      ? `/users/profile/public/${userId}`
      : `/helpers/${userId}`;

  const { isLoading, data } = useApiGet(
    `profile-${userId}`,
    endpoint,
    undefined,
    { staleTime: 5000, enabled: true }
  );

  const getProfileId = () => {
    const queryString = Object.fromEntries(searchParams);
    return queryString.profile || data.profiles[0]._id;
  };

  const renderContent = () => {
    if (isLoading) {
      return <h2>Loading...</h2>;
    }

    return (
      <>
        <Row>
          <Col>
            <Link to={() => window.location.goBack()}>
              <ArrowBack />
            </Link>
          </Col>
        </Row>
        {data.helper ? (
          <HelperFullProfile user={data} size="lg" selectable={false} />
        ) : (
          <CustomerProfile user={data} profileId={getProfileId()} />
        )}
      </>
    );
  };

  return (
    <InternalLayout>
      <Helmet>
        <title>Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>{renderContent()}</Container>
    </InternalLayout>
  );
};

export default UserPublicProfile;
