import React from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Prompt from "../../common/Prompt";

const Education = () => {
  const { user, updateUser } = useUser();
  const { formData, onChange, hasChanged, setHasChanged } = useFormData({
    education: get(user, "helper.profile.education", ""),
    educationLocation: get(user, "helper.profile.educationLocation", ""),
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  return (
    <SubInternalLayout title={`Education`}>
      <Helmet>
        <title>Education | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <p className="text-muted body1">Tell us about your education.</p>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          save.mutate({
            "helper.profile.education":
              formData.education.length === 0 ? null : formData.education,
            "helper.profile.educationLocation":
              formData.education.length === 0
                ? null
                : formData.educationLocation,
          });
        }}
      >
        <Row>
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Label>Education Level</Form.Label>
              <Form.Select
                name="education"
                onChange={onChange}
                value={formData.education}
              >
                <option value="">Select your level of education</option>
                {[
                  "High School",
                  "GED",
                  "Some college",
                  "Bachelor's",
                  "Master's",
                  "PHD",
                  "Other",
                ].map((level, i) => (
                  <option key={i} value={level}>
                    {level}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={6}>
            <Form.Group>
              <Form.Label>Institution Name</Form.Label>
              <Form.Control
                name="educationLocation"
                placeholder="Institution name"
                onChange={onChange}
                value={formData.educationLocation}
                maxLength={250}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="text-end mt-3">
          <Button
            type="submit"
            variant="primary"
            style={{ width: 200 }}
            disabled={!hasChanged || save.isLoading}
          >
            {save.isLoading ? <SpinIcon /> : "Save Changes"}
          </Button>
        </div>
      </form>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default Education;
