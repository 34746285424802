import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";

import ChevronRight from "@mui/icons-material/ChevronRight";
import AddCircle from "@mui/icons-material/AddCircle";

import "./css/ButtonList.css";

const ButtonList = ({
  buttons,
  className,
  styles,
  emptyMsg,
  hover = false,
}) => {
  const addClasses = ["button-list"];

  if (className) {
    addClasses.push(className);
  }

  if (hover) {
    addClasses.push("button-list-hover");
  }

  const renderEmptyState = () => {
    if (!emptyMsg) {
      return null;
    }

    return <p className="text-center lead mt-5 mb-5">{emptyMsg}</p>;
  };

  const getIcon = (btn) => {
    if (btn.icon) {
      return btn.icon;
    }

    return btn.hasContent ? <ChevronRight /> : <AddCircle />;
  };

  const getBtnDescription = (btnDesc) => {
    if (Array.isArray(btnDesc)) {
      return btnDesc.map((desc, idx) => (
        <div className="text-muted mb-0 mt-1" index={idx} key={idx}>
          {desc}
        </div>
      ));
    }

    return <p className="text-muted mb-0">{btnDesc}</p>;
  };

  return (
    <div className={addClasses.join(" ")} style={styles || {}}>
      {buttons.length === 0
        ? renderEmptyState()
        : buttons.map((btn, i) => {
            const style = {};
            const pointer =
              btn.pointer === undefined ||
              btn.pointer === null ||
              btn.pointer === true;

            if (pointer) {
              style.cursor = "pointer";
            }

            return (
              <div
                key={i}
                className="button-list-item"
                onClick={btn.onClick}
                style={style}
              >
                {btn.render ? (
                  btn.render()
                ) : (
                  <Row>
                    <Col>
                      <h5 className="m-0 p-0">{btn.label}</h5>
                      {btn.desc ? getBtnDescription(btn.desc) : null}
                    </Col>
                    <Col className="text-end">{getIcon(btn)}</Col>
                  </Row>
                )}
              </div>
            );
          })}
    </div>
  );
};

ButtonList.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      desc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      hasContent: PropTypes.bool,
      onClick: PropTypes.func,
      render: PropTypes.func,
      icon: PropTypes.node,
      pointer: PropTypes.bool,
    })
  ).isRequired,
  emptyMsg: PropTypes.string,
  className: PropTypes.string,
  styles: PropTypes.object,
  hover: PropTypes.bool,
};

export default ButtonList;
