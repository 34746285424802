import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useUser } from "../../contexts/UserContext";

const PrivateRoute = ({ as: Component, ...props }) => {
  const { user } = useUser();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  /*
  if (!user.userStripeCustomerId) {
    navigate("/onboarding");
    return <Onboarding />;
  }
  */

  return <Component {...props} />;
};

export default PrivateRoute;
