import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Button, Alert, Row, Container } from "react-bootstrap";

import ArrowBack from "@mui/icons-material/ArrowBack";

import InternalLayout from "./InternalLayout";
import { InternalResponsiveColumn } from "./../common/Common.elements";

const SubInternalLayout = ({
  alert,
  alertMutate,
  alertMutateSuccessMsg = "Your changes were successfully saved.",
  title,
  onBack,
  children,
  className,
}) => {
  const navigate = useNavigate();

  const checkAlerts = () => {
    if (alert) {
      return (
        <Alert variant={alert.variant} className="header-error">
          {alert.msg}
        </Alert>
      );
    }

    if (alertMutate) {
      if (alertMutate.error) {
        return (
          <Alert variant="danger" className="header-error">
            {alertMutate.error.message}
          </Alert>
        );
      } else if (alertMutate.isSuccess) {
        return (
          <Alert variant="success" className="header-error">
            {alertMutateSuccessMsg}
          </Alert>
        );
      }
    }

    return null;
  };

  return (
    <InternalLayout>
      {checkAlerts()}
      <Container>
        <div className={className || ""}>
          <Button
            type="button"
            variant="link"
            onClick={() => {
              if (onBack) {
                onBack();
              } else {
                navigate(-1);
              }
            }}
            className="m-0 p-0 pt-2"
          >
            <ArrowBack />
          </Button>
          <h2 className="mt-3 mb-5">{title}</h2>

          {children}
        </div>
      </Container>
    </InternalLayout>
  );
};

SubInternalLayout.propTypes = {
  alert: PropTypes.objectOf(
    PropTypes.shape({
      msg: PropTypes.string.isRequired,
      variant: PropTypes.oneOf(["danger", "warning", "success", "info"]),
    })
  ),
  title: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default SubInternalLayout;
