import React from "react";

import StepProgress from "../common/StepProgress";
import MobileSteps from "../helper/components/MobileSteps";
import { useJob } from "../../contexts/JobContext";

const JobRequestProgressBar = ({
  stage,
  isMobile = false,
  onBack = () => {},
}) => {
  const { job } = useJob();

  const labels = job.isNewCustomer
    ? [
        <>
          Select
          <br />
          Help
        </>,
        <>
          Create
          <br />
          Account
        </>,
        <>
          Payment
          <br />
          Method
        </>,
        <>
          Confirm
          <br />
          &amp; Send
        </>,
      ]
    : [
        <>
          Select
          <br />
          Help
        </>,
        <>
          Confirm
          <br />
          &amp; Send
        </>,
      ];

  const steps = job.isNewCustomer
    ? ["Select Help", "Create Account", "Payment Method", "Confirm & Send"]
    : ["Select Help", "Confirm & Send"];
  const index = steps.indexOf(stage);

  if (isMobile) {
    return (
      <MobileSteps stepsTotal={steps.length} step={index} onBack={onBack} />
    );
  }

  return <StepProgress steps={steps} active={index} labels={labels} />;
};

export default JobRequestProgressBar;
