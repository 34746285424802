import React from "react";
import { FormGroup, FormControl, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import { useProfile } from "../../../hooks/useProfile";
import Prompt from "../../common/Prompt";

const AboutMe = () => {
  const { user, updateUser } = useUser();
  const { profileId } = useParams();
  const profile = useProfile(profileId);
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    about: get(profile, "description") || "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  return (
    <SubInternalLayout title={`About me`}>
      <Helmet>
        <title>About Me | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <p className="text-muted body1">
        What are your hobbies/interests? Another prompting sentence?
      </p>
      <Prompt when={hasChanged} />

      <FormGroup>
        <FormControl
          as="textarea"
          name="about"
          className="form-control"
          placeholder="Tell us a bit about you."
          onChange={onChange}
          value={formData.about}
          style={{ minHeight: 120 }}
          maxLength={500}
        />
      </FormGroup>
      <p className="text-end m-0 p-0">
        <strong>{formData.about.length}/500 CHAR MAX</strong>
      </p>

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() => {
            const payload = {
              "customer.profiles": [...user.customer.profiles],
            };

            // get the profile currently selected by id
            const p = payload["customer.profiles"].find(
              (pro) => pro._id === profile._id
            );

            // update the record from the spread above
            p.description = formData.about;

            save.mutate(payload);
          }}
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default AboutMe;
