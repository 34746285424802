import React from "react";
import { useApiGet } from "../../../hooks/useApi";
import { ucwords } from "../../../lib/stringHelpers";

const ServicesDisplayList = ({ selectedServices, specializedCare }) => {
  const { isLoading, data: services } = useApiGet(
    "services",
    "/services",
    null,
    {
      staleTime: 300000,
    }
  );

  const getServicesLabel = () => {
    if (isLoading || !services) {
      return "Loading...";
    }

    const list = [];

    selectedServices.forEach((offered) => {
      const service = services.find((s) => offered.service === s._id);
      if (!service) {
        return;
      }
      list.push(service.name);
    });

    return list.join(", ").toLowerCase();
  };

  if (isLoading) {
    return <div>Loading services...</div>;
  }

  return (
    <>
      <p className="text-muted">{ucwords(getServicesLabel())}</p>
      {specializedCare.length > 0 ? (
        <>
          <strong className="d-block mt-3">Specialized Care</strong>
          <p className="text-muted">{specializedCare.join(", ")}</p>
        </>
      ) : null}
    </>
  );
};

export default ServicesDisplayList;
