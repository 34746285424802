import React from "react";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import ServicesList from "../../common/ServicesList";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";
import Prompt from "../../common/Prompt";

import VehicleFormProfile from "../../common/VehicleFormProfile";

const VEHICLE_SERVICES = [
  "60c3fd06573f8b1868c89ef2",
  "6286461e68f0c5cd07ad833f",
  "6286461e68f0c5cd07ad8338",
];

const convert = (offered) => {
  const obj = {};

  offered.forEach((service) => {
    obj[service.service] = {};

    service.options.forEach((opt) => {
      obj[service.service][opt.key] = opt.value;
    });
  });

  return obj;
};

const revert = (selections) => {
  return Object.keys(selections).map((serviceId) => {
    return {
      service: serviceId,
      options: Object.keys(selections[serviceId]).map((option) => {
        return {
          key: option,
          value: selections[serviceId][option],
        };
      }),
    };
  });
};

const Services = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange, setHasChanged } = useFormData({
    servicesOffered: convert(user.helper.servicesOffered),
    specializedCare: user.helper.specializedCare || [],
    vehicleCovers: user.helper.vehicleCovers || [],
  });
  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  const onCheckToggle = (care) => {
    let sel = [...formData.specializedCare];
    if (sel.includes(care)) {
      sel = sel.filter((item) => item !== care);
    } else {
      sel.push(care);
    }
    onChange({ target: { name: "specializedCare", value: sel } });
  };

  const updateVehicles = (value) => {
    let sel = { ...formData.servicesOffered };
    if (sel.hasOwnProperty(VEHICLE_SERVICES[0])) {
      sel[VEHICLE_SERVICES[0]].accommodate = value;
      onChange({ target: { name: "servicesOffered", value: sel } });
    }
    if (sel.hasOwnProperty(VEHICLE_SERVICES[1])) {
      sel[VEHICLE_SERVICES[1]].accommodate = value;
      onChange({ target: { name: "servicesOffered", value: sel } });
    }
    if (sel.hasOwnProperty(VEHICLE_SERVICES[2])) {
      sel[VEHICLE_SERVICES[2]].accommodate = value;
      onChange({ target: { name: "servicesOffered", value: sel } });
    }
    onChange({ target: { name: "vehicleCovers", value: value } });
  };

  const showVehicleForm =
    formData.servicesOffered.hasOwnProperty(VEHICLE_SERVICES[0]) ||
    formData.servicesOffered.hasOwnProperty(VEHICLE_SERVICES[1]) ||
    formData.servicesOffered.hasOwnProperty(VEHICLE_SERVICES[2]);

  return (
    <SubInternalLayout title={`Services I'll Provide`}>
      <Helmet>
        <title>Services | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <ServicesList
        values={formData.servicesOffered}
        onChange={(updates) => {
          onChange({ target: { name: "servicesOffered", value: updates } });
        }}
      />

      {showVehicleForm ? (
        <div className="mt-5">
          <VehicleFormProfile
            currentData={formData.vehicleCovers.sort()}
            setVehicles={updateVehicles}
          />
        </div>
      ) : null}

      <h5 className="mt-5 mb-3">Specialized Care</h5>
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check
          id="dementia-care"
          type="checkbox"
          label={
            <span>
              <span
                style={{
                  display: "inline-block",
                  width: 48,
                  textAlign: "center",
                  marginRight: "1rem",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_AWS_BUCKET_URL}/icons/Dementia_48px.svg`}
                  alt="Dementia Care"
                  title="Dementia Care"
                />
              </span>
              Dementia Care
            </span>
          }
          value="Dementia Care"
          checked={formData.specializedCare.includes("Dementia Care")}
          size="lg"
          onChange={(e) => onCheckToggle("Dementia Care")}
        />
      </Form.Group>

      <div className="text-end mb-5">
        <Button
          variant="primary"
          onClick={() => {
            const payload = {
              "helper.servicesOffered": revert(formData.servicesOffered),
              "helper.specializedCare": formData.specializedCare,
              "helper.vehicleCovers": formData.vehicleCovers,
            };

            save.mutate(payload);
          }}
          disabled={!hasChanged}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>
    </SubInternalLayout>
  );
};

export default Services;
