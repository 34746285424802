import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import JoinMailingList from "../common/JoinMailingList";
import { useApiPost } from "../../hooks/useApi";
import { useFormData } from "../../hooks/useFormData";
import { useJob } from "../../contexts/JobContext";
import { SetupTitle } from "../common/Title.elements";

/*
  STEP 1
*/
const JobRequestArea = () => {
  const { user } = useUser();
  const { setTitle } = usePage();
  const { job, saveJob } = useJob();
  const { formData, onChange } = useFormData({
    zip: "",
    serviceArea: null,
  });
  const navigate = useNavigate();
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  useEffect(() => {
    // setTitle("Let's make sure Manana is available in your area.");
    setTitle(
      "Manana is not currently accepting help requests. Join our mailing list to be notified of when we're available!"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkZip = useApiPost("/service-area/check-zip", (res) => {
    saveJob({
      isNewCustomer: user === null,
      serviceArea: res,
      zip: formData.zip,
    });
    navigate("/find-help/services");
  });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const zip = query.get("zip");

    if (zip) {
      onChange({ target: { name: "zip", value: zip } });
      checkZip.mutate({ zip });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    checkZip.mutate({ ...formData });
  };

  const content = checkZip.isError ? (
    <JoinMailingList
      userType="customer"
      additionalData={{
        zip: formData.zip,
      }}
      message={null}
    />
  ) : (
    <form onSubmit={onSubmit} style={{ padding: "0 2rem 0 2rem" }}>
      <Form.Group>
        <SetupTitle>Where do you need help?</SetupTitle>
        <Form.Control
          type="text"
          size="lg"
          maxLength={5}
          name="zip"
          placeholder="Zip code"
          style={{ width: 150 }}
          value={formData.zip}
          onChange={onChange}
          required
        />
      </Form.Group>

      <hr />

      <div>
        <Button
          type="submit"
          variant="primary"
          className="w-100"
          style={{ fontFamily: "Noto Sans" }}
          disabled={formData.zip.length < 5 || checkZip.isLoading}
        >
          {checkZip.isLoading ? "..." : "NEXT"}
        </Button>
      </div>
    </form>
  );

  return (
    <RegisterLayout
      title={
        checkZip.isError
          ? "Be the first to know!"
          : "Let's make sure Manana is available in your area."
      }
      titleStyle={{ marginTop: "10%" }}
      steps={null}
      totalSteps={stepsTotal}
      activeStep={0}
      onStepBack={() => navigate("/")}
    >
      <Helmet>
        <title>Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestArea;
