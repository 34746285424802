import React, { createContext, useContext, useState } from "react";
import set from "lodash.set";

const AdminContext = createContext();

const AdminProvider = ({ children }) => {
  const [options, setOptions] = useState({
    filters: {
      search: "",
      userType: "",
      zipCode: "",
      zipRadius: "",
      page: 1,
      sortBy: "name",
      sortDir: 1,
    },
  });

  const update = (key, value) => {
    const newOpts = { ...options };
    set(newOpts, key, value);
    setOptions(newOpts);
  };

  return (
    <AdminContext.Provider value={{ options, update, setOptions }}>
      {children}
    </AdminContext.Provider>
  );
};

const useAdmin = () => {
  const context = useContext(AdminContext);
  if (context === undefined) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
};

export { AdminProvider, useAdmin };
