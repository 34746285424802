import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";
import Close from "@mui/icons-material/Close";

import { useUser } from "../../../../contexts/UserContext";
import SubInternalLayout from "../../../layout/SubInternalLayout";
import { useFormData } from "../../../../hooks/useFormData";
import { useApiPut } from "../../../../hooks/useApi";
import { useProfile } from "../../../../hooks/useProfile";
import { getProfileUpdatePayload } from "../../../../lib/profileHelpers";
import Prompt from "../../../common/Prompt";

import HealthConditionsSelect from "./HealthConditionsSelect";

const HealthConditions = () => {
  const { profileId } = useParams();
  const profile = useProfile(profileId);
  const { user, updateUser } = useUser();
  const navigate = useNavigate();
  const { formData, onChange, hasChanged, setHasChanged } = useFormData({
    conditions:
      profile.healthInformation && profile.healthInformation.healthConditions
        ? profile.healthInformation.healthConditions.conditions.map((c) => {
            return { value: c, label: c };
          })
        : [],
    notes: get(profile, "healthInformation.healthConditions.notes", ""),
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
    setHasChanged(false);
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const healthInformation = profile.healthInformation || {};
    const conditions = formData.conditions
      ? formData.conditions.map((c) => c.value)
      : [];

    const payload = getProfileUpdatePayload(user, profileId, {
      healthInformation: {
        ...healthInformation,
        healthConditions: { conditions, notes: formData.notes },
      },
    });
    save.mutate(payload);
  };

  return (
    <SubInternalLayout title="Health Conditions &amp; Diseases">
      <Helmet>
        <title>Health Conditions | Health Information | Profile | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Prompt when={hasChanged} />
      <form onSubmit={onSubmit}>
        <div className="mb-5">
          <HealthConditionsSelect
            value={null}
            onChange={(val) => {
              const item = formData.conditions.find(
                (v) => v.value === val.value
              );
              if (item) {
                return;
              }

              const newList = [...formData.conditions, val];
              onChange({ target: { name: "conditions", value: newList } });
            }}
          />
        </div>

        <div className="mb-5">
          {formData.conditions.map((c, cIndex) => {
            return (
              <div key={cIndex} className="field-list-item mt-4">
                <div className="float-end">
                  <Button
                    variant="link"
                    onClick={() => {
                      const newList = [...formData.conditions].filter(
                        (con) => con.value !== c.value
                      );
                      onChange({
                        target: { name: "conditions", value: newList },
                      });
                    }}
                  >
                    <Close />
                  </Button>
                </div>
                <span className="subtitle1">{c.label}</span>
              </div>
            );
          })}
        </div>

        <h3>Additional notes</h3>
        <Form.Control
          as="textarea"
          name="notes"
          onChange={onChange}
          placeholder="Add any additional information a helper should know."
          value={formData.notes}
          className="mb-3 mt-3"
          style={{ minHeight: "150px" }}
        />
        <Row>
          <Col>
            <Button variant="link" onClick={() => navigate(-1)}>
              CANCEL
            </Button>
          </Col>
          <Col className="text-end">
            <Button
              type="primary"
              variant="primary"
              disabled={!hasChanged}
              style={{ minWidth: "200px" }}
            >
              SAVE
            </Button>
          </Col>
        </Row>
      </form>
      <br />
      <br />
      <br />
    </SubInternalLayout>
  );
};

export default HealthConditions;
