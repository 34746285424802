/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";

import { InternalResponsiveColumn } from "../common/Common.elements";
import { useApiGet } from "../../hooks/useApi";
import Loading from "../common/Loading";
import RawUploadButton from "../common/RawUploadButton";

import logo from "../../logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "../layout/PrimaryLayout.css";

const StyledUploadWrapper = styled.div`
  width: 100%;
  border: 2px dashed #ccc;
  background: #fdfdfd;
  padding: 6px;
  text-align: center;
  margin-bottom: 1rem;

  & > div {
    margin: 2rem 1rem;
  }

  & > img {
    width: 100%;
  }
`;

const StyledHeader = styled.div`
  width: 100%;
  margin: 0;
  padding: 1.25rem;
  text-align: center;
  background: rgb(255, 226, 215);
`;

const StripeQRScreen = () => {
  const { qrCodeToken } = useParams();
  const [images, setImages] = useState({ front: null, back: null });
  const { isLoading, data } = useApiGet(
    "user-qr-code",
    `/qr-code/${qrCodeToken}`,
    null,
    {
      staleTime: 5000,
      onError: (err) => toast.error(err.message),
    }
  );

  const render = () => {
    if (isLoading) {
      return <Loading msg="Validating token..." />;
    }

    if (images.front && images.back) {
      return (
        <p>
          You have successfully submitted your identity verification document
          images. You may not close this tab, browser or page.
        </p>
      );
    }

    return (
      <div>
        <p className="fw-bold">
          Please upload a picture of the front and back of a government issued
          ID.
        </p>
        <Row>
          <Col xs={12} sm={4}>
            <StyledUploadWrapper>
              {images.front ? (
                <img src={images.front} title="Front Image" alt="Front Image" />
              ) : (
                <div>
                  <RawUploadButton
                    btnLabel="Upload Front Image"
                    endpoint={`${process.env.REACT_APP_API_URL}/users/stripe-documents/front`}
                    endpointHeaders={{
                      authorization: `Bearer ${data._token}`,
                    }}
                    onUpload={(res) => {
                      setImages({ ...images, front: res.file.preview });
                    }}
                    cropperOpts={{ viewMode: 1 }}
                    imgQuality={0.5}
                  />
                </div>
              )}
            </StyledUploadWrapper>
          </Col>
          <Col xs={12} sm={4}>
            <StyledUploadWrapper>
              {images.back ? (
                <img src={images.back} title="Back Image" alt="Back Image" />
              ) : (
                <div>
                  <RawUploadButton
                    btnLabel="Upload Back Image"
                    endpoint={`${process.env.REACT_APP_API_URL}/users/stripe-documents/back`}
                    endpointHeaders={{
                      authorization: `Bearer ${data._token}`,
                    }}
                    onUpload={(res) => {
                      setImages({ ...images, back: res.file.preview });
                    }}
                    cropperOpts={{ viewMode: 1 }}
                    imgQuality={0.5}
                  />
                </div>
              )}
            </StyledUploadWrapper>
          </Col>
        </Row>
        <hr />
      </div>
    );
  };

  return (
    <>
      <StyledHeader>
        <img src={logo} title="manana" alt="manana" style={{ maxWidth: 155 }} />
      </StyledHeader>
      <Container style={{ minHeight: "90vh", background: "#fff" }}>
        <Row>
          <InternalResponsiveColumn>
            <h2 className="mb-5 text-center">Identity Verification</h2>
            {render()}
          </InternalResponsiveColumn>
        </Row>
      </Container>
    </>
  );
};

export default StripeQRScreen;
