import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useUser } from "../../contexts/UserContext";
import InternalLayout from "../layout/InternalLayout";
import ButtonList from "../common/ButtonList";
import StripeHeaderNotice from "../common/StripeHeaderNotice";

const Account = () => {
  const { user } = useUser();
  const navigate = useNavigate();

  return (
    <InternalLayout>
      <Helmet>
        <title>Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <Container>
        <h2 className="mb-5">Account</h2>

        <StripeHeaderNotice />

        <ButtonList
          className="mb-5"
          buttons={
            user.helper
              ? [
                  {
                    label: "Account Settings",
                    hasContent: true,
                    onClick: () => navigate("/account/settings"),
                  },
                  {
                    label: "Payment Settings",
                    hasContent: true,
                    onClick: () => navigate("/account/payment-settings"),
                  },
                  /*{
                        label: "Manana Documents",
                        hasContent: true,
                        onClick: () => alert("Clicked!"),
                      },*/
                  {
                    label: "Tax Info",
                    hasContent: true,
                    onClick: () => navigate("/account/tax-info"),
                  },
                  {
                    label: "Notification Settings",
                    hasContent: true,
                    onClick: () =>
                      navigate("/account/helper-notification-settings"),
                  },
                ]
              : [
                  {
                    label: "Personal Info",
                    hasContent: true,
                    onClick: () => navigate("/account/personal-info"),
                  },
                  /*{
                        label: "Favorites",
                        hasContent: true,
                        onClick: () => navigate("/account/favorites"),
                      },*/
                  {
                    label: "Payment Settings",
                    hasContent: true,
                    onClick: () =>
                      navigate("/account/recipient-payment-settings"),
                  },
                  {
                    label: "Notification Settings",
                    hasContent: true,
                    onClick: () => navigate("/account/notification-settings"),
                  },
                ]
          }
        />
      </Container>
    </InternalLayout>
  );
};

export default Account;
