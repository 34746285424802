import styled from "styled-components";

export const ResponsiveBtnGroup = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 20px;
  }
`;

export const ResendBtn = styled.button`
  padding: 15px 15px 15px 15px;
  color: #205b68 !important;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  background-color: transparent;
`