import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { useUser } from "../../contexts/UserContext";
import {
  InternalPageContent,
  ContentNav,
  ContentBody,
} from "./components/Internal/Internal.elements";
import Footer from "./components/Footer";

import logo from "../../logo.png";

const AdminLayout = ({ children }) => {
  const { signOut } = useUser();

  return (
    <>
      <InternalPageContent>
        <ContentNav>
          <div style={{ padding: "13px 2.5rem", marginTop: "1rem" }}>
            <img
              src={logo}
              title="manana"
              alt="manana"
              style={{ maxWidth: 200, margin: "auto" }}
            />
          </div>

          <hr />

          <div className="link-item">
            <Link to="/admin">Users</Link>
          </div>
          <div className="link-item">
            <Link to="/admin/bookings" className="d-block">
              Calendar
            </Link>
          </div>

          <hr />

          <div style={{ padding: "13px 2.5rem" }}>
            <Button
              variant="outline-primary"
              onClick={() => signOut()}
              className="w-100"
            >
              Sign Out
            </Button>
          </div>
        </ContentNav>
        <ContentBody maxWidth="100%">{children}</ContentBody>
      </InternalPageContent>
      <Footer />
    </>
  );
};

export default AdminLayout;
