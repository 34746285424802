import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { useUser } from "../../contexts/UserContext";
import { usePage } from "../../contexts/PageContext";
import RegisterLayout from "../layout/RegisterLayout";
import { useFormData } from "../../hooks/useFormData";
import { useApiPost } from "../../hooks/useApi";
import { useJob } from "../../contexts/JobContext";
import { HideOnMobile } from "../helper/components/NewHelper.elements";
import { ResponsiveBtnGroup, ResendBtn } from "../common/Responsive.elements";
import { PaddedForm } from "../common/PaddedForm";

/*
  STEP 5
*/
const JobRequestRegisterVerify = () => {
  const codeRef = useRef();
  const { job, saveJob } = useJob();
  const { user, verificationToken, verified } = useUser();
  const { title, setTitle } = usePage();
  const { formData, onChange } = useFormData({
    code: "",
  });
  const [resendBtnDisabled, setResendBtnDisabled] = useState(true);
  const [showResendAlert, setShowResendAlert] = useState(false);
  const navigate = useNavigate();
  const stepsTotal = job.isNewCustomer ? 4 : 2;

  useEffect(() => {
    codeRef.current.focus();

    if (!job.serviceArea) {
      navigate("/find-help");
    }
    disableResendBtn();
    setTitle(
      "We've sent you a verification link to the email address provided."
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApi = useApiPost(`/register/verify`, (res) => {
    verified(res);
    saveJob({ customer: res._id });
    navigate("/find-help/profile");
  });

  const resendAutorizationCode = useApiPost(`/register/resend-verification`);

  const onSubmit = (e) => {
    e.preventDefault();
    callApi.mutate({ token: verificationToken, code: formData.code });
  };

  const disableResendBtn = () => {
    setResendBtnDisabled(true);
    setTimeout(() => {
      setResendBtnDisabled(false);
    }, 1000 * 10); // 10 seconds;
  };

  const onResendCode = (e) => {
    setShowResendAlert(true);
    disableResendBtn();
    resendAutorizationCode({ email: user.email });
  };

  const content = (
    <PaddedForm onSubmit={onSubmit}>
      <HideOnMobile>
        <Button
          type="button"
          variant="link"
          onClick={() => navigate(-1)}
          style={{ margin: 0, marginBottom: "2rem", padding: 0 }}
          disabled={true}
        >
          <ArrowBack />
        </Button>
      </HideOnMobile>

      {showResendAlert && (
        <Alert variant="success" className="header-error">
          A new verification code has been sent to your email.
        </Alert>
      )}

      <h2 className="mb-3">Enter your verification code to continue.</h2>
      <p className="text-muted mb-4">
        A verification code was sent to the email address you provided on the
        previous screen.
      </p>
      {callApi.isError ? (
        <Alert variant="danger">{callApi.error.message}</Alert>
      ) : null}
      <Form.Group>
        <Form.Label>Verification Code</Form.Label>
        <Form.Control
          type="text"
          name="code"
          size="lg"
          onChange={onChange}
          placeholder="Verification Code"
          ref={codeRef}
          required
        />
      </Form.Group>

      <ResponsiveBtnGroup>
        <ResendBtn
          type="button"
          onClick={() => onResendCode()}
          disabled={resendBtnDisabled}
        >
          RESEND CODE
        </ResendBtn>
        <Button
          type="submit"
          variant="primary"
          style={{ minWidth: 200, fontFamily: "Noto Sans" }}
          disabled={formData.code.length < 6 || callApi.isLoading}
        >
          NEXT
        </Button>
      </ResponsiveBtnGroup>
    </PaddedForm>
  );

  return (
    <RegisterLayout
      title={title}
      isJobRequest={true}
      totalSteps={stepsTotal}
      activeStep={"Create Account"}
      onStepBack={() => navigate("/find-help/register")}
    >
      <Helmet>
        <title>Verify Your Email | Find Help | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      {content}
    </RegisterLayout>
  );
};

export default JobRequestRegisterVerify;
