import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { HMSRoomProvider } from "@100mslive/react-sdk";
import { HelmetProvider } from "react-helmet-async";

import { UserProvider } from "./contexts/UserContext";
import { PageProvider } from "./contexts/PageContext";
import { JobProvider } from "./contexts/JobContext";
import { AdminProvider } from "./contexts/AdminContext";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import ErrorFallback from "./components/errorBoundary/ErrorFallback";
import MainRouter from "./components/routers/MainRouter";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient();

function App() {
  return (
    <UserProvider>
      <HMSRoomProvider>
        <PageProvider>
          <JobProvider>
            <AdminProvider>
              <QueryClientProvider client={queryClient}>
                <HelmetProvider>
                  <ErrorBoundary fallback={<ErrorFallback />}>
                    <MainRouter />
                  </ErrorBoundary>
                  <ToastContainer />
                </HelmetProvider>
              </QueryClientProvider>
            </AdminProvider>
          </JobProvider>
        </PageProvider>
      </HMSRoomProvider>
    </UserProvider>
  );
}

export default App;
