import React from "react";
import PropTypes from "prop-types";
import PersonOutline from "@mui/icons-material/PersonOutline";

const ProfilePicture = ({ user, size = "sm", style = {} }) => {
  if (!user || (!user.customer && !user.helper)) {
    // system message
    return (
      <div className={`profile-photo-${size}`} style={style}>
        <PersonOutline />
      </div>
    );
  }

  if (user.customer && !user.customer.profile) {
    const sub = user.customer.profiles.find((p) => p.relationship === "_self");
    user.customer.profile = sub ? sub : user.customer.profiles[0];
  }

  const info = user.customer || user.helper;

  if (!info.profile || !info.profile.profilePicture) {
    return (
      <div className={`profile-photo-${size}`} style={style}>
        <PersonOutline />
      </div>
    );
  }

  return (
    <img
      src={info.profile.profilePicture}
      alt={user.name}
      title={user.name}
      className={`profile-photo-${size}`}
      style={style}
    />
  );
};

ProfilePicture.propTypes = {
  user: PropTypes.object,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  style: PropTypes.object,
};

export default ProfilePicture;
