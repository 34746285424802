import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";

import NavDropdown from "./NavDropdown";

import logo from "../../../../logo.png";

const NavLink = styled(Link)`
  display: inline-block;
  font-family: "Noto Sans";
  font-style: regular;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.78px;
  margin-left: 1.5em;
  text-decoration: none;
`;

const NavButton = styled(Button)`
  display: inline-block;
  font-family: "Noto Sans";
  font-style: regular;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1.78px;
  margin-left: 1.5em;
  text-decoration: none;
`;

const getUrl = () => {
  if (window.location.hostname === "app.mananahelp.com") {
    return "https://www.mananahelp.com";
  }

  return "";
};

const Desktop = ({ user, signOut, links, showLogo = true }) => {
  return (
    <div className="desktop-navigation">
      <Row>
        <Col sm={4} className="text-start">
          {showLogo ? (
            <Link to={`${getUrl()}/`}>
              <img
                src={logo}
                alt="manana"
                title="manana"
                style={{ maxWidth: 225, marginTop: 6 }}
              />
            </Link>
          ) : null}
        </Col>
        <Col>
          {links.links.map((link, linkIdx) => {
            return (
              <NavLink
                key={linkIdx}
                to={link.url}
                onClick={() => {
                  if (typeof link.onClick === "function") {
                    link.onClick();
                  }
                }}
                className={link.className || ""}
              >
                {link.label.toUpperCase()}
              </NavLink>
            );
          })}
          <NavDropdown signOut={signOut} links={links.dropdown} />
        </Col>
      </Row>
    </div>
  );
};

export default Desktop;
