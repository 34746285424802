import React from "react";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import styled from "styled-components";

import { ucwords } from "../../../lib/stringHelpers";

const Wrapper = styled.div`
  & tr {
    cursor: pointer;
  }
`;

const JobRequestList = ({ jobs }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Table>
        <thead></thead>
        <tbody>
          {jobs.map((job, jobIdx) => {
            return (
              <tr key={jobIdx} onClick={() => navigate(`/bookings/${job._id}`)}>
                <td>
                  <label>Status</label>
                  <p>{ucwords(job.jobStatus)}</p>
                </td>
                <td>
                  <label>Date</label>
                  <p>{moment(job._nextDate).format("MM/DD/YYYY")}</p>
                </td>
                <td>
                  <label>Type</label>
                  <p>{job.jobType[0]}</p>
                </td>
                <td>
                  <label>Hours</label>
                  <p>{job.hours}</p>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default JobRequestList;
