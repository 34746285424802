import React from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import get from "lodash.get";
import { Helmet } from "react-helmet-async";
import Texture from "@mui/icons-material/Texture";

import { useUser } from "../../../contexts/UserContext";
import SubInternalLayout from "../../layout/SubInternalLayout";
import { useFormData } from "../../../hooks/useFormData";
import { useApiPut } from "../../../hooks/useApi";
import SpinIcon from "../../common/SpinIcon";

const TaxInfo = () => {
  const { user, updateUser } = useUser();
  const { formData, hasChanged, onChange } = useFormData({
    taxId: get(user, "helper.taxId") || "",
  });

  const save = useApiPut("/users/profile", (res) => {
    toast.success("Your changes have been successfully saved.");
    updateUser(res);
  });

  return (
    <SubInternalLayout title={`Tax Info`}>
      <Helmet>
        <title>Tax Info | Account | Manana</title>
        <meta name="description" content="Manana Help" />
      </Helmet>
      <div className="field-list-item">
        <Form.Group>
          <Form.Control
            type="text"
            name="taxId"
            placeholder="Tax ID or Social Security Number"
            onChange={onChange}
            value={formData.taxId}
          />
        </Form.Group>

        <Link
          to=""
          style={{
            display: "block",
            margin: "1.5rem 0",
            textDecoration: "none",
            fontWeight: 600,
            fontSize: "1.15rem",
          }}
        >
          <Texture /> DOWNLOAD MY 1099
        </Link>
        <Link
          to=""
          style={{
            display: "block",
            margin: "1.5rem 0",
            textDecoration: "none",
            fontWeight: 600,
            fontSize: "1.15rem",
          }}
        >
          <Texture /> DOWNLOAD MY PROOF OF INCOME
        </Link>
      </div>

      <div className="text-end mt-3">
        <Button
          type="button"
          onClick={() =>
            save.mutate({
              "helper.taxId": formData.taxId,
            })
          }
          disabled={!hasChanged || save.isLoading}
        >
          {save.isLoading ? <SpinIcon /> : "Save Changes"}
        </Button>
      </div>

      <br />
      <br />
    </SubInternalLayout>
  );
};

export default TaxInfo;
