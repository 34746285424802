import styled from "styled-components";

export const MobileLoadingMessage = styled.div`
  @media (max-width: 768px) {
    height: 150px;
    background-color: white;
    padding: 25px;
    font-size: 22px;

  }
`